import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import * as documentsAPIs from '../../../../shared/documentsApis';


// config file
export default class Sellers extends React.Component {
  constructor(props) {
    super(props);
    // let filter = localStorage.getItem("sellerFilter")
    this.state = {
      type: 'Sellers',
      tabType: 'Deal Mgmt',
      apiUrl: apiCalls.Seller,
      // sellerFilter: filter,
      responseKey: "sellers",
      names:[],
      relSellerNames:[],
      stateClaimIds:[]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {   
    window.addEventListener('beforeunload', this.clearStoredValues);
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions["Deal Mgmt"] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }

    // await this.getApaType()
    // let filter = JSON.parse(localStorage.getItem("sellerFilter"))
    // await this.setState({
    //   sellerFilter: filter
    // })
  }
  clearStoredValues = () => {    
      if(sessionStorage.getItem(documentsAPIs.DOC_UPLOAD_TAB_NAME)){        
        sessionStorage.removeItem(documentsAPIs.DOC_UPLOAD_TAB_NAME);
      }
      if(sessionStorage.getItem(documentsAPIs.AVAIL_DOCS_TAB_NAME)){
        sessionStorage.removeItem(documentsAPIs.AVAIL_DOCS_TAB_NAME);
      }
  }
  componentWillUnmount() {
    this.clearStoredValues();
    window.removeEventListener('beforeunload', this.clearStoredValues); // remove the event handler for normal unmounting
}

  // getApaType = async () => {
  //   let ApaTypeArray = [];
  //   let filterCriteria = {}
  //   filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
  //   fetchMethodRequest('GET', `apaTypeConfiguration?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
  //     if (response && response["apaTypeConfigurations"] && response["apaTypeConfigurations"].length > 0) {
  //       let apaTypeConfigurations = response["apaTypeConfigurations"]
  //       for (let apaTypeConfiguration of apaTypeConfigurations) {
  //         ApaTypeArray.push({ label: apaTypeConfiguration.apaTypeID + "-" + apaTypeConfiguration.apaTypeName, value: apaTypeConfiguration.apaTypeID })
  //       }
  //       this.setState({ ApaTypeArray: ApaTypeArray })
  //     }
  //   })
  //   return this.state.ApaTypeArray;
  // }

  getSellerTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": true,
        "displayInSettings": true,
        "sortable": false
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "DealID",
        "filterType": "num",
        "mobile": false,
        "header": "Deal ID",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "ScoringStatus",
        "mobile": false,
        "header": "Scoring Status",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "EntityName",
        "mobile": false,
        "header": "Entity Name",
        "filter": true,
        "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "DealType",
        "mobile": false,
        "header": "Deal Type",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "APAType",
        "mobile": false,
        "header": "APA Type",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "CaseNumber",
      //   "mobile": false,
      //   "header": "Case Number",
      //   "filter": true,
      //   // "fieldType": 'Badge',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CaseStatus",
        "mobile": false,
        "header": "Case Status",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "District",
      //   "mobile": false,
      //   "header": "District",
      //   "filter": true,
      //   // "fieldType": 'Badge',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Division",
        "mobile": false,
        "header": "Division",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 170,
      //   "field": "BankruptcyChapter",
      //   "mobile": false,
      //   "header": "Bankruptcy Chapter",
      //   "filter": true,
      //   // "fieldType": 'Badge',
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "OPPSalesRep",
        "mobile": false,
        "header": "OPP Sourcing Lead",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      // {

      //   "show": true,
      //   "textAlign": "left",
      //   "width": 190,
      //   "field": "BankruptcyFilingDate",
      //   "fieldType": "Date",
      //   "filterType": "num",
      //   "dateFormat": 'MM-DD-yyyy',
      //   "mobile": true,
      //   "header": "Bankruptcy Filing Date",
      //   "filter": true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      {

        "show": true,
        "textAlign": "left",
        "width": 160,
        "filterType": "num",
        "field": "ClosingDate",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Closing Date",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {

        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "FinalDecreeDate",
        "fieldType": "Date",
        "filterType": "num",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Final Decree Date",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {

        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "DWDateInserted",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "DW Date Inserted",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "DWLastModifiedBy",
        "mobile": false,
        "header": "DW Last Modified By",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {

        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "DWDateLastModified",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "DW Date Last Modified",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },

    ]
    return data;
  };

  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let responseKey = this.state.responseKey;
      if (tabType === 'Deal Mgmt') {
        apiUrl = apiCalls.Seller;
        responseKey = "sellers"
      } else if (tabType === 'Deal Sizing') {
        apiUrl = apiCalls.Dealsizing;
        responseKey = "ns_scoring_trackers"
      } else {
        apiUrl = apiCalls.Seller;
        responseKey = "sellers"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey
      })
    }

    let data = [];
    if (tabType === 'Deal Mgmt') {
      data = this.getSellerTableFields();
      let index = data.findIndex(a => a.selectionMode === "multiple")
      if (index === -1 && this.state.readOnly) {
        let insertField = {
          textAlign: "center",
          width: 47,
          field: "",
          header: "",
          selectionMode: "multiple",
          show: true,
          mobile: true,
          displayInSettings: false,
        }
        data.unshift(insertField);
      } else {
        if (index !== -1 && !this.state.readOnly) {
          data.splice(index, 1)
        }
      }
    } else if (tabType === 'Deal Sizing') {
      data = this.getDealSizedTableFields();
    } else {
      data = this.getSellerTableFields();

      let index = data.findIndex(a => a.selectionMode === "multiple")
      if (index === -1 && this.state.readOnly) {

        let insertField = {
          textAlign: "center",
          width: 47,
          field: "",
          header: "",
          selectionMode: "multiple",
          show: true,
          mobile: true,
          displayInSettings: false,
        }
        data.unshift(insertField);
      } else {
        if (index !== -1 && !this.state.readOnly) {
          data.splice(index, 1)
        }
      }
    }
    return data;
  };
  getDealSizedTableFields = () => {
    let data = [
      {
        "show": true,
        "mobile": true,
        "textAlign": 'left',
        "width": 60,
        "fieldType": 'expander',
        "header": "expander"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "estateID",
        "filterType": "num",
        "mobile": true,
        "header": "Seller Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Seller Name",
        "mobile": true,
        "field": "estateName",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "scoringPropertyCountNonTeal",
        "mobile": true,
        "filterType": "num",
        "header": "Property Count NonTeal",
        "allowInt": true,
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "filterType": "num",
        "field": "scoringPropertyCountTeal",
        "mobile": true,
        "header": "PropertyCount Teal",
        "allowInt": true,
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "filterType": "num",
        "field": "totalCountInsertedIntoBatchHistory",
        "mobile": true,
        "allowInt": true,
        "header": "total Inserted Into History",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "header": "Status",
        "field": "scoringStatus",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "scoringDateTime",
        "mobile": true,
        "fieldType": "Date",
        "filterType": "num",
        "dateFormat": 'MM/DD/YYYY',
        "header": "Start Date",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Completion Date",
        "mobile": true,
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "field": "scoringEndTime",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Scoring Type",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "Date",
        // "dateFormat": 'MM-DD-yyyy',
        "field": "scoringType",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

    ]
    return data;
  };

  getFormFields = () => {

    return
  }



  submit = async (item) => {
  }
  
  setDocumentsDropdownsData = (field, data) => {    
    this.setState({ [field]: data });    
  }
 

  render() {

    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={this.state.tabType === "Deal Mgmt" ? true : false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          fontAwesome={this.state.tabType === "Deal Mgmt" ? true : false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          globalSearch={this.state.tabType === "Deal Mgmt" ? "Entity Name" : 'Estate Name'}
          type={this.state.type}
          ApaTypeArray={this.state.ApaTypeArray ? this.state.ApaTypeArray : []}
          displayright={true}
          icon={this.state.tabType === "Deal Mgmt" ? "users" : "line-spacing"}
          sortField={this.state.tabType === "Deal Mgmt" ? "" : "scoringDateTime"}
          // activeTab={"Deal Mgmt"}
          isSelectMode={this.state.tabType === "Deal Mgmt" ? "multiple" : ""}
          dataKey={this.state.tabType === "Deal Mgmt" ? "DealID" : "id"}//for data key on selection
          isSql={this.state.tabType === "Deal Mgmt" ? "yes" : "no"}
          // tabType={this.state.readOnly ? true : false}
          // tabOptions={["Deal Mgmt"]}
          routeTo='sellers'
          displayViewOfForm='modal'
          apiResponseKey={this.state.responseKey}
          className={true}
          apiUrl={this.state.apiUrl}

          // apiUrl={this.state.activeTab === "Deal Mgmt" && this.state.sellerFilter ? this.state.apiUrl + this.state.sellerFilter : this.state.apiUrl}
          entityType='employee'
          names={this.state.names}
          relSellerNames={this.state.relSellerNames}
          stateClaimIds={this.state.stateClaimIds}
          setDocumentsDropdownsData={this.setDocumentsDropdownsData}

        />

      </span>
    );
  }
}