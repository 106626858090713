import React, { useEffect, useState } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'reactstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DataTables from '../CommonDataTable/DataTable';
import fetchMethodRequest from "../../../config/service";
import TableFields from './MailForwardsAssignmentFields.json';
import {Dropdown} from 'primereact/dropdown';
import Loader from '../../App/Loader';


const MailForwardsAssignment = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]); 
    const [updateData, setUpdateData] = useState<any>({});
    const [users, setUsers] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>({});

    const getTableFields = () => {        
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        if(activeTab === 2 || activeTab === 4){           
            tableFields.shift();
            tableFields.push( {
                "show": true,
                "textAlign": "left",
                "width": 200,
                "field": "AssignedTo",
                "mobile": true,
                "header": "Assigned To",
                "displayInSettings": true,        
                "filter": true,
                "sortable": true
            })
        }  
        tableFields.splice(1, 0,{
            "show": true,
            "textAlign": "left",
            "width": 90,
            "field": "MailFwdTrackingID",
            "mobile": true,
            "header": "Mail Forward ID",
            "displayInSettings": true,
            "filter": true,
            "sortable": true,
            "fieldType": "customActions",
            "getCustomActions": redirectToMailForwardDetails
        });
        for(let i=0;i<tableFields.length;i++){
            if(tableFields[i].field === 'MailFwdAtUSPSExpiredFlag'){
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = showUSPSEpiredColumn;
                break;
            }
        }      
        return tableFields;
    }

    const showUSPSEpiredColumn = (row:any) => {
        return <span>{row.MailFwdAtUSPSExpiredFlag ? 'Yes' : 'No'}</span>
    }

    const redirectToMailForwardDetails = (col:any) => {
        let reqData ={
            MailFwdTrackingID:col.MailFwdTrackingID,
            CompanyID:col.CompanyID,
            CompanyName:col.CompanyName,
            Line1:col.Line1,
            City:col.City,
            Zip:col.Zip,            
            BusinessLocationID:col.BusinessLocationID,
            LocationNameLabel:col.LocationNameLabel,
            StateProvince:col.StateProvince    
        }
        return <div className="cursor-pointer text-info" onClick={() => window.open(`/mailForwardsDetails?mailFwdtrackDetails=${JSON.stringify(reqData)}`, '_blank', 'noopener=true')} >
           {col.MailFwdTrackingID}
        </div>
    }

    const onSelectRows = (selectedRows:Array<any>) => {       
        setSelectedRows(selectedRows);        
    }

    useEffect(() => {
        getMailFwdStatuses();
        getUsers();    
    }, []);

    const getUsers = async () => {
        const res = await fetchMethodRequest('GET', 'users?filters: {"limit":1000,"page":1,"criteria":[{"key":"role","value":["Search Manager","Search Analyst"],"type":"in","add_Filter":false},{"key":"email","value":["raj@oprausers.onmicrosoft.com","hilloni@oprausers.onmicrosoft.com","hemang@oprausers.onmicrosoft.com"],"type":"in","add_Filter":false}],"sortfield":"created","direction":"desc"}', '', '', '', '');
        if (res && res.users && res.users.length > 0) {            
            setUsers(res.users.filter((e:any) => e.display_name.includes('Integrity') || ["raj@oprausers.onmicrosoft.com","hilloni@oprausers.onmicrosoft.com","hemang@oprausers.onmicrosoft.com"].includes(e.email)));
        } 
    }

    const getMailFwdStatuses = async () => {
        const res = await fetchMethodRequest('GET', `mftStatusAndReasons/getAllStatusAndReasons`, '', '', '', '');
        if (res && res.mftAllStatusAndReasons && res.mftAllStatusAndReasons.length > 0) {
            const filtersData = res.mftAllStatusAndReasons.filter((e: any) => e.screenTypeForStatus.includes("Mail Forwards Assignment"));
            setMftStatuses(filtersData);
            if (filtersData.length === 1) {
                onChangeInputValues('mailFwdStatus', filtersData[0]);                
            }
        }
    }

    const onChangeInputValues = (field: string, value: any) => {              
        setUpdateData((prevDetails: any) => ({ ...prevDetails, [field]: value }));        
        if(field === 'mailFwdStatus' && value && value.mailFwdReasons){ 
            const filteredReasons = value.mailFwdReasons.filter((e:any) => e.screenTypeForReason.includes("Mail Forwards Assignment"));       
            setMftReasons(filteredReasons);
            if(updateData.mailFwdReason || filteredReasons.length === 1){
                onChangeInputValues('mailFwdReason',filteredReasons.length === 1 ? filteredReasons[0] : '');
            }
        }       
    }

    const doBulkUpdate = async () => {
        setIsLoading(true);
        setDoRefresh(false);        
        let body = {
            mftIDs: selectedRows.map((e: any) => e.MailFwdTrackingID),
            mftStatusID: updateData.mailFwdStatus && updateData.mailFwdStatus.mailFwdStatusID ? updateData.mailFwdStatus.mailFwdStatusID : null,
            mftReasonID: updateData.mailFwdReason && updateData.mailFwdReason.mailFwdReasonID ? updateData.mailFwdReason.mailFwdReasonID : null,
            mftNote: updateData.notes,
            assignedTo: updateData.selUser && updateData.selUser.azureUserId ? updateData.selUser.azureUserId : ''
        }
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/bulkUpdateMFTs`, body, '', '', '');
        setIsLoading(false);
        setRespMessage({ type: res.success ? 'success' : 'danger', message: res.success ? `${selectedRows.length} records updated successfully`:res.message });
        if (res.success) {
            onChangeInputValues('notes', '');
            onChangeInputValues('selUser', '');
            setDoRefresh(true);
        }
        setTimeout(() => {
            setRespMessage({ type: '', message: '' });
        }, 1000 * 5);
    }

    const getAction = () => {
       return <Accordion className="mt-2">
                    <AccordionTab header={<div className="d-flex justify-content-between w-100"><div>Action</div> <div style={{paddingLeft:'50vw'}} >{respMessage.message && <span className={respMessage.type === 'success' ? 'text-success' : respMessage.type === 'danger' ? "text-danger" : ''}>{respMessage.message}</span>} </div> </div>}>
                        <div className="card-body mt-2 p-1">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label className="mb-0">Mail Fwd Status</label>
                                            <Dropdown value={updateData.mailFwdStatus} options={mftStatuses} optionLabel="mailFwdStatus" onChange={(e) => { onChangeInputValues('mailFwdStatus',e.value)} }
                                            style={{ minWidth: '100%' }} filter={true} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="mb-0">Mail Fwd Reason</label>
                                            <Dropdown value={updateData.mailFwdReason} disabled={!updateData.mailFwdStatus} options={mftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeInputValues('mailFwdReason',e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                        </div>

                                        <div className="col-md-2">
                                            <label className="mb-0">Assign To</label>
                                            <Dropdown value={updateData.selUser} options={users} optionLabel="display_name" dataKey="_id" onChange={(e) => { onChangeInputValues('selUser',e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                        </div>

                                        <div className="col-md-3 ">
                                            <label className="mb-0">Note</label>
                                            <InputTextarea disabled={selectedRows.length === 0} value={updateData.notes} onChange={(e) => onChangeInputValues('notes',(e.target as HTMLInputElement).value)} className="w-100" rows={3} />
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <div className="d-flex flex-column justify-content-center h-100">
                                                <div>
                                                    <Button disabled={selectedRows.length === 0} color="primary" type='submit' onClick={doBulkUpdate}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>

                </Accordion>
    }

    const getDataGrid = () => {
        const tabType:string  = (activeTab === 1 || activeTab === 2) ? 'CR' : 'BK';
        const api:string = (activeTab === 1 || activeTab === 3) ? 'mailForwardAssignments/getMFTAssignmentsFiltered' : (activeTab === 2 || activeTab === 4) ? 'mailForwardAssignments/getMFTAssignmentsHistoryFiltered' : '';
        return <DataTables
        //   onRef={(ref) => (this.datatableref = ref)}
        // ref={dataTableRef}
        getTableFields={getTableFields}
        formFields={getTableFields}
        exportRequried={false}
        printRequried={false}
        addRequried={false}
        editRequired={true}
        sortField={"LastFwdRequestDate"}
        deleteRequired={false}
        viewRequired={true}
        settingsRequired={false}
        filterRequired={true}
        gridRequried={false}
        isSelectMode={""}
        // isShownnFieldType={true}
        sample={false}
        scrollHeight={"580px"}
        dataKey={"MailFwdTrackingID"}        
        globalSearch={'Search'}
        type={"Mail Forwards Assignment"}
        displayright={true}
        icon='faCity'
        // settingsData={this.state.settingsData}
        fontAwesome={true}
        MFTab={tabType}
        // routeTo='propertyEditor'
        displayViewOfForm='modal'
        hideHeader={true}
        // apiResponseKey='details'
        className={true}
        // apiUrl={"mailForwardAssignments/getMFTAssignmentsFiltered"}
        apiUrl={api}
        entityType='employee'
        // selDealId={dealId}
        // docLibraryTopFilters={docLibraryTopFilters}
        hideProgress={true}
        // removeTopFilter={removeTopFilter}
        // doSearch={doSearch}
        paginatorPosition={'top'}
        style={{ marginTop: 10 }}
        donotIncludeParamsInFilter={true}
        showFilterElements={true}
        showTopActions={true}
        onSelectRows={onSelectRows}
        doRefresh={doRefresh}
        removePadding={true}        
    />
    }

    

    return <div className="card-body mail-fwd-details p-2 mt-1">
        <Nav tabs >
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 1, h6: true }}
                    onClick={() => { setActiveTab(1) }}
                >
                    CR Due
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 2, h6: true }}
                    onClick={() => { setActiveTab(2) }}
                >
                    CR History
                </NavLink>
            </NavItem>
            <NavItem >
                <NavLink
                    className={{ active: activeTab === 3, h6: true }}
                    onClick={() => { setActiveTab(3) }}
                >
                    BK Due
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 4, h6: true }}
                    onClick={() => { setActiveTab(4) }}
                >
                    BK History
                </NavLink>
            </NavItem>

            <NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                {/* <div>
                    <Button color="primary"  outline className="mb-1" onClick={() => { alert('Save Details') }}>
                    <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                </div> */}

            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId={1} >
                {activeTab === 1 && <>{getAction()}</>}
                {activeTab === 1 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={2}>
                {activeTab === 2 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={3}>
                {activeTab === 3 && <>{getAction()}</>}
                {activeTab === 3 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={4}>
                {activeTab === 4 && <>{getDataGrid()}</>}
            </TabPane>


        </TabContent>
        <Loader loader={isLoading} />
    </div>

}

export default MailForwardsAssignment;