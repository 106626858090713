import config from './config';

let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
        return headers;
    } else {
        return headers;
    }
}

const setCustomFormData = (customFormData, formData) => {
    if (customFormData) {
        for (let customForm in customFormData) {
            formData.append(customForm, customFormData[customForm])
        }
    }
    return formData
}

/**
 * UNABLE TO USE due to network security blocking client side code from accessing the set-cookie header. See: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 * Using a response from an API, gets the instanceID that the response came from.
 * @param {(Response)} res
 */
// export const getApiInstanceIdFromResponse = (res) => {
//     if (!res) return undefined;

//     const setCookieHeader = res.headers.getSetCookie(); //returns an array of fields and values from the set-cookie header. IE: ["ARRAffinity=98729873jkjawd89", "Path=/", "HttpOnly"]
//     const arrAffinity = setCookieHeader.find(s => s.toLowerCase().includes("arraffinity"));

//     if (!arrAffinity) return undefined;

//     let startPosition = arrAffinity.indexOf("=");

//     if (!startPosition) return undefined;

//     //extract the instanceId from the text
//     const instanceId = arrAffinity.slice(startPosition + 1);

//     return instanceId.trim();
// }

/**
 * using the passed in params, builds a requests and sends to the desired api.
 * @param {*} method 
 * @param {*} url 
 * @param {*} body 
 * @param {*} type 
 * @param {*} customFormData 
 * @param {string | undefined} apiInstanceTargetId **Currently unusable until we are able to obtain instanceID from the API Response ** the instanceID from the API we are targeting. This will allow specific instances on the API to be hit if the instanceID is passed in.
 * @returns 
 */
const fetchMethodRequest = (method, url, body, type, customFormData, apiInstanceTargetId,otherBaseURL = '') => {
    // let loginScreen = url.includes("localhost")
    if (url === 'auth/login') {
        // if (loginScreen === true) {
        // return sendRequestToServer(method, url, body, headers);
        // } else {
        throw Error("Login not available with fetchMethod. Use sendLoginRequestForToken")
        // }
    } else {
        let headers = getLoggedInuserToken();

        // set instanceId to use in API request if specified
        if (apiInstanceTargetId && apiInstanceTargetId !== "") headers.Cookie = `ARRAffinity=${apiInstanceTargetId}`

        // upload method conditions, headers
        if (type && type === 'upload') {
            let formData = new FormData();
            if (body && !body.files) {
                formData.append('file', body);
                formData = setCustomFormData(customFormData, formData)
            } else {
                if (body && body.files) {
                    for (var file of body.files) {
                        formData.append('files', file);
                        formData = setCustomFormData(customFormData, formData)
                    }
                }
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }
        // console.log("service urllllll", url)
        return sendRequestToServer(method, url, body, headers,otherBaseURL)
            .then(response => {
                if (response) {
                    if (response.errorCode && response.errorCode === 9001) { // token expiry
                        return response;
                    } else {
                        return response;
                    }
                }
            })
            .catch((err) => {

            });
    }
}


/**
 * generate guid and update headers from Azure Access Token
 * @param {*} accessToken 
 * @returns 
 */
export const sendLoginRequestForToken = (accessToken) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    return sendRequestToServer('POST', 'auth/login', {
        'accessType': config.entityType,
        offsetTime: new Date().getTimezoneOffset() / 60,
    }, headers, false)
        .then(
            (response) => {
                if (response && response.respCode && response.respCode === 200) {
                    let userDetails = response.details;
                    if (userDetails && userDetails._id) {
                        if (response.accessToken) {
                            let tokenInfo = {
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                                tokenExpires: response.tokenExpires,
                            };
                            userDetails = { ...userDetails, ...tokenInfo };
                            // save user credentials in storage

                            let expiryTime = parseFloat(response.expireTime) * 60 * 1000
                            localStorage.setItem('loggedTime', expiryTime);
                            localStorage.setItem("presentTime", new Date())
                            localStorage.setItem("onTask", true)
                            // save user credentials in storage
                            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));

                        }
                        if (userDetails && userDetails.rolePermissions) {
                            let permissions = userDetails.rolePermissions;
                            localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                        }
                    }
                    return userDetails;
                } else if (response.errorCode) {
                    throw Error(`Login Failed: ${response.errorMessage}`)
                }
            });
}

/**
 * Builds out the request and sends to the API
 * @param {*} method 
 * @param {*} url 
 * @param {*} body 
 * @param {*} headers 
 * @param {*} catchExceptions 
 * @returns 
 */
const sendRequestToServer = (method, url, body, headers,otherBaseURL, catchExceptions = true) => {
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
    }
    if (reqHeaders && (url === "auth/loginemail" || url === "auth/loginemail")) {
        delete reqHeaders.Authorization;
    }
    let isImageReqSent = false;
    let responsePromise;

    if (body && body.isUploadingImage) {
        // only for image upload
        isImageReqSent = true;
        var fetchurl = `${config.apiUrl}${url}`
        if (url && (url.includes("uploadMissingMoneyZip") || url.includes("propertyloaderrepo"))) {
            fetchurl = `${config.missingMoneyUrl}${url}`
            if (url.includes("propertyloaderrepo")) {
                fetchurl = fetchurl.replace('propertyloaderrepo/', "")
            }
        }
        responsePromise = fetch(`${fetchurl}`, {
            method: method,
            headers: {
                'Authorization': `${reqHeaders.Authorization}`
            },
            body: body.imageInfo
        })
    }

    if (!isImageReqSent) {
        // send request for call except image upload 
        if (url && url.includes("us-zipcode.api.smartystreets.com")) {
            responsePromise = fetch(`${url}`, { method: method, headers: reqHeaders })
        }
        else if (method === 'GET' || method === 'DELETE') {
            if (url && (url.substring(0, 3) === "opp")) {
                responsePromise = fetch(`${config.nameUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })
            } else {
                if (url && url.includes("sellerData")) {
                    responsePromise = fetch(`${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } else if (url.includes("openCorporatesSearch/branchData") || url.includes("openCorporatesSearch/searchData") || url.includes("openCorporatesSearch/fileDownload") || url.includes("openCorporatesSearch/getOCReports")) {
                    responsePromise = fetch(`${config.openCorporateSearchUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } 
                else if(url.includes("mailForwardsDetails") || url.includes("mailForwardTracking") || url.includes("mftStatusAndReasons")){                    
                    responsePromise = fetch(`${config.mailForwardsURL}${url}`, { method: method, headers: reqHeaders});
                } 
                else if (url && (url.includes("fileImportQueue") || url.includes("fileImportQueue/getErrorsForFile"))) {
                    responsePromise = fetch(`${config.missingMoneyUrl}${url}`, {
                        method: method,
                        headers: {
                            'Authorization': `${reqHeaders.Authorization}`
                        },

                    })

                } else if (url.includes("getPropertyPresentFromDifferentSources") || url.includes("queueList") || url.includes("restartServer")
                    || url.includes("updatePropertyAddressDetails") || url.includes("getPropertyPresentFromDifferentSources") || url.includes("seller/dataSourceList") ||
                    url.includes("seller/propertyStatus") || url.includes("seller/StateList") || url.includes("propertySearches") || url.includes("updateSimilarProperties") ||
                    url.includes("StartOrStopDealsBatch") || url.includes("ScoringDealsBatch") || url.includes("StartOrStopBatch") || url.includes("ScoringBatch") ||
                    url.includes("scoringBucketName")||url.includes("bucketGet")||  url.includes("deals/summaryStats")) 
                  {
                    responsePromise = fetch(`${config.apiDealUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders })

                } else if (url) {
                    responsePromise = fetch(`${otherBaseURL ? otherBaseURL : config.apiUrl}${url}`, { method: method, headers: reqHeaders })
                }
            }
        } else if (method === 'POST' || method === 'PUT') {
            if (url && url.includes("settings/ScoringBatch?flag=") || url.includes("settings/ScoringDealsBatch?flag=")) {
                responsePromise = fetch(`${config.apiDealUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            } else if (url.includes("openCorporatesSearch/branchData") || url.includes("openCorporatesSearch/searchData")) {
                responsePromise = fetch(`${config.openCorporateSearchUrl}${url}&site=Opra2`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            } else if(url.includes("mailForwardsDetails") || url.includes("mailForwardTracking") || url.includes("mftStatusAndReasons") || url.includes("mftHistory") || url.includes("mailForwardAssignments") || url.includes("mailForwardIntegrity")){                
                responsePromise = fetch(`${config.mailForwardsURL}${url}`, { method: method, headers: reqHeaders, body: JSON.stringify(body)});
            }else {
                responsePromise = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
            }
        }
    }

    return responsePromise
        .then(res => {
            var headers = res.headers.get('Expires');
            var loggedTime = parseFloat(headers)
            if (loggedTime) {
                let expiryTime = loggedTime * 60 * 1000
                localStorage.setItem('loggedTime', expiryTime);
                localStorage.setItem("presentTime", new Date())
                localStorage.setItem("onTask", true)
            }
            return res.json()
        })
        .then(responseJson => {
            if (responseJson && responseJson['errorMessage'] === "Session expired please login again.") {
                localStorage.setItem('sessionexpired', true)
            }

            return responseJson;
        }).catch(err => {
            if (catchExceptions) {
                localStorage.setItem('serverError', true)
            } else {
                throw err;
            }
            // showToasterMessage(config.serverErrMessage, 'error');
            // // localStorage.setItem('serverErrMessage', true)
            // return 'serverError';
        });
}
export default fetchMethodRequest;
