import fetchMethodRequest from '../config/service';
import config from '../config/config';
import apiNames from '../config/apiCalls';

export const getDealsDropdownValues = async (dealName: string): Promise<any> => {
    const results = await fetchMethodRequest('GET', `${apiNames.propertysellers}?str=${encodeURIComponent(dealName)}`, '', '', '', '');
    if (results && results.sellers && results.sellers.length > 0) {
        return Promise.resolve(results.sellers);
    } else {
        return Promise.resolve([]);
    }
}

export const getPropertyStatuses = async (statusUsage: string, sortFields: string): Promise<any> => {
    const filterCriteria = {
        "criteria": [{ "key": "activeIndicator", "value": true, "type": "eq", "add_Filter": true },
        { "key": "propertyStatusUsage", "value": statusUsage, "type": "eq", "add_Filter": true }],
        "sortfield": sortFields, "direction": "asc"
    };
    const results = await fetchMethodRequest('GET', `${apiNames.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=status`, '', '', '', '');
    if (results && results.propertyStatusReasons && results.propertyStatusReasons.length > 0) {
        return Promise.resolve(results.propertyStatusReasons);
    } else {
        return Promise.resolve([]);
    }
}

export const getPropertyStatusReasons = async (selStatuses:Array<string>,statusUsage: string, sortFields: string,getAll?:boolean): Promise<any> => {
    const filterCriteria:any = {
        "criteria": [{ "key": "activeIndicator", "value": true, "type": "eq", "add_Filter": true },
        { "key": "propertyStatusUsage", "value": statusUsage, "type": "eq", "add_Filter": true }],
        "sortfield": sortFields, "direction": "asc"
    };
    if(selStatuses && selStatuses.length > 0){
        filterCriteria.criteria.push({"key":"status","value":selStatuses,"type":"in"});
    }
    let api = `${apiNames.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`;
    if(!getAll){
        api = `${api}&dropdown=reason`;
    }
    const results = await fetchMethodRequest('GET', api, '', '', '', '');
    if (results && results.propertyStatusReasons && results.propertyStatusReasons.length > 0) {
        return Promise.resolve(results.propertyStatusReasons);
    } else {
        return Promise.resolve([]);
    }
}