import React, { useEffect, useRef, useState } from "react";
import DataTables from '../CommonDataTable/DataTable';
import fields from './MappedProportySearchReportFields.json';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputSwitch } from 'primereact/inputswitch';
import DealDropdown from "../../../shared/commonFields/DealDropdown";
import states from '../../../shared/states.json';
import * as commonApis from "../../../shared/commonApis";
import MultiSortModal from "../PropertyEditorV2/components/MultisortModal";

const MappedProportySearchReport = () => {
    const [topFilters, setTopFilters] = useState<any>({});
    const [propertyStatus, setPropertyStatus] = useState<Array<any>>([]);
    const [propertyReasons, setPropertyReasons] = useState<Array<any>>([]);
    const [propStatusDropDownValues, setPropStatusDropDownValues] = useState<Array<any>>([]);
    const [propReasonsDropDownvalues, setPropReasonsDropDownsValues] = useState<Array<any>>([]);
    const [disableGoButton, setDisableGoButton] = useState<boolean>(true);
    const [isOpenMultiSortModal, setIsOpenMultiSortModal] = useState<boolean>(false);
    const [formattedTopFilters, setFormattedTopFilters] = useState<Array<any>>([]);
    const [sellerID, setSellerID] = useState<string>();
    const [multiSortFilters, setMultiSortFilters] = useState<Array<any>>([]);
    const [multiSortFields, setMultiSortFields] = useState<Array<any>>([]);
    const [selectedfieldsforSort, setSelectedfieldsforSort] = useState<Array<any>>([]);
    const [showDataTable, setShowDataTable] = useState<boolean>(false);
    const [fromColReOrder, setFromColReOrder] = useState<boolean>(false);
    const [showClaimAttributes, setShowClaimAttributes] = useState<boolean>(false);
    const [naupaCategories, setNaupaCategories] = useState<any>([])

    const rolesPermission = JSON.parse(localStorage.getItem('rolePermissions') || '');

    const claimAttributesColumns = [
        {
            "show": true,
            "textAlign": "left",
            "width": 200,
            "field": "claimFiledDate",
            "mobile": true,
            "header": "Claim Filed Date",
            "filter": true,
            "sortable": true
        },
        {
            "show": true,
            "textAlign": "left",
            "width": 130,
            "field": "claimStatus",
            "mobile": true,
            "header": "Claim Status",
            "displayInSettings": true,
            "filter": false,
            "sortable": true
        },
        {
            "show": true,
            "textAlign": "left",
            "width": 120,
            "field": "claimStatusReason",
            "mobile": true,
            "header": "Claim Status Reason",
            "displayInSettings": true,
            "filter": false,
            "sortable": true
        }
    ]
    useEffect(() => {
        getPropertyStatus();
    }, []);

    useEffect(() => {
        enableDisableLoadButton();
        return () => {
            setDisableGoButton(true);
        }
    }, [topFilters]);



    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(fields));
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'propertyStatus') {
                console.log(propStatusDropDownValues);
                tableFields[i].filterType = "multiSelect";
                tableFields[i].filterOptions = propStatusDropDownValues;
            } else if (tableFields[i].field === 'propertyStatusReason') {
                tableFields[i].filterType = "multiSelect";
                tableFields[i].filterOptions = propReasonsDropDownvalues;
            }
        }
        let multiSortArray = tableFields.map((e: any, i: number) => {
            return { label: e.header, value: e.field, type: e.fieldType ? e.fieldType : "string", index: i.toString() };
        });
        setMultiSortFilters(multiSortArray);
        return tableFields;
    }

    const onSelectDeal = (deal: any) => {
        setSellerID(deal.DealID);
        onSelectFormValues('selDeal', deal);
    }

    const onSelectFormValues = (field: string, value: any) => {
        setTopFilters((prevData: Object) => ({ ...prevData, [field]: value }));
        if (field === 'propertyStatus' && (!value || value.length === 0)) {
            onSelectFormValues('propertyStatusReason', '');
            setPropertyReasons([]);
        }
    }

    const enableDisableLoadButton = () => {
        let disabled = true;
        for (let field in topFilters) {
            if ((Array.isArray(topFilters[field]) && topFilters[field].length > 0) || (!Array.isArray(topFilters[field]) && topFilters[field])) {
                disabled = false;
            }
        }
        setDisableGoButton(disabled);
    }

    const getCloseIcon = (field: string) => {
        return <FontAwesomeIcon icon={faTimes}
            className='pl-1 mr-2'
            color={topFilters[field] ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            title="Clear Filter"
            id="clear stClaimId"
            onClick={() => onSelectFormValues(field, '')}
            style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${topFilters[field] ? 'auto' : 'none'}` }}
        />
    }

    const getLabel = (label: string, field: string) => {
        return <label className='d-flex justify-content-between mb-0'>
            <span>{label}</span>
            {getCloseIcon(field)}
        </label>
    }

    const getPropertyStatus = async () => {
        const [results, reasons] = await Promise.all([commonApis.getPropertyStatuses('Property Editor', 'stageSequence, statusSequence, reasonSequence'), commonApis.getPropertyStatusReasons([], 'Property Editor', 'stageSequence, statusSequence, reasonSequence', true)]);
        setPropertyStatus(results.filter((e:any) => e.positiveValidation));
        let statusArray: Array<any> = [];
        let reasonsArray: Array<any> = [];
        if (reasons && reasons.length > 0) {
            reasons.forEach((reason: any) => {
                if (reason.positiveValidation) {
                    const statusIndex = statusArray.findIndex(obj => obj.label === reason.status);
                    if (statusIndex === -1) {
                        statusArray.push({ label: reason.status, value: reason.status })
                    }
                    const reasonIndex = reasonsArray.findIndex(obj => obj.label === reason.reason);
                    if (reasonIndex === -1) {
                        reasonsArray.push({ label: reason.reason, value: reason.reason })
                    }
                }
            });
        }
        setPropStatusDropDownValues(results.map((e: any) => { return { label: e.status, value: e.status } }));
        setPropReasonsDropDownsValues(reasonsArray);
        setShowDataTable(true);
    }

    const getPropertyStatusReasons = async (selStatus: Array<any>) => {
        let statuses = selStatus.map((e) => e.status);
        const results = await commonApis.getPropertyStatusReasons(statuses, 'Property Editor', 'stageSequence, statusSequence, reasonSequence');
        setPropertyReasons(results);
        if (topFilters.propertyStatusReason && topFilters.propertyStatusReason.length > 0) {
            let reasons = results.map((e: any) => e.reason);
            let availReasons = topFilters.propertyStatusReason.filter((e: any) => reasons.includes(e.reason));
            onSelectFormValues('propertyStatusReason', availReasons);
        }
    }

    const closeMultiSort = () => {
        setIsOpenMultiSortModal(false);
    }

    const applyMultiSort = (elem: any) => {
        setMultiSortFields(elem);
        let fieldsObj: any = {};
        multiSortFilters.forEach((e: any, i) => { fieldsObj[e.value] = i });

        setSelectedfieldsforSort(elem.map((obj: any, i: number) => {
            if (obj.key && fieldsObj.hasOwnProperty(obj.key)) {
                const fIndex = fieldsObj[obj.key];
                return {
                    "value": {
                        "label": multiSortFilters[fIndex].label,
                        "value": multiSortFilters[fIndex].value,
                        "type": multiSortFilters[fIndex].type
                    },
                    "fieldType": multiSortFilters[fIndex].type,
                    "type": "",
                    "index": fIndex.toString()

                }
            }
        }));
    }

    const onClickClearAllFilters = () => {
        setTopFilters({ propertyCity: '', propertyAddressLine1: '', propertyMinValueAmount: '', propertyMaxValueAmount: '', holderName: '', propertyDescription: '' });
        setFormattedTopFilters([]);
        clearSortingItems();
    }

    const onClickGoButton = () => {
        const tempFormattedTopFilters = [];
        for (let field in topFilters) {
            if (Array.isArray(topFilters[field]) && topFilters[field].length > 0) {
                let value = topFilters[field];
                if (field === 'propertyState') {
                    value = topFilters[field].map((e) => e.shortCode);
                } else if (field === 'propertyStatus') {
                    value = topFilters[field].map((e) => e.status);
                } else if (field === 'propertyStatusReason') {
                    value = topFilters[field].map((e) => e.reason);
                }
                tempFormattedTopFilters.push({ key: field, value: value, type: 'in' });
            } else if (topFilters[field]) {
                let type = 'regexOr';
                let value = topFilters[field];
                if (field === 'selDeal') {
                    value = topFilters[field].DealID;
                    type = 'eq';
                } else if (field === 'propertyMinValueAmount') {
                    type = 'gte';
                }
                else if (field === 'propertyMaxValueAmount') {
                    type = 'lte';
                }
                tempFormattedTopFilters.push({ key: field === 'selDeal' ? 'CompanyID' : field, value: value, type: type, add_Filter: true });
            }
        }
        setFormattedTopFilters(tempFormattedTopFilters);
        clearSortingItems();
    }

    const clearSortingItems = (fromColReOrder?: boolean) => {
        if (fromColReOrder) {
            setFromColReOrder(true);
        }
        if (multiSortFields && multiSortFields.length > 0) {
            setMultiSortFields([]);
        }
        if (selectedfieldsforSort && selectedfieldsforSort.length > 0) {
            setSelectedfieldsforSort([]);
        }
    }

    const updateMultiSortFields = (tableFields: Array<any>) => {
        let multiSortArray = tableFields.map((e: any, i: number) => {
            return { label: e.header, value: e.field, type: e.fieldType ? e.fieldType : "string", index: i.toString() };
        });
        setMultiSortFilters(multiSortArray);
    }

    const getDataTable = React.useMemo(() => {
        return <DataTables
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"BusinessName, PropertyState, NameMatchScore"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            // isShownnFieldType={true}
            sample={false}
            scrollHeight={"580px"}
            datakey={"_id"}
            // isSelectMode={"multiple"}
            globalSearch={'Search'}
            type={"Mapped Property Search Report"}
            displayright={true}
            icon='faCity'
            // settingsData={this.state.settingsData}
            fontAwesome={true}
            // routeTo='propertyEditor'
            displayViewOfForm='modal'
            hideHeader={true}
            apiResponseKey='ns_scored_positively_mapped_properties'
            className={true}
            apiUrl={"positivelyMappedProperties"}
            entityType='employee'
            // selDealId={dealId}
            // docLibraryTopFilters={docLibraryTopFilters}
            hideProgress={true}

            // removeTopFilter={removeTopFilter}
            // doSearch={doSearch}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            openMultiSort={() => { setIsOpenMultiSortModal(true) }}
            donotIncludeParamsInFilter={true}
            topSectionFilters={formattedTopFilters}
            sellerID={sellerID}
            multiSortFields={multiSortFields}
            propertyStatusArray={propStatusDropDownValues}
            propertyStatusReasonArray={propReasonsDropDownvalues}
            screenPermissionsRoute={"Mapped Property Search"}
            clearSortingItems={clearSortingItems}
            updateMultiSortFields={updateMultiSortFields}
            fromColReOrder={fromColReOrder}
            resetColReorderFlag={() => setFromColReOrder(false)}
            optionalColumns={claimAttributesColumns}
            showOptionalColumns={showClaimAttributes}
            enableOptionalColumnsOption={() => setShowClaimAttributes(true)}
        />
    }, [multiSortFields, formattedTopFilters, sellerID, fromColReOrder,showClaimAttributes])

    return <>
        <div className="card pb-0 mt-2">
            <div className="card-body">
                {(rolesPermission && rolesPermission['Mapped Property Search'] && (rolesPermission['Mapped Property Search'] === 'Edit' || rolesPermission['Mapped Property Search'] === 'View')) &&
                    <div className="row">
                        <div className="col-sm-11">
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    {getLabel('Deal', 'selDeal')}
                                    <div className="w-100">
                                        <DealDropdown onSelectDeal={onSelectDeal} selectedDeal={topFilters.selDeal} />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('Prop. Addr Line 1', 'propertyAddressLine1')}
                                    <InputText value={topFilters.propertyAddressLine1} onChange={(e) => { onSelectFormValues('propertyAddressLine1', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {getLabel('Prop. City', 'propertyCity')}
                                            <InputText value={topFilters.propertyCity} onChange={(e) => { onSelectFormValues('propertyCity', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />

                                        </div>
                                        <div className="col-md-6 mb-2">
                                            {getLabel('Prop. State', 'propertyState')}
                                            <MultiSelect options={states} optionLabel="label" value={topFilters.propertyState} onChange={(e) => { onSelectFormValues('propertyState', e.value) }}
                                                style={{ minWidth: '100%' }} maxSelectedLabels={2} />
                                        </div>
                                    </div>

                                </div>


                                <div className="col-md-3 mb-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {getLabel(`Prop. Min Value ${'<='}`, 'propertyMinValueAmount')}
                                            <InputText value={topFilters.propertyMinValueAmount} type="number" onChange={(e) => { onSelectFormValues('propertyMinValueAmount', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />
                                        </div>
                                        <div className="col-md-6">
                                            {getLabel(`Prop. Max Value ${'>='}`, 'propertyMaxValueAmount')}
                                            <InputText value={topFilters.propertyMaxValueAmount} type="number" onChange={(e) => { onSelectFormValues('propertyMaxValueAmount', (e.target as HTMLInputElement).value) }}
                                                style={{ minWidth: '100%' }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('Prop. Status', 'propertyStatus')}
                                    <MultiSelect value={topFilters.propertyStatus} filter={true} options={propertyStatus} optionLabel="status" dataKey="_id" onChange={(e) => { onSelectFormValues('propertyStatus', e.value); getPropertyStatusReasons(e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('Prop. Reason', 'propertyStatusReason')}
                                    <MultiSelect value={topFilters.propertyStatusReason} filter={true} options={propertyReasons} optionLabel="reason" dataKey="_id" onChange={(e) => { onSelectFormValues('propertyStatusReason', e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('Holder Name', 'holderName')}
                                    <InputText value={topFilters.holderName} onChange={(e) => { onSelectFormValues('holderName', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('Property Desc', 'propertyDescription')}
                                    <InputText value={topFilters.propertyDescription} onChange={(e) => { onSelectFormValues('propertyDescription', (e.target as HTMLInputElement).value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    {getLabel('NAUPA Category', 'naupA_Code_Category')}
                                    <MultiSelect value={topFilters.naupA_Code_Category} filter={true} options={naupaCategories} optionLabel="reason" dataKey="_id" onChange={(e) => { onSelectFormValues('naupA_Code_Category', e.value) }}
                                        style={{ minWidth: '100%' }} />
                                </div>
                                <div className="col-md-3 d-flex">
                                    <div className="mr-2 d-flex align-items-center">Claim Attributes</div>
                                    <InputSwitch checked={showClaimAttributes} onChange={(e) => setShowClaimAttributes(e.value)} />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center flex-column ">
                            <Button color="success" type='submit' className='go-button mr-0' style={{ width: '60%' }} disabled={disableGoButton} onClick={onClickGoButton}>
                                GO
                            </Button>
                            <Button color="primary" outline style={{ width: '60%' }} onClick={onClickClearAllFilters}>
                                X
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div >
            {showDataTable &&
               <>{getDataTable}</> 
            }

        </div>
        {isOpenMultiSortModal &&
            <MultiSortModal
                selectedfieldsforSort={selectedfieldsforSort}
                isOpenMultiSortModal={isOpenMultiSortModal}
                multiSortFields={multiSortFilters}
                cancelMultiSortModel={closeMultiSort}
                applyMultisort={applyMultiSort}
                restrictNumber={5}
            />
        }
    </>
}

export default MappedProportySearchReport;