/* eslint indent: "off" */
import React from 'react';
import {
  Route, Switch
} from 'react-router-dom';
// import useParams from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
// import TaskTemplate from '../Cruds/TaskTemplate/index'
// Login
import Landing from '../Landing/LogIn';

// Dashboard
import Home from '../Dashboard/index';
import UserView from '../UserView/index'

// Profile
// import ChangePassword from '../Account/ChangePassword/index';
// import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
// import ForgotPassword from '../Account/ForgotPassword/index';
// import LoginChangePassword from '../Account/LoginChangePassword/index';
// import Profile from '../Account/Profile';
// import Roles from '../Cruds/Role/index';
// Tables
// import Employee from '../Cruds/Users/index';
// import Fields from '../Cruds/Fields/index'
// import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
// import Purposes from '../Cruds/Purpose/index'
// import Labels from '../Cruds/Label/index'
// import Status from '../Cruds/Status/index'
// import Tasks from '../Cruds/Tasks/index'
// import Attribute from '../Cruds/Attribute/index'
// import Rules from '../Cruds/Rules/index'
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import Profile from '../Account/Profile';
import Attribute from '../Cruds/Attribute/index';
import Activities from '../Cruds/AuditsLog';
import Company from '../Cruds/Company/index';
import Fields from '../Cruds/Fields/index';
import Investors from '../Cruds/Investors/index';
import Labels from '../Cruds/Label/index';
import Departments from '../Cruds/Department/index'
import Purposes from '../Cruds/Purpose/index';
import Reports from '../Cruds/Reports/index';
import Roles from '../Cruds/Role/index';
import Rules from '../Cruds/Rules/index';
import Settings from '../Cruds/Settings/index';
import Status from '../Cruds/Status/index';
import Tasks from '../Cruds/Tasks/index';
import Charts from '../Cruds/Charts/index'
import Sellers from '../Cruds/Sellers/index';
import AvailableToFile from '../Cruds/AvailableToFile/index';
import AvailabletoConfirmonSite from '../Cruds/AvailableToConfirmOnSite/index'
import RequiredEvidence from '../Cruds/RequiredEvidence'
import HighValueProperties from '../Cruds/HighValueProperties'
import ClaimSummary from '../Cruds/ClaimSummary';
import CrMetrix from '../Cruds/CrMatrix/index';
import SellersForm from '../Cruds/CommonModals/SellerModal';
import DealManagement from '../Cruds/DealMgmt/DealTabs';

// import SellersForm from '../Cruds/CommonModals/SelerNModel';

import CrMatrixForm from '../Cruds/CommonModals/CrMatrixModal';
import TaskTemplate from '../Cruds/TaskTemplate/index';
import OpenCorporatesPage from '../Cruds/OpenCorporates/index';
import ReportsDashboard from '../Cruds/ReportsDashboard/index';
import ProcessDocuments from '../Cruds/ProcessDocuments/index';
import SendRequest from '../Cruds/SendRequest/index';
import ReportBugPage from '../Cruds/ReportBug/index';
import ReportBugPageNew from '../Cruds/ReportBugNew/index';
import NameScoring from '../NameScoring/index';
import UPGrid from '../Cruds/UPGrid/index';
import UPGridDashboard from '../PlanningGridDashboard/index'
import StateUP from '../StateUP/index'

// import SiteDiscoveryUPGrid from '../Cruds/SiteDiscoveryUPGrid/index';
// import NonSiteDiscoveryUPGrid from '../Cruds/NonSiteDiscoveryUPGrid';
import Properties from '../Cruds/Properties/index'
import DealSizingReport from '../Cruds/DealSizingReport/index'
import DealSizingReportForPe from '../Cruds/DealSizingReportForPe';
import CompanyView from '../Cruds/CompanyTabView/index';
import Buckets from '../Buckets/index';
import AvailableToMapV2 from '../Cruds/AvailableToMapV2/index';
import NextActionUserReportConfig from '../Cruds/NextActionUserReportConfiguration'
import RuleConfigurationScreen from '../Cruds/RuleWorkflow/index'
import RuleConfigurationAddEditScreen from '../RuleConfiguration/index'
import NameImport from '../NameAddressImport/index'
import DealSizing from '../Cruds/DealSizing/index'
import DealSizingThreshold from '../DealSizingThreshold/index';
import BucketCheck from '../BucketCheck/index';
import ClaimManagementNew from '../Cruds/ClaimManagementNew';
import Configuration from '../Configurations/index'
import DealConfiguration from '../Cruds/DealConfigurations/index'
import StatusReason from '../StatusReason/index'
import Summary from '../Cruds/Summary/index'
import UPOpprtunity from '../Cruds/UpOpportunity/index'
import AvailableToMap from '../Cruds/AvailableToMap/index';
import AvailableToReMap from '../Cruds/AvailableToReMap/index';
import PropertyEditor from '../Cruds/Properties/index'
import Claims from '../Cruds/Claims/index'
import StateUPFiling from '../Cruds/StateUpFiling';
import UppropertyConfirmation from '../Cruds/UppropertyConfirmation'
import SearchTerms from '../Cruds/SearchTerms';
import searchTermOnSite from '../Cruds/SearchTermOnSite'
import sellerSummaryReport from '../Cruds/SellerSummaryReport/index'
import ManualMatchLoading from '../Cruds/ManualMatchTable/index'
import DealStatisticsScreen from '../Cruds/DealStatistics/index'
import FactSetScreen from '../Cruds/FactSet/index'
import OpenCorporatesBeta from '../Cruds/OpenCorporatesBeta/index'
import NamesLookupScreen from '../Cruds/NamesLookup/index'
import AddressLookupScreen from '../Cruds/AddressLookup/index'
import RecoveryPlannerV2 from '../Cruds/RecoveryPlannerV2/index'
import PropertySearch from '../Cruds/PropertySearch/index'
// Tables
import Employee from '../Cruds/Users/index';
// import LoginCheck from '../Landing/loginCheck';
// Dashboard
// import Home from '../Dashboard/index';
import Kanban from '../Kanban/index';
// // Login
// import Landing from '../Landing/LogIn';
// import Layout from '../Layout/index';
// import AdminSettings from '../Settings/index';
// import MainWrapper from './MainWrapper';
import NamesReport from '../Cruds/NamesReport/index'
import DsSchedular from '../Cruds/DsScheduler/index';
import DsQueue from '../Cruds/DsQueue/index';
import RsQueue from '../Cruds/RsQueue/index';
import RsSchedular from '../Cruds/RsScheduler/index';
import SPLogs from '../Cruds/SPLogs/index';
import ScheduledJobLog from '../Cruds/ScheduledJobLog/index'
import ValidatedPropertyUpdater from '../Cruds/ValidatedPropertyUpdater/index';
import SellerNegativeNotice from '../Cruds/SellerNegativeNotice/index';
import SellerNegativeNoticeViewers from '../Cruds/SellerNegativeNoticeViewers/index'
import ResearchRequestViewers from '../Cruds/ResearchRequestViewers/index'
import RFIView from '../Cruds/RFIView/index'
import NameIssueConfiguration from '../Cruds/NameIssueConfiguration/index'
import ChecklistConfiguration from '../Cruds/Checklist/index'
import SiteConfirmationEditor from '../Cruds/SiteConfirmationEditor/index'
import PropertyEditorV2 from '../Cruds/PropertyEditorV2/components/NewPropEditor'
import DealSizingPropertyEditorV2 from '../Cruds/PropertyEditorV2/DealSizing/NewPropEditor'
import DocumentUpload from '../Cruds/DocumentUpload/index';
import AvailableDocumentsPage from '../Cruds/AvailableDocuments/index';
import OnlineClaimStatus from '../Cruds/OnlineClaimStatus/index'
import ClaimsPacket from '../Cruds/ClaimsPacket/index';
import PdfEditor from '../Cruds/PdfEditor/index';
import DocumentForm from '../Cruds/DocumentForm/index';
import DataFeedInclusion from '../Cruds/DataFeedInclusion/index';
import OwnedNamesandAddresses from '../Cruds/OwnedNamesandAddresses/index';
import TopConfiguration from '../Cruds/TopMenuConfiguration/index';
import TransmittalLetter from '../Cruds/TransmittalLetter/index';
import MissingMoney from '../Cruds/MissingMoney/index';
import PropertyLoader from '../Cruds/PropertyLoader/index';
import PropertySearchv2 from '../Cruds/PropertySearchv2/index'
import AddProperties from '../Cruds/AddProperties/index';
import MailForwards from '../Cruds/MailForwards/MailForwards';
import MailForwardsDetails from '../Cruds/MailForwardDetails/MailForwardDetails';
import MailForwardsAssignment from '../Cruds/MailForwardsAssignment/MailForwardsAssignment';
import MailForwardsIntegrity from '../Cruds/MailForwardsIntegrity/MailForwardsIntegrity';

import ReadyToReview from '../Cruds/ReadyToReview/index'
import FollowUp from '../Cruds/FollowUp/index'
import NextActionUserReport from '../Cruds/NextActionUserReport/index'
import AvailableToFileV2 from '../Cruds/AvailableToFileV2/index'
import MappedProportySearchReport from '../Cruds/MappedProportiesSearchReport/MappedProportySearchReport';


const Tables = () => {

  return <Switch>
    <Route path="/users" component={Employee} />
    <Route path="/fields" component={Fields} />
    <Route path="/roles" component={Roles} />
    <Route path="/nameissueConfiguration" component={NameIssueConfiguration} />
    <Route path="/listView" component={Tasks} />
    <Route path="/listView/:id" component={Tasks} />
    <Route path="/sellers" component={Sellers} />
    <Route path="/availabletoFile" component={AvailableToFile} />
    <Route path="/availabletoConfirmonSite" component={AvailabletoConfirmonSite} />
    <Route path="/reportsrequiredevidence" component={RequiredEvidence} />
    <Route path="/sellersAdd" component={SellersForm} />
    <Route path="/sellersView/deal/:id" component={SellersForm} />
    <Route path="/crMatrixAdd" component={CrMatrixForm} />
    <Route path="/crMatrixEdit/:id" component={CrMatrixForm} />
    {/* <Route path="/sellersEdit/:id" component={SellersForm} />
    <Route path="/sellersView/:id" component={SellersForm} /> */}
    <Route path="/availableToMapV2" component={AvailableToMapV2} />
    <Route path="/sellersEdit/deal" component={DealManagement} />
    <Route path="/sellersEdit/name" component={DealManagement} />
    <Route path="/sellersEdit/address" component={DealManagement} />
    <Route path="/sellersEdit/documentLibrary" component={DealManagement} />
    <Route path="/sellersEdit/documentUpload" component={DealManagement} />
    <Route path="/sellersEdit/claimDocumentation" component={DealManagement} />
    <Route path="/highValueProperties" component={HighValueProperties} />
    <Route path="/purposes" component={Purposes} />
    <Route path="/labels" component={Labels} />
    <Route path="/rfiView" component={RFIView} />
    <Route path="/checklistConfiguration" component={ChecklistConfiguration} />
    <Route path="/siteConfirmationEditor" component={SiteConfirmationEditor} />
    <Route path="/ResearchRequestViewers" component={ResearchRequestViewers} />
    <Route path="/SellerNegativeNoticeViewers" component={SellerNegativeNoticeViewers} />
    <Route path="/sellerNegativeNotice" component={SellerNegativeNotice} />
    <Route path="/departments" component={Departments} />
    <Route path="/status" component={Status} />
    <Route path="/settings" component={Settings} />
    <Route path="/ruleConfiguration" component={RuleConfigurationScreen} />
    <Route path="/dealStatistics" component={DealStatisticsScreen} />
    <Route path="/factSet" component={FactSetScreen} />
    <Route path="/openCorporatesBeta" component={OpenCorporatesBeta} />
    <Route path="/namesLookup" component={NamesLookupScreen} />
    <Route path="/addressLookup" component={AddressLookupScreen} />
    <Route path="/recoveryPlanningv2" component={RecoveryPlannerV2} />
    <Route path="/propertySearch" component={PropertySearch} />
    <Route path="/propertySearchv2" component={PropertySearchv2} />
    <Route path="/sendRequest" component={SendRequest} />
    <Route path="/reportBug" component={ReportBugPage} />
    <Route path="/userRequest" component={ReportBugPageNew} />
    <Route path="/attribute" component={Attribute} />
    <Route path="/adminSettings" component={AdminSettings} />
    <Route path="/activities" component={Activities} />
    <Route path="/rules" component={Rules} />
    <Route path="/properties" component={Properties} />
    <Route path="/nsproperties" component={Properties} />
    <Route path="/dealsizingreport" component={DealSizingReport} />
    <Route path="/dealsizingreportforpe" component={DealSizingReportForPe} />
    <Route path="/dealproperties" component={Properties} />
    <Route path="/reports" component={Home} />
    <Route path="/crmatrix" component={CrMetrix} />
    <Route path="/reportslistView" component={Reports} />
    <Route path="/executive" component={ReportsDashboard} />
    <Route path="/integrity" component={ReportsDashboard} />
    <Route path="/company" component={Company} />
    <Route path="/investor" component={Investors} />
    <Route path="/nameScoring" component={NameScoring} />
    <Route path="/planningGrid" component={UPGridDashboard} />
    <Route path="/reportPlan" component={UPGrid} />
    <Route path="/nonSiteDiscoveryPlan" component={UPGrid} />
    <Route path="/siteDiscoveryPlan" component={UPGrid} />
    <Route path="/taskTemplaterd" component={TaskTemplate} />
    <Route path="/processDocuments" component={ProcessDocuments} />
    <Route path="/open-corporates" component={OpenCorporatesPage} />
    <Route path="/dealSizing" component={DealSizing} />
    <Route path="/bucketCheck" component={BucketCheck} />
    {/* <Route path="/charts" component={Charts} /> */}
    <Route path="/charts/:id" component={Charts} />
    <Route path="/claimsummary" component={ClaimSummary} />
    <Route path="/topConfiguration" component={TopConfiguration} />
    <Route path="/configurations" component={Configuration} />
    <Route path="/dealconfigurations" component={DealConfiguration} />
    <Route path="/onlineclaimStatusConfiguration" component={OnlineClaimStatus} />
    <Route path="/statusReason" component={StatusReason} />
    <Route path="/propertySummary" component={Summary} />
    <Route path="/propertyEditor" component={PropertyEditor} />
    <Route path="/claims" component={ClaimManagementNew} />
    <Route path="/propertyEditorV2" component={PropertyEditorV2} />
    <Route path="/dealSizingPropertyEditorV2" component={DealSizingPropertyEditorV2} />
    <Route path="/claims/:CompanyID/:oppClaimID/:estateName" component={ClaimManagementNew} />
    <Route path="/uppropconfirmation" component={UppropertyConfirmation} />
    <Route path="/searchTerm" component={SearchTerms} />
    <Route path="/searchTermOnSite" component={searchTermOnSite} />
    <Route path="/manuallyLoaded" component={ManualMatchLoading} />
    <Route path="/dsqueue" component={DsQueue} />
    <Route path="/dsscheduler" component={DsSchedular} />
    <Route path="/rsqueue" component={RsQueue} />
    <Route path="/rsscheduler" component={RsSchedular} />
    <Route path="/spLogs" component={SPLogs} />
    <Route path="/scheduledJobLog" component={ScheduledJobLog} />
    <Route path="/ValidatedPropertyUpdater" component={ValidatedPropertyUpdater} />
    <Route path="/stateUp" component={StateUP} />
    <Route path="/stateUpFiling" component={StateUPFiling} />
    <Route path="/upOpportunity2" component={UPOpprtunity} />
    <Route path="/availableToMap" component={AvailableToMap} />
    <Route path="/availableToReMap" component={AvailableToReMap} />
    <Route path="/claimBuilder" component={ClaimsPacket} />
    <Route path="/sellerSummaryReport" component={sellerSummaryReport} />
    <Route path="/dealSizingThreshold" component={DealSizingThreshold} />
    <Route path="/ruleConfigurationedit" component={RuleConfigurationAddEditScreen} />
    <Route path="/ruleConfigurationadd" component={RuleConfigurationAddEditScreen} />
    <Route path="/Sellers/documentUpload" component={Sellers} />
    <Route path="/Sellers/availableDocuments" component={Sellers} />
    <Route path='/pdfEditor' component={PdfEditor} />
    <Route path='/documentUpload' component={DocumentForm} />
    <Route path="/names-addressImport" component={NameImport} />
    <Route path="/data_feed-Inclusion" component={DataFeedInclusion} />
    <Route path="/ownedNamesandAddresses" component={OwnedNamesandAddresses} />
    <Route path="/nextActionUserReportConfig" component={NextActionUserReportConfig} />
    <Route path="/transmittalLetter" component={TransmittalLetter} />
    <Route path="/missingMoney" component={PropertyLoader} />
    <Route path="/addProperties" component={AddProperties} />
    <Route path="/readyToReview" component={ReadyToReview} />
    <Route path="/outstandingclaimsreport" component={FollowUp} />
    <Route path="/nextactionuserreport" component={NextActionUserReport} />

    <Route path="/namesReport" component={NamesReport} />

    <Route path="/availableToFollowUp" component={FollowUp} />
    <Route path="/mappedPropertyReport" component={MappedProportySearchReport} /> 
    <Route path="/mailForwards" component={MailForwards} />
    <Route path="/mailForwardsDetails" component={MailForwardsDetails} />
    <Route path="/mailForwardsAssignment" component={MailForwardsAssignment} />
    <Route path="/mailFwdSubmissionReport" component={MailForwardsIntegrity} /> 

    <Route path="/availableToFileV2" component={AvailableToFileV2} />

    <Route path="/companyView/:companyId" render={(props) => <CompanyView
      {...props} />} />
  </Switch >
}

const Account = () => (
  <Switch>
    <Route path="/profile" component={Profile} />
  </Switch>
);

const DefaultScreen = () => (
  <Switch>
    <Route path="/" component={Landing} />
  </Switch>
);

const wrappedRoutes = () => {
  let url = window.location.href
  if (url.includes("sellerId") || url.includes("propertyId")) {
    localStorage.setItem("nspropertiesparamsUrl", url)
  } else {
    localStorage.setItem("Type", url)
  }
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <Layout route={window.location.pathname} />
      <div className="container__wrap">
        <Route path="/" component={Account} />
        <Route path="/" component={Tables} />
        <Route path="/dashBoard" component={Home} />
        <Route path="/taskView" component={Kanban} />
        <Route path="/userView" component={UserView} />
        <Route path="/buckets" component={Buckets} />
      </div>
    </div>
  }
  else {
    return <DefaultScreen />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/taskdetails" component={Landing} />
        <Route path="/log_in" component={Landing} />
        <Route path="/logout" component={Landing} />
        <Route path="/" component={wrappedRoutes} />
        <Route component={ErrorNotFound} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
