import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';
import DeleteRowModal from './DeleteRowModal';
import fetch from '../../../config/service';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { faEdit, faCopy } from '@fortawesome/free-regular-svg-icons';
import dateFormats from '../../UI/FormatDate/formatDate';


// config file
class NotesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CommentsArray: [],
            AddNewNotes: false,
            edited: "no",
            editedOldComment: "no",
            EditNewNotes: false

        };
    }

    componentDidMount = async (type) => {
        let CommentsArray = this.state.CommentsArray
        CommentsArray = JSON.parse(JSON.stringify(this.props.Comments))
        if (type) {
            CommentsArray.sort(function (a, b) {
                return new Date(a.created) - new Date(b.created);
            });
        } else {
            if (CommentsArray && CommentsArray.length > 0) {
                CommentsArray.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                });
            }
        }
        await this.setState({
            CommentsArray: CommentsArray
        })
        // if (this.props.type === "Notes1") {
        //     if (this.props.Comments.length > 0) {
        //         let commentsNotes = this.props.Comments
        //         let commentNote = commentsNotes[0].note
        //         // this.setState({ comment: commentNote })
        //     } else {
        //         this.setState({ comment: "" })

        //     }
        // }
        this.applydefaultTemplateForSelectedRows()
    }
    applydefaultTemplateForSelectedRows = async () => {
        let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
        const shortName = new Date().toLocaleString('en-US', { month: 'short' });
        const DateName = new Date().getDate();
        let selectednote = `${shortName} - ${DateName}  ${loginCredentials.displayName}\n`
        await this.setState({ selectednote: selectednote });
    }
    cancelReset = async (type) => {
        if ((this.props.checkFormType && this.props.checkFormType === "False") || (this.props.screenType === "rfiNotes" && this.props.formType === "add") || (this.props.screenType === "claimDetails" && (this.props.checkNoteMultiSelect === undefined || this.props.checkNoteMultiSelect.length > 1))) {
            this.props.cancelReset()
        }
        else if (this.state.edited === "yes" || this.state.editedOldComment === "yes") {
            await this.setState({ openUnsaveModal: true })
        } else {
            this.props.cancelReset()
        }
    }
    closeTaskAddModel = async (type) => {
        this.props.cancelReset()
    }
    closeSaveModelPopUp = async () => {
        await this.setState({ openUnsaveModal: false })
        await this.submit()
    }
    // Delete Modal
    getDeleteRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openUnsaveModal}
                closeDeleteModal={this.closeSaveModelPopUp}
                deleteSelectedRow={this.closeTaskAddModel}
                unSaved={true}
                notesUnsaved={true}
                confirmModalText={"You have unsaved notes."}
                type={this.props.type}
            />
        )
    }
    submit = async () => {
        await this.setState({ isHit: true })
        if ((this.props.screenType === "claimDetails" && (this.props.checkNoteMultiSelect === undefined || this.props.checkNoteMultiSelect.length > 1 || (this.props.checkFormType && this.props.checkFormType === "False"))) || (this.props.screenType === "rfiNotes" && this.props.formType === "add")) {
            this.props.saveClaimNotes(this.state.commentNew, this.props.notesSno, this.state.CommentsArray, this.state.noteItem, (this.state.edited === "yes" || this.state.editedOldComment === "yes") ? "yes" : "no")
        } else {
            let claimNotes = this.state.CommentsArray && this.state.CommentsArray.length > 0 ? this.state.CommentsArray : []
            if (this.state.noteItem !== undefined) {
                claimNotes[0].note = this.state.noteItem.note
            }
            if (this.state.commentNew !== undefined) {
                claimNotes.unshift({ note: this.state.commentNew })
            }
            let body
            let method, api

            method = "PUT"
            if (this.props.screenType === "claimDetails") {
                let data = {
                    claimNotes: claimNotes
                }
                body = data
                api = `nsClaim/${this.props.checkNoteMultiSelect[0]._id}/UpdateNotes`
            } else if (this.props.screenType === "NextActionReport") {
                let data = {
                    followUpNotes: claimNotes
                }
                body = data
                api = `followUps/${this.props.noteEditId}/UpdateNotes`
            }
            else {
                let data = {
                    notes: claimNotes
                }
                body = data
                api = `${this.props.url ? this.props.url : "tasks"}/${this.props.rfiValues._id}/UpdateNotes`
            }

            return fetch(method, api, body)
                .then(async (response) => {
                    if (response && response.respCode) {
                        await this.setState({ edited: "no", editedOldComment: "no" })
                        if (this.props.screenType === "NextActionReport") {
                            this.props.saveClaimNotes(this.state.commentNew, this.props.notesSno, this.state.CommentsArray, this.state.noteItem, this.state.edited, response.details)
                        } else {
                            this.props.saveClaimNotes(this.state.commentNew, this.props.notesSno, this.state.CommentsArray, this.state.noteItem, this.state.edited)
                        }
                        // if (!type) this.saveDataFormat(values)
                    } else if (response && response.errorMessage) {
                        // this.getErrorMessage(response.errorMessage)
                    }
                }).catch((err) => {
                    return err;
                });
            //hit this function if submit is successful after saving in server
        }
        await this.setState({ isHit: false })
    }
    AddNewNotes = async () => {
        await this.setState({ AddNewNotes: true })
    }
    EditNewNotes = async (item, index) => {
        let commentArray = this.state.CommentsArray
        // let z = commentArray.shift()
        await this.setState({ EditNewNotes: true, noteItem: item, CommentsArray: commentArray })

    }
    clearEditNotes = async () => {
        let commentArray = this.state.CommentsArray
        let z = commentArray.shift()
        await this.setState({ EditNewNotes: false, editedOldComment: "no", noteItem: undefined, CommentsArray: commentArray, removed: "true" })


    }
    clearNewNotes = async () => {
        await this.setState({ AddNewNotes: false, edited: "no", commentNew: undefined })

    }
    onChangeNote = async (note) => {

        this.setState({ commentNew: note, edited: "yes" })
    }
    onChangeNoteEdit = async (note, type, item) => {
        let itemed = this.state.noteItem
        itemed.note = note

        await this.setState({ noteItem: itemed, editedOldComment: "yes" })

    }
    //Copy Function
    clipToCopyBoard(data) {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(data)
        }
    }


    deleteSelectedRow = () => {
        this.props.deleteSelectedRow();
    }

    render() {
        return (

            < div >

                <Modal isOpen={this.props.openNotesModal} fade={false}
                    className='modal-dialog-centered modal-dialog--primary m-auto notes_modal_width'
                >
                    <ModalBody className='deleteModalBody' style={{
                        padding: 10,
                        // width: 110,
                        height: 250,
                        overflow: "auto",
                        textAlign: "left"

                    }}>
                        {this.state.CommentsArray && this.state.CommentsArray.length > 0 && !this.props.claimNotesEdit ?
                            this.state.CommentsArray.map((item, index) => {
                                let noteCreates = new Date(item.created);
                                let monthNo = dateFormats.formatDate(new Date(noteCreates), 'MM-DD-YYYY')
                                // let date = noteCreates.getDate()
                                // let year = noteCreates.getFullYear()
                                let noteUpdates = new Date(item.updated);
                                let monthupdate = dateFormats.formatDate(new Date(noteUpdates), 'MM-DD-YYYY')

                                return <ol className="pl-0" key={index} style={{ borderBottom: "inset" }}>
                                    < >
                                        {item.created ?
                                            <span className="kanban_title notes_details" style={{ display: "block" }}>
                                                <p>
                                                    <span className="mr-0 notesdate_font"><b className='mr-2'>Date :</b>{monthNo}</span>
                                                    <span className="ml-3 notesdate_font"><b className='mr-2'>Updated By:</b>{item.createdBy}</span>
                                                    {/* {item.updated ?
                                                        <><span className="ml-3 notesdate_font"><b className='mr-2'>Updated On:</b>{monthupdate}</span>
                                                            <span className="ml-3 notesdate_font"><b className='mr-2'>Updated By:</b>{item.updatedBy}</span></> : null} */}
                                                    <span><FontAwesomeIcon
                                                        style={{ width: "14px", marginRight: 5, marginTop: 4, marginLeft: 4, float: "right" }}
                                                        icon={faCopy}
                                                        onClick={() => this.clipToCopyBoard(item.note)}
                                                        color={'red'}
                                                        data-toggle="tool-tip"
                                                        title={"Click to copy the notes"}
                                                    /></span>
                                                </p>
                                            </span> : <span>{index + 1}</span>}</>
                                    <p className="mt-0 pb-1" style={{ whiteSpace: "break-spaces" }}
                                    >{item.note}</p>
                                </ol>
                            }) :
                            this.state.CommentsArray && this.state.CommentsArray.length > 0 ?
                                this.state.CommentsArray.map((item, index) => {
                                    let noteCreates = new Date(item.created);
                                    let monthNo = dateFormats.formatDate(new Date(noteCreates), 'MM-DD-YYYY')
                                    let noteUpdates = new Date(item.updated);
                                    let monthupdate = dateFormats.formatDate(new Date(noteUpdates), 'MM-DD-YYYY')
                                    // let date = noteCreates.getDate()
                                    return <ol className=" mt-1 pl-0" key={index} style={{ borderBottom: "inset" }}>
                                        {this.state.AddNewNotes && index === 0 ?
                                            <div className='d-flex'>
                                                <InputTextarea
                                                    cols={70}
                                                    rows={10}
                                                    value={this.state.commentNew ? this.state.commentNew : this.state.selectednote}
                                                    style={{ borderLeft: "1px solid lightgray", marginBottom: "10px" }}
                                                    // disabled={this.props.isDisabledTab}
                                                    // onFocus={this.changeCursorPos}
                                                    onChange={(e) => this.onChangeNote(e.target.value, "note")}
                                                />
                                                <span>
                                                    <FontAwesomeIcon icon={faTimes}
                                                        className='pl-1 mr-2'
                                                        color={'red'}
                                                        data-toggle="tool-tip"
                                                        title="Clear Notes"
                                                        onClick={() => this.clearNewNotes()}
                                                        style={{ width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" }}
                                                    /></span>

                                            </div>

                                            : null}
                                        {this.state.EditNewNotes && index === 0 ?
                                            <div className='d-flex'>
                                                <span>
                                                    <InputTextarea
                                                        cols={70}
                                                        rows={10}
                                                        value={this.state.noteItem.note ? this.state.noteItem.note : this.state.selectednote}
                                                        style={{ borderLeft: "1px solid lightgray", marginBottom: "10px" }}
                                                        // disabled={this.props.isDisabledTab}
                                                        // onFocus={this.changeCursorPos}
                                                        onChange={(e) => this.onChangeNoteEdit(e.target.value, "note", this.state.noteItem)}
                                                    />
                                                    {this.state.removed !== "true" ?
                                                        <FontAwesomeIcon icon={faTimes}
                                                            className='pl-1 mr-2'
                                                            color={'red'}
                                                            data-toggle="tool-tip"
                                                            title="Clear Notes"
                                                            onClick={() => this.clearEditNotes()}
                                                            style={{ width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" }}
                                                        /> : null}
                                                </span>

                                            </div>

                                            : null}
                                        {this.state.EditNewNotes && index === 0 ? null : item.created ?
                                            <span className="kanban_title notes_details d-block">
                                                <p><span className="mr-0 notesdate_font"><b className='mr-2'>Date:</b>{monthNo}</span>
                                                    <span className="mr-0 ml-3 notesdate_font"><b className='mr-2'>Updated By:</b>{item.createdBy}</span>
                                                    {/* {item.updated ? <><span className="ml-3 notesdate_font"><b className='mr-2'>Updated on:</b>{monthupdate}</span>
                                                        <span className="ml-3 notesdate_font"><b className='mr-2'>Updated By:</b>{item.updatedBy}</span></>
                                                        : null} */}
                                                </p>

                                                {/* <span className="mr-1 notesdate_font">{date}</span>{item.createdBy ? "-" : ""} */}
                                            </span> : <p>{index + 1}</p>}
                                        {this.state.EditNewNotes && index === 0 ? null : index === 0 ?
                                            <Button color='primary' className="btnRadius" style={{ float: "right", marginRight: "7px" }}
                                                onClick={() => this.EditNewNotes(item, index)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className='mt-0 mr-0' size='lg'
                                                    data-toggle="tool-tip" title="Edit New Notes"
                                                />
                                            </Button>
                                            : null}
                                        <p className="mt-0 pb-1" style={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}
                                        >
                                            {this.state.EditNewNotes && index === 0 ? null : item.note}</p>
                                        <div>

                                        </div>
                                    </ol>


                                }) :

                                this.state.AddNewNotes ?
                                    <div className='d-flex'>
                                        <InputTextarea
                                            cols={70}
                                            rows={10}
                                            value={this.state.commentNew ? this.state.commentNew : this.state.selectednote}
                                            style={{ borderLeft: "1px solid lightgray", marginBottom: "10px" }}
                                            // disabled={this.props.isDisabledTab}
                                            // onFocus={this.changeCursorPos}
                                            onChange={(e) => this.onChangeNote(e.target.value, "note")}
                                        />
                                        <span>
                                            <FontAwesomeIcon icon={faTimes}
                                                className='pl-1 mr-2'
                                                color={'red'}
                                                data-toggle="tool-tip"
                                                title="Clear Notes"
                                                onClick={() => this.clearNewNotes()}
                                                style={{ width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" }}
                                            /></span>

                                    </div>

                                    : null


                            // <div>
                            //     {/* <InputTextarea
                            //         cols={70}
                            //         rows={10}
                            //         value={this.state.comment ? this.state.comment : this.state.selectednote}
                            //         style={{ borderLeft: "1px solid lightgray", marginBottom: "10px" }}
                            //         // disabled={this.props.isDisabledTab}
                            //         // onFocus={this.changeCursorPos}
                            //         onChange={(e) => this.onChangeNote(e.target.value, "note")}
                            //     /> */}
                            //     <InputTextarea
                            //         cols={70}
                            //         rows={10}
                            //         value={this.state.comment ? this.state.comment : this.state.selectednote}
                            //         style={{ borderLeft: "1px solid lightgray", marginBottom: "10px" }}
                            //         // disabled={this.props.isDisabledTab}
                            //         // onFocus={this.changeCursorPos}
                            //         onChange={(e) => this.onChangeNote(e.target.value, "note")}
                            //     />

                            //     <Button color='primary' className="btnRadius" style={{ float: "right", marginRight: "7px" }}
                            //     //  onClick={() => this.addSubItemtoPriorityList(this.state.sublevel1Count, item, i, 'level1')}
                            //     >
                            //         <FontAwesomeIcon
                            //             icon={faEdit}
                            //             className='mt-0 mr-0' size='lg'
                            //             data-toggle="tool-tip" title="Edit New Notes"
                            //         />
                            //     </Button>
                            // </div>

                        }



                    </ModalBody>
                    <ModalFooter className="modalFooter " style={{ width: "100%", padding: "0px", backgroundColor: "white", borderTop: "none" }}>
                        {this.props.type === "Notes1" && this.props.claimNotesEdit ?
                            <div>
                                <Button color="primary" outline
                                    // onClick={this.props.saveClaimNotes(this.state.comment)}
                                    onClick={() => this.AddNewNotes()}
                                    className='deleteModalBtn marginRight'>Add New Notes</Button>
                                <Button color="primary" outline
                                    disabled={this.state.isHit}
                                    onClick={() => this.submit(this.state.commentNew, this.props.notesSno, this.state.CommentsArray, this.state.noteItem, this.state.edited)}
                                    // onClick={() => this.props.saveClaimNotes(this.state.commentNew, this.props.notesSno, this.state.CommentsArray, this.state.noteItem, this.state.edited)}
                                    className='deleteModalBtn marginRight'>Submit</Button>

                            </div>

                            : null}
                        <Button color="primary" outline
                            onClick={this.cancelReset}
                            className='deleteModalBtn marginRight'>Back</Button>
                    </ModalFooter>
                </Modal >
                {this.state.openUnsaveModal ? this.getDeleteRowModal() : null}
            </div >
        );
    }
}
export default withTranslation('common')(NotesModal);
