import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
// validate
// import validate from './validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../../../redux/actions/userActions';
import SessionWarningModal from '../../../Cruds/CommonModals/SessionWarningModal';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import configMessages from '../../../../config/configMessages';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class AvailableFile extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      hitGoButton: false,
      States: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      filterCriteria: {},
      SelectedStates: [],
      DealType: [],
      // DealTypeV2: ["BK", "CR"],
      DealTypeV2: [],
      isGreen25: true,
      isGreen10_25: false,
      isGreen5_10: false,
      isGreen1_5: false,
      isGreen1: false,
      isGreenAll: false,
      isBlue25: true,
      isBlue10_25: false,
      isBlue5_10: false,
      isBlue1_5: false,
      isBlue1: false,
      isBlueAll: false,
      isExcludeRoyalties: true,
      excludeCoOwners: false,
      isexcludePossiblyPaid: false,
      ranges: [">$25k"],
      selectedBK: true,
      selectedCR: true
    };
  }
  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.searchsellerItems()
    await this.getDepartments()
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")&&!user.defaultUser) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      await this.setState({ Department: DepartmentArray, departMentIdArray: departMentIdArray })
      this.submit()
    }

  }
  assignTeamFromResourceNeeded = async (DepartmentArray, departmentIdArray) => {
    await this.setState({ Department: DepartmentArray, departMentIdArray: departmentIdArray, Department: DepartmentArray })

  }
  onChangeDepartment = async (e) => {
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ Department: DepartmentArray, departMentIdArray: departmentIdArray, Department: e.value, })
    // await this.getDataFromServer(this.state.filterCriteria)
  }
  /*
   Fetch all Departments from the api 
   */
  getDepartments = () => {
    let apiUrl;
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" },{ key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id

            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  changeDealType = async () => {
    this.setState({ DealTypeV2: [] })
  }
  handleChange = async (e, type) => {
    var ranges = this.state.ranges
    var index;
    if (type === "isGreen25") {
      await this.setState({
        isGreen25: this.state.isGreen25 === false ? true : false
      });
      if (!ranges.includes(">$25k") && this.state.isGreen25) {
        ranges.push(">$25k")
      } else {
        index = ranges.indexOf(">$25k");
        ranges.splice(index, 1);
      }
    } else if (type === "isGreen10_25") {
      await this.setState({
        isGreen10_25: this.state.isGreen10_25 === false ? true : false
      });
      if (!ranges.includes("$10k-$25k") && this.state.isGreen10_25) {
        ranges.push("$10k-$25k")
      } else {
        index = ranges.indexOf("$10k-$25k");
        ranges.splice(index, 1);
      }
    } else if (type === "isGreen5_10") {
      await this.setState({
        isGreen5_10: this.state.isGreen5_10 === false ? true : false
      });
      if (!ranges.includes("$5k-$10k") && this.state.isGreen5_10) {
        ranges.push("$5k-$10k")
      } else {
        index = ranges.indexOf("$5k-$10k");
        ranges.splice(index, 1);
      }
    } else if (type === "isGreen1_5") {
      await this.setState({
        isGreen1_5: this.state.isGreen1_5 === false ? true : false
      });
      if (!ranges.includes("$1k-$5k") && this.state.isGreen1_5) {

        ranges.push("$1k-$5k")

      } else {
        index = ranges.indexOf("$1k-$5k");
        ranges.splice(index, 1);
      }
    } else if (type === "isGreen1") {
      await this.setState({
        isGreen1: this.state.isGreen1 === false ? true : false
      });
      if (!ranges.includes("<$1k") && this.state.isGreen1) {
        ranges.push("<$1k")
      } else {
        index = ranges.indexOf("<$1k");
        ranges.splice(index, 1);
      }

    } else if (type === "isGreenAll") {
      await this.setState({
        isGreenAll: this.state.isGreenAll === false ? true : false,
      });
      await this.setState({
        beforeAllranges: ranges
      })
      if (!ranges.includes("All") && this.state.isGreenAll) {

        ranges.push("All")
        // await this.setState({
        //   isGreen25: false,
        //   isGreen10_25: false,
        //   isGreen5_10: false,
        //   isGreen1_5: false,
        //   isGreen1: false,
        // });
      } else {
        index = ranges.indexOf("All");
        ranges.splice(index, 1);
      }

    } else if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
      })
    } else if (type === "confirmedSite") {

      await this.setState({
        confirmedSite: e,
      })
    } else if (type === "DealType") {


      if (this.props.type === "UP Opportunity – V2") {
        await this.setState({
          DealTypeV2: e,
          hitGoButton: true
        })
      } else {
        await this.setState({
          DealTypeV2: e,
          hitGoButton: true
        })
      }
    } else {
      await this.setState({
        SelectedStates: e
      })
    }
    await this.setState({
      ranges: ranges
    })
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }
  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === "Session expired please login again." || response.errorMessage === "Token not provided")) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleChangeExcludeRoyalties = (type) => {
    this.setState({
      isExcludeRoyalties: !this.state.isExcludeRoyalties
    })
  }
  handleChangeExcludeCoOwners = (type) => {
    this.setState({
      excludeCoOwners: !this.state.excludeCoOwners
    })
  }
  handleChangePossiblyPaid = (type) => {
    this.setState({
      isexcludePossiblyPaid: !this.state.isexcludePossiblyPaid
    })
  }



  resetFilters = async () => {
    let filterCriteria = this.props.filterCriteria;
    filterCriteria["criteria"] = []
    await this.setState({
      SelectedStates: [],
      DealType: [],
      DealTypeV2: [],
      selectedSeller: "",
      isGreen25: true,
      isGreen10_25: false,
      isGreen5_10: false,
      isGreen1_5: false,
      isGreen1: false,
      isGreenAll: false,
      confirmedSite: "",
      isBlue25: true,
      isBlue10_25: false,
      isBlue5_10: false,
      isBlue1_5: false,
      isBlue1: false,
      isBlueAll: false,
      excludeCoOwners: false,
      isexcludePossiblyPaid: false,
      isExcludeRoyalties: true,
      ranges: [">$25k"],
    })
    let Obj = {
      ranges: this.state.ranges,
      fromSummaries: true
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")&&!user.defaultUser) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      // this.submit()
    }
    this.setState({ Department: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })
    await this.props.setTeam(DepartmentArray, departMentIdArray)
    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      Obj.states = this.state.SelectedStates
    }
    if (this.state.DealType && this.state.DealType.length > 0) {
      Obj.DealType = this.state.DealType
    }
    if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
      Obj.DealType = this.state.DealTypeV2
    }
    this.submit()

  }
  submit = () => {
    if (this.props.type === "UP Opportunity") {
      let Obj = {
        ranges: this.state.ranges,
        fromSummaries: true
      }
      if (this.state.selectedSeller)
        Obj.sellerId = this.state.selectedSeller.EstateID
      if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
        Obj.states = this.state.SelectedStates
      }
      if (this.state.DealType && this.state.DealType.length > 0) {
        Obj.DealType = this.state.DealType
      }

      let addfilters = []
      if (this.state.isExcludeRoyalties) {
        addfilters.push("isExcludeRoyalties")
      }
      if (this.state.excludeCoOwners) {
        addfilters.push("excludeCoOwners")
      }
      if (this.state.isexcludePossiblyPaid) {
        addfilters.push("isexcludePossiblyPaid")
      }
      this.props.getUsersSummaryData(Obj, addfilters)
    } else {
      let Obj = {

      }
      // if (this.state.selectedSeller)
      //   Obj.sellerId = this.state.selectedSeller.EstateID
      // if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      //   Obj.states = this.state.SelectedStates
      // }
      let deals = []

      // if (this.state.selectedBK) {
      //   deals.push("BK")
      // }
      // if (this.state.selectedCR) {
      //   deals.push("CR")
      // }
      if (this.state.selectedSeller)
        Obj.sellerId = this.state.selectedSeller.EstateID
      if (this.state.SelectedStates && this.state.SelectedStates.length > 0 && this.props.type !== "CA-N Properties") {
        Obj.states = this.state.SelectedStates
      }
      if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
        Obj.dealType = this.state.DealTypeV2

      }

      if (this.state.confirmedSite && this.state.confirmedSite.length > 0) {
        Obj.ConfirmedOnsite = this.state.confirmedSite
      }
      if (this.state.Department) {
        // let index = filterCriteria["criteria"].findIndex(obj => obj.key === "Department")
        // if (index !== -1) {
        //   filterCriteria["criteria"].splice(index, 1)

        // }
        // filterCriteria.criteria.push({ key: "Department", value: this.state.Department, type: "in" })
        Obj.departMentIdArray = this.state.departMentIdArray
      }



      Obj.hitGoButton = this.state.hitGoButton

      // Obj.dealType = deals
      // Obj.states = []
      // let addfilters = []
      // if (this.state.isExcludeRoyalties) {
      //   addfilters.push("isExcludeRoyalties")
      // }
      // if (this.state.excludeCoOwners) {
      //   addfilters.push("excludeCoOwners")
      // }
      // if (this.state.isexcludePossiblyPaid) {
      //   addfilters.push("isexcludePossiblyPaid")
      // }
      this.props.setDataCriteriaforOpportunity(this.props.filterCriteria, Obj)
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      this.props.type === "UP Opportunity" ?
        <div>
          <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
            <Card className="py-2 ">
              <CardBody className="p-0">
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                  <div className='w-100 property_Summary'>
                    <Row >
                      <h4 htmlFor="cb3 col-sm-4 mr-4" className="p-checkbox-label" >Available Ranges :
                        <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandatory Field"
                          style={{ width: 8 }}
                        /> </h4>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-3"
                          checked={this.state.isGreen25}
                          onChange={(e) => this.handleChange(e, "isGreen25")}
                        />
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label" style={{ marginRight: "50px", fontSize: 14 }}>{">$25k"}</label>

                      </div>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-2"
                          checked={this.state.isGreen10_25}
                          onChange={(e) => this.handleChange(e, "isGreen10_25")}
                        />
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px", fontSize: 14 }}>{">$10k-$25k"}</label>

                      </div>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-2"
                          checked={this.state.isGreen5_10}
                          onChange={(e) => this.handleChange(e, "isGreen5_10")}
                        />
                        <label htmlFor="cb3  col-sm-3" className="p-checkbox-label" style={{ marginRight: "50px", fontSize: 14 }}>{"$5k-$10k"}</label>
                      </div>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-2"
                          checked={this.state.isGreen1_5}
                          onChange={(e) => this.handleChange(e, "isGreen1_5")}
                        />
                        <label htmlFor="cb3  col-sm-3" style={{ marginRight: "50px", fontSize: 14 }} className="p-checkbox-label">{"$1k-$5k"}</label>

                      </div>
                      <div className='mt-2'>
                        <Checkbox
                          label='isexcludePossiblyPaid'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'isexcludePossiblyPaid'}
                          className=" ml-2"
                          checked={this.state.isGreen1}
                          onChange={(e) => this.handleChange(e, "isGreen1")}
                        />
                        <label htmlFor="cb3  col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px", fontSize: 14 }}>{"<$1k"}</label>
                      </div>
                      <div className='mt-2'>
                        <Checkbox
                          label='isexcludePossiblyPaid'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'isexcludePossiblyPaid'}
                          className=" ml-2"
                          checked={this.state.isGreenAll}
                          onChange={(e) => this.handleChange(e, "isGreenAll")}
                        />
                        <label htmlFor="cb3  col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px", fontSize: 14 }}>{"All"}</label>
                      </div>
                    </Row>

                    <Row className="mt-1">

                      <div className="ml-3" style={{ width: "30%" }}>
                        <div className={"pb-2"} >
                          <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Deal</label>
                          <div >
                            <AutoComplete
                              value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller&&this.state.selectedSeller.DealYear?this.state.selectedSeller.DealYear:""})` : this.state.selectedSeller}
                              // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                              suggestions={this.state.filteredSuggestions}
                              completeMethod={this.searchsellerItems}
                              minLength={1}
                              itemTemplate={this.editorTemplateForDeal}
                              field={"EstateName"}
                              style={{ width: "100%" }}
                              dropdown={true}
                              onChange={(e) => this.handleChange(e, 'Seller')}
                              appendTo={document.body} />
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group col-sm-2 pl-0" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Deal Type</label>
                        <div className="form__form-group-field">
                          <MultiSelect
                            // id={item.id}
                            name={"dealType"}
                            component={MultiSelect}
                            onChange={(e) => this.handleChange(e.value, "DealType", "in")}
                            type={"dropDown"}
                            value={this.state.DealType}
                            filter={true}
                            style={{ width: "100%" }}
                            options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                            placeholder={""}
                          // isDisable={item.isDisable ? item.isDisable : false}
                          />
                        </div>
                      </div>
                      <div className="form__form-group col-sm-2 pl-0" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>State</label>
                        <div className="form__form-group-field">
                          <MultiSelect
                            // id={item.id}
                            name={"states"}
                            component={MultiSelect}
                            onChange={(e) => this.handleChange(e.value, "States", "in")}
                            type={"dropDown"}
                            filter={true}
                            value={this.state.SelectedStates}
                            style={{ width: "100%" }}
                            options={this.state.States}
                            placeholder={""}
                          // isDisable={item.isDisable ? item.isDisable : false}
                          />
                        </div>
                      </div>
                      {/* <div>
                      <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Excl.Possibly Paid"}</label>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'isexcludePossiblyPaid'}
                          className=""
                          checked={this.state.isexcludePossiblyPaid}
                          onChange={(e) => this.handleChangePossiblyPaid(e, "isexcludePossiblyPaid")}
                        />
                      </div>
                    </div> */}

                      <div>
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-0" style={{ marginRight: "10px", fontSize: 14 }}>{"Excl. Co Owners"}</label>
                        <div className='mt-2'>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'excludeCoOwners'}
                            className=""
                            checked={this.state.excludeCoOwners}
                            onChange={(e) => this.handleChangeExcludeCoOwners(e, "excludeCoOwners")}
                          />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-0" style={{ marginRight: "50px", fontSize: 14 }}>{"Excl. Royalties"}</label>
                        <div className='mt-2'>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'Royalities'}
                            className=""
                            checked={this.state.isExcludeRoyalties}
                            onChange={(e) => this.handleChangeExcludeRoyalties(e, "isExcludeRoyalties")}
                          />
                        </div>
                      </div>


                      <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button>
                      <div className="mt-2" >
                        <span className=" " style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                              width: 16,
                              height: 16,
                              margin: 5,
                              marginTop: 20,
                            }}
                            color="red"
                            onClick={() => this.resetFilters()}
                          />
                        </span>
                      </div>

                    </Row>

                    <Row className="ml-0 mt-2">
                      <b><h5 htmlFor="cb3 col-sm-4 mr-4" className="p-checkbox-label my-2" >Exclusions :</h5></b><span className="my-2">
                        {configMessages.ExclusionMessage}
                      </span></Row>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col >
          {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        </div>

        :
        <div style={{ marginTop: "20px" }}>
          <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
            <Card className="py-2 ">
              <CardBody className="p-0">
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                  <div className='w-100 property_Summary'>
                    {/* {
                      this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 ?
                        <div className="d-flex align-items-center" style={this.props.tabNo % 2 == 0 || this.props.tabNo == 0 ? { width: "100%", backgroundColor: "#f5eee3", marginBottom: "5px", height: "40px" } : { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" }}>
                          <h4 className='ml-2'><b>{this.props.tabType}: </b>
                            {this.props.tabDescriptionArray}</h4></div> :
                        null
                    } */}

                    <Row className="mt-1">

                      <div className="ml-1" style={{ width: "24%" }}>
                        <div className={"pb-2"} >
                          <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Deal</label>
                          <div >
                            <AutoComplete
                              value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                              // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                              suggestions={this.state.filteredSuggestions}
                              completeMethod={this.searchsellerItems}
                              minLength={1}
                              itemTemplate={this.editorTemplateForDeal}
                              field={"EstateName"}
                              style={{ width: "100%" }}
                              dropdown={true}
                              onChange={(e) => this.handleChange(e, 'Seller')}
                              appendTo={document.body} />
                          </div>
                        </div>
                      </div>
                      <div className="form__form-group col-sm-2 pl-0 ml-1" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Deal Type</label>
                        <div className="form__form-group-field">
                          <MultiSelect
                            // id={item.id}
                            name={"dealType"}
                            component={MultiSelect}
                            onChange={(e) => this.handleChange(e.value, "DealType", "in")}
                            type={"dropDown"}
                            filter={true}
                            value={this.state.DealTypeV2}
                            style={{ width: "100%" }}
                            options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                            placeholder={""}
                          // isDisable={item.isDisable ? item.isDisable : false}
                          />
                        </div>
                      </div>
                      <div className="form__form-group col-sm-2 pl-0 ml-2" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>Confirmed Onsite</label>
                        <div className="form__form-group-field">
                          <Dropdown
                            value={this.state.confirmedSite}
                            appendTo={document.body}
                            filter={true}
                            style={{ width: "100%", height: "32px" }}
                            options={[{ label: "Only Confirmed", value: "Only Confirmed" }, { label: "Both", value: "Both" }, { label: "Not Confirmed", value: "Not Confirmed" }]}
                            onChange={(e) => this.handleChange(e.value, "confirmedSite", "in")}
                          />
                        </div>
                      </div>

                      <div className="kanban_filter_field col-sm-2 ml-1" id='department'>
                        <label className="mr-1 kanban_filter_label" id="department" style={{ marginTop: 3 }}>
                          Team:
                        </label>
                        <MultiSelect
                          id="department"
                          className={"form__form-group-field "}
                          style={{ width: "100%", height: 32 }}
                          filter={true}
                          value={this.state.Department}
                          options={this.state.taskDepartmentItems}
                          onChange={(e) => this.onChangeDepartment(e, "Department")}
                          placeholder=''
                        />
                      </div>


                      {this.props.type !== "CA-N Properties" ?
                        <div className="form__form-group col-sm-2 pl-0 ml-2" >
                          <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>State</label>
                          <div className="form__form-group-field">
                            <MultiSelect
                              // id={item.id}
                              name={"states"}
                              component={MultiSelect}
                              onChange={(e) => this.handleChange(e.value, "States", "in")}
                              type={"dropDown"}
                              filter={true}
                              value={this.state.SelectedStates}
                              style={{ width: "100%" }}
                              options={this.state.States}
                              placeholder={""}
                            // isDisable={item.isDisable ? item.isDisable : false}
                            />
                          </div>
                        </div>
                        : null}
                      {/* <div>
                      <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Excl.Possibly Paid"}</label>
                      <div className='mt-2'>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'isexcludePossiblyPaid'}
                          className=""
                          checked={this.state.isexcludePossiblyPaid}
                          onChange={(e) => this.handleChangePossiblyPaid(e, "isexcludePossiblyPaid")}
                        />
                      </div>
                    </div> */}

                      <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button>
                      <div className="mt-2" >
                        <span className=" " style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                              width: 16,
                              height: 16,
                              margin: 5,
                              marginTop: 20,
                            }}
                            color="red"
                            onClick={() => this.resetFilters()}
                          />
                        </span>
                      </div>

                    </Row>


                  </div>
                </form>
              </CardBody>
            </Card>
          </Col >
          {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        </div>



    );
  }
}




// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AvailableFile = reduxForm({
  form: "AvailableFileForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(AvailableFile);

// You have to connect() to any reducers that you wish to connect to yourself
AvailableFile = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(AvailableFile);

export default withTranslation('common')(AvailableFile);