// eslint-disable no-console, no-control-regex
import React from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Table,
} from 'reactstrap';
import classNames from 'classnames';
import { CSVLink } from "react-csv";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';
import { OverlayPanel } from 'primereact/overlaypanel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Column } from 'primereact/column';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotesModal from '../Cruds/CommonModals/NotesModal'
import Scrollbar from 'react-smooth-scrollbar';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import PaginatorComponent from '../Cruds/CommonDataTable/PaginatorComponent';
import renderCheckBoxField from '../../shared/components/form/ToggleButton';
import Checkbox from '../../shared/components/form/CheckBox';

//config
import config from '../../config/config';
import apiCalls from '../../config/apiCalls';
import configMessages from '../../config/configMessages';
import fetchMethodRequest from '../../config/service';

//components 
import AutoComplete from './components/AutoComplete';
import Select from '../../shared/components/form/Select';
import { MultiSelect } from 'primereact/multiselect';
import MultiSelectDropDown from './components/MultiSelect';
import RenderFileInputField from '../Form/components/FileUpload';
import DatePicker from '../../shared/components/form/DatePicker';
import TimePicker from '../../shared/components/form/TimePicker';
import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import renderRadioButtonField from '../../shared/components/form/RadioButton';

import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';

// Loader
import Loader from '../App/Loader';
import OnClick from '../Cruds/onclick/onclick';

// modals
import CompanyModal from '../Cruds/CommonModals/RulesModal'
import DeleteRowModal from '../Cruds/CommonModals/DeleteRowModal';
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal'
import { faArrowCircleLeft, faExpand, faUsers, faFolder, faArrowLeft, faPlus, faMinus, faSave, faExternalLinkAlt, faStarOfLife, faTimes, faTimesCircle, faToggleOff, faFilter, faBuilding, faToggleOn, faInfo } from '@fortawesome/free-solid-svg-icons';

// const ruleEngineIndex = require('../../rule-engine/js/ruleengine.build')
const radioRequired = value => {
  let error = undefined;
  if (value || typeof value === 'string') {
    error = undefined
  } else {
    error = configMessages.fillRadio
  }
  return error
}
let timeout

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const normalizeStringwithoutsc = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/([^\w]+-_|\s+)/g, '');
  return `${onlyNums}`;
};

const normalizenumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\.\d]/g, "");
  return `${onlyNums}`;
};

const multiSelectValidation = (value) => {
  let error = undefined;
  if (value && value.length > 0) {
    error = undefined
  } else {
    error = configMessages.fillField
  }
  return error

}

class FormModal extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      formFields: [],
      formValues: {},
      colored: false,
      header: true,
      permissions: {},
      isLoading: false,
      filterCriteria: {
        limit: 50, page: 1, criteria: [],
        sortfield: 'CompanyBuySideID',
        direction: 'desc'
      },

      selectFilterValue: "",
      sessionExpiryModal: false,
      sessionWarning: false,
      menuList: this.props.menuList ? this.props.menuList : [],
      defaultValue: true,
      roleOptions: [],
      slno: 0,
      exportData: [],
      removedFiles: [],
      userName: '',
      expandAll: false,
      selectsearchFilterValue: "",
      holding: [],
      investorStatus: [],
      investorType: [],
      paymentItem: "",
      filterSelect: "",
      HoldingFilter: false,
      hidebtn: false,
      InvestorStatusFilter: false,
      InvestorTypeFilter: false,
      searchFilterCriteria: [],
      rowData: '',
      formType: this.props.formType,
      userData: '',
      displayBreadCrumbValue: this.props.displayBreadCrumbValue,
      displayBreadCrumbField: this.props.displayBreadCrumbField,
      originalTableFields: this.props.originalTableFields,
      userStatus: this.props.userStatus,
      openUserPasswordResetModal: false,
      actions: '',
      activeTab: "1",
      confirmType: '',
      confirmModalText: '',
      validationExists: false,
      openDeleteModal: false,
      activitiesData: [],
      totalRecordsLength: 0,
      first: 0,
      rows: 10,
      keyCount: 0,
      attributeListArray: [],
      showKeyPairTable: true,
      attributeCode: true,
      attributeData: this.props.attributeData,
      statusFilterCriteria: {
        criteria: [{
          key: "activeIndicator",
          value: true,
          type: "eq"
        }]
      },
      priorityCount: 0,
      taskPriorityList: [],
      sublevel1Count: 0,
      sublevel2Count: 0,
      investorCompany: {},
      mbd: false,
      companySourceType: null,
      DealIdLimit: 2000,
      noData: true,
      selectedCompanyName: "",
      elasticThreshold: 2500,
      purgeUnreviewedProperties: false
    };
  }

  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 60000);
    }
    if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
      localStorage.removeItem('serverError')
    }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  componentDidMount = async () => {
    // this.getMenuListFromServer()
    if (this.props.formType === 'add') {
      await this.props.reset();
      await this.props.load({})
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // if (this.props.sessionWarning === true) {
    //   await this.setState({
    //     sessionWarning: true,
    //   })
    // }
    let sessionexpired = await localStorage.getItem('sessionexpired')
    if (sessionexpired === "true") {
      await this.setState({ sessionExpiryModal: true })
    }
    let loggedUser = JSON.parse(localStorage.getItem("loginCredentials"))
    let loginRole = loggedUser.displayName

    this.setState({
      loginRole: loginRole,
      formType: this.props.formType,
      displayBreadCrumbValue: this.props.displayBreadCrumbValue,
      displayBreadCrumbField: this.props.displayBreadCrumbField,
      userStatus: this.props.userStatus,
    })
    this.getFormFields();
    this.handlePercentage('1')
    // if (this.props.type === "Company" || this.props.type === "Investor") {
    //   this.getActivities()
    // }
    if (this.props.type === "Roles") {
      let menu = this.props.menuList.sort(this.compare)
      var result = menu.map(function (el) {
        var o = Object.assign({}, el);
        o.Edit = true;
        o.View = true;
        o.NoView = false;
        return o;
      })
      this.setState({
        menuList: result
      })
    }
    if (this.props.type === "Purposes" && this.props.formType === "add") {
      this.getRecords('', '', apiCalls.Status, 'statusName')
    }
    if (this.props.type === "Available To File - Statuses" && this.props.formType === "edit") {
      this.getRecordsForReason({ status: this.props.selectedInfo.status }, 'status', apiCalls.propertyStatusReason, 'reason')
    }
  }

  compare(a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  onPageChange = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      await this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
        // expandAll: false,
        expandedRows: null

      })
    }
    this.getActivities('', this.state.filterCriteria)
  }

  getTableFields = () => {
    let data
    if ((this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor") {
      data = this.props.getAcquisitionFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "3") {
      if (this.props.city === "Public") {
        data = this.props.getPublicAffiliateCompanies()
      } else {
        data = this.props.getAffiliatesFields()
      }
    } else if ((this.props.type === "Company" && (this.state.activeTab === "4" || this.state.activeTab === "5")) || this.props.type === "Investor") {
      data = [
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 130,
          field: 'CompanyID',
          getField: "CompanyID",
          fieldType: (this.state.tabName && this.state.tabName === "Investments") || this.props.type === "Investor" ? 'ParentCompany' : "",
          header: 'Company ID',
          filter: false,
          sortable: true,
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 200,
          field: 'CompanyName',
          header: 'Company Name',
          filter: true,
          sortable: true
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 130,
          field: 'InvestorID',
          getField: "InvestorID",
          fieldType: this.state.tabName && this.state.tabName === "Investors" ? 'ParentCompany' : "",
          header: 'Investor ID',
          filter: false,
          sortable: true,
        }, {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 200,
          field: 'InvestorName',
          header: 'Investor Name',
          filter: true,
          sortable: true
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 200,
          field: 'InvestorSince',
          fieldType: 'Date',
          "dateFormat": 'MM/DD/YYYY',
          type: 'date',
          header: 'Investor Since',
          filter: true,
          sortable: true
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 130,
          field: 'Holding',
          header: 'Holding',
          filterElement: this.state.holding,
          filter: true,
          // filter: false,
          sortable: true,
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 165,
          field: 'InvestorStatus',
          header: 'Investor Status',
          filter: true,
          // filter: false,
          filterElement: this.state.investorStatus,
          sortable: true
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 165,
          field: 'InvestorType',
          header: 'Investor Type',
          // filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
          filterElement: this.state.investorType,
          filter: true,
          // filter: false,
          sortable: true
        },
        {
          show: true,
          mobile: true,
          textAlign: 'left',
          width: 200,
          field: 'InvestorWebsite',
          fieldType: 'website',
          header: 'Investor Website',
          filter: true,
          sortable: false
        },
      ];
    } else if (this.props.type === "Company" && this.state.activeTab === "6") {
      data = this.props.getNewsFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "7") {
      data = this.props.getContactsFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "8") {
      data = this.props.getNamesAddressFields()
    } else if (this.props.type === "Company" && this.state.activeTab === "9") {
      if (this.props.city === "Public") {
        data = this.props.getPublicCompanies()
      } else {
        data = this.props.getPreAPAFields()
      }
    }
    return data;
  };

  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows ? this.state.rows : this.state.filterCriteria["limit"]}
        onPageChange={this.onPageChange}
        isWeb={true}
      />
    )
  }

  getActivities = async (id, filterCriteria, forFilter) => {
    if (this.props.type === "Company") {
      localStorage.setItem('SearchedCompany', this.state.userData.CompanyName)
    }
    let url, dataObj, str, key, apiUrl, sortField;
    if (this.state.userData.CompanyID) {
      str = this.state.userData.CompanyID
    } else {
      str = this.state.userData.CIK
    }
    if ((this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor") {
      let filterCriteria = this.state.filterCriteria
      if (this.state.userData.TargetCompanyID) {
        filterCriteria['criteria'] = []
        filterCriteria['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyBuySideID"
        url = `${apiCalls.ChildCompanies}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${this.state.userData.TargetCompanyID}`
        dataObj = "childCompanies"
      } else {
        filterCriteria['criteria'] = []
        filterCriteria['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyBuySideID"
        url = `${apiCalls.ChildCompanies}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
        dataObj = "childCompanies"
      }
    } else if ((this.props.type === "Company" && this.state.activeTab === "4") || this.props.type === "Investor") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "InvestorID"
      filter['criteria'] = [{ key: "InvestorID", value: str, type: 'eq' }];
      if (forFilter || this.state.searchFilterCriteria.length > 0) {
        for (let searchCriteria of this.state.searchFilterCriteria) {
          if (searchCriteria.value.length > 0) {
            filter['criteria'].push({
              key: searchCriteria.key,
              value: searchCriteria.value,
              type: 'in'
            });
          }
        }
      }
      if (!forFilter) {
        await this.setState({ holding: [], investorStatus: [], investorType: [] })
      }
      await this.setState({ filterCriteria: filter })
      url = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}`
      dataObj = "investors"
    } else if (this.props.type === "Company" && this.state.activeTab === "3") {
      let filter = this.state.filterCriteria
      if (this.props.city === "Public") {
        apiUrl = apiCalls.publicAffiliates;
        key = "ParentCik";
        dataObj = "publicAffiliates";
        sortField = "Name"
      } else {
        apiUrl = apiCalls.affiliates;
        key = "CI.CompanyID";
        dataObj = "affiliates";
        sortField = "CompanyID"
      }
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : sortField
      filter['criteria'] = [{ key: key, value: str, type: 'eq' }];
      url = `${apiUrl}?filter=${JSON.stringify(this.state.filterCriteria)}`
    } else if (this.props.type === "Company" && this.state.activeTab === "5") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "CompanyID"
      filter['criteria'] = [{ key: "CI.CompanyID", value: str, type: 'eq' }];
      if (forFilter || this.state.searchFilterCriteria.length > 0) {
        for (let searchCriteria of this.state.searchFilterCriteria) {
          if (searchCriteria.value.length > 0) {
            filter['criteria'].push({
              key: searchCriteria.key,
              value: searchCriteria.value,
              type: 'in'
            });
          }
        }
      }
      if (!forFilter) {
        await this.setState({ holding: [], investorStatus: [], investorType: [], HoldingFilter: false, InvestorStatusFilter: false, InvestorTypeFilter: false })
      }
      await this.setState({ filterCriteria: filter })
      url = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}`
      dataObj = "investors"
    } else if (this.props.type === "Company" && this.state.activeTab === "6") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "PublishDate"
      filter['criteria'] = [];
      url = `${apiCalls.News}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "news"
    } else if (this.props.type === "Company" && this.state.activeTab === "7") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "PersonName"
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = [];
      url = `${apiCalls.Leaders}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "leaders"
    } else if (this.props.type === "Company" && this.state.activeTab === "8") {
      let filter = this.state.filterCriteria
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : "BusinessName"
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = [];
      url = `${apiCalls.AddressNames}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = "addressnames"
    } else if (this.props.type === "Company" && this.state.activeTab === "9") {
      let filter = this.state.filterCriteria
      if (this.props.city === "Public") {
        apiUrl = "businessNames/publicAPA";
        dataObj = "publicAPAs";
        sortField = "CIK"
      } else {
        apiUrl = apiCalls.PreAPA;
        dataObj = "apas";
        sortField = "CompanyName"
      }
      filter['sortfield'] = filterCriteria && filterCriteria['sortfield'] ? filterCriteria['sortfield'] : sortField
      filter['direction'] = filterCriteria && filterCriteria['direction'] ? filterCriteria['direction'] : "asc"
      filter['criteria'] = [];
      url = `${apiUrl}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${str}`
      dataObj = dataObj
    }
    await this.setState({
      isLoading: true
    })
    this.handlePercentage('1')
    return fetchMethodRequest('GET', url).then(async (response) => {
      if (response && response[dataObj] && response[dataObj].length > 0) {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        let responseData = '', totalRecordsLength = this.state.totalRecordsLength;
        let rows
        let totalGrandChildrenCount = this.state.totalGrandChildrenCount;
        if (response && response[dataObj] && response[dataObj].length && response[dataObj].length >= 0) {
          if ((this.state.activeTab === "4" || this.state.activeTab === "5") && !forFilter && this.state.filterCriteria.page === "1") {
            await localStorage.setItem("uniqueHoldings", JSON.stringify(response["uniqueHoldings"]["results"]))
            await localStorage.setItem("uniqueInvestorStatuses", JSON.stringify(response["uniqueInvestorStatuses"]["results"]))
            await localStorage.setItem("uniqueInvestorTypes", JSON.stringify(response["uniqueInvestorTypes"]["results"]))
          }

          if ((this.state.activeTab === "4" || this.state.activeTab === "5") && !forFilter) {
            let uniqueHoldings = localStorage.getItem("uniqueHoldings")
            let uniqueInvestorStatuses = localStorage.getItem("uniqueInvestorStatuses")
            let uniqueInvestorTypes = localStorage.getItem("uniqueInvestorTypes")
            let HoldingArray = [], InvestorStatus = [], InvestorType = []
            for (let uniqueHold of JSON.parse(uniqueHoldings)) {
              if (uniqueHold.Holding === "") {
                HoldingArray.push({ label: "Blank", value: uniqueHold.Holding })
              } else {
                HoldingArray.push({ label: uniqueHold.Holding, value: uniqueHold.Holding })
              }
            }
            for (let UniqueInvestorStatus of JSON.parse(uniqueInvestorStatuses)) {
              if (UniqueInvestorStatus.InvestorStatus === "") {
                InvestorStatus.push({ label: "Blank", value: UniqueInvestorStatus.InvestorStatus })
              } else {
                InvestorStatus.push({ label: UniqueInvestorStatus.InvestorStatus, value: UniqueInvestorStatus.InvestorStatus })
              }
            }
            for (let UniqueInvestorType of JSON.parse(uniqueInvestorTypes)) {
              if (UniqueInvestorType.InvestorType === "") {
                InvestorType.push({ label: "Blank", value: UniqueInvestorType.InvestorType })
              } else {
                InvestorType.push({ label: UniqueInvestorType.InvestorType, value: UniqueInvestorType.InvestorType })
              }
            }
            this.setState({ holding: HoldingArray, investorStatus: InvestorStatus, investorType: InvestorType })
          }
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
            rows = response.pagination.limit;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
          if (response.totalGrandChildrenCount) {
            totalGrandChildrenCount = response.totalGrandChildrenCount ? response.totalGrandChildrenCount : 0;
          }
          responseData = this.updateDateFormat(response[dataObj], this.state.dateFormat);
        } else if (response && response[dataObj].length && response[dataObj].length >= 0) {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
            rows = response.pagination.limit;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
          responseData = this.updateDateFormat(response[dataObj], this.state.dateFormat);
        } else {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
          }
          if (forFilter) {
            if (response.pagination) {
              totalRecordsLength = response.pagination.totalCount;
              rows = response.pagination.limit;
            }
          }
        }
        await this.setState({
          activitiesData: responseData,
          isLoading: false,
          totalRecordsLength: totalRecordsLength,
          totalGrandChildrenCountLength: totalGrandChildrenCount,
          rows: rows
        })
        if (this.state.activeTab === "2") {
          this.expandorCollapse()
        }
      } else {
        if (response && (response['errorMessage'] === configMessages.warningMessage || response['errorMessage'] === configMessages.tokenMessage) || (response['errorMessage'] === configMessages.sessionExpired)) {
          await this.setState({
            sessionWarning: true,
          })
        } else if (response && response['errorMessage']) {
          this.getErrorMessage(response.errorMessage)
        }
        await this.setState({
          activitiesData: [],
          isLoading: false,
          totalRecordsLength: 0,
          totalGrandChildrenCountLength: 0,
        })
      }
    }).catch((err) => {
      return err
    })
  }

  getCompanyTableFields = (companyType) => {
    let data;
    if (companyType === "Public") {
      data = this.props.getPublicCompanies()
    } else {
      data = this.props.getTableFields()
    }
    return data;
  };

  getTableFieldItem = async (field, screen) => {
    let tablefieldsToShow
    // if (this.state.shownTable === "Company") {
    tablefieldsToShow = this.getCompanyTableFields(screen);
    let addFields =
      [{
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'CompanyAlsoKnownAs',
        header: 'Company Also Known As',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'CompanyFormerName',
        header: 'Company Former Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'CompanyLegalName',
        header: 'Company Legal Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'CompanyNameAliases',
        header: 'Company Name Aliases',
        filter: true,
        sortable: true
      }]
    tablefieldsToShow = [...tablefieldsToShow, ...addFields]
    // } else {
    //   tablefieldsToShow = this.getTableFields();
    // }
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field === field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }

  getFormFields = async () => {
    if (this.props.formFields) {
      let formFields = await this.props.formFields(this.props.selectedInfo);
      if (this.props.formType === "edit" && this.props.selectedInfo && this.props.type === "Rules") {
        if (this.props.selectedInfo.action === "sendNotification") {
          let msgField = {
            //'show': false,
            "value": "",
            "type": "textarea",
            "name": "message",
            "label": "Message",
            "id": "message",
            "placeholder": "Mesage",
            "required": false

          }
          // if (this.props.type === "Rules" && selectedRowInfo.action === "sendNotification") {
          formFields.splice(4, 0, msgField);
          formFields.join(" ")
          // }
        }

      }
      await this.setState({ formFields: formFields, });
    }
  }

  getFormFieldItem = async (key) => {

    let formFields = await this.props.formFields();
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }

    return null;
  }

  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  // handleNextAndBackActions = async () => {
  //   let { allUsersData } = this.props;
  //   let { slno } = this.state;
  //   let data = '';
  //   data = allUsersData[slno];
  //   if (this.props.formType === 'view') {
  //     await this.setState({
  //       editRowDataID: data['_id']
  //     })
  //     await this.handleViewDisplay(data, 'view');
  //   } else {
  //     await this.getRowData(data, 'edit')
  //   }
  //   await this.setState({
  //     filterCriteria: { limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
  //     first: 0,
  //     rows: 10,
  //     totalRecordsLength: 0,
  //   })
  //   // await this.getActivities();
  // }

  getViewData = async (rowData, type, rowDataIndex, userData, _id, screen) => {
    await this.setState({
      rowData: rowData,
      editRowDataID: _id,
      formType: type,
      slno: rowDataIndex,
      userData: userData,
      // screen: "Company"
    });
    if (this.state.activeTab !== "1" || this.props.type === "Investor") {
      this.getActivities()
    }
  };

  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  //to get company Data from the id in investors and company activities Table
  getchildCompanyDetails = async (id, type, screen, field) => {
    let val = id[field];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: "CompanyID", value: val, type: 'eq' }];
    fetchMethodRequest('GET', `businessNames/companies?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response) {
          let data = response.companies[0];
          if (this.props.type === "Investor") {
            await this.setState({
              shownTable: screen
            })
          }
          if (this.props.type === "Company") {
            await this.setState({
              activeTab: "1"
            })
          }
          if (data) {
            this.handleViewDisplay(data, 'view')
          } else {
            data = []
            this.handleViewDisplay(data, 'view')
          }
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          this.setState({
            sessionWarning: true
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  handleViewDisplay = async (rowData, type, data, companyType) => {
    let _id = rowData['_id'] ? rowData['_id'] : "";
    // let _id = ""
    let rowDataIndex = this.getUserData(rowData['_id'])
    let keys = Object.keys(rowData);
    let formFields = [];
    if (formFields) {
      if (this.props.type) {
        if (rowData) {
          let values, self = this, icon = false;
          // hari get all the labels from 
          // hari get all the labels from 

          keys.forEach(async function (key) {
            let labelKey;
            labelKey = await self.getTableFieldItem(key, companyType);
            if (labelKey === null) {
              labelKey = key;
            } else {
              let val
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              if (labelKey.field === 'LastUpdated') {
                let date = dateFormats.addDaysToDate(rowData[key], 1);
                date = dateFormats.formatDate(date, config.dateDBFormat);
                values = {
                  label: labelKey.header,
                  value: date
                }
                val = date
              } else if (labelKey.field === "Revenue") {
                if (rowData[key] % 1) {
                  val = rowData[key].toFixed(2)
                } else {
                  val = rowData[key]
                }
              } else if (labelKey.field === "HQCity") {
                // val = rowData['HQCity'] + "," + rowData["HQState_Province"] ? rowData["HQState_Province"] : ""
                if (!rowData['HQCity'] && rowData["HQState_Province"]) {
                  val = rowData["HQState_Province"]
                } else if (!rowData['HQState_Province'] && rowData["HQCity"]) {
                  val = rowData["HQCity"]
                } else if (rowData['HQCity'] && rowData['HQState_Province']) {
                  val = rowData['HQCity'].concat(' ,', rowData["HQState_Province"]);
                }
              } else {
                val = rowData[key] === "null" ? "" : rowData[key];
              }
              values = {
                label: labelKey.header,
                value: val ? val : ""
              }
              formFields.push(values);
              icon = false;
            }

          });
        }
      }
      if (companyType === "Public") {
        let values = {
          label: "Data Source",
          value: "SEC"
        }
        formFields.push(values)
      }
      await this.setState({
        formType: 'view',
        userData: rowData,
        activitiesData: [],
        totalRecordsLength: 0,
        totalGrandChildrenCountLength: 0,
        displayBreadCrumbValue: rowData[this.state.displayBreadCrumbField],
        userStatus: rowData['status']
      });
      await this.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
    }
  }

  getUserData(_id) {
    let data = this.props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  updateDateFormat(itemdata) {
    let modifiedData = [];
    let tablefieldsToShow = this.getTableFields();
    if (this.state.activeTab !== "2") {
      for (let i = 0; i < itemdata.length; i++) {
        for (let k = 0; k < tablefieldsToShow.length; k++) {
          if ("Date" === tablefieldsToShow[k]['fieldType']) {
            itemdata[i][tablefieldsToShow[k]['field']] =
              dateFormats.formatDate(
                itemdata[i][tablefieldsToShow[k]['field']],
                tablefieldsToShow[k]['dateFormat'], 'sameDate');
          }
        }
        modifiedData.push(itemdata[i])
      }
    }
    if (this.state.activeTab === "2") {
      for (let i = 0; i < itemdata.length; i++) {
        if (itemdata[i].DealDate) {
          itemdata[i].DealDate = dateFormats.formatDate(
            itemdata[i].DealDate,
            "MM-DD-YYYY", 'sameDate');
        }
        if (itemdata[i].GrandChildCompanies && itemdata[i].GrandChildCompanies.length > 0) {
          this.updateDateFormat(itemdata[i].GrandChildCompanies, 'MM-DD-YYYY', 'sameDate')
        }
        modifiedData.push(itemdata[i])
      }
    }

    return modifiedData;

  }



  //Get From Fields data on Edit
  getRowData = async (selectedRowInfo, type, paymentItem, index) => {
    if (this.props.type === "Attributes" || this.props.type === "Process Documents") {
      if ((this.props.type === "Attributes" && selectedRowInfo.attributeCode.toLowerCase() === "status") ||
        (this.props.type === "Attributes" && selectedRowInfo.attributeCode.toLowerCase() === "purpose") ||
        (this.props.type === "Attributes" && selectedRowInfo.attributeCode.toLowerCase() === "label")) {
        await this.setState({
          attributeCode: false
        })
      } else {
        await this.setState({
          attributeCode: true
        })
      }
      let values = selectedRowInfo.values ? selectedRowInfo.values : selectedRowInfo.documents ? selectedRowInfo.documents : null
      let AttributeList = []
      if (values && values.length > 0) {
        for (let i = 0; i < values.length; i++) {
          let key = `key${i}`
          let value = `value${i}`
          if (this.props.type === "Attributes") {
            AttributeList.push({ [key]: values[i].key, [value]: values[i].value, label: `key${i}`, value: `value${i}` })
            selectedRowInfo[key] = values[i].key
            selectedRowInfo[value] = values[i].value
          } else {
            AttributeList.push({ [key]: values[i].documentName, [value]: values[i].documentUrl, label: `key${i}`, value: `value${i}` })
            selectedRowInfo[key] = values[i].documentName
            selectedRowInfo[value] = values[i].documentUrl
          }
          delete selectedRowInfo.values
          delete selectedRowInfo.key
          delete selectedRowInfo.value

        }
        this.setState({
          attributeListArray: AttributeList,
          displayTable: true,
        })
      }
    }
    let keys = Object.keys(selectedRowInfo);
    for (let i = 0; i < keys.length; i++) {
      let fieldItem = await this.getFormFieldItem(keys[i]);
      if (fieldItem) {
        if ((fieldItem.type === 'multipleprofile' || fieldItem.type === 'ckeditor' || fieldItem.type === 'profile') && selectedRowInfo[fieldItem.name]) {
          this.setState({
            [fieldItem.name]: selectedRowInfo[fieldItem.name]
          })
        }
        if (fieldItem.type === 'dropDown' && fieldItem.isMultiSelect) {
          let states = selectedRowInfo[fieldItem.name]
          let roleOptions = [];
          if (states && states.length > 0) {
            for (let i = 0; i < states.length; i++) {
              roleOptions.push({
                label: states[i],
                value: states[i]
              })
            }
            await this.setState({
              roleOptions: roleOptions
            })
          } else {
            await this.setState({
              roleOptions: []
            })
          }
        }
      }
    }
    if (this.props.type === "Checklists") {
      selectedRowInfo.required = selectedRowInfo.required === true ? "True" : "False"
    }
    if (this.props.type === "Rules") {
      await this.setState({
        searchApi: selectedRowInfo.assignType,
        searchField: selectedRowInfo.assignType === "User" ? "display_name" : "role"
      })
    }

    if (selectedRowInfo['permissions']) {
      let permissionsArray = []
      let permissions = selectedRowInfo['permissions']
      if (permissions) {
        let keys = Object.keys(permissions);
        keys.forEach((element) => {
          if (element) {
            selectedRowInfo[element] = permissions[element];
            let permissonObj = {
              title: element,
            }
            if (type === 'edit') {
              if (selectedRowInfo[element] === 'Edit') {
                permissonObj.Edit = false;
              } else if (selectedRowInfo[element] === 'View') {
                permissonObj.View = false;
              } else if (selectedRowInfo[element] === 'NoView') {
                permissonObj.NoView = false;
              }
            } else {
              if (selectedRowInfo[element] === 'Edit' || selectedRowInfo[element] === 'View') {
                selectedRowInfo[element] = 'NoView';
                permissonObj.NoView = false;
              }
            }
            permissionsArray.push(permissonObj);
          }
        });
      }
      if (selectedRowInfo.nextActionDateDaysDefault && selectedRowInfo.nextActionDateDaysDefault === 0) {
        selectedRowInfo.nextActionDateDaysDefault = selectedRowInfo.nextActionDateDaysDefault.toString()
      }
      // delete and push the items from the menulist
      for (let i = 0; i < permissionsArray.length; i++) {
        const index = this.state.menuList.findIndex(object => {
          return object.title === permissionsArray[i].title;
        })
        if (index === -1) {
          permissionsArray.splice(i, 1)
        }
      }
      for (let i = 0; i < this.state.menuList.length; i++) {
        const index = permissionsArray.findIndex(object => {
          return object.title === this.state.menuList[i].title;
        })
        if (index === -1) {
          permissionsArray.push({ title: this.state.menuList[i].title, NoView: false })
        }
      }
      permissionsArray = permissionsArray.sort(this.compare)
      await this.setState({
        menuList: permissionsArray
      })
    }
    if (this.props.type === "ClaimsManagement") {
      selectedRowInfo.checkForm = paymentItem.checkForm
      selectedRowInfo.note = paymentItem.note
      selectedRowInfo.checkNumber = paymentItem.checkNumber
      selectedRowInfo.checkAmount = paymentItem.checkAmount
      selectedRowInfo.checkDate = paymentItem.checkDate
      selectedRowInfo.ediedId = index
    }
    if (selectedRowInfo.notes) {
      this.setNotesToChange(selectedRowInfo.notes)
    }
    if (this.props.load) {
      this.props.load(selectedRowInfo);
    }
    this.setState({
      isLoading: false,
      editRowDataID: selectedRowInfo._id,
      displayBreadCrumbValue: selectedRowInfo[this.state.displayBreadCrumbField],
      formType: type,
      userData: selectedRowInfo,
      paymentItem: paymentItem,
      Comments: selectedRowInfo.note
    });
  }

  closeFormModal = async () => {
    this.clearFormFields();
    this.props.reset();
    if (this.props.type !== "ClaimsManagement" && this.props.type !== "State Attributes") {
      this.props.getDataFromServer(this.props.filterCriteria);
    } else if (this.props.type === "State Attributes") {
      this.props.getFilteredValues(this.props.filterCriteria)
    }
    this.props.closeFormModal();

  }

  flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]|\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }

  getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = this.getTableFields()
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field === field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }

  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    if (tableItem.fieldType === "Array") {
      let val = this.flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Date") {
      // let val = dateFormats.formatDate(item[column.field], "MM-DD-YYYY");
      let val = item[column.field].split(" ");
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Link" && this.props.type === "Investor") {
      return <div className='textElipses'>
        <span
          onClick={() => this.getchildCompanyDetails(item.TargetCompanyID, 'view', 'Company')}>
          {item[column.field]}</span>
      </div >
    } else if (tableItem.fieldType === "ParentCompany") {
      return <div className='textElipses'>
        <span
          onClick={() => this.getchildCompanyDetails(item, 'view', 'Investor', tableItem.getField)}>
          {item[column.field]}</span>
      </div >
    } else if (tableItem.fieldType === "BoolText") {
      return <div>
        <span >{item[column.field].toString()}</span></div>

    } else if (tableItem.fieldType === "website") {
      return <span>
        <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={tableItem.field === "Parent10KExhibit21Url" || tableItem.field === "Parent10KUrl" ? `${item[column.field]}` : `http://${item[column.field]}`} > {item[column.field]}</a>
        </span>
      </span >
    } else if (tableItem.fieldType === "url") {
      return <span>
        <span>
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${item['Url']}`}>{item[column.field]}</a>
        </span>
      </span>
    } else if (this.state.activeTab === "9" && this.props.city === "Public" && tableItem.field === "Name") {
      return <span style={{ textTransform: "capitalize" }}>
        {item[column.field]}
      </span>
    }
    else {
      // if (item[column.field] === 0) {
      //   return item[column.field];
      // }
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return item[column.field];
      }
    }
  }
  // changeFieldValues = async (item, column) => {
  //   let self = this, tableItem;
  //   tableItem = self.getActivtiesTableFieldItem(column.field);
  //   if (tableItem.fieldType === "Array") {
  //     let val = self.flattenArray(item[column.field]);
  //     return <span style={tableItem.style} title={val}>
  //       {val}
  //     </span>
  //   } else {
  //     // if (item[column.field] === 0) {
  //     //   return item[column.field];
  //     // }
  //     if ((item[column.field]) && typeof item[column.field] !== 'object') {
  //       return item[column.field];
  //     }
  //   }
  // }

  // form Submit
  submit = (values) => {
    if ((this.props.type === "Deal Sizing Queue" && this.state.sellerNameFlag) || (this.props.type === "Deal Sizing Queue" && this.state.EstateIds && (this.state.EstateIds.length <= this.state.DealIdLimit)) ||
      (this.props.type === "Regular Scoring Queue" && this.state.sellerNameFlag) || (this.props.type === "Regular Scoring Queue" && this.state.EstateIds && (this.state.EstateIds.length <= this.state.DealIdLimit)) || (values && Object.keys(values).length > 0)) {
      this.setState({ hidebtn: true })
      this.saveDataToServer(values);
    } else {
      return;
    }
  }

  clearFormFields = async () => {
    if (this.props.load) {
      this.props.load({});
    }
    let formData = [...this.state.formFields];
    formData.forEach((item) => {
      item.value = '';
      item.invalid = false;
      item.message = ''
    });
    await this.setState({
      formFields: formData
    })
    this.getFormFields();
  }

  onEditorChange = async (evt) => {
    this.setState({
      [evt.editor.name]: evt.editor.getData()
    });
  }
  ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  deleteImagesCall = async () => {
    let body = {
      fileNames: this.state.removedFiles
    };
    await this.setState({ isLoading: true })
    fetch('POST', apiCalls.reportBugDelete, body)
      .then(async (response) => {
        if (response && response.respCode) {
        } else if (response && response.errorMessage) {
          // this.getErrorMessage(response.errorMessage)
        }
        await this.setState({ isLoading: false })
      }).catch((err) => {
        return err;
      });
  }
  //send data to server
  saveDataToServer = async (formValues) => {
    let validationExists;
    if (this.state.attributeListArray) {
      // let AttributeList = [];
      // let keyCount;
      let list = this.state.attributeListArray;
      let AttributeList = [];
      let keyCount;
      if (this.props.type === "add") {
        keyCount = this.state.keyCount + 1;
      } else {
        keyCount = this.state.attributeListArray.length;
      }
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let key = list[i][`key${i}`]
          let value = list[i][`value${i}`]
          if (!key || !value || key === null || value === null) {
            // validationExists = true
            await this.setState({
              errordocMessage: "Please fill the fields before saving the record",
              documentFieldErr: true,
              validationExists: true
            })
          } else {
            await this.setState({
              errordocMessage: "",
              documentFieldErr: false,
              validationExists: false
            })
          }
          if (this.props.type === "Process Documents") {
            AttributeList.push({ documentName: key, documentUrl: value })
          } else {
            AttributeList.push({ key: key, value: value })
          }
          delete formValues[value];
          delete formValues[key];
        }
      } else {
        if (this.props.type === "Process Documents") {
          await this.setState({
            errordocMessage: "Please add atleast one document",
            documentFieldErr: true,
            validationExists: true
          })
        }
      }
      if (AttributeList && AttributeList.length > 0) {
        if (this.props.type === "Process Documents") {
          formValues.documents = AttributeList
        } else {
          formValues.values = AttributeList
        }
      }

    }
    if (this.props.type === "Checklists") {
      formValues.required = formValues.required === "True" ? true : false
    }
    if (this.props.type === "Users" && this.props.DepartmentArrayForUsers && this.props.DepartmentArrayForUsers.length > 0) {
      if (formValues.department) {
        let z = this.props.DepartmentArrayForUsers.find(elem => elem.value === formValues.department)
        if (z) {
          formValues.departmentId = z.departmentId

        }
      }
    }
    if (this.props.type === "State Attributes") {
      let loggedUser = JSON.parse(localStorage.getItem("loginCredentials"))
      var noteArray = this.state.userData && this.state.userData.note ? this.state.userData.note : []
      if (formValues.notes && formValues.notes.length > 0) {
        var pushedObj = {
          note: formValues.notes,
          createdBy: loggedUser.displayName,
          created: dateFormats.formatDate(new Date(), config.dbOnlyDateFormat)
        }
        noteArray.unshift(pushedObj)
      }
      if (formValues.category && formValues.hasOwnProperty('isMandatory') && formValues.isMandatory === null) {
        formValues.isMandatory = false;
      }
      formValues.note = noteArray
      formValues.activeIndicater = formValues.activeIndicater ? formValues.activeIndicater : false
    }

    if (this.props.type == 'User Requests') {
      formValues.notes = this.state.updatedNotes
      await this.deleteImagesCall()
      formValues.fileNames = this.state["fileNames"] && this.state["fileNames"].length ? this.state["fileNames"] : [];
      formValues.issueResolvedDate = formValues["issueResolvedDate"] && formValues["issueResolvedDate"] != null ?
        dateFormats.formatDate(new Date(formValues["issueResolvedDate"]), config.dbOnlyDateFormat) : null;

      formValues.targetReleaseDate = formValues["targetReleaseDate"] && formValues["targetReleaseDate"] != null ?
        dateFormats.formatDate(new Date(formValues["targetReleaseDate"]), config.dbOnlyDateFormat) : null;

      formValues.updatedAtUtc = formValues["updatedAtUtc"] && formValues["updatedAtUtc"] != null ?
        dateFormats.formatDate(new Date(formValues["updatedAtUtc"]), config.dbOnlyDateFormat) : null;

      formValues.issueReportedDate = formValues["issueReportedDate"] && formValues["issueReportedDate"] != null ?
        dateFormats.formatDate(new Date(formValues["issueReportedDate"]), config.dbOnlyDateFormat) : null;

      formValues.issueRelatedDealName = formValues.issueRelatedDealName != null && typeof formValues.issueRelatedDealName !== "string" ? `${formValues.issueRelatedDealName.DealType} - ${formValues.issueRelatedDealName.SellerNickName} (${formValues.issueRelatedDealName.DealYear ? formValues.issueRelatedDealName.DealYear : ""})` : formValues.issueRelatedDealName !== null ? formValues.issueRelatedDealName : null;
    }

    if (this.props.type === "ClaimsManagement") {
      // if (typeof (formValues._id) === "object") {
      //   let Claims = []
      //   formValues.GrandChildCompanies.forEach(obj => {
      //     Claims.push({ _id: obj._id })
      //   })
      //   formValues.Claims = Claims
      // } else {
      let payment = {
        checkForm: formValues.checkForm,
        note: formValues.note,
        checkNumber: formValues.checkNumber,
        checkAmount: parseFloat(formValues.checkAmount),
        checkDate: formValues.checkDate,
      }
      if (formValues.Payments && formValues.Payments.length > 0) {
        if (formValues.ediedId !== undefined) {
          formValues.Payments[formValues.ediedId] = payment
        } else {
          formValues.Payments.push(payment)
        }
      } else {
        formValues.Payments = [payment]
      }
      formValues.pageLimit = parseInt(formValues.pageLimit)

      delete formValues.checkForm;
      delete formValues.note;
      delete formValues.checkNumber;
      delete formValues.checkAmount;
      delete formValues.checkDate;
      // }
    }
    if (formValues.claimSequence) {
      formValues.claimSequence = parseInt(formValues.claimSequence)
    }
    if (formValues.nextActionDateDaysDefault) {
      formValues.nextActionDateDaysDefault = parseInt(formValues.nextActionDateDaysDefault)
    }
    if (this.props.type === "ClaimsManagement") {
      await this.props.handleUsersData(formValues)
    } else {
      let userBody = Object.assign({}, formValues);
      if (!validationExists && !this.state.validationExists) {
        this.setState({
          isLoading: true
        });
        this.handlePercentage('1')
        if (formValues) {
          let method, apiUrl;
          if (this.state.formType === 'edit') {
            // delete userBody.email
            delete userBody.password;
            method = 'PUT';
            if (this.props.type === "Legal Entity Suffix" || this.props.type === "Legal Entity Abbreviation") {
              apiUrl = `${this.props.apiUrl}`;
            } else if (this.props.type === "ClaimsManagement") {
              if (typeof (formValues._id) === "object") {
                apiUrl = `${apiCalls.OverrideClaimStatus}`;
              } else {
                apiUrl = `${apiCalls.Claims}/${this.state.editRowDataID}`;
              }
            } else {
              apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID ? this.state.editRowDataID : userBody.BusinessNameIssueCodeId}`;
            }
          } else if (this.state.formType === 'view') {
            delete userBody.email
            delete userBody.password;
            method = 'PUT';
            apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
          } else {
            method = 'POST';
            apiUrl = this.props.type.includes("Queue") ? "Queue/addnewEstateToQueue" : this.props.apiUrl
          }
          // for (let i = 0; i < this.state.formFields.length > 0; i++) {
          for (let i = 0; i < this.state.formFields.length; i++) {
            if (this.state.formFields[i].show === false)
              continue;
            if (this.state.formFields[i].type === 'autoComplete') {
              if (this.state.formFields[i]["options"] && this.state.formFields[i]["options"].length > 0) {
                for (let j = 0; j < this.state.formFields[i]["options"].length; j++) {
                  let keys = Object.keys(this.state.formFields[i]["options"][j])
                  let values = Object.values(this.state.formFields[i]["options"][j]);
                  if (keys && keys[0] && values && values[0] && formValues[this.state.formFields[i]["name"]][values[0]]) {
                    userBody[keys[0]] = formValues[this.state.formFields[i]["name"]][values[0]]
                  }
                }
              }
            }
            if (this.state.formFields[i].type === 'multipleprofile' || this.state.formFields[i].type === 'ckeditor' || this.state.formFields[i].type === 'profile') {
              userBody[this.state.formFields[i].name] = this.state[this.state.formFields[i].name];
            }
            if (this.state.formFields[i].type === 'permission') {
              let permissions = this.state.permissions;
              let keys = Object.keys(permissions);
              this.state.menuList.forEach((item, index) => {
                if (keys && keys.length > 0) {
                  keys.forEach((key) => {
                    if (item.title === key) {
                      if (this.state.formType === "edit") {
                        formValues[item.title] = permissions[item.title] ? permissions[item.title] : formValues[item.title]
                        permissions[item.title] = formValues[item.title]
                      } else {
                        permissions[item.title] = permissions[item.title]
                      }
                      this.setState({
                        permissions: permissions
                      })
                      permissions = this.state.permissions
                    } else {
                      if (this.state.formType === "add") {
                        permissions[item.title] = permissions[item.title] ? permissions[item.title] : "NoView"
                        permissions = permissions
                      }
                      if (this.state.formType === "edit") {
                        formValues[item.title] = permissions[item.title] ? permissions[item.title] : formValues[item.title]
                        permissions[item.title] = formValues[item.title]
                      }
                      // }
                    }
                    permissions = this.state.permissions
                  })
                } else {
                  if (this.state.formType === "add") {
                    permissions[item.title] = "NoView"
                  } else {
                    permissions[item.title] = Object.keys(item)[1]
                  }
                }

              })
              userBody.permissions = { ...permissions }
            }
          }
          if (this.props.type.includes("Queue")) {

            if (this.props.type === "Regular Scoring Queue") {
              userBody.Entity = {}
              userBody.Entity.SellerName = formValues.SellerName && formValues.SellerName.SellerNickName ? formValues.SellerName.SellerNickName : ""
              userBody.Entity.SellerID = formValues.SellerName && formValues.SellerName.DealID ? formValues.SellerName.DealID : 0
              userBody.Entity.ScoringType = formValues.SellerName && formValues.SellerName.DealID ? "singleName" : "MBD"
              userBody.Entity.CompanyName = this.state.selectedCompanyName
              userBody.Entity.ElasticThreshold = this.state.elasticThreshold
              userBody.EstateIds = this.state.EstateIds
            } else if (this.props.type === "Deal Sizing Queue") {
              userBody.Entity = {}
              userBody.Entity.SellerName = formValues.SellerName && formValues.SellerName.SellerNickName ? formValues.SellerName.SellerNickName : ""
              userBody.Entity.SellerID = formValues.SellerName && formValues.SellerName.DealID ? formValues.SellerName.DealID : 0
              userBody.Entity.ScoringType = "DealSizing"
              userBody.EstateIds = this.state.EstateIds
              userBody.purgeUnreviewedProperties = this.state.purgeUnreviewedProperties
            }
            delete formValues.SellerName
          }
          if (this.props.type === "Purposes") {
            userBody.labelName = formValues.labelName.labelName ? formValues.labelName.labelName : formValues.labelName
            userBody.labelId = formValues.labelName.id
            delete formValues.label
          }
          if (this.props.type === "Property Reason") {
            userBody.status = formValues.status.status ? formValues.status.status : formValues.status
            userBody.statusId = formValues.status.id
            delete formValues.status
          }
          if (this.props.type === "Claim Reason") {
            userBody.status = formValues.status.status ? formValues.status.status : formValues.status
            userBody.statusId = formValues.status.id
            delete formValues.status
          }
          if (formValues.id && this.props.type === "Deal Status Configuration") {
            userBody.id = parseFloat(formValues.id)
          }
          if (formValues.apaTypeID && this.props.type === "APA Type Configuration") {
            userBody.apaTypeID = parseFloat(formValues.apaTypeID)
          }
          if (formValues.dealStageID && this.props.type === "Deal Stage Configuration") {
            userBody.dealStageID = parseFloat(formValues.dealStageID)
          }

          if (formValues.dealTypeID && this.props.type === "Deal Type Configuration") {
            userBody.dealTypeID = parseFloat(formValues.dealTypeID)
          }


          if (this.props.type === "State-UP Filing Sub Category") {
            userBody.categoryId = formValues.categoryName.categoryId ? formValues.categoryName.categoryId : formValues.categoryId
            userBody.categoryName = formValues.categoryName.categoryId ? formValues.categoryName.categoryName : formValues.categoryName
          }
          if (this.props.type === "Available To File - Statuses") {
            userBody.status = formValues.status.status ? formValues.status.status : formValues.status
            userBody.statusId = formValues.status.id
            delete formValues.status
          }
          if (this.props.type === "Status") {
            userBody.labelName = this.state.statusPurpose ? this.state.statusPurpose : formValues.labelName
            userBody.labelId = this.state.statusPurposeId
            userBody.purposeName = formValues.purposeName.labelName ? formValues.purposeName.labelName : formValues.purposeName
            userBody.purposeId = formValues.purposeName.id
            delete formValues.labelName
            delete formValues.purposeName
          }
          if (this.props.type === 'Deal Status Configuration') {
            if (formValues.searchable === "True" || formValues.searchable == 1) {
              userBody.searchable = 1
            } else {
              userBody.searchable = 0
            }

          }
          if (this.props.type === "Rules" && userBody.assignType && userBody.assignTo) {
            userBody.rules = this.getRulesFromRuleEngine();
            if (this.props.formType !== "edit") {
              if (userBody.assignType === "User") {
                userBody.assignToId = userBody.assignTo._id
                userBody.assignTo = userBody.assignTo.display_name
              } else {
                userBody.assignTo = userBody.assignTo.role
              }
            } else {
              if (userBody.assignType === "User") {
                userBody.assignToId = formValues.assignTo._id ? formValues.assignTo._id : formValues.assignToId
                userBody.assignTo = formValues.assignTo.display_name ? formValues.assignTo.display_name : formValues.assignTo
              } else {
                userBody.assignTo = formValues.assignTo.role
                delete userBody.assignToId
              }
            }
          }
          return fetchMethodRequest(method, apiUrl, userBody)
            .then(async (response) => {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
              if (response && response.respCode) {
                if (this.props.type === "State Attributes") {
                  this.props.getFilteredValues(this.props.filterCriteria)
                } else { await this.props.getDataFromServer(this.props.filterCriteria); }
                // showToasterMessage(response.respMessage, 'success');
                if (this.props.displayViewOfForm === 'modal' || this.props.displayViewOfForm === 'screen') {
                  this.props.closeFormModal('save', response.quantityId);
                } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
                  this.setState({
                    sessionWarning: true
                  })
                } else if (response && response.expireTime) {
                  let expiryTime = parseFloat(response.expireTime) * 60 * 1000
                  localStorage.setItem('loggedTime', expiryTime);
                } else {
                  if (this.state.formType !== 'add') {
                    await this.setState({
                      formType: 'view'
                    })
                    await this.handleNextAndBackActions();
                  } else {
                    this.props.closeFormModal('save', response.quantityId);
                  }
                }
                this.clearFormFields();
                this.props.reset();
              } else if (response && response.errorMessage) {
                let errorCaseResponse = {
                  errorMessage: response.errorMessage,
                  errorResponse: true,
                };
                localStorage.setItem('errorCaseResponse', JSON.stringify(errorCaseResponse))
                this.getErrorMessage(response.errorMessage)
                // this.props.closeFormModal();
              }
              this.setState({
                isLoading: false,
                hidebtn: false
              });
            }).catch((err) => {
              //this.getErrorMessage(config.serverErrMessage)
              this.props.closeFormModal();
              return err;
            });
        } else {
          return;
        }
      }
    }
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  getchildcomp(exportData, mainData) {
    for (let child of exportData) {
      mainData.push(child)
      if (child.ChildCount > 0) {
        mainData = this.getchildcomp(child.GrandChildCompanies, mainData)
      }
    }
    return mainData;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true })
    this.handlePercentage('1')
    let url, apiDataarr;
    let apiUrl, responseObj; let mainData = []
    delete this.state.filterCriteria['limit'];
    delete this.state.filterCriteria['sortfield'];
    this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
    if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
      delete this.state.filterCriteria.globalSearch
    }
    if ((this.props.type === "Company" || this.state.shownTable === "Company") && this.state.activeTab !== "3" && this.state.activeTab !== "4" && this.state.activeTab !== "5" && this.state.activeTab !== "6" && this.state.activeTab !== "7" && this.state.activeTab !== "8" && this.state.activeTab !== "9") {
      apiUrl = `${apiCalls.ChildCompanies}?str=${this.state.userData.CompanyID}&type=exportToCsv`;
      url = "childCompanies"
    } else if (this.props.type === "Investor" || this.state.activeTab === "4") {
      this.state.filterCriteria['criteria'] = [{ key: "InvestorID", value: this.state.userData.CompanyID, type: 'eq' }];
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`;
      url = "investors"
    } else if (this.state.activeTab === "3") {
      if (this.props.city === "Public") {
        this.state.filterCriteria['criteria'] = [{ key: "ParentCik", value: this.state.userData.CIK, type: 'eq' }];
        apiDataarr = "businessnames/publicAffiliates"
        url = "publicAffiliates"
      } else {
        this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: this.state.userData.CompanyID, type: 'eq' }];
        apiDataarr = apiCalls.affiliates
        url = "affiliates"
      }
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiDataarr}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`;
    } else if (this.state.activeTab === "5") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: this.state.userData.CompanyID, type: 'eq' }];
      apiUrl = `${apiCalls.Investors}?filter=${JSON.stringify(this.state.filterCriteria)}&type=exportToCsv`
      url = "investors"
    } else if (this.state.activeTab === "6") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.News}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${this.state.userData.CompanyID}&type=exportToCsv`
      url = "news"
    } else if (this.state.activeTab === "7") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.Leaders}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${this.state.userData.CompanyID}&type=exportToCsv`
      url = "leaders"
    } else if (this.state.activeTab === "8") {
      // this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      this.state.filterCriteria['criteria'] = [];
      apiUrl = `${apiCalls.AddressNames}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${this.state.userData.CompanyID}&type=exportToCsv`
      url = "addressnames"
    } else if (this.state.activeTab === "9") {
      let filter = this.state.filterCriteria
      filter['criteria'] = [];
      let sortField;
      if (this.props.city === "Public") {
        // this.state.filterCriteria['criteria'] = [{ key: "ParentCik", value: this.state.userData.CIK, type: 'eq' }];
        apiDataarr = "businessnames/publicAPA";
        url = "publicAPAs";
        sortField = "CIK"
      } else {
        // this.state.filterCriteria['criteria'] = [{ key: "CI.CompanyID", value: this.state.userData.CompanyID, type: 'eq' }];
        apiDataarr = apiCalls.PreAPA;
        url = "apas";
        sortField = "CompanyName"
      }
      filter['sortfield'] = sortField;
      this.state.filterCriteria['limit'] = this.state.totalRecordsLength;
      apiUrl = `${apiDataarr}?filter=${JSON.stringify(this.state.filterCriteria)}&str=${this.state.userData.CompanyID ? this.state.userData.CompanyID : this.state.userData.CIK}&type=exportToCsv`
      url = url
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          await this.setState({ sessionExpiryModal: true })
        }
        if (response && response[url]) {
          if (url === "childCompanies") {
            let updatedatedata = this.updateDateFormat(response[url])
            responseObj = this.updateDateFormat(response[url])
            responseObj = this.getchildcomp(updatedatedata, mainData)
            for (var item of responseObj) {
              let keys = Object.keys(item);
              keys.forEach(async function (key) {
                if (key.includes("DW")) {
                  delete item[key]
                }
              })
              delete item.GrandChildCompanies
            }
          } else {
            responseObj = this.updateDateFormat(response[url])
          }
          if (this.state.activeTab === "9" && this.props.city === "Private") {
            item = responseObj[0]
            let item2 = {}
            item2["CompanyID"] = item.CompanyID
            item2["CompanyLegalName"] = item.CompanyLegalName
            // item2.JDX = ""
            item2["JDX Status"] = ""
            item2["Sub - status"] = ""
            item2["Status Date"] = ""
            item2["Officers, If Active"] = ""
            item2.Sources = ""
            item2.Notes = ""
            item2["SOS ID"] = ""
            item2["Confirmed Legal Name"] = ""
            item2["CompanyName"] = item.CompanyName
            item2["CompanyNameAliases"] = item.CompanyNameAliases
            item2["CompanyAlsoKnownAs"] = item.CompanyAlsoKnownAs
            item2["CompanyFormerName"] = item.CompanyFormerName
            item2["ParentCompany"] = item.ParentCompany
            responseObj[0] = item2
          }
          responseObj.forEach((item) => {
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          if (this.props.city === "Public" && this.state.activeTab === "9") {
            for (var item of responseObj) {
              responseObj.forEach(async function (key) {
                key.CompanyLegalName = key.Name
                key.JDX = key.StateofIncorporation
                key["JDX Status"] = ""
                key["Sub - status"] = ""
                key["Status Date"] = ""
                key["Officers, If Active"] = ""
                key.Sources = ""
                key.Notes = ""
                key["SOS ID"] = ""
                key["Confirmed Legal Name"] = ""
                key["CompanyName"] = item.CompanyName
                key["CompanyNameAliases"] = item.CompanyNameAliases
                key["CompanyAlsoKnownAs"] = item.CompanyAlsoKnownAs
                key["CompanyFormerName"] = item.CompanyFormerName
                key["ParentCompany"] = item.ParentCompany
              })
            }
            for (var item of responseObj) {
              responseObj.forEach(async function (key) {
                delete key.Name
                delete key.StateofIncorporation
              })
            }
          }


          this.setState({
            exportData: responseObj,
            isLoading: false
          }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkRef.link.click();
          });
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          this.setState({
            sessionWarning: true
          })
        }
      }).catch((err) => {
        this.setState({
          isLoading: false
        });
        return err;
      });
  }

  //getStores
  getRecords = (valve, key, apiUrl, name) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: key, value: valve, type: 'eq' }];
    let url;
    if (this.props.type === "Purposes") {
      url = `${apiUrl}?filter=${JSON.stringify(this.state.statusFilterCriteria)}`
    } else {
      url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`
    }
    fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        if (this.props.type === "Purposes") {
          apiUrl = "statuss"
        }
        let states = response[apiUrl];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i][name], value: states[i][name] })
          }
          await this.setState({
            roleOptions: roleOptions
          })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            roleOptions: [],
            sessionWarning: true
          })
        }
      }
    }).catch((err) => {
      // this.getErrorMessage(config.serverErrMessage)
      return err
    })
  }
  //getStores
  getRecordsForReason = (valve, key, apiUrl, name) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: key, value: valve.status, type: 'eq' }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }];
    let url;
    url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`
    fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        if (this.props.type === "Purposes") {
          apiUrl = "statuss"
        }
        let states = response["propertyStatusReasons"];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i][name], value: states[i][name] })
          }
          await this.setState({
            roleOptions: roleOptions
          })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            roleOptions: [],
            sessionWarning: true
          })
        }
      }
    }).catch((err) => {
      // this.getErrorMessage(config.serverErrMessage)
      return err
    })
  }

  // //Handle auto complete data
  handleAutoCompleteData = async (value, name) => {
    if (name === "SellerName" && value && (value.length > 0 || value.EstateID)) {
      if (value && value.EstateID) {
        this.searchnameItems(value.EstateID)
      }
      this.setState({
        sellerNameFlag: true
      })
    } else {
      this.setState({
        sellerNameFlag: false
      })
    }
  }

  getDropdownMultiselect(i, item) {
    return (
      <div className="form__form-group-field mb-2">
        <Field key={i}
          name={item.name}
          component={MultiSelectDropDown}
          id={item.id}
          filter={true}
          placeholder={item.placeholder}
          value={item.value ? item.value : this.state.roleOptions}
          validate={item.required ? [required, multiSelectValidation] : null}
          filterElement={item.options ? item.options : this.state.roleOptions && this.state.roleOptions.length > 0 ? this.state.roleOptions : ""}
          maxSelectedLabels={this.state.maxSelectedLabels ? this.state.maxSelectedLabels : null}
        />
      </div>
    )
  }

  handleChange = () => {

  }

  getSwitch(i, item) {
    return (
      <div className="form__form-group">
        <div className="form__form-group-field">
          <Field
            id={item.name ? item.name : null}
            name={item.name}
            component={renderCheckBoxField}
            label={item.label}
            checked={item.checked}
          />
        </div>
      </div>
    )
  }

  //for Showing Key Value Table Based on Attribute Code and ValueCode
  showTable = async (e, type) => {

    if (type === "valueCode") {
      await this.setState({
        valueCode: e
      })
    }
    if (type === "attributeCode") {
      if (e.toLowerCase() === "status" ||
        e.toLowerCase() === "purpose" ||
        e.toLowerCase() === "label") {
        await this.setState({
          showKeyPairTable: false
        })
      } else {
        await this.setState({
          showKeyPairTable: true
        })
      }
    }
    if (this.state.valueCode === "LST" && this.state.showKeyPairTable) {
      await this.setState({
        displayTable: true
      })
    } else {
      await this.setState({
        displayTable: false
      })
    }
  }

  async handleFnEnableControlsBasedOnValue(e, dependent) {
    let msgField = {
      //'show': false,
      "value": "",
      "type": "textarea",
      "name": "message",
      "label": "Message",
      "id": "message",
      "placeholder": "Mesage",
      "required": false
    }
    let formFields = this.state.formFields
    if (this.props.type === "Rules" && dependent === "action" && e === "sendNotification") {
      let indexOfObject = formFields.findIndex(object => {
        return object.name === "message";
      });
      if (indexOfObject === -1) {
        formFields.splice(4, 0, msgField);
        formFields.join(" ")
      }
    } else if (dependent === "action" && e !== "sendNotification") {
      if (formFields[4].name === "message")
        formFields.splice(4, 1);
    }
    this.props.change('labelName', e.purposelabel)
    if (this.props.type === 'Status') {
      this.setState({
        statusPurpose: e.purposelabel,
        statusPurposeId: e.purposelabelId
      })
    }
    if (this.props.type === 'Rules' && dependent !== "action") {
      await this.setState({
        searchApi: e,
        searchField: e === "User" ? "display_name" : "role"

      })
      if (dependent !== "action") {
        this.props.change('assignTo', '')
      }
    }
    this.showTable(e, 'valueCode')
    if (this.props.type === "Purposes") {
      let counts = this.props.allUsersData.reduce((c, { labelName: key }) => (c[key] = (c[key] || 0) + 1, c), {});
      let orderNo = counts[e.labelName]
      if (orderNo) {
        this.props.change('sequence', orderNo + 1)
      } else {
        this.props.change('sequence', 1)
      }
    }
    if (this.props.type === "Checklists") {
      this.getRecords(e, 'labelName', apiCalls.Purposes, 'purposeName')
    }
    if (this.props.type === "Available To File - Statuses") {
      this.getRecordsForReason(e, 'status', apiCalls.propertyStatusReason, 'reason')
    }
    // let formFields = this.state.formFields;
    // for (let i = 0; i < dependent.length; i++) {
    //   if (e && e === Object.keys(dependent[i])[0]) {
    //     formFields = await this.showField(formFields, dependent[i][Object.keys(dependent[i])[0]], true);
    //   }
    //   else {
    //     formFields = await this.showField(formFields, dependent[i][Object.keys(dependent[i])[0]], false)
    //   }
    // }
    if (this.props.type === "User Requests") {
      if (e === "Resolved" && this.state.userData.issueStatus !== "Resolved") {
        this.props.change("issueResolvedDate", dateFormats.formatDate(new Date(), config.dbOnlyDateFormat))
      }
      if (e !== "Resolved" && this.state.userData.issueStatus !== "Resolved") {
        this.props.change("issueResolvedDate", "")
      }
    }
    await this.setState({ formFields: formFields });
  }


  getDropdown(i, item) {
    return (
      <div className="form__form-group-field mb-2">
        <Field key={i}
          name={item.name}
          id={item.name}
          component={Select}
          options={item.options ? item.options : this.state.roleOptions && this.state.roleOptions.length > 0 ? this.state.roleOptions : []}
          onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
          // onChange={(e) => this.handleChange(e)}
          defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
          placeholder={item.placeholder}
          isDisable={item.disable ? item.disable : false}
        />
      </div>
    )
  }

  getNumber(i, item) {
    return (
      <div className="form__form-group-field mb-2">

        <Field
          name={item.name}
          id={item.name}
          component={DefaultInput}
          type={"number"}
          onChange={(e) => this.handleFnEnableControlsBasedOnValue(e, item.name)}
          placeholder={item.placeholder}

        // id={item.id}
        // value={item.value}

        />
      </div>
    )
  }

  showField(formFields, itemname, show = true) {
    let value = true;
    if (show === false)
      value = show;
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === itemname) {
        formFields[i].show = value;
        //await this.setState({ formFields: formFields });  
        break;
      }
    }
    return formFields;
  }

  onChangePermissions(e, p, item, index) {
    this.setState(prevState => {
      let menuList = Object.assign({}, prevState.menuList);
      menuList[index].Edit = true;
      menuList[index].View = true;
      menuList[index].NoView = true;
      menuList[index][p] = false;
    })
    let permissions = this.state.permissions;
    permissions[e] = p
    this.setState({
      permissions: permissions
    })

  }

  getRolePermissions() {
    return (
      <div className="form form--horizontal" style={{ paddingLeft: 0, marginLeft: 0, marginTop: 10 }}>
        {this.state.menuList && this.state.menuList.length > 0 ?
          this.state.menuList.map((item, index) => {

            return <div className="form__form-group col-sm-12 align-middle" key={index} style={{ padding: 0, width: "100%", display: "flex" }}>
              <span className="form__form-group-label col-sm-3 p-0" style={{ margin: "10px 0px", color: "rgba(79 75 75)", float: "left" }}> {item.title}</span>
              <div className="stv-radio-buttons-wrapper ml-auto pr-0" style={{ float: "right" }}>
                <div key={index} >
                  <input type="radio"
                    className="stv-radio-button"
                    name={item.title}
                    value={"Edit"}
                    id={`edit_${index} `}
                    checked={item.Edit === false}
                    onChange={(e) => this.onChangePermissions(item.title, "Edit", item, index)}
                  />
                  <label htmlFor={`edit_${index} `} style={{ padding: "5px 20px" }}>Edit</label>
                  <input type="radio"
                    className="stv-radio-button"
                    name={item.title}
                    value={"View"}
                    checked={item.View === false}
                    id={`view_${index}`}
                    onChange={(e) => this.onChangePermissions(item.title, "View", item, index)}
                  />
                  <label htmlFor={`view_${index}`} style={{ padding: "5px 20px" }}>View</label>
                  <input type="radio"
                    className="stv-radio-button"
                    name={item.title}
                    value="No View"
                    checked={item.NoView === false}
                    id={`noview_${index}`}
                    onChange={(e) => this.onChangePermissions(item.title, "NoView", item, index)}
                  />
                  <label htmlFor={`noview_${index}`} >No View</label>
                </div>
              </div>
            </div>
          }) : null
        }
      </div >
    );
  }

  getProfile(i, item) {
    return (
      <div key={i}>
        <Field key={i}
          id={item.name}
          onRef={(ref) => (this.profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type='profile'
          acceptType="image/*"
          url={apiCalls.LocationImagePath}
          getFileName={(file) => this.getFileName(file, item)}
          imagePath={item.imagePath}
        />
        <div className='col-md-2' style={{ padding: '20px' }}>
          <div style={{ justifyContent: 'center' }}>
            {(this.state[item.name]) ?
              <img
                src={`${config.imgUrl}/${this.state[item.name]}`}
                className='detailsImgStyle' alt="" />
              : null
            }
          </div>
        </div>
      </div>
    )
  }

  getFileName = async (file, item) => {
    let image = file;
    await this.setState({
      [item.name]: image
    });
  }

  getDate(i, item) {
    return (
      <div key={i} className="form__form-group">
        <div className="form__form-group-field mb-2">
          <Field key={i}
            className='mb-2'
            name={item.name ? item.name : null}
            placeholder={item.placeholder ? item.placeholder : null}
            id={item.id ? item.id : item.name}
            component={DatePicker}
            minDate={this.state.minDate ? this.state.minDate : null}
            maxDate={this.state.maxDate ? this.state.maxDate : null}
            screen={this.props.type}
          />
          <div className="iconstyle form__form-group-icon">
            <CalendarBlankIcon />
          </div>
        </div>
        <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
      </div>
    )
  }

  getTime(i, item) {
    return (
      <div key={i} className="form__form-group">
        <div className="form__form-group-field">
          <Field key={i}
            name={item.name ? item.name : null}
            placeholder={item.placeholder ? item.placeholder : null}
            id={item.id ? item.id : item.name}
            component={TimePicker}
            screen={this.props.type}
          />
        </div>
        <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
      </div>
    )
  }

  getCkEditor(i, item) {
    let self = this;
    return (
      <CKEditor
        key={i}
        name={item.name}
        id={item.id}
        data={self.state[item.name] ? self.state[item.name] : null}
        onChange={this.onEditorChange}
      />
    )
  }

  getRadio(i, item) {
    return (
      <div className='mb-0'>
        <div className='row'>
          {item.options.map((option, ind) => {
            return <Field key={this.props.type + i + ind}
              name={item.name ? item.name : null}
              component={renderRadioButtonField}
              label={option.label}
              id={item.id ? item.id : item.name}
              radioValue={option.value}
              disabled={false}
              defaultChecked={option.defaultChecked ? option.defaultChecked : null}
              validate={item.required ? radioRequired : null}
              showError={option.showError ? true : false}
            />
          })
          }
        </div >
      </div >
    )
  }

  getAutoComplete(i, item) {
    return (
      <Field key={i}
        name={item.name ? item.name : null}
        id={item.id ? item.id : item.name}
        itemTemplate={item.name === "issueRelatedDealName" ? item.name : null}
        component={AutoComplete}
        type={item.type}
        filterField={item.filterField}
        // searchField:e=== "User" ? "display_name":"role"
        filterValue={item.filterValue}
        filterType={item.isNotEq}
        multiple={item.isMultiple}
        className={item.className && item.className === "inputLabel" ? "inputLabel" : null}
        dealDropDown={item.dealDropDown ? item.dealDropDown : false}
        placeholder={item.placeholder ? item.placeholder : ''}
        searchApi={this.state.searchApi === "User" ? "users" : this.state.searchApi === "Role" ? "roles" : item.searchApi}
        searchField={this.state.searchField ? this.state.searchField : item.searchField}
        onChange={(event) => this.handleAutoCompleteData(event, item.name)}
        screen={this.props.type}
        allow={this.state.allowDuplicates}
        filterFieldType={item.filterFieldType ? item.filterFieldType : null}
        disabled={this.state.EstateIds && this.state.EstateIds.length > 0 && this.state.EstateIds[0] !== '' ? true : item.disable ? item.disable : false}
        formType={this.props.formType}
        type={this.props.type}
      />
    )
  }

  getTextArea(i, item) {
    return (
      <Field key={i}
        name={item.name ? item.name : null}
        component={DefaultTextArea}
        row={item.row ? item.row : "5"}
        id={item.id ? item.id : item.name}
        type={item.type ? item.type : null}
        placeholder={item.placeholder ? item.placeholder : null}
        validate={!this.state.isTaskSet && item.name === 'otherTask' ? [required] : null}
        isDisable={item.disable ? item.disable : false}
      />
    )
  }

  getButtonGroup(i, item) {
    return (
      <ButtonToolbar className='mt-0'>
        <ButtonGroup size="sm" >
          {item.options.map((button) => {
            return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
              color="primary"
              id={item.id ? item.id : item.label}
              outline size="sm"
              active={this.state[`is${button.label}ReOrder`]}
              onClick={() => this.handleReOrdering(button.value)}>{button.label}</Button>
          })
          }
        </ButtonGroup>
      </ButtonToolbar>
    )
  }

  onChange(e) {

    this.showTable(e, 'attributeCode')
    if (this.props.type === "Purposes") {
    }

  }

  getDefault(i, item) {
    const { t } = this.props;
    return (
      <div>
        <Field key={i}
          name={item.name ? item.name : null}
          id={item.name ? item.name : null}

          component={DefaultInput}
          isDisable={
            (this.props.formType && this.props.formType === 'edit' && (item.type === "email" || item.type === "password"))
              || (this.props.type === "Status" && item.name === "labelName") ?
              true : (this.props.type === "Purposes" && item.name === "sequence" && this.props.formType === 'add') ? true : item.disable}
          type={item.type ? item.type : "text"}
          onChange={(e) => this.onChange(e)}
          id={item.id ? item.id : null}
          defaultValue={item.value ? item.value : null}
          placeholder={item.placeholder ? t(item.placeholder) : null}
          validate={item.required ? [required] : null}
          normalize={item.formatType === 'US' ? normalizePhone : item.formatType === 'Number' ? normalizenumber : item.name === "checkNumber" ? normalizeStringwithoutsc : null}

        />
      </div>
    )
  }

  getButtonToolbar() {
    const { t } = this.props;
    return (
      // <div className='d-flex'>
      // <div >
      <div className={this.props.displayViewOfForm === 'screen' ? 'col-12 float-right text-right' : 'col-12 px-0 pl-3'}>
        {/* <span className='float-right'> */}

        {/* <ButtonToolbar> */}
        {/* {this.props.displayViewOfForm === 'modal' ? <Button color='primary' type="button" onClick={() => this.setState({ formType: 'view' })}>
                {t('Back') ? t('Back') : 'Back'}
              </Button> : this.state.formType !== 'add' ? <Button color='primary' type="button" onClick={() => this.setState({ formType: 'view' })}>
                {t('Back') ? t('Back') : 'Back'}
              </Button> : null} */}
        <Button color='danger' outline type="button" className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mr-auto mb-0"} onClick={this.closeFormModal}>
          <FontAwesomeIcon
            style={{ width: "15px" }}
            icon={faArrowCircleLeft}

          />
          {t('Back') ? t('Cancel') : 'Cancel'} </Button>
        <Button
          style={{ float: "right" }}
          color='primary'
          className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mb-0"}
          outline
          type="submit"
          disabled={this.state.hidebtn}
        >
          <FontAwesomeIcon
            style={{ width: "15px" }}
            icon={faSave}
          />

          {this.props.type === "ClaimsManagement" ?
            this.state.formType && this.state.paymentItem === "" ?
              t('Save') ? t('Save') : 'Save'
              : t('Update') ? t('Update') : 'Update'
            : this.props.type !== "ClaimsManagement" && this.state.formType && this.state.formType === 'add' ?
              t('Save') ? t('Save') : 'Save'
              : t('Update') ? t('Update') : 'Update'
          }
        </Button>
        {/* </ButtonToolbar> */}

      </div>
      // </div>
    )
  }

  addChildCompanyModal = (type, rowData) => {
    this.setState({
      companyModal: true,
      type: type,
      companyData: rowData
    })
  }

  closecompanyModal = () => {
    this.setState({
      companyModal: false,
    })
  }
  getCompanyModal = () => {
    return (
      < CompanyModal
        isOpenAddModal={this.state.companyModal}
        isEditData={this.state.rowData}
        type={this.state.type}
        isCloseModal={this.closecompanyModal} />
    )

  }

  filteredValues = async (e, type) => {
    let filterCriteria = this.state.filterCriteria
    if (e && e.persist) {
      e.persist();
    }
    if (type) {
      await this.setState({
        selectsearchFilterValue: ""
      })
      filterCriteria.page = "1"
      await this.setState({
        page: "1",
        first: "0",
      })
      delete filterCriteria.globalSearch
      this.getActivities('', filterCriteria)
    } else {
      await this.setState({
        selectsearchFilterValue: e.target.value
      })
      if (e.key === "Enter") {
        filterCriteria.globalSearch = {
          value: encodeURIComponent(e.target.value),
          type: "user"
        }
        filterCriteria.page = 1
        this.setState({
          first: 0
        })
        this.getActivities('', filterCriteria)
      }
    }
  }

  handleExpandChange = async (value) => {
    await this.setState({ expandAll: !this.state.expandAll ? true : false });
    this.expandorCollapse()
  }

  handlePurgeUnreviewedPropertiesChange = async () => {
    await this.setState({ purgeUnreviewedProperties: !this.state.purgeUnreviewedProperties ? true : false });
  }

  expandorCollapse = async () => {
    if (this.state.expandAll) {
      this.expandAll(this.state.activitiesData)
      // this.setState({
      //   dataKey: "CompanyID"
      // })
    } else {
      this.collapseAll()
    }

  }
  getEditButton() {
    const { t } = this.props;

    return (
      <div className='d-flex'>
        <div className='col-12 pl-0'>
          {(this.props.type === "Company" && this.state.activeTab === "2") || this.state.shownTable === "Investor" ?
            <div className="float-left ml-2 row ">
              <div className="mt-3">
                <input
                  label='Expand All'
                  type='checkbox'
                  component={Checkbox}
                  name={'expand'}
                  className="analystCheckbox mb-0 mr-2"
                  checked={this.state.expandAll}
                  onChange={() => this.handleExpandChange('expandAll')}
                />
                <label>Expand All</label>
              </div>
              <span style={{ marginTop: 15, marginLeft: 5 }}>({this.state.activitiesData.length})</span>
              <span style={{ marginTop: 15, marginLeft: 5 }}>({this.state.totalGrandChildrenCountLength})</span>
            </div> : null}
          <span className='row float-right mb-3'>
            <span className='' style={{ marginTop: "16px" }}>
              <Button color="primary" outline
                className='ml-auto mb-0 mr-0 form_exportbtn'
                size={'sm'} onClick={this.getDataToExport}>
                <FontAwesomeIcon
                  icon='download'
                  data-toggle="tool-tip" title={t("Export To CSV")}
                  className='pl-1' size='lg' />
              </Button>
              <CSVLink
                data={this.state.exportData}
                filename={this.props.type === "Company" && this.props.city === "Public" ?
                  `${this.state.userData.Name}-${this.state.tabName}.csv` :
                  this.props.type === "Company" && this.props.city === "Private" ?
                    `${this.state.userData.CompanyName}-${this.state.tabName}.csv` :
                    `${this.state.userData.CompanyName}.csv`}
                className="hidden text-white"
                ref={(r) => this.csvLinkRef = r}
                target="_blank" >
              </CSVLink>
            </span>
            <div
              className="p-inputgroup searchField mt-3 mr-2"
            >
              <span className="p-input-icon-left">
                <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                <span className="p-input-icon-right">
                  {this.state.selectsearchFilterValue.length > 0 ?
                    <FontAwesomeIcon
                      color='red'
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      data-toggle="tool-tip"
                      onClick={() => this.filteredValues(this.props.filterCriteria, 'refresh')}
                    />
                    : null}
                  <InputText
                    type="search"
                    name='globalSearch'
                    id='globalSearch'
                    value={this.state.selectsearchFilterValue}
                    style={{ height: 37, paddingLeft: "2.5rem" }}
                    onKeyPress={(e) => this.filteredValues(e)}
                    onChange={(e) => this.filteredValues(e)}
                    placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                    size={25}
                  //  disabled={true}
                  />
                </span>
              </span>
              {/* <input
                name="globalSearchcompany"
                id="globalSearchcompany"
                component={DefaultInput}
                // onClick={(e) => this.onFilterChange(e, '', 'globalSearch')}
                onKeyDown={(e) => this.filteredValues(e)}
                ref={node => this.input = node}
                type="text"
                placeholder="Search"
              /> */}
            </div>
          </span>
        </div>
      </div >
    )
  }

  getModalHeader() {
    const { t } = this.props;
    return (
      <ModalHeader >
        <button className=" modal__close-btn" type="button" onClick={this.closeFormModal} >
          <FontAwesomeIcon
            style={{ width: "15px" }}
            // color="red"
            icon={faTimes}
          // data-toggle="tool-tip"
          // title={"save"}
          />
        </button>
        <div style={{ display: "flex" }}>
          {this.props.fontAwesome ?
            <FontAwesomeIcon
              className={'tableheadericon'}
              color="#354f6e"
              icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Sellers" ? faUsers : this.props.type === "Process Documents" ? faFolder : ""} /> :
            <span className={`tableheadericon lnr lnr-${this.props.icon}`} />}
          {this.props.type !== "ClaimsManagement" ?
            <h5 className="bold-text modal__title pl-2">

              {this.state.formType &&
                this.state.formType === 'edit' ?
                t('Edit') ? t('Edit') : 'Edit' :
                this.state.formType &&
                  this.state.formType === 'view' ?
                  t('View') ? t('View') : 'View' : t('Add') ? t('Add') : 'Add'
              }{' '}
              {this.props.type === "Users" ? "User" : this.props.type === "Labels" ? "Label" : this.props.type === "Departments" ? "Team" : this.props.type === "ClaimsManagement" ? "Payments" : this.props.type}
            </h5>
            : <h5 className="bold-text modal__title pl-2">{this.state.paymentItem === "" ? "Add Payment" : "Edit Payment"}</h5>}
        </div>


      </ModalHeader>
    )
  }

  getDependentFields(type, formType) {
    return null;
  }

  getFileNames = async (responses, type, field) => {
    var responsesdocuments = this.state[field];
    for (var file of responses) {
      responsesdocuments.push(file.fileName)
    }
    if (type == "success") {
      await this.setState({
        [field]: responsesdocuments
      })
    }
  }
  getMultiplePhotoUpload(i, item) {
    let url = this.props.type == 'User Requests' ? config.multipleimgUrl + "/userRequests" : config.imgUrl
    return (
      <div>
        <Field key={i}
          onRef={(ref) => (this.profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type='profile'
          id={'photo'}
          acceptType={'image/*'}
          url={this.props.type == 'User Requests' ? apiCalls.reportBugUpload : apiCalls.LocationImagePath}
          getMulipleFileName={(file) => this.getMulipleFileName(file, item)}
          multiple={true}
          screen={this.props.type}
          getFileNames={this.getFileNames}
          customUpload={item.customUpload ? true : false}
        />

        <Scrollbar>
          <div className='mindivForMultipleupload row'  >
            {this.state[item.name] && this.state[item.name].length > 0 ? this.state[item.name].map((imagLocationItem, imagLocationIndex) => (<div className='col-sm-4' style={{ padding: '20px' }}>
              <div key={imagLocationIndex} style={{ justifyContent: 'center' }}>
                <img
                  key={imagLocationIndex}
                  src={`${url}/${imagLocationItem}`}
                  className='detailsImgStyle' alt="" />
                <FontAwesomeIcon icon='times-circle' className='timesCircleIcon'
                  onClick={() => this.removeMultipleUploadedImages(imagLocationIndex, item)}
                />
              </div>
            </div>)) : null
            }
          </div>
        </Scrollbar>
      </div>
    )
  }
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.handleActions}
        confirmModalText={this.state.confirmModalText}
      />
    )
  }
  // Store selected Images in state
  getMulipleFileName = async (file, item) => {
    let multipleLocationImage = this.state[item.name];
    multipleLocationImage.push({ "file": file })
    await this.setState({
      [item.name]: multipleLocationImage
    });
  }
  getRulesFromRuleEngine = () => {
    var ruleElement = document.getElementById("ruleData");
    if (ruleElement) {
      return JSON.parse(ruleElement.value)
    }
    else {
      return null
    }
  }
  // Remove selected image from state
  removeMultipleUploadedImages = async (imagLocationIndex, item) => {
    var removedFiles = this.state.removedFiles;
    let multipleLocationImage = this.state[item.name];
    removedFiles.push(item.name[imagLocationIndex]);
    multipleLocationImage.splice(imagLocationIndex, 1);
    await this.setState({
      [item.name]: multipleLocationImage,
      removedFiles: removedFiles,
    })
  }

  changeNote = (Comments) => {
    this.setState({
      isnotesModal: true,
      Comments: Comments
    })
  }

  cancelNotes = async (type, id) => {
    this.setState({
      isnotesModal: false,
      openpaymentsModal: false,
      selectedRowPayments: ""
    })

  }

  
  getNotesModal() {
    return (
      <NotesModal
        openNotesModal={this.state.isnotesModal}
        cancelReset={this.cancelNotes}
        saveClaimNotes={this.saveClaimNotes}
        // claimNotesEdit={this.state.claimNotesEdit}
        // notesSno={this.state.notesSno}
        Comments={this.state.Comments}
        type={this.props.type === "ClaimsManagement" ? "Notes1" : "Notes"}
      />
    )
  }


  getTabforCronInfo() {
    return (
      <>

        <Tabs
          defaultActiveKey="minute"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="minute" title="Minute" >
            <p className='ml-4'><span className='mr-2'>*</span>	any value</p>
            <p className='ml-4'><span className='mr-2'>,</span>	value list separator</p>
            <p className='ml-4'><span className='mr-2'>-</span>	range of values</p>
            <p className='ml-4'><span className='mr-2'>/</span>	step values</p>
            <p className='ml-4'><span className='mr-2'>0-59</span> allowed Values</p>

          </Tab>
          <Tab eventKey="hour" title="Hour">
            <p className='ml-4'><span className='mr-2'>*</span>	any value</p>
            <p className='ml-4'><span className='mr-2'>,</span>	value list separator</p>
            <p className='ml-4'><span className='mr-2'>-</span>	range of values</p>
            <p className='ml-4'><span className='mr-2'>/</span>	step values</p>
            <p className='ml-4'><span className='mr-2'>0-23</span> allowed Values</p>

          </Tab>
          <Tab eventKey="date" title="Date" >
            <p className='ml-4'><span className='mr-2'>*</span>	any value</p>
            <p className='ml-4'><span className='mr-2'>,</span>	value list separator</p>
            <p className='ml-4'><span className='mr-2'>-</span>	range of values</p>
            <p className='ml-4'><span className='mr-2'>/</span>	step values</p>
            <p className='ml-4'><span className='mr-2'>1-31</span> allowed Values</p>

          </Tab>
          <Tab eventKey="month" title="Month" >
            <p className='ml-4'><span className='mr-2'>*</span>	any value</p>
            <p className='ml-4'><span className='mr-2'>,</span>	value list separator</p>
            <p className='ml-4'><span className='mr-2'>-</span>	range of values</p>
            <p className='ml-4'><span className='mr-2'>/</span>	step values</p>
            <p className='ml-4'><span className='mr-2'>1-12</span> allowed Values</p>
            <p className='ml-4'><span className='mr-2'>JAN-DEC</span> alternate Single Values</p>

          </Tab>
          <Tab eventKey="day" title="Day" >
            <p className='ml-4'><span className='mr-2'>*</span>	any value</p>
            <p className='ml-4'><span className='mr-2'>,</span>	value list separator</p>
            <p className='ml-4'><span className='mr-2'>-</span>	range of values</p>
            <p className='ml-4'><span className='mr-2'>/</span>	step values</p>
            <p className='ml-4'><span className='mr-2'>0-6</span> allowed Values</p>
            <p className='ml-4'><span className='mr-2'>SUN-SAT</span> alternate Single Values</p>
            <p className='ml-4'><span className='mr-2'>7</span>  sunday(non standard)</p>

          </Tab>
        </Tabs>
        <p>Ex:</p>
        <p>26 13 5 2 * Means 14:26 on day 5 of month February</p>
        <p>26 14 5   Means 14:26 on day 5 of month.</p>
      </>

    )
  }

  searchnameItems = async (ID) => {

    let apiUrl = `${apiCalls.sellerRelevanceNames}?estateId=${ID}&nameType=sellerRelevanceName `
    this.setState({ isLoading: true })

    if (apiUrl) {
      return fetchMethodRequest('GET', apiUrl).then(async (response) => {
        if (response) {
          let namedropdownData = [];
          if (response["sellerRelevanceNames"] && response["sellerRelevanceNames"].length && response["sellerRelevanceNames"].length > 0) {
            namedropdownData = response["sellerRelevanceNames"]

          } else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)

          }
          if (namedropdownData && namedropdownData.length === 0) {
            this.setState({
              filterednameSuggestions: [],
              noData: true,
              isLoading: false
            });
          } else {
            let modifiedLabels = []
            for (let name of namedropdownData) {
              if (name.BusinessName !== null || name.nameID !== null) {
                modifiedLabels.push({
                  label: name.BusinessNamesID + ": " + name.BusinessName,
                  value: name.BusinessName
                })
              }
            }
            namedropdownData = modifiedLabels;
          }
          await this.setState({
            filterednameSuggestions: namedropdownData,
            noData: false,
            isLoading: false
          });
        }
      }).catch((err) => {
        return err;
      });
    }
    this.setState({ isLoading: false })
  }

  setNotesToChange = async (notes) => {

    let note1 = ""
    if (notes && notes.length > 0) {
      await notes.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      for (let note of notes) {
        note1 = note1 + `Date: ${note.updated ? dateFormats.formatDate(note.updated, "MM/DD/YY") : note.created ? dateFormats.formatDate(note.created, "MM/DD/YY") : dateFormats.formatDate(new Date(), "MM/DD/YY")} Created By:${note.updatedBy ? note.updatedBy : note.createdBy ? note.createdBy : this.state.loginRole} Note: ${note.note}\n`
      }
    }
    let userData = this.state.userData

    userData.notes = notes

    await this.setState({
      updatedNotes: notes,
      userData: userData
    })
    await this.props.change("notes", note1)
  }
  getItemField(item, i) {
    return (
      <div id={item.name} key={this.props.type + i}
        className={
          this.props.displayViewOfForm === "screen" || (this.props.type == 'User Requests' && item.type != 'multipleprofile' && item.name !== "issueDescription") ? 'col-sm-6' : (item.isAddFormHidden && this.state.formType === 'add') ? 'd-none' : (item.isEditFormHidden && this.state.formType === 'edit') ? 'd-none' : (item.type === 'ckeditor' || item.type === 'permission' || item.type === 'multipleprofile' ? 'col-sm-12' : (
            (item.type === 'autoComplete' && item.name === 'assignedTo') ||
              item.name === 'closebutton' ? 'col-sm-4' : (this.props.type === 'Property And Claim Status') ? "col-sm-6" : 'col-sm-12'))}
      >
        <div className="form__form-group" >
          <label className="form__form-group-label">
            {item.type === "permission" ? <span style={{ fontSize: 17 }}>{item.label}</span> :
              item.label}{item.required ? <FontAwesomeIcon icon={faStarOfLife}
                className='pl-1'
                color='red'
                data-toggle="tool-tip"
                title="Mandotary Field"
                style={{ width: 8 }}
              /> : ''}
            {item.name === "notes" ? <FontAwesomeIcon icon={faExternalLinkAlt}
              className='pl-1'
              color='red'
              data-toggle="tool-tip"
              title="Note History"
              onClick={() => this.props.changeNoteClaims(this.state.userData)}
              style={{ width: 20 }}
            /> : ''}
            {item.name === "schedule" ? <div>
              <span className="btn-group-vertical tooltipClaims mb-2" onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
                title="click to see definations" >
                <FontAwesomeIcon icon={faInfo}
                  className='pl-1'
                  color='blue'
                  data-toggle="tool-tip"
                  title="Info"
                  onClick={(e) => this.op.toggle(e)}
                  style={{ width: 20 }}
                />
              </span>
              <OverlayPanel className='info_form' style={{ zindex: 1067 }} ref={(el) => this.op = el} appendTo={document.body} showCloseIcon={true} dismissable={true}>
                {this.getTabforCronInfo()}
              </OverlayPanel>
            </div> : null}


          </label>
          {item.type === 'dropDown' && item.isMultiSelect ?
            this.getDropdownMultiselect(i, item)
            : item.type === 'dropDown' ?
              this.getDropdown(i, item)
              : item.type === 'number' ?
                this.getNumber(i, item)
                : item.name === 'closebutton' ?
                  this.getCloseButton(i, item)
                  : item.type === 'profile' ?
                    this.getProfile(i, item)
                    : item.type === 'date' ?
                      this.getDate(i, item)
                      : item.type === 'time' ?
                        this.getTime(i, item)
                        : item.type === 'ckeditor' ?
                          this.getCkEditor(i, item)
                          : item.type === 'empty' ?
                            <div> </div>
                            : item.type === 'radio' ?
                              this.getRadio(i, item)
                              : item.type === 'permission' ?
                                this.getRolePermissions()
                                : item.type === 'autoComplete' ?
                                  this.getAutoComplete(i, item)
                                  : item.type === 'textarea' ?
                                    this.getTextArea(i, item)
                                    : item.type === 'buttonGroup' ?
                                      item.options && item.options.length > 0 ? this.getButtonGroup(i, item) : null
                                      : item.type === 'multipleprofile' ?
                                        this.getMultiplePhotoUpload(i, item)
                                        : item.type === 'switch' ?
                                          this.getSwitch(i, item)

                                          : this.getDefault(i, item)

          }
        </div>
      </div>

    )
  }

  getFields() {
    let allFields = <div></div>
    let item;
    if (!this.props.batchRequired) {
      for (let i = 0; i < this.state.formFields.length; i++) {
        item = this.state.formFields[i];
        if (item.show === false || (item.isAddFormHidden === true && this.state.formType === 'add') || (item.isEditFormHidden === true && this.state.formType === 'edit')) {

        } else {
          allFields = <>{allFields}{this.getItemField(item, i)}</>
        }
      }
    }
    if (this.props.type === "Regular Scoring Queue" || this.props.type === "Deal Sizing Queue") {
      allFields = <>{allFields}
        <div className="col-sm-12" style={{ textAlign: "center" }}>
          {!this.state.noData && this.props.type === "Regular Scoring Queue" && !this.props.batchRequired ?
            <div style={{ width: "100%", textAlign: "left" }}>
              <div>
                <label style={{ marginBottom: 4, width: "100%" }}>Business Name</label>
                <div >
                  <MultiSelect
                    className={"form__form-group-field "}
                    style={{ marginBottom: 4, width: "100%", height: 32 }}
                    appendTo={document.body}
                    name={"Name"}
                    value={this.state.selectedCompanyName}
                    options={this.state.filterednameSuggestions}
                    onChange={(e) => this.onBusinessNameChangeDropdown(e, 'dropdownFilter')} />
                </div>
              </div>
              <div>
                <label style={{ marginBottom: 4, width: "100%" }}>Elastic Threshold</label>
                <input
                  name="elasticThreshold"
                  id="elasticThreshold"
                  component={DefaultInput}
                  value={this.state.elasticThreshold}
                  onChange={(e) => this.setState({ elasticThreshold: e.value })}
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
            : null}
          {(this.props.type === "Regular Scoring Queue" && this.props.batchRequired) || this.props.type === "Deal Sizing Queue" ?
            <div style={{ width: "100%", textAlign: "left" }}>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
                  {"OPRA Deal Id's "}
                  {`(Copy and paste in the box;
                   One Id per line)`}
                </div>
                <div className='col-sm-6' style={{ textAlign: "left" }}>
                  <textarea
                    type="search"
                    name='estateIdsValue'
                    id='estateIdsValue'
                    value={this.state['estateIdsValue']}
                    disabled={this.state.sellerNameFlag ? true : false}
                    onChange={(e) => this.onEstateIdsChange(e)}
                    placeholder={'Deal Ids'}
                    style={{ height: "auto" }}
                    rows="15"
                  />
                </div>
                <div>
                  {
                    this.state.EstateIds && this.state.EstateIds.length > this.state.DealIdLimit ?
                      <div className="mb-1" style={{ height: '10px' }}>
                        {<span className="form__form-group-error">{"Maximum Limit is 2000"}</span>}
                      </div>
                      : null
                  }
                </div>
              </div>
            </div>
            : null}

          {this.props.type === "Deal Sizing Queue" ?
            <div style={{ width: "100%", textAlign: "left" }}>
              <div className='row mr-0 ml-0 pt-1'>
                <div className='col-sm-2' style={{ textAlign: "left", placeSelf: "center" }}>
                  <input
                    label='Purge Unreviewed Properties'
                    type='checkbox'
                    component={Checkbox}
                    name={'purgeUnreviewedProperties'}
                    className="analystCheckbox mb-0 mr-2"
                    checked={this.state.purgeUnreviewedProperties}
                    onChange={() => this.handlePurgeUnreviewedPropertiesChange()}
                  />
                </div>
                <div className='col-sm-10' style={{ textAlign: "left", placeSelf: "center" }}>
                  <label>Purge Unreviewed Properties</label>
                </div>
              </div>
            </div>
            : null}
          {/* Use this code in future if needed */}
          {/* <div className='row mr-0 ml-0 pt-1'>
            <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
              {'MBD Names and Addresses Table'}
            </div>
            <div className='col-sm-6' style={{ textAlign: "left", placeSelf: "center" }}>
              <FontAwesomeIcon
                icon={this.state.mbd ? faToggleOn : faToggleOff}
                className='pl-1'
                // size='lg'
                style={{ color: '#04740e', width: "40px", height: "auto" }}
                data-toggle="tool-tip"
                title={"mbd"}
                onClick={() => this.mbdTable()}
              />
            </div>
          </div> */}

        </div></>
    }
    return allFields;
  }

  onEstateIdsChange = async (e) => {
    console.log("onEstateIdsChange", e.target.value)
    const str = e.target.value;
    var result = str.split(/\r?\n/);
    console.log("result", result)
    this.setState({
      estateIdsValue: e.target.value,
      EstateIds: result
    })
  }

  mbdTable = async (e) => {
    await this.setState({
      mbd: !this.state.mbd
    })
    if (this.state.mbd) {
      await this.setState({
        companySourceType: "MBD",
        ElasticScoringType: "RegularScoring",
        ExpandedScoring: false
      })
    }
  }

  setSlno = async (actionType) => {
    // const { totalRecords, first, rows, onPageChange, isWeb } = this.props;
    let slno = this.state.slno;
    if (actionType === 'back') {
      if (slno !== 0) {
        await this.setState({ slno: slno - 1 })
        this.handleNextAndBackActions(actionType)
      } else {
        await this.setState({ slno: slno + 1 })
      }
    } else if (actionType === 'next') {
      let total = '';
      total = this.props.allUsersData.length
      if (slno !== total) {
        await this.setState({ slno: slno + 1 })
        this.handleNextAndBackActions(actionType)
      } else {
        await this.setState({ slno: slno })
      }
    }
  }

  getPaginationWithIcons() {
    // const { totalRecords, first, rows, onPageChange, isWeb } = this.props;
    const { slno } = this.state;
    return (
      <Row>
        <Col sm={12} className='pb-2 text-right'>
          <span className='showingNumber'>
            {slno === 0 ? 1 : slno + 1} / {this.props.allUsersData.length}&nbsp;&nbsp;
          </span>
          <span>
            <ButtonGroup className='mb-0'>
              <Button color="primary"
                outline
                disabled={slno === 0 ? true : false}
                size="sm"
                className="p-1 ml-auto mt-1 mb-0"
                onClick={() => this.setSlno('back')}
              >
                <FontAwesomeIcon
                  icon='chevron-left'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="List"

                />
              </Button>
              <Button color="primary"
                outline
                disabled={slno === this.props.allUsersData.length - 1 ? true : false}
                size="sm"
                className="p-1 ml-auto mt-1 mb-0"
                onClick={() => this.setSlno('next')}
              >
                <FontAwesomeIcon
                  icon='chevron-right'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="List"

                />
              </Button>
            </ButtonGroup>
          </span>
        </Col>
      </Row>
    )
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getActivities}

      />
    )
  }
  //getModalBody
  getModalBody(handleSubmit) {
    return (
      <>
        <form key={this.props.type} onSubmit={handleSubmit(this.submit)} autoComplete={'off'}>
          <ModalBody className="modal__body mb-0 ">
            <Card className='pb-0 '>
              <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader"
              />
              <CardBody className='modalCardBody' >
                {/* <form key={this.props.type} onSubmit={handleSubmit(this.submit)} autoComplete={'off'}>
                <Loader loader={this.state.isLoading} /> */}
                <div className="row form" >
                  {this.getFields()}
                  {this.state.sessionExpiryModal ?
                    <SessionExpiryModal
                      SOpen={this.state.sessionExpiryModal}
                    />
                    : null
                  }
                  {this.props.type === "Attributes" && this.state.displayTable ? this.getKeyValueButton() : null}
                  {this.props.type === "Process Documents" ? this.getKeyValueButton() : null}
                  {this.props.getDependentFields && this.props.getDependentFields(this.props.type, this.props.formType)}
                </div>

                {/* </form> */}
              </CardBody>
            </Card>
            {this.state.errorResponse ? <span className=" errorbgColor errorTextColor px-3" style={{ fontSize: 14 }}>{this.state.errorMessage}</span> : null}
            {this.state.documentFieldErr ? <span className="errorbgColor errorTextColor px-3" style={{}}> {this.state.errordocMessage}</span> : null}
          </ModalBody>
          <ModalFooter className="modalFooter">
            {this.getButtonToolbar()}
          </ModalFooter>
        </form>

      </>
    )
  }

  AddChecklistField = (priorityCount) => {
    this.setState({
      priorityCount: priorityCount
    })
    let taskPriorityList = this.state.taskPriorityList;
    taskPriorityList.push({
      label: `level1status${this.state.priorityCount}`,
      value: `level1processName${this.state.priorityCount}`,
      subPriorities: []
    })
    this.setState({
      taskPriorityList: taskPriorityList,
    });
  }

  addSubItemtoPriorityList(SublevelCount, array, index, type, listItem) {
    if (type === 'level1') {
      this.setState({
        sublevel1Count: SublevelCount + 1,
      });
      let taskPriorityList = this.state.taskPriorityList
      taskPriorityList[index].subPriorities.push({
        label: `sublevel1status${this.state.sublevel1Count}`,
        value: `sublevel1processName${this.state.sublevel1Count}`,
        subPrioritieslevel2: []
      })

    } else {
      this.setState({
        sublevel2Count: SublevelCount + 1,
      });
      let taskPriorityList = this.state.taskPriorityList

      taskPriorityList[listItem].subPriorities[index].subPrioritieslevel2.push({
        label: `sublevel2status${this.state.sublevel2Count}`,
        value: `sublevel2processName${this.state.sublevel2Count}`,
      })
    }

  }

  getPriorityField() {
    return (
      <div className='d-flex col-12 '>
        <div className='col-sm-12 px-0'>
          {
            this.state.taskPriorityList && this.state.taskPriorityList.length > 0 ?
              this.state.taskPriorityList.map((item, i) => {
                return <div>
                  <div className="row form mb-1">
                    <div className="col-sm-1">
                      <Field key={i}
                        className="key_table"
                        name={item.label ? item.label : `key${i}`}
                        component={Checkbox}
                        type={"text"}
                        id={item.name ? item.name : null}
                        placeholder="key"
                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                      // onChange={(e) => this.handlekeyValueChange(e, `key${i}`, i)}
                      />
                    </div>
                    <div className="col-sm-5"><Field key={i}
                      name={item.value ? item.value : `value${i}`}
                      component={DefaultInput}
                      className="key_table"
                      type={"text"}
                      id={item.name ? item.name : null}
                      placeholder="Value"
                      value={item[`value${i}`] ? item[`value${i}`] : ""}
                      isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                    // onChange={(e) => this.handlekeyValueChange(e, `value${i}`, i)}
                    />
                    </div>
                    <div className="col-sm-1">
                      <Button color='primary' className="btnRadius" onClick={() => this.addSubItemtoPriorityList(this.state.sublevel1Count, item, i, 'level1')}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='mt-0 mr-0' size='lg'
                          data-toggle="tool-tip" title="List"
                        />
                      </Button>
                    </div>
                    <div className="col-sm-5"></div>
                  </div>
                  {item.subPriorities && item.subPriorities.length > 0 ? this.getsubItemField(this.state.taskPriorityList[i].subPriorities, i, 'level1') : null}
                </div>
              }) : ""}

        </div>
      </div >
    )
  }

  getsubItemField(listItem, index, type) {
    return (
      <div className='d-flex ml-2 col-12 '>
        <div className='col-sm-12 px-0'>
          {
            listItem && listItem.length > 0 ?
              listItem.map((item, i) => {
                return <div><div className="row form mb-1">
                  <div className="col-sm-1">
                    <Field key={i}
                      className="key_table"
                      name={item.label ? item.label : `key${i}`}
                      component={Checkbox}
                      type={"text"}
                      placeholder="key"
                      isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                    // onChange={(e) => this.handlekeyValueChange(e, `key${i}`, i)}
                    />
                  </div>
                  <div className="col-sm-5"><Field key={i}
                    name={item.value ? item.value : `value${i}`}
                    component={DefaultInput}
                    className="key_table"
                    type={"text"}
                    placeholder="Value"
                    value={item[`value${i}`] ? item[`value${i}`] : ""}
                    isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                  // onChange={(e) => this.handlekeyValueChange(e, `value${i}`, i)}
                  />
                  </div>
                  {type === "level1" ?
                    <div className="col-sm-1">
                      <Button color='primary' className="btnRadius" onClick={() => this.addSubItemtoPriorityList(this.state.sublevel2Count, item, i, 'level2', index)}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className='mt-0 mr-0' size='lg'
                          data-toggle="tool-tip" title="List"
                        />
                      </Button>
                    </div> :
                    <div className="col-sm-1">
                      <Button color='primary' className="btnRadius" onClick={() => this.addSubItemtoPriorityList(this.state.sublevel2Count, item, i, 'level2', index)}>
                        <FontAwesomeIcon
                          icon={faMinus}
                          className='mt-0 mr-0' size='lg'
                          data-toggle="tool-tip" title="List"
                        />
                      </Button>
                    </div>}
                  <div className="col-sm-5"></div>
                </div>
                  {item.subPrioritieslevel2 && item.subPrioritieslevel2.length > 0 ? this.getsubItemField(this.state.taskPriorityList[index].subPriorities[i].subPrioritieslevel2, '', 'level2') : null}
                </div>
              }) : ""}

        </div>
      </div >
    )
  }

  //getScreenBody
  getScreenBody(handleSubmit) {
    return (
      <form key={this.props.type} onSubmit={handleSubmit(this.submit)} autoComplete={'off'}>
        <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader"
        />
        <div className={this.props.formType === 'add' ? "row form pt-3" : "row form "}>
          {this.getFields()}
          {/* <RuleEngineIndex /> */}
          {this.state.sessionExpiryModal ?
            <Redirect to="/log_in" />
            // <SessionExpiryModal
            //   SOpen={this.state.sessionExpiryModal}
            // />
            : null
          }
          {this.props.getDependentFields && this.props.getDependentFields(this.props.type, this.props.formType)}
        </div>
        {this.props.type === "Rules" ?
          <OnClick
            attributeData={this.props.attributeData}
            init-rule-data={(this.state.userData && this.state.userData.rules) ? JSON.stringify(this.state.userData.rules) : ''} />
          : null}
        {this.props.type === "Task Template" ?
          <div className="row ml-0">
            <Button color='primary' onClick={() => this.AddChecklistField(this.state.priorityCount + 1)}>
              Checklist Item
            </Button>
          </div>
          : null}
        {this.getPriorityField()}
        {this.getButtonToolbar()}
      </form>
    )
  }

  //getModalView
  getModalView() {
    const { handleSubmit } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
      'modal_Property_And_Claim_Status': this.props.type === "Property And Claim Status"
    });

    return (
      <Modal
        fade={this.state.formType !== 'view' && this.props.type != 'User Requests' ? true : false}
        isOpen={this.props.openFormModal}
        className={`right modal-dialog-centered modal-dialog--primary  ${modalClass}`}
      >
        {this.getModalHeader()}
        {this.state.formType === 'view' ?
          <ModalBody className="modal__body mb-0 pt-1" style={this.state.formType === 'view' ? { padding: "0px 0px" } : { padding: "15px 15px 80px" }} >
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody' style={this.state.formType === 'view' ? { padding: "30px" } : ""} >
                {this.getViewBody()}
                {this.getEditButton()}
              </CardBody>
            </Card>
          </ModalBody> : this.getModalBody(handleSubmit)}
      </Modal>

    )
  }

  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: ''
    })
  }

  getHeader() {
    const { t } = this.props;
    return (
      // <div className='d-flex'>
      //   <div className='col-12 px-0 pb-1'>
      //     <span className='float-left pt-2'>
      //       <h4 style={{ textTransform: 'capitalize' }}><b><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
      //         {t(this.props.type)}
      //       </Link>
      //         {this.state.formType !== 'add' && this.state.displayBreadCrumbValue ? ` / ${this.state.displayBreadCrumbValue} ` : null}
      //       </b> </h4>
      //     </span>
      //   </div>
      // </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {this.state.formType === 'view' ?
          <div className="row">
            <div>
              <Link to={this.props.routeTo} onClick={this.closeFormModal} style={{ fontSize: "16px", paddingLeft: "0px", paddingRight: "10px", fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className='genderIconAlignment'
                  icon={faArrowLeft}
                  data-toggle="tool-tip"
                  title="Back"
                  style={{ width: '14', marginTop: 7, marginLeft: 7, cursor: 'pointer' }}
                />
              </Link>
              <span className="ml-2" style={{ fontSize: "20px" }}>{this.state.userData.CompanyName}</span>
            </div>
            <div className="row">

            </div>
          </div>
          : <h4>
            {this.props.type === "Status" ? <span style={{ fontSize: "15px" }}>
              <FontAwesomeIcon icon={faToggleOff} /></span> :
              <span className={`modallnrIcon lnr lnr-${this.props.icon}`} />}
            <Link to={this.props.routeTo} onClick={this.closeFormModal} style={{ fontSize: "16px", paddingLeft: 10, fontWeight: "bold" }}>
              {this.state.formType &&
                this.state.formType === 'edit' ?
                t('Edit') ? t('Edit') : 'Edit' :
                this.state.formType &&
                  this.state.formType === 'view' ?
                  t('View') ? t('View') : 'View' : t('Add') ? t('Add') : 'Add'
              }{' '}{this.props.type}

            </Link>
          </h4>}
      </div>
    )
  }

  submitActionsData = async (method, url) => {
    return fetchMethodRequest(method, url)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false,
          actions: '',
        });
        if (response && response.respCode) {
          // showToasterMessage(response.respMessage, 'success');
          await this.props.getDataFromServer(this.props.filterCriteria);
          await this.props.closeFormModal()
          await this.handleNextAndBackActions();
          // this.props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          // showToasterMessage(response.errorMessage, 'error');
          this.getErrorMessage(response.errorMessage)
        }
      }).catch((err) => {

        return err;
      });
  }

  getErrorMessage = (errorMessage) => {
    if (errorMessage && errorMessage.length > 0) {
      this.setState({
        errorMessage: errorMessage,
        errorResponse: true
      })
    }
  }

  handleActions = async () => {
    let apiUrl = this.props.apiUrl, url = '', method = '';
    if (this.state.confirmType === 'Delete') {
      method = 'DELETE';
      url = `${apiUrl}/${this.state.editRowDataID}`;
      this.submitActionsData(method, url)
    }
    if (this.state.confirmType === 'Block') {
      method = 'PUT';
      url = `${apiUrl}/block/${this.state.editRowDataID}?block=true`;
      this.submitActionsData(method, url)
    }
    if (this.state.confirmType === 'ResetPassword') {
      await this.setState({
        openUserPasswordResetModal: true,
        openDeleteModal: false
      })
    }

  }
  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    this.setState({
      openDeleteModal: true,
      selectActions: selectActions,
    });
  }
  confirmActionType = async (type) => {
    if (type === 'Delete') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Delete',
      })
      this.deleteConfirmAction()
    } else if (type === 'Block') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Block',
      })
      this.deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await this.setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false
      })
      // this.deleteConfirmAction()
    }
  }

  //onActionsChange
  onActionsChange = async (event, type) => {
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value,
      })
      this.confirmActionType(event.target.value)
    }
  }

  getViewBody() {
    const { t } = this.props
    return (<div>
      <div className='row'>
        <div className='col-sm-4 pb-2'>
          {this.props.actionsTypes && this.props.actionsTypes.length > 0 ? <Dropdown
            style={{ minWidth: '10%', lineHeight: 1.3, }}
            className='mr-3'
            // appendTo={document.body}
            name='actions'
            value={this.state.actions}
            options={this.props.actionsTypes}
            placeholder={t('Actions')}
            onChange={(e) => this.onActionsChange(e, 'dropdownFilter')}
          /> : null}
        </div>
        <div className='col-sm-8 text-lg-right'>
          {this.state.originalTableFields && this.state.originalTableFields.length > 0 ? this.state.originalTableFields.map((item, index) => {
            return item.fieldType === 'Badge' && item.options && item.options.length > 0 ? item.options.map((optionsItem, optionsIndex) => {
              return (
                <Button key={optionsIndex} color='primary' onClick={() => this.saveDataToServer({ "status": optionsItem.value })} disabled={this.state.userStatus === optionsItem.value ? true : false}>{optionsItem.label}</Button>
              )
            }) : null
          }) : null}
        </div>
      </div>
      {this.state.rowData && this.state.rowData.length > 0 ?
        <div className="row form" >
          {this.state.rowData.map((item, i) => {
            return (
              item.label !== 'Sno' ?
                <div className="col-sm-12 col-12" key={i}>
                  <div className="row" style={{ margin: "auto" }}>
                    <div
                      className="col-3 paddingRowDataCol"
                    >
                      <span className='fontWeight'>
                        {t(item.label)}
                      </span>
                    </div>

                    <div className="col-8 paddingOfRowData"
                    >
                      {item.label === "Parent Company" ?
                        <a href="#" style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" onClick={() => this.getchildCompanyDetails(this.state.userData, '', 'Investor', 'ParentCompanyID')}>{item.value}</a> :
                        item.label === "Website" ?
                          <a style={{ color: '#0e4768', cursor: 'pointer' }} className="pl-0" target="_blank" href={`http://${item.value}`}>{item.value}</a>
                          : <span>{item.value}</span>
                      }
                    </div>
                  </div>
                </div> : null
            );
          })}
        </div>
        : <div style={{
          textAlign: "center", fontWeight: 800
        }}><span style={{ textAlign: "center" }}>

            No data is found for this Company
          </span></div>
      }
    </div>
    )
  }

  //sorting fields
  sortChange = (event) => {
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = this.state.filterCriteria;
      filterCriteria['direction'] = sortCount === 0 ? "desc" : 'asc';
      filterCriteria['sortfield'] = sortField;

      this.setState({
        sortCount: sortCount,
        filterCriteria: filterCriteria
      });
      this.getActivities('', filterCriteria, { key: "fieldName", value: "selectedFilterValue" });
    }
  }
  onBusinessNameChangeDropdown = async (event, type) => {

    await this.setState({
      selectedCompanyName: event.target.value
    })
  }
  onFilterChangeDropdown = async (event, type) => {
    let searchFilterCriteria = this.state.searchFilterCriteria
    let index = searchFilterCriteria.findIndex((element) => element.key === event.target.name);
    if (index > -1) {
      searchFilterCriteria.splice(index, 1);
    }
    searchFilterCriteria.push({ key: event.target.name, value: event.target.value })
    await this.setState({ searchFilterCriteria: searchFilterCriteria })
    this.setState({
      isLoading: true,
      selectedRows: '',
      filterSelect: event.target.value
    })
    if (type === 'dropdownFilter') {
      await this.setState({
        [event.target.name]: event.target.value
      })
    }
    let fieldName = '', filterCriteria = this.state.filterCriteria,
      selectedFilterValue, selecterFilterType;
    if (event) {
      fieldName = event.target.name;
      selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
      if (type === 'dropdownFilter') {
        selecterFilterType = 'eq'
      }
      if ((selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        filterCriteria['criteria'].push({
          key: fieldName,
          value: selectedFilterValue,
          type: 'in'
        });
      }
      this.state.filterCriteria.page = "1"
      this.setState({ first: 0 })
      await this.getActivities("", this.state.filterCriteria, { key: fieldName, value: selectedFilterValue })
    }
  }
  // to show filter element dropdown only when clicked on filter icon

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    let tablefieldsToShow = this.getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            expander={item.field === "expander" ? true : false}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: item.field === "expander" ? 'none' : 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field === 'status' || item.field === 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: "6px 15px", fontWeight: 500, width: item.width, fontSize: 13,
              color: config.blackColor, backgroundColor: config.templateColor, textAlign: 'left'
            }}
            filter={item.filter && (item.field === "Holding" || item.field === "InvestorStatus" || item.field === "InvestorType") ? item.filter : false}
            filterElement={

              item.filterElement && (item.field === "Holding" || item.field === "InvestorStatus" || item.field === "InvestorType") ?
                <div >
                  < FontAwesomeIcon
                    className='genderIconAlignment'
                    icon={faFilter}
                    data-toggle="tool-tip"
                    title="search"
                    style={{ color: 'black', width: '14', marginTop: "-15px", marginLeft: 7, cursor: 'pointer', float: "right" }}
                    onClick={() => this.searchPerform(item.field)} />

                  <div style={this.state[item.field + "Filter"] ? { height: 26, display: "block" } : { height: 26, display: "none" }} >
                    <MultiSelect
                      style={{ minWidth: '10%', lineHeight: 1.1 }}
                      appendTo={document.body}
                      name={item.field}
                      value={this.state[item.field]}
                      options={item.field === "Holding" ? this.state.holding : item.field === "InvestorStatus" ? this.state.investorStatus : item.field === "InvestorType" ? this.state.investorType : null}
                      onChange={(e) => this.onFilterChangeDropdown(e, 'dropdownFilter')} />
                  </div>
                </div>
                : item.filterElement ?
                  <div style={{ height: 26 }} >
                    <MultiSelect
                      style={{ minWidth: '10%', lineHeight: 1.1 }}
                      appendTo={document.body}
                      name={item.field}
                      value={this.state[item.field]}
                      options={item.field === "Holding" ? this.state.holding : item.field === "InvestorStatus" ? this.state.investorStatus : item.field === "InvestorType" ? this.state.investorType : null}
                      onChange={(e) => this.onFilterChangeDropdown(e, 'dropdownFilter')} />
                  </div> : null

            }
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}
            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }

  //format Date Field
  dateBodyTemplate = (rowData) => {
    return rowData.DealDate = dateFormats.formatDate(
      rowData.DealDate,
      "MM-DD-YYYY");
  }

  //getRow Expand collapse
  rowExpansionTemplate = (data) => {
    return (
      <div style={{ padding: '0px' }}>
        {data.GrandChildCompanies && data.GrandChildCompanies.length > 0 ?
          <div className="child_Companie">
            <DataTable value={data.GrandChildCompanies}
              scrollable
              scrollHeight="200px"
              responsiveLayout="scroll"
              expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
              rowExpansionTemplate={this.rowExpansionTemplate} dataKey={!this.state.expandAll ? "" : "DealID"}>
              <Column expander bodyStyle={{ width: '60px' }}></Column>
              <Column field="CompanyID" bodyStyle={{ width: '130px' }}></Column>
              <Column field="TargetCompanyID" bodyStyle={{ width: '180px' }}></Column>
              <Column field="TargetCompanyName" bodyStyle={{ width: '200px' }}></Column>
              <Column field="DealID" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealDate" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealType" bodyStyle={{ width: '120px' }}></Column>
              <Column field="DealSize" bodyStyle={{ width: '120px' }}></Column>
              <Column field="CompanyStage" bodyStyle={{ width: '150px' }}></Column>
              <Column field="Industry" bodyStyle={{ width: '110px' }}></Column>
              <Column field="LeadPartner" bodyStyle={{ width: '170px' }}></Column>
            </DataTable>
          </div>
          :
          // <div className="p-md-10" >Child Companies are not available under this Company.</div>
          <></>
        }
      </div>

    );
  }

  getDataTable() {
    let self = this;
    return (
      <DataTable
        ref={(el) => this.dt = el}
        value={this.state.activitiesData}
        totalRecords={this.state.totalRecordsLength}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={this.rowExpansionTemplate}
        paginator={false}
        lazy={true}
        dataKey={!this.state.expandAll ? "" : "DealID"}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={this.sortChange}
        globalFilter={this.state.globalFilter}
        onFilter={this.onFilterChange}
        scrollable={true}
        selection={false}
        scrollHeight='1000px'
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={this.state.isLoading}
        style={this.state.activitiesData && this.state.activitiesData.length === 0 ?
          { textAlign: 'center', marginTop: 0 }
          : { marginTop: 0 }}
      >
        {self.getColumns()}
      </DataTable>
    )
  }


  getActivitiesHeader() {
    const { t } = this.props
    return (
      <div className='col-12  pb-1'>
        <span className='float-left pt-2'>
          <h4 style={{ textTransform: 'capitalize' }}><b><Link to={(this.props.routeTo)} onClick={this.closeFormModal}>
            {t('Activities')}
          </Link>
            {this.state.formType !== 'add' && this.state.displayBreadCrumbValue ? ` / ${this.state.displayBreadCrumbValue} ` : null}
          </b> </h4>
        </span>
        <span className='float-right pt-2'>
          {this.state.totalRecordsLength > 50 ?
            this.getPaginator()
            : null}
        </span>
      </div>
    )
  }

  getKeyValueButton() {
    // const { t } = this.props
    return (
      <div className='d-flex col-12 pl-0 pr-0 keylist'>
        <div className='col-12 px-0'>
          <div className='row ml-0 mr-0'>
            <h5 className={this.state.attributeCode ? "mr-auto mt-auto mb-auto" : "mt-2 mb-3"} style={{ paddingLeft: "15px", fontWeight: "400" }}>{this.props.type === "Process Documents" ? "Documents" : "Values"}</h5>
            {this.state.attributeCode ?
              <span className='float-right' style={{ marginRight: "16px" }}>
                <ButtonToolbar>
                  <Button color='primary' outline onClick={() => this.KeyValueTable()}>
                    Add
                  </Button>
                </ButtonToolbar>
              </span>
              : null}
          </div>
          <div>
            {this.getKeyValueTable()}
          </div>
        </div>
      </div>
    )
  }

  KeyValueTable = async (keyCount) => {
    await this.setState({
      KeyValueTable: true,
      keyCount: keyCount
    })
    if (this.state.validationExists && this.state.documentFieldErr) {
      await this.setState({
        errordocMessage: "Please add atleast one document",
        documentFieldErr: false,
        validationExists: false
      })
    }
    let attributeListArray = this.state.attributeListArray;
    await this.setState({
      KeyValueTable: true,
      keyCount: attributeListArray.length > 0 ? attributeListArray.length : 0
    })

    for (let i = 0; i < attributeListArray.length; i++) {
      let key = attributeListArray[i][`key${i}`]
      let value = attributeListArray[i][`value${i}`]
      if (!key || !value || key === null || value === null) {
        // validationExists = true
        await this.setState({
          errordocMessage: "Please fill the fields before saving the record",
          documentFieldErr: true,
          validationExists: true
        })
      } else {
        await this.setState({
          errordocMessage: "",
          documentFieldErr: false,
          validationExists: false
        })
      }
    }
    if (!this.state.validationExists) {
      attributeListArray.push({
        label: `key${this.state.keyCount}`,
        value: `value${this.state.keyCount}`,
      })
    }
    //attributeListArray.push([])
    await this.setState({
      attributeListArray: attributeListArray,
    });
  }

  handlekeyValueChange = (e, key, index) => {
    let attributeListArray = this.state.attributeListArray
    attributeListArray[index][key] = e
    this.setState({
      documentFieldErr: false,
      validationExists: false,
      attributeListArray: this.state.attributeListArray
    })
  }

  deleteKeyValueItem = async (i) => {
    // let l = `key${i}`
    let list_arr = this.state.attributeListArray;
    list_arr.splice(i, 1)
    let afterdelarr = []
    for (let i = 0; i < list_arr.length; i++) {
      let label_lab = `key${i}`
      let value_lab = `value${i}`
      afterdelarr.push({
        label: `key${i}`,
        value: `value${i}`,
        [label_lab]: list_arr[i][list_arr[i].label],
        [value_lab]: list_arr[i][list_arr[i].value]
      })
    }
    await this.setState({
      attributeListArray: JSON.parse(JSON.stringify(afterdelarr))
    })

  }

  getKeyValueTable() {
    return (
      <div className='d-flex col-12 '>
        <div className='col-12 px-0'>
          <Table bordered={true}>
            <thead>
              <tr>
                <th>{this.props.type === "Process Documents" ? "Name" : "Key"}</th>
                <th>{this.props.type === "Process Documents" ? "Url" : "Value"}</th>
                {this.state.attributeCode ? <th>Actions</th> : null}
              </tr>
            </thead>
            <tbody style={{ maxHeight: 80 }}>
              {
                this.state.attributeListArray && this.state.attributeListArray.length > 0 ?
                  this.state.attributeListArray.map((item, i) => {
                    return <tr key={i}>
                      <td style={{ padding: "3px" }}><input key={i}
                        className="key_table"
                        name={item.label ? item.label : `key${i}`}
                        component={DefaultInput}
                        value={item[item.label] ? item[item.label] : ""}
                        type={"text"}
                        placeholder={this.props.type === "Process Documents" ? "Name" : "key"}
                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                        onChange={(e) => this.handlekeyValueChange(e.target.value, `key${i}`, i)}
                      /></td>
                      <td style={{ padding: "3px" }}> <input key={i}
                        name={item.value ? item.value : `value${i}`}
                        component={DefaultInput}
                        className="key_table"
                        type={"text"}
                        placeholder={this.props.type === "Process Documents" ? "Url" : "value"}
                        value={item[item.value] ? item[item.value] : ""}
                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                        onChange={(e) => this.handlekeyValueChange(e.target.value, `value${i}`, i)}
                      /></td>
                      {this.state.attributeCode ?
                        <td style={{ padding: "3px", textAlign: "center" }}>
                          <FontAwesomeIcon
                            className='genderIconAlignment'
                            icon={faTimesCircle}
                            data-toggle="tool-tip"
                            title="Delete"
                            style={{ color: '#bf1725', width: '14', marginTop: 7, marginLeft: 7, cursor: 'pointer' }}
                            onClick={() => this.deleteKeyValueItem(i, "Delete")} />
                        </td>
                        : null}
                    </tr>
                  }) : ""}
            </tbody>

          </Table>
        </div>
      </div >
    )
  }

  //getScreenView
  getScreenView() {
    const { handleSubmit, } = this.props;
    return (
      <div>
        {this.props.openFormModal ? <div
        >
          <div className='row'>
            <div className='col-sm-12 pb-2'>
              <span className='float-left'>
                {this.getHeader()}
              </span>
            </div>
          </div>
          {this.state.formType !== 'view' ? this.getScreenBody(handleSubmit) : this.getViewBody()}
          {this.state.formType === 'view' ? this.getEditButton() : null}
          {this.state.formType !== 'add' && this.props.type !== "Rules" ?
            < div >
              <div className='row'>
                {/* {this.getActivitiesHeader()} */}
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  {this.getDataTable()}
                  {/* {this.getDataTable2()} */}
                </div>
              </div>
              <div className='row'>
                {this.getActivitiesHeader()}
              </div>
            </div> : null
          }
        </div> : null
        }
      </div>
    );
  }

  redirectToCompany = async (val, type) => {
    if (type === "refresh") {
      this.props.getDataFromServer(val, 'refresh')
      this.closeFormModal()
      // let filterCriteria = this.state.filterCriteria
      // delete filterCriteria.globalSearch
      // this.getActivities('', filterCriteria)
      // await this.setState({
      //   selectFilterValue: null
      // })
    } else if (type === "results") {
      this.props.closeFormModal();
      this.props.onShowPrivateCompanies(val)

    }
    else {
      // await this.setState({
      //   selectFilterValue: val
      // })
      this.props.onFilterChange(val, '', 'DetailView')

    }
  }

  getScreenHeader() {
    const { t } = this.props;
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12'>
            <h4><span className='postionRelative pt-2'>
              <span className={`tableheadericon lnr lnr-${this.props.icon}`} />
              <b
              ><Link to={(this.props.routeTo)} onClick={this.closeFormModal} className="tableType" >
                  {t(this.props.type)}
                </Link>{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
              </b>
            </span>
              <span className="formError pt-2 pl-4" style={{ color: "red", fontSize: 14 }}>{this.props.city === "Public" && this.state.tabName === "Names/Address" ? "Data for this screen is not available for now.We are working on this." : this.state.errorResponse ? this.state.errorMessage : null}</span>
              <span className='float-right pl-3 ' style={{ display: "inline-flex" }}>
                <div>
                  <div
                    className="p-inputgroup searchField"
                  >
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                      <span className="p-input-icon-right">
                        {this.props.selectFilterValue.length || this.state.selectFilterValue.length > 0 ?
                          <FontAwesomeIcon
                            color='red'
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            data-toggle="tool-tip"
                            onClick={() => this.redirectToCompany(this.props.filterCriteria, 'refresh')}
                          />
                          : null}
                        <InputText
                          type="search"
                          name='globalSearch'
                          id='globalSearch'
                          value={this.state.selectFilterValue ? this.state.selectFilterValue : this.props.selectFilterValue}
                          style={{ height: 37, paddingLeft: "2.5rem" }}
                          onKeyPress={(e) => this.redirectToCompany(e)}
                          onChange={(e) => this.redirectToCompany(e)}
                          placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                          size={25}
                        //  disabled={true}
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </span>
            </h4>
          </div >
        </div >

      </div >
    )
  }

  render() {
    const { displayViewOfForm } = this.props;
    return (
      <div>
        {displayViewOfForm === 'modal' ?
          this.getModalView()
          : displayViewOfForm === 'screen' && this.props.type === "Company" ?
            <div>
              <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader"
              />
              {this.getScreenHeader()}
              {this.getTabView()}
            </div>
            : displayViewOfForm === 'screen' ?
              this.getScreenView() : null
        }
        {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

        {this.state.companyModal ? this.getCompanyModal() : null}
        {this.state.isnotesModal ? this.getNotesModal() : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div>
    );

  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
  form: "Common Form Modal", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(FormModal);

export default withTranslation('common')(FormModal);