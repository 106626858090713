
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
// fecth method from service.js file
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CSVLink } from "react-csv";
import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';
import moment from 'moment-timezone';
import config from '../../config/config';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ConfirmaionModel from '../Cruds/CommonModals/CSVNameModal'

import ExcelReact from '../Cruds/CommonDataTable/ExcelReact'
import { green } from '@material-ui/core/colors';
class OpenCorporateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      exportData: [],
      propertyStatus: ["EstateName", "BusinessName", "PropertyName", "SourceState", "PropertyCity"],
      setGoButton: true,
      company: '',
      address: '',
      feinLeiEin: '',
      companyCheck: true,
      addressCheck: false,
      displayErrorMessage: false,
      displaySuccessMessage: false,
      message: null,
      errorMessage: null,
      outputFileName: null,
      filterCriteria: this.props.filterCriteria,
      states: [
        {
          "value": "us_ak",
          "label": "AK",
        },
        {
          "value": "us_al",
          "label": "AL",
        },
        {
          "value": "us_ar",
          "label": "AR",
        },
        {
          "value": "us_az",
          "label": "AZ",
        },
        {
          "value": "us_ca",
          "label": "CA",
        },
        {
          "value": "us_co",
          "label": "CO",
        },
        {
          "value": "us_ct",
          "label": "CT",
        },
        {
          "value": "us_dc",
          "label": "DC",
        },
        {
          "value": "us_de",
          "label": "DE",
        },
        {
          "value": "us_fl",
          "label": "FL",
        },
        {
          "value": "us_ga",
          "label": "GA",
        },
        {
          "value": "us_hi",
          "label": "HI",
        },
        {
          "value": "us_ia",
          "label": "IA",
        },
        {
          "value": "us_id",
          "label": "ID",
        },
        {
          "value": "us_il",
          "label": "IL",
        },
        {
          "value": "us_in",
          "label": "IN",
        },
        {
          "value": "us_ks",
          "label": "KS",
        },
        {
          "value": "us_ky",
          "label": "KY",
        },
        {
          "value": "us_la",
          "label": "LA",
        },
        {
          "value": "us_ma",
          "label": "MA",
        },
        {
          "value": "us_md",
          "label": "MD",
        },
        {
          "value": "us_me",
          "label": "ME",
        },
        {
          "value": "us_mi",
          "label": "MI",
        },
        {
          "value": "us_mn",
          "label": "MN",
        },
        {
          "value": "us_mo",
          "label": "MO",
        },
        {
          "value": "us_ms",
          "label": "MS",
        },
        {
          "value": "us_mt",
          "label": "MT",
        },
        {
          "value": "us_nc",
          "label": "NC",
        },
        {
          "value": "us_nd",
          "label": "ND",
        },
        {
          "value": "us_ne",
          "label": "NE",
        },
        {
          "value": "us_nh",
          "label": "NH",
        },
        {
          "value": "us_nj",
          "label": "NJ",
        },
        {
          "value": "us_nm",
          "label": "NM",
        },
        {
          "value": "us_nv",
          "label": "NV",
        },
        {
          "value": "us_ny",
          "label": "NY",
        },
        {
          "value": "us_oh",
          "label": "OH",
        },
        {
          "value": "us_ok",
          "label": "OK",
        },
        {
          "value": "us_or",
          "label": "OR",
        },
        {
          "value": "us_pa",
          "label": "PA",
        },
        {
          "value": "us_ri",
          "label": "RI",
        },
        {
          "value": "us_sc",
          "label": "SC",
        },
        {
          "value": "us_sd",
          "label": "SD",
        },
        {
          "value": "us_tn",
          "label": "TN",
        },
        {
          "value": "us_tx",
          "label": "TX",
        },
        {
          "value": "us_ut",
          "label": "UT",
        },
        {
          "value": "us_va",
          "label": "VA",
        },
        {
          "value": "us_vt",
          "label": "VT",
        },
        {
          "value": "us_wa",
          "label": "WA",
        },
        {
          "value": "us_wi",
          "label": "WI",
        },
        {
          "value": "us_wv",
          "label": "WV",
        },
        {
          "value": "us_wy",
          "label": "WY",
        },
        {
          "value": "ca",
          "label": "CAN",
        },
        {
          "value": "ca_bc",
          "label": "BC",
        },
        {
          "value": "ca_nb",
          "label": "NB",
        },
        {
          "value": "ca_nl",
          "label": "NL",
        },
        {
          "value": "ca_ns",
          "label": "NS",
        },
        {
          "value": "ca_nu",
          "label": "NU",
        },
        {
          "value": "ca_on",
          "label": "ON",
        },
        {
          "value": "ca_pe",
          "label": "PE",
        },
        {
          "value": "ca_qc",
          "label": "QC",
        },
        {
          "value": "pr",
          "label": "PR",
        }
      ]
    };
    localStorage.removeItem("searchName")
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // this.getDynamicFields()
  }

  componentDidUpdate() {
    if (this.state.displaySuccessMessage || this.state.displayResErrorMessage) {
      setTimeout(() => this.setState({ displaySuccessMessage: false, displayResErrorMessage: false, errorMessage: "", message: "" }), 30000);
    }
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  setResData = async (a, b) => {
    if (a) {
      await this.setState({
        errorMessage: b,
        displayResErrorMessage: a
      });
    } else {
      await this.setState({
        errorMessage: null,
        displayResErrorMessage: false
      });
    }
  }
  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  submit = async (values) => {
    if (values === "Go") {
      if (this.state.searchName && (this.state.searchName !== "" || this.state.searchName === null)) {
        await this.setState({
          setGoButton: true
        })
        let filterCriteria = this.props.filterCriteria
        localStorage.setItem("searchName", this.state.searchName.EstateID)
        let data = []
        if (this.props.activeTab === "OC API Data") {
          data = await this.changeingColumnFieldsOrder()
        }
        if (this.props.type === "OpenCorporateBranchBatch") {
          if (["", null].includes(this.state.outputFileName)) {
            this.setState({
              displayErrorMessage: true,
              displaySuccessMessage: false,
              message: "Please provide valid file name."
            });
          } else {
            this.setState({
              displayErrorMessage: false,
              displaySuccessMessage: false,
              message: null
            });
            this.getDataToExport();
          }
        } else {
          this.props.getFilteredValues(filterCriteria, data, this.state.CompanyNames);
        }
      } else {
        this.props.getErrorMessage("Please search for a company")
      }
    }
  }
  submitSearch = async () => {
    if (this.state.company || this.state.address || this.state.feinLeiEin) {
      await this.setState({
        setGoButton: true
      })
      let filterCriteria = this.props.filterCriteria

      let data = []
      if (this.props.activeTab === "OC API Data") {
        data = await this.changeingColumnFieldsOrder()
      }
      let inputs = {}
      if (this.state.companyCheck && this.state.company) {
        inputs.company = this.state.company
      }
      if (this.state.addressCheck && this.state.address) {
        inputs.address = this.state.address
      }
      if (this.state.feinLeiEinCheck && this.state.feinLeiEin) {
        inputs.feinLeiEin = this.state.feinLeiEin
      }
      if (this.state.selectedStates && this.state.selectedStates) {
        inputs.jurisdiction_code = this.state.selectedStates
      }

      this.props.getFilteredValues(filterCriteria, data, inputs)
    } else {
      this.props.getErrorMessage("Please search for a company")
    }
  }

  changeingColumnFieldsOrder = () => {

    let tableFields = this.props.tableFieldsToShow
    tableFields = this.props.getTableFields
    if (this.props.activeTab === "OC API Data") {
      let dataindex
      let tablenotKnownFields = tableFields.filter(function (el) {
        return !el.field.includes("Count") || !el.field.includes("Value")
      });

      for (var item of tablenotKnownFields) {
        if (this.state.propertyStatus.includes(item.field)) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = true
        } else if (!this.state.propertyStatus.includes(item.field) && !item.field.includes("Count") && !item.field.includes("Value")) {
          dataindex = tableFields.findIndex(obj => obj.field === item.field)
          tableFields[dataindex].show = false
        }
      }
      let sortedOrder = this.state.propertyStatus
      let data = [], index
      let knownFields = []
      for (var record of sortedOrder) {
        index = tableFields.findIndex(obj => obj.field === record)
        data.push(tableFields[index])
      }
      knownFields = tableFields.filter(function (el) {
        return el.field.includes("Count") || el.field.includes("Value")
      });
      tableFields = [...data, ...knownFields]
      let xlsx_headers = localStorage.getItem("tableFields_download") ? localStorage.getItem("tableFields_download") : {}
      xlsx_headers = { [this.props.activeTab]: tableFields }
      localStorage.setItem("tableFields_download", JSON.stringify(xlsx_headers))
    }
    return tableFields


  }

  onSearchNameChange = async (e) => {
    const str = e.target.value;
    var result = str.split(/\r?\n/);
    result = result.filter(str => str.trim() !== "");
    // console.log("result", result)
    this.setState({
      searchName: e.target.value,
      CompanyNames: result,
      displayErrorMessage: this.props.type == "OpenCorporateBranch" ? result && result.length > 25 ? true : false : false,
      setGoButton: this.props.type == "OpenCorporateBranch" ? result && result.length > 25 ? true : false : false,
      errorMessage: this.props.type == "OpenCorporateBranch" ? result && result.length > 25 ? "You can search upto 25 companies only." : "" : ""
    })
  }

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {

    await this.setState({ isLoading: true, isexported: true })
    let filterCriteriaData = this.state.filterCriteria;
    // delete filterCriteriaData['limit'];
    // delete filterCriteriaData['page'];
    let url;
    let apiUrl, body, method = "GET";

    if (this.props.type === "OpenCorporateBranch") {
      url = "openCorporates";
      apiUrl = `${apiCalls.openCorporateBranch}?str=${encodeURIComponent(JSON.stringify(this.state.CompanyNames))}&type=export`;
    } else if (this.props.type === "OpenCorporateBranchBatch") {
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        url = "openCorporates";
        let email;
        if (user.secondaryEmail) {
          email = user.secondaryEmail;
        } else if (!user.secondaryEmail && user.email) {
          email = user.email;
          if (email.includes("oprausers.onmicrosoft"))
            email = email.replace("oprausers.onmicrosoft", "oakpointpartners")
        }
        if (user.email && user.displayName && this.state.outputFileName) {
          method = "POST";
          body = {
            batch: "OC",
            str: this.state.CompanyNames,
            email: email,
            displayName: user.displayName,
            fileName: this.state.outputFileName
          };
          apiUrl = `${apiCalls.openCorporateBranch}?batch=OC`;
        }
      }
    }
    else {
      let filtersQuery = ''
      if (this.state.companyCheck && this.state.company) {
        filtersQuery += `&company=${encodeURIComponent(JSON.stringify(this.state.company))}`
      }
      if (this.state.addressCheck && this.state.address) {
        filtersQuery += `&address=${encodeURIComponent(JSON.stringify(this.state.address))}`
      }
      if (this.state.feinLeiEinCheck && this.state.feinLeiEin) {
        filtersQuery += `&feinLeiEin=${encodeURIComponent(JSON.stringify(this.state.feinLeiEin))}`
      }
      if (this.state.selectedStates && this.state.selectedStates) {
        filtersQuery += `&jurisdiction_code=${encodeURIComponent(JSON.stringify(this.state.selectedStates))}`
      }
      url = "openCorporateSearch";
      apiUrl = `${apiCalls.openCorporateSearch}?filter=${JSON.stringify(filterCriteriaData)}${filtersQuery}`
    }
    if (apiUrl) {
      return fetchMethodRequest(method, apiUrl, body)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired === "true") {
            await this.setState({ sessionExpiryModal: true })
          }
          let obj = [];
          if (response && response.openCorporates) {
            var exportData = await this.updateDateFormat(response.openCorporates, "yyyy/MM/DD")
            obj.push({
              name: "Companies",
              headers: [
                //"Company-id",
                "Company-name",
                "Company-jurisdiction_code",
                "Company-company_number",
                "Company-current_status",
                "Company-incorporation_date_orig",
                "Company-dissolution_date_orig",
                "Company-branch",
                "Company-home_jurisdiction_text",
                "Company-home_jurisdiction_company_number",
                "Company-current_alternative_legal_name",
                "Company-previous_names",
                "Company-registered_address.in_full",
                "Non-Registered Addresses-in_full",
                "Company-has_been_liquidated",
                "Company-has_insolvency_history",
                "Company-registry_url",
                "Company-retrieved_at_orig",],
              data: exportData
            })
          }
          if (response && response.companies) {
            obj.push({
              name: "Companies",
              headers: ['company_number',
                'jurisdiction_code',
                'home_jurisdiction_code',
                'home_jurisdiction_company_number',
                'name',
                'normalised_name',
                'previous_names',
                'current_alternative_legal_name',
                'registered_address.in_full',
                'registry_url',
                'company_type',
                'nonprofit',
                'current_status',
                'incorporation_date',
                'dissolution_date',
                'branch',
                'business_number',
                'current_alternative_legal_name_language',
                'home_jurisdiction_text',
                'native_company_number',
                'registered_address.street_address',
                'retrieved_at',
                'restricted_for_marketing',
                'inactive',
                'accounts_next_due',
                'accounts_reference_date',
                'accounts_last_made_up_date',
                'annual_return_next_due',
                'annual_return_last_made_up_date',
                'has_been_liquidated',
                'has_insolvency_history',
                'has_charges',
                'number_of_employees',
                'registered_address.locality',
                'registered_address.region',
                'registered_address.postal_code',
                'registered_address.country',
                'industry_code_uids',
                'latest_accounts_date',
                'latest_accounts_cash',
                'latest_accounts_assets',
                'latest_accounts_liabilities'],
              data: response.companies
            })
          }
          if (response && response.alternative_names) {
            obj.push({
              name: "Alternative_names",
              headers: ["company_number", "jurisdiction_code", "name", "type", "start_date", "end_date"],
              data: response.alternative_names
            })
          }
          if (response && response.feinLeiEin) {
            obj.push({
              name: "feinLeiEin",
              headers: ['name',
                'normalised_name',
                'previous_names',
                'current_alternative_legal_name',
                'alternative_names',
                'company_number',
                'jurisdiction_code',
                'uid',
                'identifier_system_code'],
              data: response.feinLeiEin
            })
          }
          if (response && response.addresses) {
            obj.push({
              name: "Addresses",
              headers: ['source',
                'registered_address.in_full',
                'company_number',
                'jurisdiction_code',
                'name',
                'normalised_name',
                'previous_names',
                'current_alternative_legal_name',
                'address_type',
                'street_address',
                'locality',
                'region',
                'postal_code',
                'country',
                'country_code',
                'start_date',
                'end_date'],
              data: response.addresses
            })
          }
          if (this.props.type === "OpenCorporateBranchBatch") {
            await this.setState({
              displaySuccessMessage: response.message ? response.message : false,
              displayErrorMessage: response.errorMessage ? response.errorMessage : false,
              displayResErrorMessage: response.errorMessage ? response.errorMessage : false,
              errorMessage: response.errorMessage ? response.errorMessage : "",
              message: response.message ? response.message : "",
              isLoading: false
            })
          } else {
            await this.setState({
              sheets: obj,
              downloadSheet: true,
              isLoading: false,
              errorMessage: null,
              message: null,
              displayResErrorMessage: false,
              displayErrorMessage: false,
              displaySuccessMessage: false,
            })
            document.getElementsByClassName("excel-React")[0].children[0].click()
          }

          // let headers = [], item2 = {}, data = []
          // let arrayFormatcolumns = []
          // if (this.props.type === "OpenCorporateBranch") {
          //   arrayFormatcolumns = ["Company-id",
          //     "Company-name",
          //     "Company-jurisdiction_code",
          //     "Company-company_number",
          //     "Company-current_status",
          //     "Company-incorporation_date_orig",
          //     "Company-dissolution_date_orig",
          //     "Company-branch",
          //     "Company-home_jurisdiction_text",
          //     "Company-home_jurisdiction_company_number",
          //     "Company-current_alternative_legal_name",
          //     "Company-previous_names",
          //     "Alternative Name-id",
          //     "Alternative Name-name",
          //     "Alternative Name-jurisdiction_code",
          //     "Alternative Name-company_number",
          //     "Alternative Name-type",
          //     "Alternative Name-start_date_orig",
          //     "Alternative Name-end_date_orig",
          //     "Company-registered_address.in_full",
          //     "Non-Registered Addresses-in_full",
          //     "Company-has_been_liquidated",
          //     "Company-has_insolvency_history",
          //     "Company-registry_url",
          //     "Company-retrieved_at_orig",
          //     "Identifier Type",
          //     "Identifier Value"]
          // } if (this.props.type === "OpenCorporateSearch" && this.state.company) {
          //   arrayFormatcolumns = ['Company-id',
          //     'Company-name',
          //     'Company Former Name',
          //     'Company-jurisdiction_code',
          //     'Company-company_number',
          //     'Company-current_status',
          //     'Company-incorporation_date_orig',
          //     'Company-dissolution_date_orig',
          //     'Company-branch',
          //     'Company-home_jurisdiction_text',
          //     'Company-home_jurisdiction_company_number',
          //     'Company-current_alternative_legal_name',
          //     'Company-previous_names',
          //     'Alternative Name-id',
          //     'Alternative Name-name',
          //     'Alternative Name-jurisdiction_code',
          //     'Alternative Name-company_number',
          //     'Alternative Name-type',
          //     'Alternative Name-start_date_orig',
          //     'Alternative Name-end_date_orig',
          //     'Company-registered_address.in_full',
          //     'Non-Registered Addresses-in_full',
          //     'Company-has_been_liquidated',
          //     'Company-has_insolvency_history',
          //     'Company-registry_url',
          //     'Company-retrieved_at_orig',
          //     'Identifier Type',
          //     'Identifier Value',
          //     'Officer Name']
          // } else if (this.props.type === "OpenCorporateSearch" && this.state.feinLeiEin) {
          //   arrayFormatcolumns = ['Company-id',
          //     'Company-name',
          //     'Company Former Name',
          //     'Company-jurisdiction_code',
          //     'Identifier Value',
          //     'Officer Name',
          //     'Company-company_number',
          //     'Company-current_status',
          //     'Company-incorporation_date_orig',
          //     'Company-dissolution_date_orig',
          //     'Company-branch',
          //     'Company-home_jurisdiction_text',
          //     'Company-home_jurisdiction_company_number',
          //     'Company-current_alternative_legal_name',
          //     'Company-previous_names',
          //     'Alternative Name-id',
          //     'Alternative Name-name',
          //     'Alternative Name-jurisdiction_code',
          //     'Alternative Name-company_number',
          //     'Alternative Name-type',
          //     'Alternative Name-start_date_orig',
          //     'Alternative Name-end_date_orig',
          //     'Company-registered_address.in_full',
          //     'Non-Registered Addresses-in_full',
          //     'Company-has_been_liquidated',
          //     'Company-has_insolvency_history',
          //     'Company-registry_url',
          //     'Company-retrieved_at_orig',
          //     'Identifier Type']
          // } else if (this.props.type === "OpenCorporateSearch" && this.state.address) {
          //   arrayFormatcolumns = ['Company-id',
          //     'Company-name',
          //     'Company Former Name',
          //     'Company-jurisdiction_code',
          //     'Company-registered_address.in_full',
          //     'Non-Registered Addresses-in_full',
          //     'Company-company_number',
          //     'Company-current_status',
          //     'Company-incorporation_date_orig',
          //     'Company-dissolution_date_orig',
          //     'Company-branch',
          //     'Company-home_jurisdiction_text',
          //     'Company-home_jurisdiction_company_number',
          //     'Company-current_alternative_legal_name',
          //     'Company-previous_names',
          //     'Alternative Name-id',
          //     'Alternative Name-name',
          //     'Alternative Name-jurisdiction_code',
          //     'Alternative Name-company_number',
          //     'Alternative Name-type',
          //     'Alternative Name-start_date_orig',
          //     'Alternative Name-end_date_orig',
          //     'Company-has_been_liquidated',
          //     'Company-has_insolvency_history',
          //     'Company-registry_url',
          //     'Company-retrieved_at_orig',
          //     'Identifier Type',
          //     'Identifier Value',
          //     'Officer Name']
          // }
          // else if (response[url] && response[url].length > 0) {
          //   arrayFormatcolumns = Object.keys(response[url][0])
          // }
          // if (response && response[url] && response[url].length && response[url].length > 0) {
          //   let exportData = response[url]
          //   exportData = await this.updateDateFormat(exportData, config.dateDBFormat)
          //   let item = exportData[0]
          //   for (var key of arrayFormatcolumns) {

          //     item2[key] = item[key]

          //   }
          //   for (let key in item2) {
          //     let index = headers.findIndex(obj => obj.key === key)
          //     if (index === -1) {
          //       headers.push(
          //         { label: key.charAt(0).toUpperCase() + key.slice(1), key: key }
          //       )
          //     }
          //   }
          //   exportData[0] = item2
          //   exportData.forEach((item) => {
          //     for (let key in item) {
          //       if (item[key] && item[key].name) {
          //         item[key] = item[key].name;
          //       }
          //     }
          //     for (let key in item) {
          //       if (item[key] && item[key].name) {
          //         item[key] = item[key].name;
          //       }
          //     }
          //     item = exportData[0]
          //   });
          //   this.setState({
          //     exportData: exportData,
          //     exportHeaders: headers,
          //     isLoading: false,
          //   }, () => {
          //     // click the CSVLink component to trigger the CSV download
          //     this.csvLinkRef.link.click();
          //     this.setState({
          //       exportData: [],
          //       isexported: false
          //     })
          //   });
          // } else {
          //   this.setState({
          //     isLoading: false
          //   });
          // }
        }).catch((err) => {
          this.setState({
            isLoading: false
          });
          return err;
        });
    }
  }

  updateDateFormat(itemdata, dateFormat) {
    let modifiedData = [];
    let dateFields = [
      "Company-incorporation_date_orig",
      "Company-dissolution_date_orig",
      "Alternative Name-start_date_orig",
      "Alternative Name-end_date_orig",
      "Company-retrieved_at_orig"
    ]
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < dateFields.length; k++) {
        if (itemdata[i][dateFields[k]] !== null) {
          itemdata[i][dateFields[k]] = dateFormats.formatDate(itemdata[i][dateFields[k]], dateFormat, "NS");
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  handleCheckboxChange(option) {
    console.log("option", option, this.state[option])
    // this.setState({
    //   [option]: !this.state[option],
    // });
    let input = option.replace("Check", "")
    if (this.state[input] !== '') {
      this.setState({
        setGoButton: false
      });
    }
    this.setState({
      companyCheck: option === 'companyCheck',
      addressCheck: option === 'addressCheck',
      feinLeiEinCheck: option === 'feinLeiEinCheck',
    });
  };

  handleInputChange(option, value) {
    this.setState({
      setGoButton: false,
      [option]: value,
    });
  };

  // ---------------------------------- will use in future ------------------------------------
  // getDataFromServer = async () => {

  //   await this.setState({ isLoading: true, isexported: true })
  //   let filterCriteriaData = this.state.filterCriteria;
  //   let url;
  //   let apiUrl;


  //   let filtersQuery = ''
  //   if (this.state.companyCheck && this.state.company) {
  //     filtersQuery += `&company=${this.state.company}`
  //   }
  //   if (this.state.addressCheck && this.state.address) {
  //     filtersQuery += `&address=${this.state.address}`
  //   }
  //   if (this.state.feinLeiEinCheck && this.state.feinLeiEin) {
  //     filtersQuery += `&feinLeiEin=${this.state.feinLeiEin}`
  //   }
  //   url = "openCorporateSearch";
  //   apiUrl = `${apiCalls.openCorporateSearch}?filter=${JSON.stringify(filterCriteriaData)}${filtersQuery}`


  //   if (apiUrl) {
  //     return fetchMethodRequest('GET', apiUrl)
  //       .then(async (response) => {
  //         let sessionexpired = await localStorage.getItem('sessionexpired')
  //         if (sessionexpired === "true") {
  //           await this.setState({ sessionExpiryModal: true })
  //         }
  //         let headers = [];
  //         if (response) {

  //           this.setState({
  //             response: response,
  //             isLoading: false,
  //           });
  //         } else {
  //           this.setState({
  //             isLoading: false
  //           });
  //         }
  //       }).catch((err) => {
  //         this.setState({
  //           isLoading: false
  //         });
  //         return err;
  //       });
  //   }
  // }


  // getDynamicFields = async () => {
  //   let companyFields = ["company_number", "jurisdiction_code", "name", "normalised_name", "company_type", "nonprofit", "current_status", "incorporation_date", "dissolution_date", "branch", "business_number", "current_alternative_legal_name", "current_alternative_legal_name_language", "home_jurisdiction_text", "native_company_number", "previous_names", "retrieved_at", "registry_url", "restricted_for_marketing", "inactive", "accounts_next_due", "accounts_reference_date", "accounts_last_made_up_date", "annual_return_next_due", "annual_return_last_made_up_date", "has_been_liquidated", "has_insolvency_history", "has_charges", "number_of_employees", "registered_address.street_address", "registered_address.locality", "registered_address.region", "registered_address.postal_code", "registered_address.country", "registered_address.in_full", "home_jurisdiction_code", "home_jurisdiction_company_number", "industry_code_uids", "latest_accounts_date", "latest_accounts_cash", "latest_accounts_assets", "latest_accounts_liabilities"]
  //   let dynamicColumns = companyFields.map((col, i) => {
  //     if (col) {
  //       return <Column
  //         key={col}
  //         field={col}
  //         header={col}
  //         textAlign={"left"}
  //         bodyStyle={{ width: 120 }} />;
  //     }
  //   });
  //   await this.setState({ dynamicColumns: dynamicColumns })

  // }

  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact
          EstateName={this.state.FileName ? this.state.FileName : `${this.props.type} - ${todaydate}`}
          sheets={this.state.sheets}
          type="test"
        />
      </span>
    )
  }

  EnterEvent = async (e) => {
    if (e.key === "Enter" && this.state.setGoButton === false) {
      this.submitSearch();
    }
  }

  onChangeState = async (e) => {
    await this.setState({
      setGoButton: false,
      selectedStates: e.value,
    })
  }

  getConfirmaionModelforCSV() {
    return (
      <ConfirmaionModel
        openConfirmationModal={this.state.isopenConfirmaionModelforCSV}
        closeConfirmationModal={this.closeConfirmationModalforCSV}
        shownText={"Please provide excel file name."}
      // confirm={this.handleCheckValidations}
      />
    )
  }

  closeConfirmationModalforCSV = async (type) => {
    await this.setState({
      isopenConfirmaionModelforCSV: false
    })
    if (type) {
      await this.setState({
        FileName: type
      })
      this.getDataToExport()
    }
  }

  OpenConfirmaionModelorSave = async () => {
    await this.setState({
      isopenConfirmaionModelforCSV: true
    })
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Loader
          loader={this.state.isLoading}
          progress={this.state.progress}
          className={"progress-loader"} />
        <div style={{
          display: 'block',
          // border: "2px solid lightgrey"
        }} >
          {this.props.type == "OpenCorporateBranch" ?
            <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submit)}>
              <div className='row col-sm-12 m-0 p-0 d-flex' style={{ alignSelf: "center" }}>
                <div className='col-sm-11 d-flex' style={{ justifyContent: "center", width: "100%", alignItems: "center" }}>
                  <div className='col-sm-3 col-lg-4'>

                    <div style={{ padding: "initial" }}>

                      <div >
                        <InputTextarea
                          type="textArea"
                          name={"searchName"}
                          value={this.state['searchName']}
                          onChange={(e) => this.onSearchNameChange(e)}
                          placeholder={"Copy and paste in the box, One Company Name per line upto 25 companies"}
                        />
                        {this.state.displayErrorMessage ? <span className="col-sm-10" style={{
                          color: "red", display: "flex", justifyContent: "center",
                          marginLeft: "auto", marginRight: "auto", textAlign: "center"
                        }}>
                          {"You can search upto 25 companies only."}</span> : ""}
                      </div>

                    </div>

                  </div>
                  <div className='col-sm-1  ' style={{ alignSelf: "center", margin: "0px 0px", width: "8%" }}>

                    <Button color="primary"
                      outline
                      className="mb-0"
                      style={this.state.setGoButton ? { color: "white", backgroundColor: '#354f6e' } : { color: "white", backgroundColor: '#354f6e' }}
                      // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                      disabled={this.state.setGoButton ? true : false}
                      onClick={() => this.submit("Go")}>
                      Go
                    </Button>
                  </div>
                </div>
                <div className='col-sm-1'>
                  {this.state['searchName'] &&
                    <Button color="primary" outline
                      className='px-2 ml-auto mb-0 mr-0'
                      style={{ height: 37 }}
                      // disabled={this.props.type ==="Manually Loaded Properties"}
                      size={'sm'} onClick={this.OpenConfirmaionModelorSave}>
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To xlx"}
                        className='pl-1' size='lg' />
                    </Button>}
                  {this.state['searchName'] &&
                    <CSVLink
                      data={this.state.exportData}
                      headers={this.state.exportHeaders}
                      filename={`OpenCorporates.csv`}
                      className="hidden text-white p-0"
                      ref={(r) => this.csvLinkRef = r}
                      target="_blank" >
                    </CSVLink>}
                </div>
              </div>
            </form>
            : this.props.type == "OpenCorporateBranchBatch" ?
              <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submit)}>
                <div className='row col-sm-12 m-0 p-0 d-flex' style={{ alignSelf: "center" }}>
                  <div className='col-sm-12 d-flex' style={{ justifyContent: "center", width: "100%", alignItems: "center" }}>
                    <div className='col-sm-3 col-lg-4'>

                      <div style={{ padding: "initial" }}>
                        <div className='mb-2' >
                          <input
                            type="text"
                            name='outputFileName'
                            placeholder='Provide the file Name for the output Excel file'
                            value={this.state['outputFileName']}
                            onChange={async (e) => e.target && e.target.value && e.target.value != "" ? await this.setState({
                              outputFileName: e.target.value
                            }) : null}
                            required={true}
                          />
                          {this.state.displayErrorMessage ? <span className="form__form-group-error">{this.state.message}</span> : ""}
                        </div>
                        <div >
                          <InputTextarea
                            type="textArea"
                            name={"searchName"}
                            value={this.state['searchName']}
                            onChange={(e) => this.onSearchNameChange(e)}
                            placeholder={"Copy and paste in the box, One Company Name per line upto 2000 companies"}
                          />
                        </div>
                      </div>
                      {this.state.displaySuccessMessage || this.state.displayResErrorMessage ? <span className="my-2 px-3" style={{
                        color: this.state.displaySuccessMessage ? "green" : "red", display: "flex", justifyContent: "center", height: 25,
                        marginLeft: "auto", marginRight: "auto", textAlign: "center"
                      }}>
                        {this.state.displayResErrorMessage ? this.state.errorMessage : this.state.message}</span> : ""}
                    </div>
                    <div className='col-sm-1  ' style={{ alignSelf: "center", margin: "0px 0px", width: "8%" }}>

                      <Button color="primary"
                        outline
                        className="mb-0"
                        style={this.state.setGoButton ? { color: "white", backgroundColor: '#354f6e' } : { color: "white", backgroundColor: '#354f6e' }}
                        // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                        disabled={this.state.setGoButton ? true : false}
                        onClick={() => this.submit("Go")}>
                        Go
                      </Button>
                    </div>

                  </div>
                </div>

              </form>
              :
              // <>
              <form className="form" style={{ backgroundColor: "#ffffff", paddingTop: "10px", paddingBottom: "10px" }} onSubmit={handleSubmit(this.submitSearch)}>
                <>
                  <div className='col-sm-11' style={{ display: "flex" }}>
                    <h3>Search Type</h3>
                    <div className="col-sm-2" style={{ width: "100%" }} >
                      <div>
                        <span>
                          Jurisdiction
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2 clearIcon'
                            id={"clear_PE Firm"}
                            color={this.state.selectedStates ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.setState({ selectedStates: null, setGoButton: false })}
                            style={this.state.selectedStates ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          />
                        </span>
                      </div>
                      <MultiSelect
                        name="state"
                        id='state'
                        style={{ height: 33, width: "100%" }}
                        options={this.state.states}
                        value={this.state.selectedStates}
                        placeholder='ALL'
                        onChange={(e) => this.onChangeState(e)}
                        filter={true}
                        size={"20"} />
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    {(this.state.company || this.state.address || this.state.feinLeiEin) &&
                      <Button color="primary" outline
                        className='px-2 ml-auto mb-0 mr-0'
                        style={{ height: 37 }}
                        // disabled={this.props.type ==="Manually Loaded Properties"}
                        size={'sm'} onClick={this.getDataToExport}>
                        <FontAwesomeIcon
                          icon='download'
                          data-toggle="tool-tip" title={"Export To xlx"}
                          className='pl-1' size='lg' />
                      </Button>}
                  </div>
                </>
                <div className='row col-sm-12 m-0 p-0 d-flex' style={{ alignSelf: "center" }}>
                  <div className='col-sm-4'>
                    <div >
                      <label style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                      }}>
                        <input
                          style={{ marginRight: "revert" }}
                          type="radio"
                          checked={this.state.companyCheck}
                          onChange={() => this.handleCheckboxChange('companyCheck')}
                        />
                        {" Company:"}
                      </label>
                      <input
                        type="text"
                        value={this.state.company}
                        disabled={this.state.companyCheck ? false : true}
                        onChange={(e) => this.handleInputChange('company', e.target.value)}
                        onKeyDown={(e) => this.EnterEvent(e)}
                      />
                      {"Select the type of search, enter search text and hit the Enter key"}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div >
                      <label style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                      }}>
                        <input
                          style={{ marginRight: "revert" }}
                          type="radio"
                          checked={this.state.addressCheck}
                          onChange={() => this.handleCheckboxChange('addressCheck')}
                        />
                        {" AddressLine1:"}
                      </label>
                      <input
                        type="text"
                        value={this.state.address}
                        disabled={this.state.addressCheck ? false : true}
                        onChange={(e) => this.handleInputChange('address', e.target.value)}
                        onKeyDown={(e) => this.EnterEvent(e)}
                      />
                      {"Start with initial part of the address line instead of copying and pasting entire address"}
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div >
                      <label style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-evenly",
                        alignItems: "center"
                      }}>
                        <input
                          style={{ marginRight: "revert" }}
                          type="radio"
                          checked={this.state.feinLeiEinCheck}
                          onChange={() => this.handleCheckboxChange('feinLeiEinCheck')}
                        />
                        {" FEIN/LEI/EIN/TIN:"}
                      </label>
                      <input
                        type="text"
                        value={this.state.feinLeiEin}
                        disabled={this.state.feinLeiEinCheck ? false : true}
                        onChange={(e) => this.handleInputChange('feinLeiEin', e.target.value)}
                        onKeyDown={(e) => this.EnterEvent(e)}
                      />
                      {"Input or paste one FEIN/TIN/EIN/LEI number in XX-XXXX or XXXXXX format"}
                    </div>
                  </div>
                </div>
                {/* <div className='row col-sm-12 m-0 pt-2 d-flex' style={{ alignSelf: "center" }}> */}
                {/* <div className='col-sm-11' style={{ justifyContent: "center", width: "100%", alignItems: "center" }}> */}

                {/* <Button color="primary"
                    outline
                    className="mb-0"
                    style={this.state.setGoButton ? { color: "white", backgroundColor: '#354f6e' } : { color: "white", backgroundColor: '#354f6e' }}
                    // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                    disabled={this.state.company || this.state.address || this.state.feinLeiEin ? false : true}
                    onClick={() => this.submitSearch()}>
                    Go
                  </Button> */}
                {/* </div> */}
                {/* <div className='col-sm-1'> */}
                {/* {(this.state.company || this.state.address || this.state.feinLeiEin) &&
                    <Button color="primary" outline
                      className='px-2 ml-auto mb-0 mr-0'
                      style={{ height: 37 }}
                      // disabled={this.props.type ==="Manually Loaded Properties"}
                      size={'sm'} onClick={this.getDataToExport}>
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To CSV"}
                        className='pl-1' size='lg' />
                    </Button>} */}
                {/* {(this.state.company || this.state.address || this.state.feinLeiEin) &&
                    <CSVLink
                      data={this.state.exportData}
                      headers={this.state.exportHeaders}
                      filename={`OpenCorporateSearch.csv`}
                      className="hidden text-white p-0"
                      ref={(r) => this.csvLinkRef = r}
                      target="_blank" >
                    </CSVLink>} */}
                {/* </div> */}
                {/* </div> */}
              </form>
            //  {this.state.response && this.state.response.companies ?
            //   <div style={{ padding: '0px' }}>
            //     <div className='one'>
            //       <DataTable value={this.state.response.companies}
            //         scrollable
            //         scrollHeight="200px"
            //         responsiveLayout="scroll"
            //       >
            //         {this.state.dynamicColumns}

            //       </DataTable>
            //     </div>
            //     <div className='two'>
            //       <DataTable value={this.state.response.alternative_names}
            //         scrollable
            //         scrollHeight="200px"
            //         responsiveLayout="scroll"
            //       >
            //         {this.state.dynamicColumns}

            //       </DataTable>
            //     </div>
            //   </div >
            //   : ""} 
            //  </> 
          }
        </div >
        {this.state.isopenConfirmaionModelforCSV ? this.getConfirmaionModelforCSV() : null}
        {this.state.downloadSheet ? this.getExcelDownload() : null}
      </div >

    );
  }
}



OpenCorporateForm = reduxForm({
  form: "User Password Reset Form", // a unique identifier for this form
  enableReinitialize: true,
})(OpenCorporateForm);

// You have to connect() to any reducers that you wish to connect to yourself
OpenCorporateForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(OpenCorporateForm);

export default withTranslation('common')(OpenCorporateForm);