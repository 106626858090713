import React, { useEffect, useRef, useState } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row
} from 'reactstrap';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'reactstrap';
import fetchMethodRequest from "../../../config/service";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DatePicker from 'react-datepicker';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import dateFormats from '../../UI/FormatDate/formatDate';
import Loader from '../../App/Loader';
import Alerts from '../DocumentUpload/components/Alerts';
import * as MailFwdService from '../../../shared/mailForwardsService';


const MailForwardsDetails = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [mailForwardsDetails, setMailForwardsDetails] = useState<any>({});
    const [notesDetails, setNotesDetails] = useState<Array<any>>([]);
    const [selDate, setSelDate] = useState<Date>();
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>({});
    const [templateIDs, setTemplateIDs] = useState<any>([]);
    const mftStatusReasons = useRef<Array<any>>([]);


    useEffect( () => {        
        getMailFwdStatuses();
        let params: any = new URLSearchParams(window.location.search);        
        const trackDetails = params.get('mailFwdtrackDetails') ? JSON.parse(decodeURIComponent(params.get('mailFwdtrackDetails'))) : [];
        if (trackDetails.MailFwdTrackingID) {
            setIsLoading(true);
            setTimeout(() => {
                getMailFwdDetails(trackDetails.MailFwdTrackingID, trackDetails);
                getMailFwdNotes(trackDetails.MailFwdTrackingID);
            }, 100)

        } else {
            let mailDetails: any = trackDetails;            
            if (trackDetails.CompanyID) {
                getDealDetails(trackDetails.CompanyID)
                getTemplateDetails(trackDetails.CompanyID);
                
            }
            setMailForwardsDetails(mailDetails);

        }

        return () => {
            setMailForwardsDetails('');
        }
    }, []);

    const getTemplateDetails = async (dealID:string) => {
        setIsLoading(true);        
        const govTemplateID = await MailFwdService.getGoverningTemplateID(dealID);  
        if(govTemplateID){
            const govTemplateDetails = await MailFwdService.getGoverningTemplateDetails(govTemplateID);
            setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails,MailForwardsGoverningTemplate:govTemplateDetails, MailForwardsGoverningTemplateNumber: govTemplateDetails.GoverningTemplateVersion, MailForwardsGoverningTemplateText: govTemplateDetails.GoverningTemplateMessage,MailForwardsGoverningTemplateSummary:govTemplateDetails.GoverningTemplateSummary}));
        }       
        setIsLoading(false); 
    }    

    const getMailFwdNotes = async (trackID: string) => {
        const res = await fetchMethodRequest('POST', `mftHistory/getHistoryForMFT`, { id: parseInt(trackID) }, '', '', '');
        if (res && res.data && res.data.length > 0) {
            const formattedRes = res.data.map((e: any) => { return { ...e, DateInserted: dateFormats.formatDate(e.DateInserted, "MM/DD/YYYY HH:mm z", "timeZone") } })
            setNotesDetails(formattedRes);
        }
    }

    const getMailFwdDetails = async (trackID: string, details: any) => {
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/getDetailsWithDealFromID`, { mailFwdID: parseInt(trackID) }, '', '', '');
        const trackDetails: any = res && res.details && res.details[0] ? res.details[0] : details;
        let mailDetails: any = { ...trackDetails };
        mailDetails.MailFwdExpiredDays = trackDetails.MailFwdExpiredDays;
        if (trackDetails.MailForwardStatusID) {
            mailDetails.mailFwdStatus = mftStatusReasons.current.find((e) => e.mailFwdStatusID.toString() === trackDetails.MailForwardStatusID.toString());
            if (mailDetails.mailFwdStatus && trackDetails.MailForwardReasonID) {
                setMftReasons(mailDetails.mailFwdStatus.mailFwdReasons);
                mailDetails.mailFwdReason = mailDetails.mailFwdStatus.mailFwdReasons.find((e: any) => e.mailFwdReasonID.toString() === trackDetails.MailForwardReasonID.toString());
            }
        }
        
        let selGovTemplateDetails:any;
       
    
        if(trackDetails.Deal_GoverningTemplateID){           
            selGovTemplateDetails = await MailFwdService.getGoverningTemplateDetails(trackDetails.Deal_GoverningTemplateID);            
        }        
       
        if(selGovTemplateDetails){
        mailDetails.MailForwardsGoverningTemplate = selGovTemplateDetails;
        mailDetails.MailForwardsGoverningTemplateNumber = selGovTemplateDetails.GoverningTemplateVersion;
        mailDetails.MailForwardsGoverningTemplateText = selGovTemplateDetails.GoverningTemplateMessage;
        mailDetails.MailForwardsGoverningTemplateSummary = selGovTemplateDetails.GoverningTemplateSummary;
        } else {
            getTemplateDetails(mailDetails.CompanyID);
        }
       
        if(mailDetails.LastFwdRequestDate) mailDetails.LastFwdRequestDate = new Date(mailDetails.LastFwdRequestDate);
        if(mailDetails.InitialFwdRequestDate) mailDetails.InitialFwdRequestDate = new Date(mailDetails.InitialFwdRequestDate);
        if(mailDetails.NextFwdRequestDueDate) mailDetails.NextFwdRequestDueDate = new Date(mailDetails.NextFwdRequestDueDate);
        if(mailDetails.TestEnvelope1SentDate) mailDetails.TestEnvelope1SentDate = new Date(mailDetails.TestEnvelope1SentDate);
        if(mailDetails.TestEnvelope1RecvdDate) mailDetails.TestEnvelope1RecvdDate = new Date(mailDetails.TestEnvelope1RecvdDate);
        if(mailDetails.TestEnvelope2SentDate) mailDetails.TestEnvelope2SentDate = new Date(mailDetails.TestEnvelope2SentDate);
        if(mailDetails.TestEnvelope2RecvdDate) mailDetails.TestEnvelope2RecvdDate = new Date(mailDetails.TestEnvelope2RecvdDate);  
        
        if(mailDetails.MailForwardNote) delete mailDetails.MailForwardNote;     
        
            setMailForwardsDetails(mailDetails);
            
        if (res && res.trackingRecord) {
            setMailForwardsDetails(res.trackingRecord);
        }
        setIsLoading(false);
    }

    const getMailFwdStatuses = async () => {
        const res = await fetchMethodRequest('GET', `mftStatusAndReasons/getAllStatusAndReasons`, '', '', '', '');
        if (res && res.mftAllStatusAndReasons && res.mftAllStatusAndReasons.length > 0) {
            const filtersData = res.mftAllStatusAndReasons.filter((e: any) => e.screenTypeForStatus.includes(MailFwdService.MAIL_FORWARD_DETAILS));
            mftStatusReasons.current = filtersData;
            setMftStatuses(filtersData);
        }
    }

    const getDealDetails = async (dealID: string) => { 
        const res = await fetchMethodRequest('GET', `seller/${dealID}`, '', '', '', '');
        if (res && res.details) {
            setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, DealType: res.details.DealType, SellerNickName: res.details.SellerNickName, CompanyID: res.details.CompanyID }));
        }

    }


    const onClickSave = async () => {
        setIsLoading(true);
        let data: any = {
            CompanyName: mailForwardsDetails.dealName,            
            BusinessLocationID: mailForwardsDetails.BusinessLocationID,
            KeyAddressFlag: mailForwardsDetails.KeyAddressFlag,
            InitialFwdRequestDate: mailForwardsDetails.InitialFwdRequestDate ? mailForwardsDetails.InitialFwdRequestDate : null,
            LastFwdRequestDate: mailForwardsDetails.LastFwdRequestDate ? mailForwardsDetails.LastFwdRequestDate : null,
            NextFwdRequestDueDate: mailForwardsDetails.NextFwdRequestDueDate ? mailForwardsDetails.NextFwdRequestDueDate : null,
            USPSMailFwdConfmCode: mailForwardsDetails.USPSMailFwdConfmCode,            
            TestEnvelope1SentDate: mailForwardsDetails.TestEnvelope1SentDate ? mailForwardsDetails.TestEnvelope1SentDate : null,
            TestEnvelope1RecvdDate: mailForwardsDetails.TestEnvelope1RecvdDate ? mailForwardsDetails.TestEnvelope1RecvdDate : null,
            TestEnvelope2SentDate: mailForwardsDetails.TestEnvelope2SentDate ? mailForwardsDetails.TestEnvelope2SentDate : null,
            TestEnvelope2RecvdDate: mailForwardsDetails.TestEnvelope2RecvdDate ? mailForwardsDetails.TestEnvelope2RecvdDate : null,
            CompanyID: mailForwardsDetails.CompanyID,            
            MailForwardStatusID: mailForwardsDetails.mailFwdStatus && mailForwardsDetails.mailFwdStatus.mailFwdStatusID ? mailForwardsDetails.mailFwdStatus.mailFwdStatusID : '',
            MailForwardReasonID: mailForwardsDetails.mailFwdReason && mailForwardsDetails.mailFwdReason.mailFwdReasonID ? mailForwardsDetails.mailFwdReason.mailFwdReasonID : '',
            RowIsCurrent: 1,
            MailForwardNote: mailForwardsDetails.MailForwardNote,
            BusinessLocation_GoverningTemplateID:mailForwardsDetails.MailForwardsGoverningTemplate ? mailForwardsDetails.MailForwardsGoverningTemplate.GoverningTemplateID : null
        };
        (Object.keys(data)).forEach((field) => {
            if (field !== 'KeyAddressFlag' && !data[field]) {
                delete data[field];
            }
        });        
        const loginCredentials: any = JSON.parse(localStorage.getItem('loginCredentials') || '');
        let body: any = {
            data: data,
            userid: loginCredentials?.azureAdUserId
        }
        let api = `mailForwardsDetails/createMFT`;
        if (mailForwardsDetails.MailFwdTrackingID) {
            api = `mailForwardsDetails/updateMFT`;
            body.mailFwdID = mailForwardsDetails.MailFwdTrackingID;
        }
        const res = await fetchMethodRequest('POST', api, JSON.parse(JSON.stringify(body)), '', '', '');
        if (res.success) {
            getMailFwdDetails(res.mailFwdID ? res.mailFwdID : mailForwardsDetails.MailFwdTrackingID, mailForwardsDetails);
            getMailFwdNotes(res.mailFwdID ? res.mailFwdID : mailForwardsDetails.MailFwdTrackingID);
        }
        onChangeInputValues('MailForwardNote', '');
        setIsLoading(false);
        if (res && res.message) {
            setRespMessage({ type: res.success ? 'success' : 'danger', message:res.message });
            setTimeout(() => {
                setRespMessage({type:'',message:''});
            }, 1000 * 5)
        }
    }

    const onChangeInputValues = (field: string, value: any) => {
        setMailForwardsDetails((prevDetails: any) => ({ ...prevDetails, [field]: value }));
        if (field === 'mailFwdStatus' && value && value.mailFwdReasons) {
            setMftReasons(value.mailFwdReasons.filter((e: any) => e.screenTypeForReason.includes(MailFwdService.MAIL_FORWARD_DETAILS)));
            if (mailForwardsDetails.mailFwdReason) {
                onChangeInputValues('mailFwdReason', '');
            }
        } else if (field === 'MailForwardsGoverningTemplateNumber') {
            onChangeInputValues('MailForwardsGoverningTemplateText', value.GoverningTemplateMessage);
            onChangeInputValues('MailForwardsGoverningTemplateSummary', value.GoverningTemplateSummary);

        }
    }



    return <>
        <div className="card-body mail-fwd-details p-2 mt-1">
            <Nav tabs >
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 1, h6: true }}
                        onClick={() => { setActiveTab(1) }}
                    >
                        Main
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 2, h6: true }}
                        onClick={() => { setActiveTab(2) }}
                    >
                        Research History
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink
                        className={{ active: activeTab === 3, h6: true }}
                        onClick={() => { setActiveTab(3) }}
                    >
                        Seller/Trustee Approvals History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 4, h6: true }}
                        onClick={() => { setActiveTab(4) }}
                    >
                        Received Mail History
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={{ active: activeTab === 5, h6: true }}
                        onClick={() => { setActiveTab(5) }}
                    >
                        USPS Forwarding History
                    </NavLink>
                </NavItem>
                <NavItem className="d-flex flex-grow-1 justify-content-center align-items-center pr-1">
                {respMessage.message && <span className={respMessage.type === 'success' ? 'text-success' : respMessage.type === 'danger' ? "text-danger" : ''}>{respMessage.message}</span>}
                </NavItem>
                <NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                    <div>
                        <Button color="primary" type='submit' className="mb-1" onClick={() => { onClickSave() }}>
                            Save
                        </Button>
                    </div>

                </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1} >

                    <div className="p-4 mb-2 w-100 border">
                        <div className="row mail-fwd-details-fields">
                            <div className="col-sm-4 d-flex flex-column">
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd ID</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.MailFwdTrackingID} placeholder={mailForwardsDetails.MailFwdTrackingID ? '' : 'System will auto assign'} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Deal Type</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.DealType} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Deal Name</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.SellerNickName} />
                                    </div>
                                </div>

                                <div className="row mb-2 mt-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Name to Forward</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.LocationName} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Key Address</label>
                                    <div className="col-sm-8 d-flex">
                                        <div className="mr-2">
                                            <RadioButton inputId="rb1" name="city" value={true} onChange={(e) => onChangeInputValues('KeyAddressFlag', true)} checked={mailForwardsDetails.KeyAddressFlag === true} />
                                            <label htmlFor="rb1" className="p-radiobutton-label">Yes</label>
                                        </div>
                                        <div  >
                                            <RadioButton inputId="rb2" name="city" value={false} onChange={(e) => onChangeInputValues('KeyAddressFlag', false)} checked={mailForwardsDetails.KeyAddressFlag === false} />
                                            <label htmlFor="rb2" className="p-radiobutton-label">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Address Line1</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.Line1} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">City</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.City} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">State</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.StateProvince} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Zip</label>
                                    <div className="col-sm-8">
                                        <InputText className="w-100" disabled value={mailForwardsDetails.Zip} />
                                    </div>
                                </div>

                                <div className="row mb-2 mt-4">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Status</label>
                                    <div className="col-sm-8">
                                        <Dropdown value={mailForwardsDetails.mailFwdStatus} options={mftStatuses} optionLabel="mailFwdStatus" onChange={(e) => { onChangeInputValues('mailFwdStatus', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Reason</label>
                                    <div className="col-sm-8">
                                        <Dropdown value={mailForwardsDetails.mailFwdReason} disabled={!mailForwardsDetails.mailFwdStatus} options={mftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeInputValues('mailFwdReason', e.value) }}
                                            style={{ minWidth: '100%' }} filter={true} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-8 d-flex flex-column">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Initial Fwd Req Date</label>
                                            <div className="col-sm-8">
                                                <div className="date-picker">
                                                    <DatePicker selected={mailForwardsDetails.InitialFwdRequestDate} onChange={(date: Date) => onChangeInputValues('InitialFwdRequestDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <label className="col-sm-4 mb-0 pr-0  label-mailF-details d-flex justify-content-end align-items-center">USPS Mail Fwd Confrm. Code</label>
                                            <div className="col-sm-8">
                                                <InputText className="w-100" value={mailForwardsDetails.USPSMailFwdConfmCode} disabled onChange={(e) => onChangeInputValues('USPSMailFwdConfmCode', (e.target as HTMLInputElement).value)} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <label className="col-sm-4 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Last Fwd Req Date</label>
                                            <div className="col-sm-8">
                                                <div className="date-picker ">
                                                    <DatePicker selected={mailForwardsDetails.LastFwdRequestDate} onChange={(date: Date) => onChangeInputValues('LastFwdRequestDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row ">
                                            <label className="col-sm-4 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">Mail Fwd Expired Days</label>
                                            <div className="col-sm-8">
                                                <InputText className="w-100" disabled value={mailForwardsDetails.MailFwdExpiredDays} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-2 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Next Fwd Req Date</label>
                                    <div className="col-sm-4">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.NextFwdRequestDueDate} onChange={(date: Date) => onChangeInputValues('NextFwdRequestDueDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>


                                <div className="row mb-2">
                                    <label className="col-sm-2 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env1 Sent Date</label>
                                    <div className="col-sm-4">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope1SentDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope1SentDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label className="col-sm-2 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env1 Received Date</label>
                                    <div className="col-sm-4">
                                        <div className="date-picker ">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope1RecvdDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope1RecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-2 mb-0 pr-0 label-mailF-colour label-mailF-details d-flex justify-content-end align-items-center">Test Env2 Sent Date</label>
                                    <div className="col-sm-4">
                                        <div className="date-picker">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope2SentDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope2SentDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-sm-2 mb-0 pr-0 label-mailF-colour  label-mailF-details d-flex justify-content-end align-items-center">Test Env2 Received Date</label>
                                    <div className="col-sm-4">
                                        <div className="date-picker mb-2">
                                            <DatePicker selected={mailForwardsDetails.TestEnvelope2RecvdDate} onChange={(date: Date) => onChangeInputValues('TestEnvelope2RecvdDate', date)} className={`p-inputtext w-100`} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2 mt-4">
                                    <label className={`col-sm-2 mb-0 pr-0  label-mailF-details d-flex justify-content-end align-items-center`}>Governing Template Version</label>
                                    <div className="col-sm-4">
                                        <InputText className="w-100" disabled onChange={(e) => onChangeInputValues('MailForwardsGoverningTemplateNumber', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.MailForwardsGoverningTemplateNumber} />

                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-2 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">Governing Template Summary</label>
                                    <div className="col-sm-4">
                                        <InputTextarea className="resize-text-area" disabled rows={2} value={mailForwardsDetails.MailForwardsGoverningTemplateSummary } />
                                    </div>
                                </div>
                                <div className="row mb-2 ">
                                    <label className="col-sm-2 mb-0 pr-0  label-mailF-details d-flex justify-content-end ">Governing Template Text</label>
                                    <div className="col-sm-4">
                                        <InputTextarea className="resize-text-area" disabled rows={5} value={mailForwardsDetails.MailForwardsGoverningTemplateText} />
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <h4>Note</h4>
                    <div className="p-1 w-100 border mb-1">

                        <div className="p-1 bg-white">
                            <div>
                                <label className="mb-0">Add Note</label>
                                <InputTextarea className="w-100" rows={3} onChange={(e) => onChangeInputValues('MailForwardNote', (e.target as HTMLInputElement).value)} value={mailForwardsDetails.MailForwardNote} />
                            </div>
                            </div>
                           


                    </div>
                   
                    <h4>Notes & Status History</h4>
                    <div className="p-1 border bg-white">
                            
                            <div className="mt-1">
                                <DataTable value={notesDetails}>
                                    <Column field="DateInserted" header="Date" />
                                    <Column field="MailForwardStatus" header="Mail Forward Status" />
                                    <Column field="MailForwardReason" header="Mail Forward Reason" />
                                    <Column field="LastModifiedBy" header="User" />
                                    <Column field="MailForwardNote" header="Note" style={{ width: '40%', textAlign: 'left' }} />
                                </DataTable>


                            </div>
                        </div>

                </TabPane>


            </TabContent>
        </div>
        <Loader loader={isLoading} />
    </>
}

export default MailForwardsDetails;