import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete'
import { RadioButton } from 'primereact/radiobutton';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../App/Loader';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service'
import dateFormats from '../UI/FormatDate/formatDate';
// validate
// import validate from './validate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import { Accordion, AccordionTab } from "primereact/accordion";
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class PropertySearchV2Filters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      click: true,
      resultSet: 0,
      SQLCount: 0,
      MongoCount: 0,
      setSearchType: "Exact",
      confOnSiteDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      DataSource: ["State UP Site", "State File", "Missing Money Site"],
      searchableProperties: true,
      confOnSite: false,
      dataArray: [
        {
          "label": "State File",
          "value": "State File",
        },
        {
          "label": "Royalties",
          "value": "Royalties",
        },
        {
          "label": "Missing Money Site",
          "value": "Missing Money Site",
        },
        {
          "label": "Co-op",
          "value": "Co-op",
        },
        {
          "label": "Australia",
          "value": "Australia",
        },
        {
          "label": "IPv4",
          "value": "IPv4",
        },
        {
          "label": "Miscellaneous",
          "value": "Miscellaneous",
        },
        {
          "label": "Bankruptcy Courts",
          "value": "Bankruptcy Courts",
        },
        {
          "label": "Bankruptcy Courts - Northern",
          "value": "Bankruptcy Courts - Northern",
        },
        {
          "label": "County",
          "value": "County",
        },
        {
          "label": "State Warrant",
          "value": "State Warrant",
        },
        {
          "label": "Bankruptcy Courts - Eastern",
          "value": "Bankruptcy Courts - Eastern",
        },
        {
          "label": "Bankruptcy Courts - Southern",
          "value": "Bankruptcy Courts - Southern",
        },
        {
          "label": "Bankruptcy Courts - Western",
          "value": "Bankruptcy Courts - Western",
        },
        {
          "label": "State UP Site",
          "value": "State UP Site",
        },
        {
          "label": "Bankruptcy Courts - Central",
          "value": "Bankruptcy Courts - Central",
        },
        {
          "label": "Bankruptcy Courts - Middle",
          "value": "Bankruptcy Courts - Middle",
        },
        {
          "label": "Restitution",
          "value": "Restitution",
        },
        {
          "label": "Singapore",
          "value": "Singapore",
        },
        {
          "label": "SEC (U.S. Securities and Exchange Commission)",
          "value": "SEC (U.S. Securities and Exchange Commission)",
        },
        {
          "label": "New Jersey Superior Court",
          "value": "New Jersey Superior Court",
        },
        {
          "label": "City Site",
          "value": "City Site",
        },
        {
          "label": "State UP Site - CA-N",
          "value": "State UP Site - CA-N",
        },
        {
          "label": "From State",
          "value": "From State",
        },
        {
          "label": "County Site",
          "value": "County Site",
        },
        {
          "label": "Rough Claims - From Data",
          "value": "Rough Claims - From Data",
        },
        {
          "label": "Co-Op Site",
          "value": "Co-Op Site",
        },
        {
          "label": "State UP Site - NY-ROCDOC",
          "value": "State UP Site - NY-ROCDOC",
        },
        {
          "label": "Excess Proceeds",
          "value": "Excess Proceeds",
        },
        {
          "label": "Long Lost Money",
          "value": "Long Lost Money",
        },
        {
          "label": "Outstanding Checks",
          "value": "Outstanding Checks",
        },
        {
          "label": "Test 40",
          "value": "Test 40",
        },
        {
          "label": "Test 1",
          "value": "Test 1",
        },
        {
          "label": "OPAM TESTING",
          "value": "OPAM TESTING",
        },
        {
          "label": "Test 2",
          "value": "Test 2",
        },
        {
          "label": "Test 5",
          "value": "Test 5",
        },
        {
          "label": "Bankruptcy Court Site",
          "value": "Bankruptcy Court Site",
        },
        {
          "label": "Test 18",
          "value": "Test 18",
        },
        {
          "label": "Test 23",
          "value": "Test 23",
        },
        {
          "label": "Test 29",
          "value": "Test 29",
        },
        {
          "label": "Test 30",
          "value": "Test 30",
        },
        {
          "label": "Test 8",
          "value": "Test 8",
        }
      ]
    };
  }

  getStates = async () => {

    await fetch('GET', `seller/StateList?type=test`)
      .then(async (response) => {
        if (response) {
          if (response.respCode) {
            // showToasterMessage(response.respMessage, 'success');
            let states = []
            for (let state of response.states) {
              states.push({ label: state.Abbreviation, value: state.Abbreviation })
            }
            await this.setState({
              States: states
            })
          } else if (response.errorMessage) {
            // display message
            // showToasterMessage(response.errorMessage, 'error');
          }
        }
      })
  }
  /**
 * 
 * @param {Object} e 
 * @param {String} key 
 * @param {String} selectedtype 
 * setting the 
 */
  async onChange(e, key, selectedtype) {
    var val
    if (e && e.persist) {
      e.persist();
    }
    if (e && e.target) {
      val = e.target.value
      if (key) {
        await this.setState({
          [key]: val,
        })
      }
      let filtercriteria = {
        "key": key,
        "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
        "type": selectedtype,
        "add_Filter": true
      }
      // filter: { "limit": 100, "page": 1, "criteria": [{ "Criteria": "propertyOwnerName", "Type": "fuzzy", "Value": "ex", "index": 0 }, { "Criteria": "holderName", "Type": "fuzzy", "Value": "ex", "index": 1 }], "sortfield": "sequence", "direction": "desc" }
      // site: Opra2

    }
  }

  async handleDataSource(e) {

    await this.setState({
      DataSource: e.value ? e.value : e
    })

  }
  getLabel = (label, field) => {
    return <label className='d-flex justify-content-between mb-0'>
      <span>{label}</span>
      {/* {this.getCloseIcon(field)} */}
    </label>
  }

  onFilterChanges = async (key, value) => {
    if (key === "statePropertyId" || key === "propertyId") {
      // value = value.replace(/[a-zA-Z]/g, '')
      value = value.replace(/[^0-9\s]/g, '')
      await this.setState({ [key]: value })
    }
  
  }
  setResultSet = async (count) => {
    await this.setState({ resultSet: count })
  }
  setSQLCount = async (count) => {
    await this.setState({ SQLCount: count })
  }
  setMongoCount = async (count) => {
    await this.setState({ MongoCount: count })
  }
  setSearchType = async (type) => {
    await this.setState({ setSearchType: type })
  }
  getDataFromServer = async () => {
    let sort = "", fields = ["propertyOwnerName", "propertyAddressLine1", "holderName", "sourceState"]
    for (let field of fields) {
      if (this.state[field]) {
        sort = sort + field + ", "
      }
    }
    await this.setState({ showMessage: true })
    let filterCriteria = { "limit": 1000, "page": 1, criteria: [], "sortfield": sort, "direction": "desc" }
    if (this.state.propertyOwnerName) {
      filterCriteria["criteria"].push({ "Criteria": "propertyOwnerName", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyOwnerName, "index": 0 })
    }
    if (this.state.propertyDescription) {
      filterCriteria["criteria"].push({ "Criteria": "propertyDescription", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyDescription, "index": 0 })
    }
    if (this.state.holderName) {
      filterCriteria["criteria"].push({ "Criteria": "holderName", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.holderName, "index": 1 })
    }
    if (this.state.sourceState && this.state.sourceState.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "sourceState", "Type": "Includes", "Value": this.state.sourceState, "index": 2 })
    }
    if (this.state.propertyStateAbbreviation && this.state.propertyStateAbbreviation.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "propertyStateAbbreviation", "Type": "Includes", "Value": this.state.propertyStateAbbreviation, "index": 2 })
    }
    if (this.state.propertyAddressLine1) {
      filterCriteria["criteria"].push({ "Criteria": "propertyAddressLine1", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyAddressLine1, "index": 3 })
    }
    if (this.state.propertyCity) {
      filterCriteria["criteria"].push({ "Criteria": "propertyCity", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyCity, "index": 4 })
    }
    if (this.state.propertyZip) {
      filterCriteria["criteria"].push({ "Criteria": "propertyZip", "Type": this.state.setSearchType === "Exact" ? "word" : "fuzzy", "Value": this.state.propertyZip, "index": 4 })
    }
    if (this.state.MinPropValue) {
      filterCriteria["criteria"].push({ "Criteria": "propertyValueMin", "Type": "gte", "Value": this.state.MinPropValue, "index": 5 })
    }
    if (this.state.MaxPropValue) {
      filterCriteria["criteria"].push({ "Criteria": "propertyValueMax", "Type": "gte", "Value": this.state.MaxPropValue, "index": 5 })
    }
    if (this.state.DataSource.length > 0) {
      filterCriteria["criteria"].push({ "Criteria": "propertyDataSource", "Type": "Includes", "Value": this.state.DataSource, "index": 5 })
    }
    if (this.state.propertyId != null && this.state.propertyId.length) {
      filterCriteria["criteria"].push({ "Criteria": "propertyId", "Type": "NumIncludes", "Value": this.state.propertyId.split((/\r?\n/)).map(obj => parseInt(obj.trim())).filter(u => u != "" && u > 0), "index": 5 })
    }
    if (this.state.statePropertyId != null && this.state.statePropertyId.length) {
      filterCriteria["criteria"].push({ "Criteria": "statePropertyId", "Type": "NumIncludes", "Value": this.state.statePropertyId.split((/\r?\n/)).map(obj => parseInt(obj.trim())).filter(u => u != "" && u > 0), "index": 5 })
    }
    var escheatmentDateCriteria = { "Criteria": "escheatmentDate", "Type": "MinMaxDate", "index": 5 };
    var dateOfLastContactCriteria = { "Criteria": "dateOfLastContact", "Type": "MinMaxDate", "index": 5 };
    var dataFlag = false;

    // "Value": this.state.DataSource,
    // "minDateValue": 
    // "maxDateValue":
    if (this.state.escheatmentDateFrom) {
      escheatmentDateCriteria.Value = escheatmentDateCriteria.minDateValue = this.convertDate(this.state.escheatmentDateFrom);
      dataFlag = true;
    }

    if (this.state.escheatmentDateTo) {
      escheatmentDateCriteria.Value = escheatmentDateCriteria.maxDateValue = this.convertDate(this.state.escheatmentDateTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...escheatmentDateCriteria });
      dataFlag = false;
    }

    if (this.state.dateOfLastContactFrom) {
      dateOfLastContactCriteria.Value = dateOfLastContactCriteria.minDateValue = this.convertDate(this.state.dateOfLastContactFrom);
      dataFlag = true;
    }

    if (this.state.dateOfLastContactTo) {
      dateOfLastContactCriteria.Value = dateOfLastContactCriteria.maxDateValue = this.convertDate(this.state.dateOfLastContactTo);
      dataFlag = true;
    }
    if (dataFlag) {
      filterCriteria["criteria"].push({ ...dateOfLastContactCriteria });
      dataFlag = false;
    }

    if (this.state.searchableProperties) {
      filterCriteria["criteria"].push({ "Criteria": "propertyStatus", "Type": "word", "Value": "Searchable", "index": 0 })
    } else {
      filterCriteria["criteria"].push({ "Criteria": "propertyStatus", "Type": "ne", "Value": "Searchable", "index": 0 })
    }

    let ExtraFilters = {}

    //condition for confirm onsite flag confOnSite
    if (this.state.confOnSite != null && this.state.confOnSite) ExtraFilters.confOnSite = this.state.confOnSite;

    if (this.state.mappedRangeFrom) ExtraFilters.mappedRangeFrom = this.convertDate(this.state.mappedRangeFrom)

    if (this.state.mappedRangeTo) ExtraFilters.mappedRangeTo = this.convertDate(this.state.mappedRangeTo)

    if (this.state.confOnSiteDateFrom) ExtraFilters.confOnSiteDateFrom = this.convertDate(this.state.confOnSiteDateFrom)

    if (this.state.confOnSiteDateTo) ExtraFilters.confOnSiteDateTo = this.convertDate(this.state.confOnSiteDateTo)

    if (this.state.Deal) ExtraFilters.Deal = this.state.Deal.EstateID

    if (this.state.positivelyReviewedProperties) ExtraFilters.positivelyReviewedProperties = this.state.positivelyReviewedProperties

    this.props.setDataCriteriaforOpportunity(filterCriteria, ExtraFilters)

  }
  convertDate = (date) => {
    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();

    // Set the time to midnight in UTC
    const utcDate = new Date(Date.UTC(utcYear, utcMonth, utcDay + 1, 0, 0, 0));
    return utcDate;
  }

  clearData = async () => {
    await this.setState({
      holderName: "",
      propertyAddressLine1: "",
      propertyOwnerName: "",
      propertyDescription: "",
      resultSet: 0,
      statePropertyId: null,
      propertyId: null,
      SQLCount: 0,
      MongoCount: 0,
      showMessage: false,
      sourceState: [],
      propertyStateAbbreviation: [],
      setSearchType: "Exact",
      propertyCity: "",
      propertyZip: "",
      mappedRangeFrom: "",
      escheatmentDateFrom: "",
      dateOfLastContactFrom: "",
      dateOfLastContactTo: "",
      escheatmentDateTo: "",
      mappedRangeTo: "",
      confOnSiteDateFrom: "",
      confOnSiteDateTo: "",
      MinPropValue: 0,
      MaxPropValue: 0,
      DataSource: "",
      Deal: "",
      positivelyReviewedProperties: null,
      searchableProperties: null,
      confOnSite: null
    })
    let filterCriteria = { "limit": 200, "page": 1, criteria: [], "sortfield": "sequence", "direction": "desc" }
    this.props.closeDataTable()
    // this.props.setDataCriteriaforOpportunity(filterCriteria, {})
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials: loginCredentials })
    this.getStates()
  }


  onChangePositivelyReviewedProperties = async (e) => {
    await this.setState(prevState => (
      {
        positivelyReviewedProperties: !prevState.positivelyReviewedProperties,
      }));
  }

  onChangeSearchableProperties = async (e) => {
    await this.setState(prevState => (
      {
        searchableProperties: !prevState.searchableProperties,
      }));
  }
  onChangeConfOnSiteProperties = async (e) => {
    await this.setState(prevState => (
      {
        confOnSite: !prevState.confOnSite,
      }));
  }

  onDateChange = async (e, key, type) => {
    if (type) {
      await this.setState({
        [key]: "",
      })
    } else {
      await this.setState({
        [key]: e.value,
      })
    }
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl

    if (event) {

      if (event.query) {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
      } else {
        apiUrl = `${apiCalls.propertysellers}`
      }

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }
          await this.setState({
            filteredSuggestions: dropdownData
          });

        }
      }).catch((err) => {
        return err;
      });
  }

  handleChange = async (e) => {
    this.setState({
      Deal: e.value
    })
  }

  render() {
    return (
      <Card className="p-0" >
        {/* <CardHeader >
          Missing Money Results File Upload
        </CardHeader> */}
        <Loader loader={this.state.isLoading} />
        <CardBody className="p-0">
          <div className='row'>

          <Accordion multiple={true} activeIndex={[0]}>
          <AccordionTab header={"Filters"}>
            {/* New GRID */}
            <div className='pv_v2_header  col-lg-12' style={{ display: "flex", alignItems: "center" }}>

              {/* Section-1 */}
              <div className='col-lg-5' style={{ alignItems: "center" }}>

                <div className='col-lg-12 pl-0 pt-2 mb-3' style={{ display: "flex", alignItems: "center", fontSize: "large" }}>
                  <div className='row mr-5 '>
                    <p><b>{"Search Mode :"}</b></p>
                    <div className="ps_v2_radio_button field-radiobutton mr-2">
                      <RadioButton inputId="Exact" style={{ color: "green" }} className="ml-2 mr-2" name="Exact" value="Exact" onChange={() => this.setSearchType("Exact")}
                        checked={this.state.setSearchType === 'Exact'}
                      />
                      <label htmlFor="city1" className='mb-0'>Exact Match Only </label>
                    </div>
                    <div className="ps_v2_radio_button field-radiobutton mr-2">
                      <RadioButton inputId="Relative" className="ml-2 mr-2" name="Relative" value="Relative" onChange={() => this.setSearchType("Relative")}
                        checked={this.state.setSearchType === 'Relative'}
                      />
                      <label htmlFor="city2" className='mb-0'>Relative Match Results </label>
                    </div>
                  </div>

                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4 mt-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Deal Name</label>
                  </div>
                  <div className='col-lg-8' style={{ color: "#f08e5d" }}>
                    Either Reported Owner Name or Property Address Line 1 must be provided
                    <AutoComplete
                      value={this.state.Deal}
                      id="Deal"
                      suggestions={this.state.filteredSuggestions}
                      completeMethod={this.searchsellerItems}
                      minLength={1}
                      field={"DealName"}
                      style={{ height: 33, width: "100%" }}
                      dropdown={true}
                      // itemTemplate={this.editorTemplateForDeal}
                      onChange={(e) => this.onChange(e, 'Deal', 'regexOr')}
                    // onChange={(e) => this.handleChange(e, 'Seller')}
                    // appendTo={document.body}
                    />

                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0, color: "#f08e5d" }}>Reported Owner Name</label>
                  </div>
                  <div className='col-lg-8' >

                    <InputText
                      value={this.state.propertyOwnerName}
                      name='propertyOwnerName'
                      id="propertyOwnerName"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'propertyOwnerName', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0, color: "#f08e5d" }}>Property Address Line1</label>
                  </div>
                  <div className='col-lg-8'>
                    <InputText
                      value={this.state.propertyAddressLine1}
                      name='propertyAddressLine1'
                      id="propertyAddressLine1"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'propertyAddressLine1', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Property City</label>
                  </div>
                  <div className='col-lg-8'>
                    <InputText
                      value={this.state.propertyCity}
                      name='propertyCity'
                      id="propertyCity"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'propertyCity', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'propertyCity', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Property State</label>
                  </div>
                  <div className='col-lg-8'>
                    <MultiSelect
                      name={"propertyStateAbbreviation"}
                      id="propertyStateAbbreviation"
                      filter={true}
                      value={this.state.propertyStateAbbreviation}
                      style={{ height: 33, width: "100%" }}
                      appendTo={document.body}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      options={this.state.States}
                      onChange={(e) => this.onChange(e, 'propertyStateAbbreviation', 'regexOr')}

                    />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Property Zip Code</label>
                  </div>
                  <div className='col-lg-8' >

                    <InputText
                      value={this.state.propertyZip}
                      name='propertyZip'
                      id="propertyZip"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'propertyZip', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'propertyZip', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Source State</label>
                  </div>
                  <div className='col-lg-8'>
                    <MultiSelect
                      name={"sourceState"}
                      id="sourceState"
                      filter={true}
                      value={this.state.sourceState}
                      style={{ height: 33, width: "100%" }}
                      appendTo={document.body}
                      // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                      maxSelectedLabels={1}
                      options={this.state.States}
                      onChange={(e) => this.onChange(e, 'sourceState', 'regexOr')}

                    />
                  </div>
                </div>

                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Property Description</label>
                  </div>
                  <div className='col-lg-8' >

                    <InputText
                      value={this.state.propertyDescription}
                      name='propertyDescription'
                      id="propertyDescription"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'propertyDescription', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'propertyDescription', 'regexOr')}
                      size={"20"} />
                  </div>

                </div>
                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Holder Name</label>
                  </div>
                  <div className='col-lg-8'>
                    <InputText
                      value={this.state.holderName}
                      name='holderName'
                      id="holderName"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'holderName', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'holderName', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>


              </div>

              {/* Section-2 */}
              <div className='col-lg-3' >
                <div className='row col-lg-12 mb-2 mt-5' >
                  {this.getLabel('OPRA Property ID(s)', 'propertyId')}
                  <textarea
                    name='OpraStId'
                    id='OpraStId'
                    value={this.state.propertyId}
                    style={{ width: "100%", height: "170px" }}

                    onChange={(e) => this.onFilterChanges('propertyId', e.target.value)}
                    placeholder={'OPRA Property ID(s)'}
                    rows="8"
                  />
                </div>

                <div className='row col-lg-12 mb-2' >
                  {this.getLabel('State Property ID(s)', 'statePropertyId')}
                  <textarea
                    name='stClaimId'
                    id="stClaimId"
                    onChange={(e) =>
                      this.onFilterChanges('statePropertyId', e.target.value)
                    }
                    style={{ width: "100%", height: "170px" }}
                    value={this.state.statePropertyId}
                    placeholder={'State Property ID(s)'}
                    rows="8"
                  />

                </div>

              </div>

              {/* Section-3 */}
              <div className='col-lg-4' style={{ alignItems: "center" }}>

                {/* Show Only Positively Mapped Properties */}
                <div className='row col-lg-12' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-9' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Show Only Positively Mapped Properties</label>
                  </div>
                  <div className='col-lg-3' style={{ textAlign: "right" }}>
                    <InputSwitch checked={this.state.positivelyReviewedProperties}
                      className='ml-2' onChange={(e) => this.onChangePositivelyReviewedProperties()} />
                  </div>
                </div>
                {/* Show Only Searchable Properties */}
                <div className='row col-lg-12 mt-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-9' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Show Only Searchable Properties</label>
                  </div>
                  <div className='col-lg-3' style={{ textAlign: "right" }}>
                    <InputSwitch checked={this.state.searchableProperties}
                      className='ml-2' onChange={(e) => this.onChangeSearchableProperties()} />
                  </div>
                </div>

                <div className='row col-lg-12 mt-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-9' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Show Only Conf. On Site Properties</label>
                  </div>
                  <div className='col-lg-3' style={{ textAlign: "right" }}>
                    <InputSwitch checked={this.state.confOnSite}
                      className='ml-2' onChange={(e) => this.onChangeConfOnSiteProperties()} />
                  </div>

                </div>

                <div className='row col-lg-12 mb-1' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Data Source</label>
                  </div>
                  <div className='col-lg-8'>
                    <MultiSelect
                      value={this.state.DataSource}
                      name={"DataSource"}
                      filter={true}
                      style={{ width: "100%", height: 32 }}
                      appendTo={document.body}
                      maxSelectedLabels={1}
                      options={this.state.dataArray ? this.state.dataArray : []}
                      onChange={(e) => this.handleDataSource(e)}
                    />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>{"Min Property Value >="}</label>
                  </div>
                  <div className='col-lg-8'>
                    <InputText
                      value={this.state.MinPropValue}
                      name='MinPropValue'
                      id="MinPropValue"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'MinPropValue', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'MinPropValue', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-2' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4 pl-1' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>{"Max Property Value >="}</label>
                  </div>
                  <div className='col-lg-8'>
                    <InputText
                      value={this.state.MaxPropValue}
                      name='MaxPropValue'
                      id="MaxPropValue"
                      style={{ height: 33, width: "100%" }}
                      onKeyPress={(e) => this.onChange(e, 'MaxPropValue', 'regexOr')}
                      onChange={(e) => this.onChange(e, 'MaxPropValue', 'regexOr')}
                      size={"20"} />
                  </div>
                </div>

                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Date Escheated</label>
                  </div>
                  <div className='row col-lg-8 pr-0' style={{ display: "flex", alignItems: "center" }}>
                    <div className='col-lg-6 pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        value={this.state.escheatmentDateFrom}
                        onChange={(e) => this.onDateChange(e, "escheatmentDateFrom", true)}
                        onSelect={(e) => this.onDateChange(e, "escheatmentDateFrom")}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        name={"escheatmentDateFrom"}
                        placeholder='From - mm/dd/yyyy'
                      />

                    </div>

                    <div className='col-lg-6 pr-0 text-right'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        minDate={this.state.escheatmentDateFrom ? this.state.escheatmentDateFrom : ""}
                        name={"escheatmentDateTo"}
                        placeholder='To - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.escheatmentDateTo}
                        onChange={(e) => this.onDateChange(e, "escheatmentDateTo", true)}
                        onSelect={(e) => this.onDateChange(e, "escheatmentDateTo")}
                      />

                    </div>
                  </div>
                </div>

                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Date of Last Contact</label>
                  </div>
                  <div className='row col-lg-8 pr-0' style={{ display: "flex", alignItems: "center" }}>
                    <div className='col-lg-6 pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        //  minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                        name={"dateOfLastContactFrom"}
                        placeholder='From - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.dateOfLastContactFrom}
                        onChange={(e) => this.onDateChange(e, 'dateOfLastContactFrom', true)}
                        onSelect={(e) => this.onDateChange(e, 'dateOfLastContactFrom')}
                      />
                    </div>

                    <div className='col-lg-6 pr-0 text-right'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        minDate={this.state.dateOfLastContactFrom ? this.state.dateOfLastContactFrom : ""}
                        name={"dateOfLastContactTo"}
                        placeholder='To - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.dateOfLastContactTo}
                        onChange={(e) => this.onDateChange(e, 'dateOfLastContactTo', true)}
                        onSelect={(e) => this.onDateChange(e, 'dateOfLastContactTo')}
                      />
                    </div>

                  </div>
                </div>

                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Conf. On Site</label>
                  </div>
                  <div className='row col-lg-8 pr-0' style={{ display: "flex", alignItems: "center" }}>
                    <div className='col-lg-6 pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        value={this.state.confOnSiteDateFrom}
                        onChange={(e) => this.onDateChange(e, "confOnSiteDateFrom", true)}
                        onSelect={(e) => this.onDateChange(e, "confOnSiteDateFrom")}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        name={"confOnSiteDateFrom"}
                        placeholder='From - mm/dd/yyyy'
                      />

                    </div>

                    <div className='col-lg-6 text-right pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        minDate={this.state.confOnSiteDateFrom ? this.state.confOnSiteDateFrom : ""}
                        name={"confOnSiteDateTo"}
                        placeholder='To - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.confOnSiteDateTo}
                        onChange={(e) => this.onDateChange(e, "confOnSiteDateTo", true)}
                        onSelect={(e) => this.onDateChange(e, "confOnSiteDateTo")}
                      />

                    </div>
                  </div>

                </div>

                <div className='row col-lg-12 mb-3' style={{ display: "flex", alignItems: "center" }}>

                  <div className='col-lg-4' style={{ textAlign: "right" }}>
                    <label style={{ marginBottom: 0 }}>Property Mapped</label>
                  </div>
                  <div className='row col-lg-8 pr-0' style={{ display: "flex", alignItems: "center" }}>

                    <div className='col-lg-6 pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                        name={"mappedRangeFrom"}
                        placeholder='From - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.mappedRangeFrom}
                        onChange={(e) => this.onDateChange(e, 'mappedRangeFrom', true)}
                        onSelect={(e) => this.onDateChange(e, 'mappedRangeFrom')}
                      />
                    </div>

                    <div className='col-lg-6 text-right pr-0'>
                      <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                        appendTo={document.body}
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange="1940:2530"
                        minDate={this.state.mappedRangeFrom ? this.state.mappedRangeFrom : ""}
                        name={"mappedRangeTo"}
                        placeholder='To - mm/dd/yyyy'
                        dateFormat="mm/dd/yy"
                        value={this.state.mappedRangeTo}
                        onChange={(e) => this.onDateChange(e, 'mappedRangeTo', true)}
                        onSelect={(e) => this.onDateChange(e, 'mappedRangeTo')}
                      />
                    </div>
                  </div>
                </div>

                <div className='row col-lg-12 mb-2 mt-4' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-6' style={{ textAlign: "right" }}>
                    <Button
                      outline
                      disabled={ !this.state.propertyAddressLine1 && !this.state.propertyOwnerName ? true : false}
                      // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                      style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#4EA250", color: "white", width: "90%" }}
                      onClick={(e) => this.getDataFromServer()}
                    >
                      Search
                    </Button>
                  </div>

                  <div className='col-lg-6' style={{ textAlign: "right" }}>
                    <Button
                      outline
                      disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                      // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                      style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white", width: "90%" }}
                      onClick={(e) => this.clearData()}
                    >
                      {"Clear All Filters"}
                    </Button>
                  </div>
                </div>

              </div>

            </div>
            </AccordionTab>
            </Accordion>
            <div className='row col-lg-12 pr-0' style={{ display: "flex", alignItems: "center" }}>
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <h4><b>{`Results Set : ${this.state.resultSet}`}</b></h4>
              </div> */}
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={(e) => this.getDataFromServer()}
                >
                  Go
                </Button>
              </div> */}
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={this.props.openShowHideColumnsModal}
                >
                  Request Excel File
                </Button>
              </div> */}
              {/* <div className='col-lg-2 mt-3 mb-2'>
                <Button color="primary"
                  outline
                  disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
                  // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                  style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
                  onClick={(e) => this.clearData()}
                >
                  Clear Data
                </Button>
              </div> */}
              {this.state.showMessage ?
                // <div className='col-lg-5 mt-3 mb-2 p-0 ml-4' style={{ fontWeight: "bold", color: "green" }}>
                <div className='row col-lg-12 mt-2  pr-0' style={{ display: "flex", alignItems: "center" }}>
                  <div className='col-lg-2 pr-1'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`Elastic Results: ${this.state.resultSet}`}</p>
                  </div>
                  <div className='col-lg-3 pr-1 pl-0'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`Conf. On Site Results: ${this.state.SQLCount} out of sampled 1000 records`}</p>
                  </div>
                  <div className='col-lg-2 pr-1 pl-0'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }} >
                      {`OPRA2 Match Results: ${this.state.MongoCount}`}</p>
                  </div>
                  <div className='col-lg-4  pr-0 pl-0'>
                    <p style={{ whiteSpace: "pre", backgroundColor: 'lightgray', padding: "0.5rem" }}>
                      {`Showing max of 1000 results. Rest can be viewed in the exported file.`}</p>
                    {/* {configMessages.propertySearchV2Message} */}
                  </div>
                  <div className='col-lg-1 pl-0' style={{ textAlignLast: "right",paddingRight: "3.0rem" }}>
                    <span  ><Button color="primary"
                      className='p-1 py-0 mb-0 ml-3' style={{ height: "38px" }}
                      size={'sm'} onClick={this.props.handleSelectedAction}
                      outline
                      disabled={this.props.allUsersData.length > 0 ? false : true}
                    >
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To excel"}
                        className='pl-1' size='lg' />
                    </Button>
                      {this.props.getExcelDownload()}</span>
                  </div>
                </div> : null}

            </div>
          </div>

        </CardBody>
      </Card >
    )
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PropertySearchV2Filters = reduxForm({
  form: "PropertySearchV2Filters", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(PropertySearchV2Filters);

// You have to connect() to any reducers that you wish to connect to yourself
PropertySearchV2Filters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(PropertySearchV2Filters);

export default withTranslation('common')(PropertySearchV2Filters);