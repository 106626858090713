import fetchMethodRequest from '../config/service';

export const MAIL_FORWARD:string = 'Mail Forwards';
export const MAIL_FORWARD_DETAILS:string = 'Mail Forward Details';
export const MAIL_FORWARD_ASSIGNMENT:string = 'Mail Forwards Assignment';
const MAIL_FORWARD_API = 'https://mailforwardingapi.dosystemsinc.com/api/';

export const getMailFwdStatusandReasons = async (screenType: string): Promise<Array<any>> => {
    const res = await fetchMethodRequest('GET', `mftStatusAndReasons/getAllStatusAndReasons`, '', '', '', '', MAIL_FORWARD_API);
    if (res && res.mftAllStatusAndReasons && res.mftAllStatusAndReasons.length > 0) {
        return Promise.resolve([res.mftAllStatusAndReasons, res.mftAllStatusAndReasons.filter((e: any) => e.screenTypeForStatus.includes(screenType))]);
    }
    return Promise.resolve([]);
}

export const getMailFwdStatusandReasonsForTableFilters = async (screenType: string, statuses: Array<any>): Promise<Array<any>> => {
    const formattedStatuses = statuses.map((e: any) => { return { label: e.mailFwdStatus, value: e.mailFwdStatusID } });
    const formattedReasons = (statuses.map((e: any) => e.mailFwdReasons).flat()).map((e: any) => { return { label: e.mailFwdReason, value: e.mailFwdReasonID } });
    return Promise.resolve([formattedStatuses, formattedReasons]);
}

export const getGoverningTemplateID = async (dealID: string) : Promise<any> => {
    const res = await fetchMethodRequest('GET', `governingTemplates/getGoverningTemplateIDForDeal/${dealID}`, '', '', '', '', MAIL_FORWARD_API);
    if(res && res.governingTemplateID){
        return Promise.resolve(res.governingTemplateID);
    }
    return Promise.resolve(null);
}

export const getGoverningTemplateDetails = async (templateID: string): Promise<any> => {
    const templateDetails = await fetchMethodRequest('GET', `governingTemplates/getGoverningTemplate/${templateID}`, '', '', '', '', MAIL_FORWARD_API);
    if (templateDetails.data) {
        return Promise.resolve({combinedLabel:`${templateDetails.data.GoverningTemplateVersion} : ${templateDetails.data.GoverningTemplateSummary}`,...templateDetails.data})
    }
    return Promise.resolve({});
}

