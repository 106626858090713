
import React from 'react';
import { Button } from 'reactstrap';
import { Row } from 'reactstrap';
// fecth method from service.js file

import fetchMethodRequest from '../../config/service';
import fetch from '../../config/service';
import dateFormats from '../UI/FormatDate/formatDate';
import { OverlayPanel } from 'primereact/overlaypanel';
import { withTranslation } from 'react-i18next';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import DeleteRowModal from '../Cruds/CommonModals/DeleteRowModal';
import apiCalls from '../../config/apiCalls';
import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import { Redirect } from 'react-router';
import configMessages from '../../config/configMessages';
import config from '../../config/config';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Accordion, AccordionTab } from 'primereact/accordion';

class PropertyFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sellers: [],
      filterCriteria: {},
      lastUpdatedDate: "",
      isChangeHit: false,
      filterednameSuggestions: [],
      sellerName: "",

      setGoButton: false,
      isSharing: false,
      modifiedAddress: [],
      selectedViewType: "Default",
      isexcludeProperties: "",
      SessionWarningModal: false,
      selectedAnalyst: [],
      isexcludeRoyalties: true,
      // taskProperties: {},
      isredirtRanges: false,
      selectedState: [],
      stateUP: true,
      sec: true,
      isCA_N: true,
      selectedDate: null,
      searchmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      upmodifiedState: [
        { label: "AL (Alabama)", value: "AL" },
        { label: "AK (Alaska)", value: "AK" },
        { label: "AZ (Arizona)", value: "AZ" },
        { label: "AR (Arkansas)", value: "AR" },
        { label: "CA (California)", value: "CA" },
        { label: "CA-N (California N)", value: "CA-N" },
        { label: "CO (Colorado)", value: "CO" },
        { label: "CT (Connecticut)", value: "CT" },
        { label: "DE (Delaware)", value: "DE" },
        { label: "FL (Florida)", value: "FL" },
        { label: "GA (Georgia)", value: "GA" },
        { label: "HI (Hawaii)", value: "HI" },
        { label: "ID (Idaho)", value: "ID" },
        { label: "IL (Illinois)", value: "IL" },
        { label: "IN (Indiana)", value: "IN" },
        { label: "IA (Iowa)", value: "IA" },
        { label: "KS (Kansas)", value: "KS" },
        { label: "KY (Kentucky)", value: "KY" },
        { label: "LA (Louisiana)", value: "LA" },
        { label: "ME (Maine)", value: "ME" },
        { label: "MD (Maryland)", value: "MD" },
        { label: "MA (Massachusetts)", value: "MA" },
        { label: "MI (Michigan)", value: "MI" },
        { label: "MN (Minnesota)", value: "MN" },
        { label: "MS (Mississippi)", value: "MS" },
        { label: "MO (Missouri)", value: "MO" },
        { label: "MT (Montana)", value: "MT" },
        { label: "NE (Nebraska)", value: "NE" },
        { label: "NV (Nevada)", value: "NV" },
        { label: "NH (New Hampshire)", value: "NH" },
        { label: "NJ (New Jersey)", value: "NJ" },
        { label: "NM (New Mexico)", value: "NM" },
        { label: "NY (New York)", value: "NY" },
        { label: "NC (North Carolina)", value: "NC" },
        { label: "ND (North Dakota)", value: "ND" },
        { label: "OH (Ohio)", value: "OH" },
        { label: "OK (Oklahoma)", value: "OK" },
        { label: "OR (Oregon)", value: "OR" },
        { label: "PA (Pennsylvania)", value: "PA" },
        { label: "PR (Puerto Rico)", value: "PR" },
        { label: "RI (Rhode Island)", value: "RI" },
        { label: "SC (South Carolina)", value: "SC" },
        { label: "SD (South Dakota)", value: "SD" },
        { label: "TN (Tennessee)", value: "TN" },
        { label: "TX (Texas)", value: "TX" },
        { label: "UT (Utah)", value: "UT" },
        { label: "VT (Vermont)", value: "VT" },
        { label: "VA (Virginia)", value: "VA" },
        { label: "DC (Washington - DC)", value: "DC" },
        { label: "WA (Washington - WA)", value: "WA" },
        { label: "WV (West Virginia)", value: "WV" },
        { label: "WI (Wisconsin)", value: "WI" },
        { label: "WY (Wyoming)", value: "WY" },
      ],
      page: 0,
      modifiedStates: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      isValidate: false,
      excludeCoOwners: false,
      excludeNotConfirmedOnSite: true,
      isexcludePossiblyPaid: false,
      modifiedStatus: [
        { label: "Possible Confirmation", value: "Possible Confirmation" },
        { label: "Waiting for Validation", value: "Waiting for Validation" },
      ],
      matchBuckets: [
        { label: "Overall Near Exact", value: "Overall Near Exact" },
        { label: "Overall Tier-1", value: "Overall Tier-1" },
        { label: "Overall Tier-2", value: "Overall Tier-2" },
        { label: "Overall Tier-3", value: "Overall Tier-3" },
        { label: "Name Near Exact", value: "Name Near Exact" },
        { label: "Name Tier-1", value: "Name Tier-1" },
        { label: "Name Tier-2", value: "Name Tier-2" },
        { label: "Name Tier-3", value: "Name Tier-3" },
        { label: "Address Near Exact", value: "Address Near Exact" },
        { label: "Address Tier-1", value: "Address Tier-1" },
        { label: "Address Tier-2", value: "Address Tier-2" },
        { label: "Address Tier-3", value: "Address Tier-3" },
      ],
      dropdownOpen: false,


    };
  }

  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          if (status.status !== null) {
            StatusArray.push({ label: status.status, value: status.status })
          }
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [], StateDataSource = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          if (status.dataSourceNames !== null && status.group !== null && status.group !== "State UP Data" && status.group !== "CA-N" && status.group !== "SEC") {
            StatusArray.push({ label: status.group, value: status.dataSourceNames })
            dataArray.push({ label: status.group, value: status.group })
          }
          if (status.group === "State UP Data" || status.group === "CA-N" || status.group === "SEC" || status.group === "Empty") {
            StateDataSource.push({ label: status.group, value: status.dataSourceNames })
          }
          if (status.group === "Empty") {

            await this.setState({ setDefaultDataGroup: true })
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray, StateDataSource: StateDataSource })
      }
    })
    return this.state.settingsData;
  }

  getNewStatusForEditor = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }

    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ propertyStatusArray: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
    await this.props.selectedOpra(val)
  }

  /**
   * Settings Call
   */
  getSettings = () => {
    var responseWarningTimeout
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    let filterCriteria = {};
    if (!responseWarningTimeout) {
      filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
      fetch('GET', `settings`).then(async (response) => {
        if (response && response.settings) {
          await this.setState({
            isexcludePossiblyPaid: response.settings[0].excludePossiblyDefaultEnable
          })
        } else {
          await this.setState({
            isexcludePossiblyPaid: false
          })
          return ""
        }
      })
    }
  }

  componentDidMount = async () => {
    let loginData = localStorage.getItem("loginCredentials");
    loginData = JSON.parse(loginData);
    let defanalyst = []
    defanalyst.push(loginData.displayName)

    this.getSettings()
    this.searchsellerItems();
    this.getClaimStatus()
    this.getNewStatusForEditor()
    this.getReasonForEditor()
    this.selectedOpra("OPRA")
    await this.getDataGroupSources()
    // this.getReasonForEditor()
    let hitback = localStorage.getItem('hitback');
    let claimSummaryId = localStorage.getItem('claimSummaryID')
    let claimSummaryName = localStorage.getItem('claimSummaryName')
    if (this.props.type === "ClaimsManagement" && hitback === "yes" && claimSummaryId) {
      let estateObj = { EstateName: claimSummaryName, EstateID: claimSummaryId }
      let filterCriteria = {}
      filterCriteria.criteria = []
      filterCriteria.page = "1"; filterCriteria.limit = 10; filterCriteria.direction = "desc"; filterCriteria.sortfield = "OppClaimID"
      filterCriteria.criteria.push({ key: 'CompanyID', value: claimSummaryId, type: 'eq', add_Filter: true })
      await this.setState({ EstateID: claimSummaryId, filterCriteria: filterCriteria, selectedSeller: estateObj })
      this.submit()
    }

    let taskProperties = JSON.parse(localStorage.getItem("TaskProperties"))
    if (taskProperties) {
      if (taskProperties && taskProperties.PropertiesSellerName && taskProperties.PropertiesSellerName.EstateID) {
        await this.getSellerName(taskProperties.PropertiesSellerName.EstateID)
        taskProperties.PropertiesSellerName.EstateName = this.state.PropertiesSellerEstateName
        taskProperties.PropertiesSellerName.DealType = this.state.EstateDetails.DealType
        taskProperties.PropertiesSellerName.DealYear = this.state.EstateDetails.DealYear
        taskProperties.PropertiesSellerName.SellerNickName = this.state.EstateDetails.SellerNickName
      }
      await this.setState({ dataSource: [] })
      let selectedStates = [], selectedProperties = []
      this.searchsellerItems(taskProperties.PropertiesSellerName.EstateID, taskProperties)

      // await this.handleChange(taskProperties.PropertiesSellerName, 'Seller', "FromTask")
      if (taskProperties.States && taskProperties.States.length > 0) {
        await this.handleChange(taskProperties.States, 'State')
        for (var sine of taskProperties.States) {
          selectedStates.push(
            sine.slice(-2),
          )
        }

      }
      if (taskProperties.Property_Id && taskProperties.Property_Id.length > 0) {
        for (var sine of taskProperties.Property_Id) {
          selectedProperties.push(
            sine
          )
        }
      }

      await this.handleChange(selectedStates, "State")
      await this.setState({
        // selectedSeller: taskProperties.PropertiesSellerName,
        // selectedState: selectedStates,
        stateUP: false,
        sec: false,
        isCA_N: false,
        taskProperties: taskProperties,
        Property_Ids: selectedProperties,
        isexcludeRoyalties: false,
        excludeNotConfirmedOnSite: false,
        selectedTaskId: taskProperties.taskID.taskID,
        selectedtask: taskProperties.taskID
      })
      await this.searchnameItems()
      this.submit("taskProperties")
      localStorage.removeItem("TaskProperties")
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.reportType && (params.reportType.includes("AvailableToMap" || params.reportType.includes("AvailableToReMap"))) && (params.bucket === "Green" || params.bucket === "Blue")) {
      await this.setState({ isShowNote: true })
    }
    if (this.props.type === "Properties") {
      await this.setState({
        selectedBucket: "Overall Near Exact"
      })
    }

    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.state.setDefaultDataGroup && this.props.type === "Property Editor" && !taskProperties) {
      this.handleChange({ value: ["Empty"] }, 'dataSource')
    }

    let userArray = []
    return fetch('GET', `${apiCalls.Users}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            if (user.display_name !== null) {
              userArray.push({ label: user.display_name, value: user.display_name })
            }
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });

    this.setState({ isLoading: false, selectedAnalyst: defanalyst, defanalyst: defanalyst });

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  groupTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <div className=""
          data-toggle="tool-tip"
          title={option.value.description}>{option.label}
        </div>
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  getData = async (obj) => {
    if ((obj && obj.selectedRanges || obj && obj.groupedflag) && this.props.type === "Property Editor") {
      await this.setState({
        isredirtRanges: true,
        Group: obj.groupedflag ? obj.groupedflag : null,
        groupMessage: obj.groupMessage ? obj.groupMessage : "",
        fiftyk: obj.fiftyk ? "Filtered On Properties Max Amount Greater than $50k" : "",
        unknown: obj.unknown ? "Filtered On Unknown Properties In Single State For A Single Name" : "",
        Count10kTo50k: obj.Count10kTo50k ? "Filtered On Properties Max Amount Between $10k and $50k" : ""

      })
    }
    if (this.props.type === 'Negative Notice List Viewer' || this.props.type === "Site Confirmation Editor") {
      await this.setState({
        selectedTaskId: obj.taskID,
      })
      if (obj.selectedSeller.EstateID !== null && obj.selectedSeller.EstateID !== "") {
        this.getNegativeNoticeList(obj.selectedSeller.EstateID)

      }
    }
    if (obj.isValidate) {
      await this.setState({
        isValidate: true,
      })
    }
    if (obj.redirectedGroup) {
      this.handleChangeSelectedGroup(obj.redirectedGroup)
    }
    if (obj.taskID) {
      this.getTaskIDs(obj.taskID)
    }
    await this.setState({
      isSharing: true,
      sharedobj: obj
    })
    if (obj.selectedProperty_Id) {
      let properties = obj.selectedProperty_Id
      await this.setState({
        Property_Id: properties
      })


    }
    if (obj.selectedOpraStId) {
      let stateProperties = obj.selectedOpraStId
      await this.setState({
        OpraStId: stateProperties
      })
    }
    if (obj.legacyNameIssueCode && obj.legacyNameIssueCode.length > 0) {
      let availableToMapLegacyNameIssueCode = []
      for (let code of obj.legacyNameIssueCode) {
        availableToMapLegacyNameIssueCode.push({ label: code, value: code })
      }
      await this.setState({ nameIssueCode: obj.legacyNameIssueCode.split(","), availableToMapLegacyNameIssueCode: availableToMapLegacyNameIssueCode })

    }
    if (obj.newNameIssueCode && obj.newNameIssueCode.length > 0) {
      let availableToMapNameIssueCode = []
      for (let code of obj.newNameIssueCode) {
        availableToMapNameIssueCode.push({ label: code, value: code })
      }
      await this.setState({ newNameIssueCode: obj.newNameIssueCode.split(","), availableToMapNameIssueCode: availableToMapNameIssueCode })
    }
    if (obj.isexcludeValidated && obj.isexcludeValidated === "true") {
      await this.setState({
        isexcludeValidated: true,
      })
    } else {
      await this.setState({
        isexcludeValidated: false,
      })
    }
    if (obj.excludeNotConfirmedOnSite && obj.excludeNotConfirmedOnSite === "true") {
      await this.setState({
        excludeNotConfirmedOnSite: true,
      })
    } else {
      await this.setState({
        excludeNotConfirmedOnSite: false,
      })
    }
    if (obj.isexcludeProperties) {
      await this.setState({
        isexcludeProperties: obj.isexcludeProperties,
      })
    }
    if (obj.isexcludePossiblyPaid && obj.isexcludePossiblyPaid === "true") {
      await this.setState({
        isexcludePossiblyPaid: true,
      })
    } else {
      await this.setState({
        isexcludePossiblyPaid: false,
      })
    }
    if (obj.isexcludeRoyalties) {
      await this.setState({
        isexcludeRoyalties: true
      })
    } else {
      await this.setState({
        isexcludeRoyalties: false
      })
    }
    if (obj.excludeCoOwners && obj.excludeCoOwners === "true") {
      await this.setState({
        excludeCoOwners: true,
      })
    } else {
      await this.setState({
        excludeCoOwners: false,
      })
    }

    if (obj.states && obj.states !== "null") {
      let decodedStates = obj.states.split(",")
      await this.setState({
        selectedStates: decodedStates
      })
    }

    if (obj.selectedPropertyStatus) {
      let decodedStatus = obj.selectedPropertyStatus.split(",")
      let eventt = {}
      eventt.value = decodedStatus
      var getstatus = this.state.propertyStatusArray.filter(function (el) {
        for (var item of decodedStatus) {
          if (el.label === item) {
            return el
          }
        }
      });
      let getStatusArray = {}
      getStatusArray.value = []
      for (var data of getstatus) {
        getStatusArray.value.push(data.value)
      }
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      await this.handleChange(getStatusArray, "selectedPropertyStatus", "redirect")
    }
    if (obj.selectedPropertyReasons) {
      let decodedStatus = obj.selectedPropertyReasons.split(",")
      let eventt = {}
      if (this.state.shownreasons === undefined) {
        await this.getReasonForEditor(obj.selectedPropertyStatus, "Redirect")
      }
      var getreasons = this.state.shownreasons.filter(function (el) {
        for (var item of decodedStatus) {
          if (el.label === item) {
            return el
          }
        }
      });
      let getreasonsArray = {}
      getreasonsArray.value = []
      for (var data of getreasons) {
        getreasonsArray.value.push(data.value)
      }
      this.handleChange(getreasonsArray, "selectedPropertyReasons")

    }
    if (obj.dataSourceRedirect && obj.dataSourceRedirect.length > 0) {
      await this.setState({
        dataSource: obj.dataSourceRedirect.split(",")
      })
    } else {
      await this.setState({ dataSource: [] })
    }

    if (obj.selectedPropertyReasonsForResource) {
      let eventt = {}
      eventt.value = obj.selectedPropertyReasonsForResource.split(",")
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyReasons")
    }
    // if (obj && obj.reportType && obj.reportType.includes("SiteConfirmationReport")) {

    //   this.handleChange({ value: ["Empty"] }, 'dataSource')
    //   // this.setState({ dataSource: this.state.StateDataSource })
    // } else {
    await this.setState({
      stateUP: false,
      sec: false,
      isCA_N: false
    })
    // }
    // if (!obj.selectedProperty_Id) {
    this.handleChange(this.state.selectedStates, "State")
    this.searchsellerItems(obj.selectedSeller.EstateID, obj)
    await this.getSellerNameSelectedOpra(obj.selectedSeller.EstateID)
    // }
  }


  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  redircttope = () => {
    this.props.getUrlbyParams()
  }

  lastUpdatedDate = (e) => {
    this.setState({ lastUpdatedDate: dateFormats.formatDate(e, "MM/DD/YYYY") })
  }

  getSellerNameSelectedOpra = async (val) => {
    return fetch('GET', `${apiCalls.propertysellers}?dealID=${val}`)
      .then(async (response) => {
        if (response && response["sellers"].length > 0) {
          if (response["sellers"][0].MigratedFlag) {
            await this.selectedOpra("OPRA2")
          } else {
            await this.selectedOpra("OPRA")
          }
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getSellerName = async (val) => {
    return fetch('GET', `${apiCalls.propertysellers}?dealID=${val}`)
      .then(async (response) => {
        if (response && response["sellers"].length > 0) {
          // let sellers = response["sellers"][0].EstateName
          let sellers = `${response["sellers"][0].DealType} - ${response["sellers"][0].SellerNickName} (${response["sellers"][0].DealYear ? response["sellers"][0].DealYear : ""})`
          await this.setState({ PropertiesSellerEstateName: sellers, EstateDetails: response["sellers"][0] })
          if (response["sellers"][0].MigratedFlag) {
            await this.selectedOpra("OPRA2")
          } else {
            await this.selectedOpra("OPRA")
          }
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getReasonForEditor = async (val, key) => {
    var ReasonsArray = [], data = []
    let filterCriteria = {}
    let array = []
    if (val && val.length > 0) {
      // if (key) {
      //   data = val.split(",")
      // } else {
      for (var obj of val) {
        if (!array.includes(obj.status)) {
          if (obj.status) {
            data.push(obj.status)
          }
        }
      }

      filterCriteria['criteria'] = [{ key: 'status', value: data, type: 'in', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
      ];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            ReasonsArray.push({ label: reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
          }
          await this.setState({ shownreasons: ReasonsArray })
          if (ReasonsArray.length === 1) {
            // var data = {
            //   value: []
            // }
            // data.value.push(ReasonsArray[0].value)
            // this.handleChange(data, "selectedPropertyReasons")
          }
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ shownreasons: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  ClearValues = () => {
    this.setState({ coutntValue: "", propCount: 0, selectedState: "", ClaimName: "", selectedName: "", selectedSeller: "", })
    this.props.clearData()
  }

  handleChangeSelectedGroup = async (e, type) => {
    await this.setState({
      selectedGroup: type == "Delete" ? "" : e.value
    })
  }

  submitValues = (values) => {
    let Object = {
      // selectedSeller: {},
      selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
      selectedStatus: this.state.selectedStatus,
      selectedAddress: this.state.selectedAddress,
      selectedState: this.state.selectedState,
      selectedBucket: this.state.selectedBucket,
      // selectedProperty_Id: this.state.Property_Id,
    }
    if (this.props.type === "Up Prop Confirmation") {
      let data = {
        EstateName: this.state.selectedSeller.EstateName,
        EstateID: this.state.selectedSeller.EstateID
      }

      Object.selectedSeller = data
    }
    if (this.state.coutntValue) {
      // let propertyId = this.state.serverCount.replaceAll("\n", ",");
      let propertyId = this.state.serverCount.map(Number);

      Object.coutntValue = propertyId
    }
    if (this.state.selectedState) {
      Object.selectedState = this.state.selectedState
    }

    this.props.sendUpPropConfirmation(Object)
  }

  showRecordsCount = (type, field) => {
    let newNameIssueCodes = this.props.IssueCodes.newNameIssueCodes
    let nameIssueCodes = this.props.IssueCodes.nameIssueCodes
    if (type === "show" && field === "nameIssueCodes" && nameIssueCodes && nameIssueCodes.length === 0) {
      this.setState({
        isShownameIssueCodes: true
      })
    } else if (type === "hide" && field === "nameIssueCodes") {
      this.setState({
        isShownameIssueCodes: false
      })
    } else if (type === "show" && field === "newNameIssueCodes" && newNameIssueCodes && newNameIssueCodes.length === 0) {
      this.setState({
        isShownewNameIssueCodes: true
      })
    } else if (type === "hide" && field === "newNameIssueCodes") {
      this.setState({
        isShownewNameIssueCodes: false
      })
    }
  }
  closeSaveModelPopUp = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.props.closeSaveModelPopUp()
    await this.props.makeNoteEditedFalse()
    await this.submit(this.state.selectedEvent)
  }
  closeTaskAddModel = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.props.makeNoteEditedFalse()
    await this.submit(this.state.selectedEvent)
  }
  /**
 * 
 * @param {string} edited 
 * function to save the note is edited or not for slecting and unselecting records
 */
  commentEditedOrNo = async (edited) => {
    await this.setState({ edited: edited })
  }
  // Delete Modal
  getDeleteRowModalForNotes() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }
  submit = async (values) => {
    // if (this.state.edited === "yes") {
    //   //to open model if note is edited and trying to close by click on icon
    //   await this.setState({ openUnsaveModal: true, selectedEvent: values })
    //   return
    // }
    if (values === "taskProperties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor") {

    } else {
      await this.setState({ selectedTaskId: null })
    }

    // if (this.state.groupMessage && this.state.filterCriteria) {
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyMaxValueAmount" && obj.add_Filter === true)
    if (index !== -1) {
      filtercriteria.criteria = filtercriteria.criteria.filter(elem => elem.key !== "propertyMaxValueAmount")
    }
    // if (this.state.groupMessage && this.state.filterCriteria) {

    let scoredDateindex = filtercriteria["criteria"].findIndex(obj => obj.key === "ScoreDate" && obj.add_Filter === true)
    if (index !== -1) {
      filtercriteria["criteria"].splice(scoredDateindex, 1)
    }
    let confirmedOnSiteDateindex = filtercriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate" && obj.add_Filter === true)
    if (confirmedOnSiteDateindex !== -1) {
      filtercriteria["criteria"].splice(scoredDateindex, 1)
    }

    this.setState({ filterCriteria: filtercriteria })
    // }
    // }

    await this.setState({ isredirtRanges: false, groupMessage: "", fiftyk: "", unknown: "", Count10kTo50k: "", })

    var newArray = this.props.allUsersData.filter(function (el) {
      return el.edited === "yes"
    });
    if (newArray && newArray.length > 0) {
      this.props.OpenConfirmaionModelorSave()
    } else {
      if (this.state.selectedAnalyst && this.props.type === "ClaimsManagement") {
        let filtercriteria = this.state.filterCriteria
        if (filtercriteria["criteria"].length > 0) {
          let index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
          if (index === -1)
            this.handleChange(this.state.selectedAnalyst, "Analyst")
        } else {
          this.handleChange(this.state.selectedAnalyst, "Analyst")
        }
      }
      if (!this.state.nameIssueCode && this.props.IssueCodes && this.props.IssueCodes.defaultIssueNames) {
        this.handleChange(this.props.IssueCodes.defaultIssueNames, "nameIssueCode")
      }
      const str = this.state.OpraStId ? this.state.OpraStId : null;
      var result = str ? str.split(/\r?\n/) : null;
      const str2 = this.state.StatePropertyID ? this.state.StatePropertyID : null;
      var result2 = str2 ? str2.split(/\r?\n/) : null;
      const opraClaimIdsstr = this.state.OpraClaimId ? this.state.OpraClaimId : null;
      var OpraclaimIdsresult = opraClaimIdsstr ? opraClaimIdsstr.split(/\r?\n/) : null;
      const opraStClaimIdsstr = this.state.stClaimId ? this.state.stClaimId : null;
      var OprastclaimIdsresult = opraStClaimIdsstr ? opraStClaimIdsstr.split(/\r?\n/) : null;
      const str1 = this.state.Property_Id ? this.state.Property_Id : null;
      var result1
      // if ((this.state.taskProperties && this.state.taskProperties.Property_Id) || this.state.selectedTaskId) {
      //   result1 = typeof str1;
      // } else {
      result1 = typeof str1 === "string" ? str1.split(/\r?\n/) : str1;

      // }

      let Object = {
        // selectedSeller: {},

        selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
        selectedStatus: this.state.selectedStatus,
        selectedAddress: this.state.selectedAddress,
        selectedState: this.state.selectedState,
        selectedBucket: this.state.selectedBucket,
        groupFlag: this.state.selectedGroup ? this.state.selectedGroup.flagName : null,
        // selectedProperty_Id: this.state.Property_Id,
        selectedProperty_Id: result1 ? result1 : null,
        // selectedOpraStId: this.state.OpraStId
        selectedOpraStId: result ? result : null,
        setectedStOpraId: result2 ? result2 : null,
        nameIssueCode: this.state.isDeleteNameIssue ? "delete" : this.state.nameIssueCode
      }
      if (this.state.selectedTaskId && (values === "taskProperties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor")) {
        Object.taskID = this.state.selectedTaskId
      }

      this.setState({ setGoButton: true, isredirtRanges: false })
      if (this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies") {
        let data = {
          EstateName: this.state.selectedSeller.EntityName,
          EstateID: this.state.selectedSeller.DealID
        }
        Object.selectedSeller = data
      }
      else if (this.props.type === "Claims" || this.props.type === "ClaimsManagement") {
        let data = {
          EstateID: this.state.EstateID,
          selectedClaimStatus: this.state.selectedClaimStatus,
          selectedOpraStClaimId: OprastclaimIdsresult ? OprastclaimIdsresult : null,
          EstateName: this.state.selectedSeller ? this.state.selectedSeller.EstateName : null,
          selectedOpraClaimIds: OpraclaimIdsresult ? OpraclaimIdsresult : null,
          selectedOpraStId: result ? result : null,
          setectedStOpraId: result2 ? result2 : null
        }
        if (this.state.ClaimName && this.state.ClaimName.nameID) {
          data.BusinessNameID = this.state.ClaimName.nameID
        }
        Object.selectedClaims = data
      }
      else if (this.props.type === "Deal Sizing Report For Private Equity Portfolio") {
        let data = {
          EstateID: this.state.selectedSeller.CompanyID
        }
        Object.selectedSeller = data
      }
      else {
        Object.selectedSeller = this.state.taskProperties ? this.state.taskProperties.PropertiesSellerName : this.state.selectedSeller
      }
      if (this.state.isexcludeProperties) {
        Object.isexcludeProperties = this.state.isexcludeProperties
      }
      if (this.state.isexcludePossiblyPaid) {
        Object.isexcludePossiblyPaid = true
      }
      if (this.state.isexcludeRoyalties) {
        Object.isexcludeRoyalties = true
      }
      if (this.state.excludeCoOwners) {
        Object.excludeCoOwners = true
      }
      if (this.state.excludeNotConfirmedOnSite) {
        Object.excludeNotConfirmedOnSite = true
      }
      if (this.state.isexcludeValidated) {
        Object.isexcludeValidated = true
      }
      if (this.state.isValidate) {
        Object.isValidate = true
      }
      if (this.props.type === "Negative Notice List Viewer") {
        Object.excludeNotConfirmedOnSite = false
      }
      if (this.props.type === "Property Editor") {
        let filtercriteria = this.state.filterCriteria
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.dataSourceArrayNew && this.state.dataSourceArrayNew.length > 0 && this.state.dataSource && this.state.dataSource.length > 0) {

          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.dataSourceArrayNew,
            type: "in",
            add_Filter: true
          })
        }
        this.setState({ filterCriteria: filtercriteria })
      } else {
        let filtercriteria = this.state.filterCriteria
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        this.setState({ filterCriteria: filtercriteria })
      }


      let filterCriteria = await this.dataSourceCustumisations()
      await this.removeFileForAfterRedirection()
      if ((this.props.type === "Negative Notice List Viewer" && this.state.selectedtask) || this.props.type !== "Negative Notice List Viewer") {
        this.props.getFilteredValues(Object, this.state.filterCriteria, this.state.isChangeHit, "setGoButton", this.props.type === "ClaimsManagement" ? this.state.filteredclaimSuggestions : this.state.filterednameSuggestions)
      } else {
        this.props.getErrorMessage("Please select Notice list")
      }
    }
  }

  dataSourceCustumisations = () => {
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
    if (this.state.StateDataSource && this.state.StateDataSource.length > 0) {
      let dataindexforSEC = this.state.StateDataSource.findIndex(obj => obj.label === "SEC")
      if (this.state.sec) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexforSEC].value]
        } else if (dataindexforSEC !== -1) {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexforSEC].value,
            type: "in",
            add_Filter: true
          })
        }
      } else {
        if (dataindexforSEC !== -1) {
          const set2 = new Set(this.state.StateDataSource[dataindexforSEC].value);
          if (index !== -1) {
            let arr = filtercriteria["criteria"][index].value
            filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
          }
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      let dataindexisCA_N = this.state.StateDataSource.findIndex(obj => obj.label === "CA-N")
      if (this.state.isCA_N) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexisCA_N].value]
        } else {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexisCA_N].value,
            type: "in",
            add_Filter: true
          }
          )
        }
      } else {
        if (index !== -1) {
          const set2 = new Set(this.state.StateDataSource[dataindexisCA_N].value);
          let arr = filtercriteria["criteria"][index].value
          filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      let dataindexforUP = this.state.StateDataSource.findIndex(obj => obj.label === "State UP Data")
      if (this.state.stateUP) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexforUP].value]
        } else {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexforUP].value,
            type: "in",
            add_Filter: true
          }
          )
        }
      } else {
        const set2 = new Set(this.state.StateDataSource[dataindexforUP].value);
        if (index !== -1) {
          let arr = filtercriteria["criteria"][index].value
          filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
        }
      }
    }
    return filtercriteria
  }


  removeFileForAfterRedirection = async () => {
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "fileFor")
    if (index !== -1) filtercriteria["criteria"].splice(index, 1)
    this.setState({ filterCriteria: filtercriteria })
  }
  handlePEFirmChange = async (e, type, key) => {
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "pefirm") {
      await this.setState({
        selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        isChangeHit: true
      })
    }
    index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
    if (index !== -1) {
      filtercriteria["criteria"].splice(index, 1)
    }
    this.setGoButton()
    await this.setState({
      filterCriteria: filtercriteria
    })
  }
  showCOunt = async (e, type) => {
    var val = JSON.stringify(e.target.value)
    val = val.replace(/\\n/g, '-')
    val = val.replace(/\,/g, '-')
    val = val.replace(/\"/g, '')
    let count = val.split("-")
    // let count = val.split("-").length
    // if (count2[count - 1].length === 0) {
    //   count2.pop()
    // }
    count = count.filter(u => u != "")


    await this.setState({ coutntValue: e.target.value, propCount: count.length, serverCount: count })
  }
  selectedViewType = async (e) => {

    await this.setState({
      selectedViewType: e
    })
    await this.props.selectedViewType(e)
  }

  copyToClipboard = async (data) => {
    navigator.clipboard.writeText(data)
  }

  handleChange = async (e, type, key) => {
    var newArray = this.props.allUsersData.filter(function (el) {
      return el.edited === "yes"
    });
    if (newArray && newArray.length > 0) {
      this.props.OpenConfirmaionModelorSave()
    } else {
      await this.setState({
        isDeleteNameIssue: false
      })
      let index;
      let filtercriteria = this.props.filterCriteria
      if (type === "Seller") {
        await this.setState({
          // selectedSeller: e.value,
          // EstateID: e.value.EstateID,
          selectedSeller: e.EstateName && e.EstateID ? e : e.value,
          EstateID: e && e.value && e.value.EstateID ? e.value.EstateID : e.EstateID ? e.EstateID : "",
          selectedNamearr: "",
          selectedName: "",
          selectedState: "",
          selectedPropertyStatus: "",
          selectedPropertyReasons: "",
          OpraClaimId: "",
          StatePropertyID: "",
          ClaimName: "",
          OpraStId: "",
          Property_Id: "",
          isexcludeRoyalties: false,
          isexcludeRoyalties: true,
          excludeCoOwners: false,
          isexcludeProperties: "",
          // excludeNotConfirmedOnSite: false,
          excludeCoOwners: false,
          selectedClaimStatus: "",
          newNameIssueCode: "",
          nameIssueCode: "",
          filterednameSuggestions: [],
          selectedGroup: ""

        })
        if (e && e.value === "") {
          // await this.props.getremoveFilters("remove")

          this.props.isShowTable(false)
        }

        if (this.props.type === "SearchTerms") {
          await this.setState({
            // search
            selectedSearchTerm: "",
            showSearchTable: false,
            errorResponse: false,
            errorMessage: ''
          })
        }
        if (this.props.type === 'Negative Notice List Viewer' || this.props.type === "Site Confirmation Editor") {
          if (this.state.EstateID !== null && this.state.EstateID !== "") {
            this.getNegativeNoticeList(this.state.EstateID)

          }
        }
        if (this.props.type === "ClaimsManagement" && this.state.EstateID) {
          this.searchClaimNamesItems()
        }
        this.props.getErrorMessage("Please Select Seller", "selectedSeller")
        if (this.props.type === "Properties") {
          await this.setState({
            selectedBucket: "Overall Near Exact"
          })
        }
        this.searchnameItems()
        this.setGoButton()
        if (key !== "FromTask") {
          if (e && e.value && e.value.MigratedFlag) {
            this.selectedOpra("OPRA2")
          } else {
            this.selectedOpra("OPRA")
          }
        }
        filtercriteria["criteria"] = []
        // this.props.change('Name', '')
      } if (type === "ClaimName") {
        await this.setState({
          ClaimName: e.value,
          selectedName: "",
          selectedStatus: "",
          selectedAddress: "",
          selectedState: "",
          isChangeHit: true
        })
        // this.props.getErrorMessage("Please Select claimID", "claimID")
        // this.searchnameItems()
        this.setGoButton()
        // filtercriteria["criteria"] = []
        // this.props.change('Name', '')
      }
      else if (type === "Name") {
        let selectedNamearr = []
        if (e && e.target && e.target.value) {
          for (var arrName of e.target.value)
            selectedNamearr.push(arrName.id)
        } else {
          for (var arr of e)
            selectedNamearr.push(arr.id)
        }
        this.setGoButton()
        await this.setState({
          // selectedName: e.value
          selectedName: e && e.target && e.target.value ? e.target.value : e,
          selectedNamearr: selectedNamearr
        })
      } else if (type === "searchTermName") {
        await this.setState({
          searchTermName: e.value,
          searchtermId: e.value.id,
          selectedSearchTerm: "",
          showSearchTable: false,
          errorResponse: false,
          errorMessage: ''
        })
      } else if (type === "Status") {
        await this.setState({
          selectedStatus: e.value
        })
        this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchMatchStatusId")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        filtercriteria["criteria"].push({
          key: "matchMatchStatusId",
          value: this.state.selectedStatus,
          type: "in",
          add_Filter: true
        })
      } else if (type === "newNameIssueCode") {
        await this.setState({
          newNameIssueCode: e.value
        })
        // this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        filtercriteria["criteria"].push({
          key: "newNameIssueCode",
          value: this.state.newNameIssueCode,
          type: "in",
          add_Filter: true
        })
      } else if (type === "nameIssueCode") {
        await this.setState({
          nameIssueCode: e.value ? e.value : e
        })
        // this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.nameIssueCode.length > 0) {
          filtercriteria["criteria"].push({
            key: "nameIssueCode",
            value: this.state.nameIssueCode,
            type: "in",
            add_Filter: true
          })
        }
      }
      else if (type === "dataSource") {

        let dataSourceArray = []
        await this.setState({
          dataSource: e.value ? e.value : e
        })
        for (let val of e.value) {
          let dataindex = this.state.dataSourceGroups.findIndex(obj => obj.label === val)
          if (dataindex !== -1) {
            let valueArr = this.state.dataSourceGroups[dataindex]
            dataSourceArray = [...dataSourceArray, ...valueArr.value]
            this.setState({ dataSourceArrayNew: dataSourceArray })
          }
        }
        // this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.dataSource.length > 0) {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: dataSourceArray,
            type: "in",
            add_Filter: true
          })

        }
      }
      else if (type === "MatchBucket") {
        await this.setState({
          selectedBucket: e.value
        })
        this.setGoButton()
      } else if (type === "Address") {
        await this.setState({
          selectedAddress: e.value
        })
        this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchingPropertyOwnerAddressLine1")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        filtercriteria["criteria"].push({
          key: "matchingPropertyOwnerAddressLine1",
          value: encodeURIComponent(this.state.selectedAddress),
          type: "eq",
          add_Filter: true
        })
        // this.submit()
      } else if (type === "selectedPropertyStatus") {
        await this.setState({
          selectedPropertyStatus: e.value,
          selectedPropertyReasons: "",
        })
        let dataforStatus = []
        this.setGoButton()
        await this.getReasonForEditor(e.value, key)
        if (e.value) {

          for (var obj of e.value) {
            if (!dataforStatus.includes(obj.status)) {
              if (obj.status) {
                dataforStatus.push(obj.status)
              }
            }
          }
        }
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0)
          filtercriteria["criteria"].push({
            key: "propertyStatus",
            value: dataforStatus,
            type: "in",
            add_Filter: true
          })
        // this.submit()
      } else if (type === "selectedPropertyReasons") {
        await this.setState({
          selectedPropertyReasons: e.value
        })
        let dataforReasons = []
        if (e.value) {

          for (var obj of e.value) {
            if (!dataforReasons.includes(obj.reason)) {
              if (obj.reason) {
                dataforReasons.push(obj.reason)
              }
            }
          }
        }

        this.setGoButton()
        // await this.getReasons(e.value)
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.selectedPropertyReasons && this.state.selectedPropertyReasons.length > 0)
          filtercriteria["criteria"].push({
            key: "propertyStatusReason",
            value: dataforReasons,
            type: "in",
            add_Filter: true
          })
        // this.submit()
      } else if (type === "State") {
        if (e && e.value) {
          await this.setState({
            selectedState: e.value
          })
        } else {
          await this.setState({
            selectedState: e,
          })
        }
        if (this.props.type === "SearchTerms") {
          await this.setState({
            // search
            selectedSearchTerm: "",
            showSearchTable: false,
            errorResponse: false,
            errorMessage: ''
          })
        }
        // if (!this.state.selectedSeller && this.state.selectedState.length !== 0) {
        //   this.props.getErrorMessage("Please Select Seller", "selectedSeller")
        // }
        this.setGoButton()
        if (this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "Site Confirmation Editor") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "property_StateId" && obj.type === "in")
        }
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.selectedState && this.state.selectedState.length > 0)
          filtercriteria["criteria"].push({
            key: this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" ? "SourceState" : this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? "SourceState" : "property_StateId",
            value: this.state.selectedState,
            type: "in",
            add_Filter: true
          })      // this.submit()
      }
      else if (type === "Analyst") {
        if (e && e.value) {
          await this.setState({
            selectedAnalyst: e.value,
          })
        } else {
          await this.setState({
            selectedAnalyst: e,
            setGoButton: true
          })
        }
        if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
          this.setGoButton()
        }
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.selectedAnalyst && this.state.selectedAnalyst.length > 0)
          filtercriteria["criteria"].push({
            key: "Analyst",
            value: this.state.selectedAnalyst,
            type: "in",
            add_Filter: true
          })      // this.submit()
      }
      else if (type === "ClaimStatus") {
        if (e && e.value) {
          await this.setState({
            selectedClaimStatus: e.value
          })
        } else {
          await this.setState({
            selectedClaimStatus: e
          })
        }
        this.setGoButton()
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus" && obj.type === "in")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.selectedClaimStatus && this.state.selectedClaimStatus.length > 0)
          filtercriteria["criteria"].push({
            key: "ClaimStatus",
            value: this.state.selectedClaimStatus,
            type: "in",
            add_Filter: true
          })      // this.submit()

      }
      else if (type === "isexcludeProperties") {
        await this.setState({
          isexcludeProperties: e
        })
        this.setGoButton()
      } else if (type === "isexcludeValidated") {
        await this.setState({
          isexcludeValidated: this.state.isexcludeValidated === true ? false : true
        })
        this.setGoButton()
      } else if (type === "Validate") {
        await this.setState({
          isValidate: this.state.isValidate === true ? false : true
        })
        this.setGoButton()
      } else if (type === "excludeCoOwners") {
        await this.setState({
          excludeCoOwners: this.state.excludeCoOwners === true ? false : true
        })
        this.setGoButton()
      }
      else if (type === "excludeNotConfirmedOnSite") {
        await this.setState({
          excludeNotConfirmedOnSite: this.state.excludeNotConfirmedOnSite === true ? false : true
        })
        this.setGoButton()
      }
      else if (type === "isexcludePossiblyPaid") {
        await this.setState({
          isexcludePossiblyPaid: this.state.isexcludePossiblyPaid === true ? false : true
        })
        this.setGoButton()
      } else if (type === "isexcludeRoyalties") {
        await this.setState({
          isexcludeRoyalties: this.state.isexcludeRoyalties === true ? false : true
        })
        this.setGoButton()
      }
      else if (type === "Property_Id") {
        await this.setState({
          Property_Id: e ? e : null
        })
        this.setGoButton()
      } else if (type === "OpraStId") {
        await this.setState({
          OpraStId: e ? e : ""
        })
        this.setGoButton()
      } else if (type === "StatePropertyID") {
        await this.setState({
          StatePropertyID: e ? e : null
        })
        this.setGoButton()
      } else if (type === "OpraClaimId") {
        await this.setState({
          OpraClaimId: e ? e : null
        })
        this.setGoButton()
      } else if (type === "stClaimId") {
        await this.setState({
          stClaimId: e ? e : null
        })
        this.setGoButton()
      } else if (type === "Delete") {
        if (e === "selectedSeller") {
          await this.setState({
            selectedName: "",
            selectedStatus: "",
            selectedState: "",
            nameIssueCode: [],
            // dataSource: [],
            selectedAddress: "",
            selectedNamearr: "",
            selectedState: this.props.type === "ClaimsManagement" ? this.state.selectedState : [],
            selectedState: this.props.type === "ClaimsManagement" ? this.state.selectedState : "",
            selectedSeller: "",
            modifiedAddress: [],
            setGoButtonClaims: true,
            selectedBucket: "",
            EstateID: "",
            stClaimId: "",
            selectedPropertyStatus: "",
            selectedPropertyReasons: "",
            OpraClaimId: "",
            filterednameSuggestions: "",
            StatePropertyID: "",
            ClaimName: "",
            selectedtask: '',
            selectedTaskId: "",
            filterednameSuggestions: "",
            OpraStId: "",
            Property_Id: "",
            isexcludeRoyalties: false,
            isexcludeRoyalties: true,
            excludeCoOwners: false,
            isexcludeProperties: "",
            // excludeNotConfirmedOnSite: false,
            excludeCoOwners: false,
            selectedClaimStatus: "",
            newNameIssueCode: "",
            nameIssueCode: "",
            filterednameSuggestions: []
            // dataSource: ""
          })
          filtercriteria["criteria"] = []
          if (this.props.type === "ClaimsManagement" && this.state.selectedState.length !== 0) {
            this.handleChange(this.state.selectedState, "State")
          }
          // this.submit()

          this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")

          this.props.getFileOptions([])
        } else if (e === "selectedName") {
          await this.setState({
            selectedName: "",
            selectedNamearr: "",
          })
        }
        // else if (e === "ClaimName") {
        //   await this.setState({
        //     ClaimName: "",
        //   })
        // } 
        else {
          await this.setState({
            [e]: "",
          })
          if (e === "nameIssueCode") {
            await this.setState({
              nameIssueCode: [],
              isDeleteNameIssue: true
            })
          } else if (e === "dataSource") {
            await this.setState({
              dataSource: [],
              // isDeleteNameIssue: true
            })
          } else
            if (!this.state.selectedSeller && this.state.selectedState.length === 0 && this.props.type === "ClaimsManagement") {
              this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
            }
          let type;
          if (e === "OpraClaimId" || e === "OpraStId" || e === "stClaimId" || e === "PropertyID" || e === "selectedState" || e === "selectedStatus" || e === "StatePropertyID" || e === "selectedAnalyst" || e === "selectedClaimStatus" || e === "newNameIssueCode" || e === "nameIssueCode") {
            type = "in"
          } else {
            type = "eq"
          }
          if ((this.props.type === "Property Editor" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor") && key === "property_StateId") {
            let kindex = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState" && obj.type === "in")
            if (kindex !== -1) {
              filtercriteria["criteria"].splice(kindex, 1)
            }
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
          } else if (key === "ClaimStatus") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus")
          } else if (this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" && key === "property_StateId") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
          } else if (key === "newNameIssueCode") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
          } else if (key === "nameIssueCode") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
          } else if (key === "dataSource") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
          } else if (key === "selectedPropertyStatus") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
          } else if (key === "selectedPropertyReasons") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
          } else if (key === "Analyst") {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst")
          } else {
            index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
          }
          if (index !== -1) {
            filtercriteria["criteria"].splice(index, 1)
          }
          if (key === "selectedPropertyStatus") {
            let reasonIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
            if (reasonIndex !== -1) {
              filtercriteria["criteria"].splice(reasonIndex, 1)
            }
            let reasonPROPIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyStatusReason")
            if (reasonIndex !== -1) {
              filtercriteria["criteria"].splice(reasonPROPIndex, 1)
            }
          }
        }

        this.setGoButton()
      }

      await this.setState({
        isChangeHit: true,
        filterCriteria: filtercriteria
      })
    }
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {

    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.submit}
      />
    )
  }
  CompanyItems = async (event, criteria) => {

    let apiUrl
    let filterCriteria = this.state.filterCriteria
    filterCriteria["criteria"] = [{
      key: "CompanyName",
      value: event && event.query ? event.query : "",
      type: "sw",
      add_Filter: true
    }]
    // filterCriteria.globalSearch = { value: event && event.query ? event.query : "", "type": "user" }
    filterCriteria.sortfield = "CompanyName"
    filterCriteria.direction = "asc"
    if (event) {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`
    } else {
      apiUrl = `${apiCalls.companiesdropdown}?filter=${JSON.stringify(filterCriteria)}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["companies"] && response["companies"].length && response["companies"].length > 0) {
            dropdownData = response["companies"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)

          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownData;
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            // this.searchnameItems(criteria)
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event) {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing Report For Companies") {
        if (event.query) {
          apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
        } else if (typeof event === "string" && event.length > 0) {
          if (criteria && criteria.selectedSeller) {
            apiUrl = `${apiCalls.propertysellers}?dealID=${encodeURIComponent(event)}`
          } else {
            apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event)}`
          }
        } else {
          apiUrl = `${apiCalls.propertysellers}`
        }
      } else {
        filterCriteria["criteria"] = [{
          key: "EntityName",
          value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
          type: "regexOr"
        }]
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "EntityName")
        if (this.props.type === "Deal Sizing Report For Companies") {
          filterCriteria.sortfield = "EntityName"
          filterCriteria.page = "1"
        }
        if (filterCriteria["criteria"][index].value.length > 0) {
          apiUrl = `${apiCalls.Seller}?filter=${JSON.stringify(filterCriteria)}`
        } else {
          apiUrl = `${apiCalls.Seller}`
        }
      }
    } else {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && event) {
        apiUrl = `${apiCalls.propertysellers}?str = ${encodeURIComponent(event.query ? event.query : event)} `
      } else {
        apiUrl = `${apiCalls.Seller} `
      }
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })

            this.searchnameItems(criteria)
          }
          if (this.props.type === "ClaimsManagement") {
            this.searchClaimNamesItems()
          }

        }
      }).catch((err) => {
        return err;
      });
  }

  searchClaimNamesItems = async (event, criteria) => {

    let apiUrl

    // businessNames?searchCompany=435&str="erwin"  
    if (this.state.EstateID) {
      apiUrl = `${apiCalls.Claims}/businessNames?searchCompany=${this.state.EstateID}&str=${event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : ""} `
    }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let dropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              dropdownData = response["sellernames"]
            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)
            }
            if (dropdownData && dropdownData.length === 0) {
              this.setState({
                filteredclaimSuggestions: [],
                noData: true
              });
            } else {
              dropdownData = dropdownData;
            }
            await this.setState({
              filteredclaimSuggestions: dropdownData
            });
            if (criteria) {
              await this.setState({
                ClaimName: dropdownData[0]
              })
              this.props.getFileOptions(dropdownData)
              // this.searchnameItems(criteria)
            }
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  searchnameItems = async (event) => {
    let apiUrl
    if (this.state.selectedSeller && this.state.selectedSeller.EstateID && (this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "SearchTerms")) {
      apiUrl = `${apiCalls.propertynames}?searchCompany=${this.state.selectedSeller.EstateID} `
    } else if (this.state.selectedSeller && this.state.selectedSeller.DealID && (this.props.type === "Deal Sizing-Properties")) {
      apiUrl = `${apiCalls.dealSizingNames}?searchCompany=${this.state.selectedSeller.DealID} `
    }
    // }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let namedropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              namedropdownData = response["sellernames"]

            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)

            }
            if (namedropdownData && namedropdownData.length === 0) {
              this.setState({
                filterednameSuggestions: [],
                noData: true
              });
            } else {
              let modifiedLabels = []
              for (let name of namedropdownData) {
                if (name.companyName !== null || name.companyName.length > 0 || name.nameID !== null || name.nameID.length > 0) {
                  modifiedLabels.push({
                    label: name.companyName,
                    value: {
                      labelName: name.companyName,
                      id: name.nameID,
                    },
                  })
                }
              }
              namedropdownData = modifiedLabels;
            }
            if (this.state.taskProperties) {
              let data = this.state.taskProperties.PropertiesSellerName.Names // for (var name of this.state.taskProperties.PropertiesSellerName.Names) {
              var newArray = [];
              for (var item of data) {
                for (var name of namedropdownData) {
                  if (name.label === item) {
                    newArray.push(name)
                  }
                }
              }
              let ids = []
              for (var itemData of newArray) {
                ids.push(itemData.value)
              }
              this.handleChange(ids, "Name")
            }
            if (event && this.state.isSharing && this.state.sharedobj.nameId) {
              let selectedValues = []
              const arr = event.nameId.split(',');
              for (var itemArr of arr) {
                for (var named of namedropdownData) {
                  if (named.value && named.value.id === itemArr) {
                    selectedValues.push(named.value)
                  }
                }
              }
              this.handleChange(selectedValues, "Name")
            }
            this.props.getFileOptions(namedropdownData)
            await this.setState({
              filterednameSuggestions: namedropdownData
            });
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  getNegativeNoticeList = async (e) => {

    let tasksArray = [];
    let body = {
      label: this.props.type === "Site Confirmation Editor" ? "Site Confirmation" : "Seller Negative Notice",
      estateID: e
    }
    return fetch('POST', `tasks/ConnectedTasks`, body)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"]
          for (let reason of propertyReasons) {

            if (this.state.selectedTaskId) {
              if (reason.taskID === this.state.selectedTaskId) {
                await this.setState({
                  reason: reason
                })
              }
            }
            tasksArray.push({ label: `${reason.taskID} - ${dateFormats.formatDate(reason.created, "MM/DD/YYYY")}`, value: reason })
          }
          await this.setState({ tasksArray: tasksArray, selectedtask: this.state.reason })
          // await this.getTaskIDs(this.state.selectedTaskId)
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getTaskIDs = async (e, type) => {
    let filter = {}
    filter.criteria = [{ key: "taskID", value: e, type: "eq" }]
    return fetch('GET', `tasks?filter=${JSON.stringify(filter)}&ignoreUnassigned=true`)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"][0].connectedProperties
          propertyReasons = propertyReasons.join("\n");
          if (type) {
            await this.setState({
              excludeNotConfirmedOnSite: false,
            })
            this.props.openFormModal(response["tasks"][0], 'edit')
          }
          await this.setState({ selectedTaskId: e })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        } else {
          this.props.getErrorMessage("Something went wrong", "", "error")
        }
      }).catch((err) => {
        return err;
      })
  }
  handleTaskChange = async (e, type) => {
    if (type) {
      await this.setState({
        selectedtask: '',
        selectedTaskId: "",
        setGoButton: false
      })
      this.props.getErrorMessage("Please Select Notice List", "removingSeller", "error")

    } else {
      await this.setState({
        selectedtask: e,
        selectedTaskId: e.taskID,
        Property_Ids: e.connectedProperties,
        setGoButton: false
      })
      this.props.getErrorMessage("", "selectedSeller")

    }
  }
  editorTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <span style={{ float: 'right', margin: '.5em .25em 0 0', width: 11 }} data-toggle="tool-tip"
          title={option.value.tooltip}
        >{option.label}</span>
      );
    }
  }

  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : option && option.APADate ? new Date(option.APADate).getFullYear() : ""})`)
  }
  hideTable() {
    if (this.props.type === "Property Editor") {
      this.props.isShowTable(false)
    }
  }

  onTabOpen = async (e, n) => {
    await this.setState({
      isOpenAccordion: 0
    })
  }

  render() {
    const { handleSubmit, settingsData, IssueCodes, PropertySharebody } = this.props;
    const { modifiedStates, modifiedStatus, matchBuckets } = this.state
    let isEditted = localStorage.getItem("ISEdited") ? true : false

    return (
      // <Accordion multiple={true} activeIndex={[0]}>
      //   <AccordionTab header="Filters" >
      this.props.type === "Deal Sizing Report For Private Equity Portfolio" ?
        <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form m-2">
            <div className='row m-0 p-0 d-flex' style={{ width: "100%" }}>

              <div className={this.props.type === "Properties" ? "w-100" : "col-sm-9"}>
                <div className='d-flex'>


                  <div className="pl-0" style={{ width: "30%" }}>
                    <div className={"pb-2"} >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"PE Firm"}{<div style={{ width: "84%" }}>
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'

                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                        <span>
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2 clearIcon'
                            id={"clear_PE Firm"}
                            color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.handleChange('selectedSeller', "Delete")}
                            style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          /></span>
                      </div>}</label>
                      <div >
                        <AutoComplete
                          value={this.state.selectedSeller && this.state.selectedSeller.companyName ? this.state.selectedSeller.companyName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions ? this.state.filteredSuggestions : []}
                          completeMethod={this.CompanyItems}
                          minLength={1}
                          id="CompanyName"
                          field={"CompanyName"}
                          style={{ width: "100%" }}
                          dropdown={true}
                          onChange={(e) => this.handlePEFirmChange(e, 'pefirm')}
                          appendTo={document.body} />
                      </div>
                    </div>
                  </div>

                  {/* } */}



                  <div className='mx-2'>
                    <div >
                      {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                        <label style={{ color: "white", marginBottom: 4 }}>
                          Button
                        </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                        </label>}
                      <div className='justify-content-center'>
                        <Button color="primary"
                          outline
                          id={"submit"}
                          className="mb-0"
                          style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5', width: 115 } : (this.state.selectedSeller) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                          // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                          disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
                          onClick={(e) => this.submit()}>
                          Go
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>






            </div>
          </form >
          {this.state.SessionWarningModal ? this.getSessionWraningModal() : null}
          {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}

        </div >
        : this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" ? <div style={{ display: 'block', border: "2px solid lightgrey" }} >
          <form className="form m-2" >
            <div className='row m-0 p-0 d-flex' style={{ width: "100%" }}>

              <div className="col-sm-9">
                <div className='d-flex'>
                  <div className="pl-0" style={this.props.type === "ClaimsManagement" ? { width: "32%" } : { width: "20%" }}>
                    <div className={"pb-2"} >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{this.props.type === "Deal Sizing Report For Companies" ? "Company" : "Deal"}{<div style={{ width: "100%" }}>
                        <span >
                          <FontAwesomeIcon icon={faStarOfLife}
                            className='pl-1'
                            color='red'
                            data-toggle="tool-tip"
                            title="Mandotary Field"
                            style={{ width: 11 }}
                          /></span>
                        <span>
                          <FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2 clearIcon'
                            id={this.props.type === "Deal Sizing Report For Companies" ? "clear_company" : "clear_Deal"}
                            color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.handleChange('selectedSeller', "Delete")}
                            style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          /></span>
                      </div>}</label>
                      <div >
                        <AutoComplete
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                          id="Deal"
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                          style={{ width: "100%" }}
                          dropdown={true}
                          itemTemplate={this.editorTemplateForDeal}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />
                      </div>
                    </div>

                  </div>

                  <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "20%", marginLeft: 10 }}>
                    <div className=" pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>Source State{<FontAwesomeIcon icon={faTimes}
                        className='pl-1 mr-2 clearIcon'
                        color={this.state.selectedState && this.state.selectedState.length > 0 ? 'red' : 'lightgrey'}
                        data-toggle="tool-tip"
                        onClick={() => this.handleChange("selectedState", "Delete", 'property_StateId')}
                        title="Clear Filter"
                        style={this.state.selectedState && this.state.selectedState.length > 0 ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                      />}</label>
                      <div className=''>
                        <MultiSelect
                          id="State"
                          name={"State"}
                          filter={true}
                          value={this.state.selectedState}
                          style={{ width: "100%", height: 32 }}
                          appendTo={document.body}
                          disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                          maxSelectedLabels={1}
                          options={modifiedStates}
                          onChange={(e) => this.handleChange(e, "State")}

                        />
                      </div></div></div>
                  <div className='mx-2' style={{ display: "Block" }} data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                    <div className="pb-1">
                      <label style={{ marginBottom: 4, width: "100%" }}>{this.props.type === "Negative Notice List Viewer" ? "Notice List" : "Task"}{this.props.type === "Negative Notice List Viewer" ? <span >
                        <FontAwesomeIcon icon={faStarOfLife}
                          className='pl-1'
                          color='red'
                          data-toggle="tool-tip"
                          title="Mandotary Field"
                          style={{ width: 11 }}
                        /></span> : null}{<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2 clearIcon'
                          color={this.state.selectedtask ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          id="clear Notice List"
                          title="Clear Filter"
                          onClick={() => this.handleTaskChange('', 'clear')}
                          style={this.state.selectedtask ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />}</label>
                      <div className='f' data-toggle="tool-tip" >
                        <Dropdown
                          value={this.state.selectedtask}
                          name={"selectedGroup"}
                          id="Notice List"
                          style={{ width: "100%", height: 32 }}
                          disabled={this.state.selectedSeller ? false : true}
                          options={this.state.tasksArray}
                          onChange={(e) => this.handleTaskChange(e.value)}
                        />
                        <span>{this.state.isShownewNameIssueCodes ? "No options available" : null}</span>
                      </div>
                    </div>
                  </div>
                  <div className='mx-2'>
                    <div >
                      {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                        <label style={{ color: "white", marginBottom: 4 }}>
                          Button
                        </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                        </label>}
                      <div className='justify-content-center'>
                        <Button color="primary"
                          outline
                          id="Go"
                          className="mb-0"
                          style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5', width: 115 } : (this.state.selectedSeller || (this.props.type === "ClaimsManagement" && (this.state.selectedState || this.state.OpraClaimId || this.state.OpraStId))) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                          disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
                          onClick={(e) => this.submit()}>
                          Go
                        </Button>
                      </div>
                    </div>

                  </div>
                  {this.props.type === "Negative Notice List Viewer" ? <div className='mx-2'>
                    <div >
                      {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                        <label style={{ color: "white", marginBottom: 4 }}>
                          Button
                        </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                        </label>}
                      <div className='justify-content-center'>
                        <span className="btn btn-outline-primary px-0" style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" onClick={() => this.props.confirmtheredirection()} href={PropertySharebody}> Open List in Property Editor</span>
                      </div>

                    </div>

                  </div> : null}
                  <div className='mx-2'>
                    <div >
                      {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                        <label style={{ color: "white", marginBottom: 4 }}>
                          Button
                        </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                        </label>}

                      <div className='justify-content-center' style={this.props.screenPermissions === "Edit" ? { display: "block" } : { display: "none" }}>
                        <a id="open Task" className={this.state.selectedTaskId ? "btn btn-outline-primary px-0" : "btn btn-outline-primary disabled px-0"} style={this.state.selectedTaskId && this.props.screenPermissions ? { color: '#0e4768', cursor: 'pointer' } : { pointerEvents: "none", color: "grey" }} target="_blank" onClick={() => this.getTaskIDs(this.state.selectedTaskId, 'openTask')} > Open Task</a>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <div className='col-sm-3 d-flex'>
                <div className='mx-2' style={this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { display: "none" } : { display: "block" }}>
                  <div className="pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>OPRA Property ID{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2 clearIcon'
                      color={this.state.Property_Id ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      title="Clear Filter"
                      id="Clear Property_Id"
                      onClick={() => this.handleChange("Property_Id", "Delete", 'matchMatchStatusId')}
                      style={this.state.Property_Id ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className='custom_text'>
                      <textarea
                        id="Property_Id"
                        // type="search"
                        name='Property_Id'
                        // id='estateIdsValue'
                        value={this.state.Property_Id}
                        onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}

                        // onChange={(e) => this.handleChange(e.target.value, "Property_Id")}
                        placeholder={'Opra Property Id'}
                        // size="10"
                        // height="100px"
                        rows="8"
                      />
                    </div>
                  </div>
                  <div className="pb-1"
                    style={this.props.missingOPRAPropertyIds && this.props.missingOPRAPropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
                  >
                    <label style={{ marginBottom: 4, width: "100%" }}>Missing Property IDs</label>
                    <div className='d-flex custom_text'>
                      <textarea
                        // type="search"
                        name='Property_Id'
                        id="Missing Property_Id"
                        // id='estateIdsValue'
                        value={this.props.missingOPRAPropertyIds}
                        onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}
                        disabled={true} style={{ backgroundColor: "white" }}
                        // onChange={(e) => this.handleChange(e.target.value, "Property_Id")}
                        placeholder={'Opra Property Id'}
                        // size="10"
                        // height="100px"
                        rows="8"
                      />
                      <FontAwesomeIcon icon={faCopy}
                        className='pl-1 mr-2'
                        style={{ width: 20 }}
                        color={'red'}
                        data-toggle="tool-tip"
                        title="Copy"
                        onClick={() => this.copyToClipboard(this.props.missingOPRAPropertyIds)}
                      />
                    </div>
                  </div>
                  {
                    this.props.type === "Property Editor" ?
                      <div className='mx-2' style={{ width: "100%", display: "Block", marginTop: "14px" }} >
                        <div style={{ paddingBottom: "4px" }}>
                          {/* <span style={{ color: "white" }}>Group By</span> */}
                          <Button color="primary"
                            outline
                            id="column order"
                            // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
                            style={{ cursor: "pointer", height: "34px", marginBottom: "0px" }}
                            disabled={this.props.isDisabledTab || isEditted}
                            onClick={this.props.openShowHideColumnsModal}
                          >
                            Column Order2
                          </Button>
                        </div>
                      </div>
                      : null

                  }
                </div>
                <div className='mx-2' style={this.props.type === "Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { display: "none" } : {}}>
                  <div className="pb-1">
                    <label style={{ marginBottom: 4, width: "100%" }}>State Property Id{<FontAwesomeIcon icon={faTimes}
                      className='pl-1 mr-2 clearIcon'
                      color={this.state.OpraStId ? 'red' : 'lightgrey'}
                      data-toggle="tool-tip"
                      title="Clear Filter"
                      onClick={() => this.handleChange("OpraStId", "Delete", 'matchMatchStatusId')}
                      style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                    />}</label>
                    <div className='custom_text'>
                      <textarea
                        // type="search"
                        name='OpraStId'
                        // id='estateIdsValue'
                        value={this.state.OpraStId}
                        onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                        placeholder={'State Property Id'}
                        // size="10"
                        // height="100px"
                        rows="5"
                      />
                    </div>
                  </div>

                  <div className="pb-1"
                    style={this.props.missingStatePropertyIds && this.props.missingStatePropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
                  >
                    <label style={{ marginBottom: 4, width: "100%" }}> Missing State Property Id</label>
                    <div className='d-flex custom_text'>
                      <textarea
                        // type="search"
                        name='OpraStId'
                        // id='estateIdsValue'
                        value={this.props.missingStatePropertyIds}
                        disabled={true} style={{ backgroundColor: "white" }}

                        onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                        placeholder={'State Property Id'}
                        // size="10"
                        // height="100px"
                        rows="8"
                      />
                      <FontAwesomeIcon icon={faCopy}
                        className='pl-1 mr-2'
                        color={'red'}
                        data-toggle="tool-tip"
                        style={{ width: 20 }}
                        title="Copy"
                        onClick={() => this.copyToClipboard(this.props.missingStatePropertyIds)}
                      />
                    </div>
                  </div>
                </div>


              </div>


            </div >
            <Row className={this.state.isredirtRanges ? "ml-3 d-flex" : "d-none"} >
              {this.state.isredirtRanges && !this.state.groupMessage ? <><b><h5 htmlFor="cb3 col-sm-4 mr-4 " className="p-checkbox-label my-2" >{this.state.isredirtRanges ? "Exclusions :" : null}</h5></b><span className="my-2 ml-2">
                {configMessages.ExclusionMessage}
              </span></> :
                <div>
                  {this.state.groupMessage && this.state.groupMessage.length > 0 ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                    <h4 className='ml-2'><b> {this.state.Group}:</b>
                      {this.state.groupMessage}</h4></div> : null}
                  {this.state.fiftyk || this.state.unknown || this.state.Count10kTo50k ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                    <h4 className='ml-2'>
                      {this.state.fiftyk ? this.state.fiftyk : this.state.unknown ? this.state.unknown : this.state.Count10kTo50k ? this.state.Count10kTo50k : ""}</h4></div>
                    : null}
                </div>
              }
            </Row>
          </form >
          {this.state.SessionWarningModal ? this.getSessionWraningModal() : null}
          {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}

        </div >
          :
          <Accordion className="pb-0" activeIndex={this.state.isOpenAccordion !== null ? 0 : null} onTabOpen={this.onTabOpen} onTabClose={this.onTabOpen}>
            <AccordionTab header="Filters" >
              <div style={{ display: 'block' }} >
                <form className="form m-2" >
                  <div className='row m-0' style={{ width: "100%" }}>
                    <div className={this.props.type === "Properties" || this.props.type === "ClaimsManagement" ? "w-100" : "col-sm-9"}>
                      <div className='d-flex'>
                        <div className="pl-0" style={this.props.type === "ClaimsManagement" ? { width: "32%" } : { width: "30%" }}>
                          <div className={"pb-2"} >
                            <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                              <span>{this.props.type === "Deal Sizing Report For Companies" ? "Company" : "Deal"}
                                <span >
                                  <FontAwesomeIcon icon={faStarOfLife}
                                    className='pl-1'
                                    color='red'
                                    data-toggle="tool-tip"
                                    title="Mandotary Field"
                                    style={{ width: 11 }}
                                  />
                                </span>
                              </span>
                              <span className='ml-auto d-flex'>
                                {this.props.lastScoredDate !== "" ?
                                  <div><b><span>Last Scored Date   </span></b>{this.props.lastScoredDate}</div>
                                  : null}
                                <FontAwesomeIcon icon={faTimes}
                                  className='pl-1 mr-2 clearIcon'
                                  color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                                  data-toggle="tool-tip"
                                  id="clear company"
                                  title="Clear Filter"
                                  onClick={() => this.handleChange('selectedSeller', "Delete")}
                                  style={this.state.selectedSeller ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                                /></span>
                            </label>
                            <div >
                              <AutoComplete
                                value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} -${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` :
                                  this.state.selectedSeller && this.state.selectedSeller.SellerNickName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller.APADate ? new Date(this.state.selectedSeller.APADate).getFullYear() : ""})`
                                    : this.state.selectedSeller}
                                suggestions={this.state.filteredSuggestions}
                                completeMethod={this.searchsellerItems}
                                onBlur={() => this.hideTable()}
                                minLength={1}
                                id="company"
                                itemTemplate={this.editorTemplateForDeal}
                                field={this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies" ? "EntityName" : "EstateName"}
                                style={{ width: "100%" }}
                                dropdown={true}
                                onChange={(e) => this.handleChange(e, 'Seller')}
                                appendTo={document.body} />
                            </div>
                          </div>

                        </div>

                        {/* } */}

                        <div className="pl-0" style={this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? { width: "32%" } : { display: "none" }}>
                          <div className={"pb-2"} >
                            <label style={{ marginBottom: 4, width: "100%" }}>Name{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.ClaimName ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              id="clear Name"
                              onClick={() => this.handleChange("ClaimName", "Delete")}
                              style={this.state.ClaimName ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div >
                              <AutoComplete
                                value={this.state.ClaimName && this.state.ClaimName.companyName ? this.state.ClaimName.companyName : this.state.ClaimName}
                                suggestions={this.state.filteredclaimSuggestions}
                                completeMethod={this.searchClaimNamesItems}
                                minLength={1}
                                id="clear Name"
                                field={this.props.type === "Claims" || this.props.type === "ClaimsManagement" ? "companyName" : ""}
                                style={{ width: "100%" }}
                                dropdown={true}
                                disabled={this.state.selectedSeller ? false : true}
                                onChange={(e) => this.handleChange(e, 'ClaimName')}
                                appendTo={document.body} />
                            </div>
                          </div>
                        </div>
                        <div style={this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "25%" } : this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%" }}>
                          <div>
                            <label style={{ marginBottom: 4, width: "100%" }}>Business Name{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.selectedName ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear bussiness_Name"
                              title="Clear Filter"
                              onClick={() => this.handleChange("selectedName", "Delete")}
                              style={this.state.selectedName ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div >
                              <MultiSelect
                                className={"form__form-group-field "}
                                style={{ width: "100%", height: 32 }}
                                filter={true}
                                id="bussiness Name"
                                name={"Name"}
                                options={this.state.filterednameSuggestions}
                                value={this.state.selectedName && this.state.selectedName.CompanyName ? this.state.selectedName.CompanyName : this.state.selectedName}
                                onChange={(e) => this.handleChange(e, "Name")}
                                maxSelectedLabels={2}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='mx-2' style={this.props.type === "Properties" ? { width: "20%", display: "Block" } : { width: "18%", display: "none" }}>
                          <div className="pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>Match Status{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.selectedStatus ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              id="clear Match_Status"
                              onClick={() => this.handleChange("selectedStatus", "Delete", 'matchMatchStatusId')}
                              style={this.state.selectedStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className='f'>
                              <MultiSelect
                                value={this.state.selectedStatus}
                                name={"Match_Status"}
                                id="Match_Status"
                                filter={true}
                                style={{ width: "100%", height: 32 }}
                                appendTo={document.body}
                                disabled={this.state.selectedSeller ? false : true}
                                maxSelectedLabels={1}
                                options={modifiedStatus}
                                onChange={(e) => this.handleChange(e, "Status")}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='mx-2' style={this.props.type === "Properties" ? { width: "20%", display: "Block" } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "19%" } : { display: "none" }}>
                          {this.props.type === "Properties" ? <div className=" pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>Match bucket{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.selectedBucket ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              id="clear bucket"
                              onClick={() => this.handleChange("selectedBucket", "Delete", 'masterbucket')}
                              style={this.state.selectedBucket ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className=''>

                              <Dropdown className='State'
                                type={'text'}
                                id="selectedBucket"
                                // filter={modifiedStates.length > 6 ? true : false}
                                value={this.state.selectedBucket}
                                placeholder={""}
                                name="selectedBucket"
                                style={{
                                  width: "100%",
                                  height: 32
                                }}
                                disabled={this.state.selectedSeller ? false : true}
                                options={matchBuckets}
                                onChange={(e) => this.handleChange(e, "MatchBucket")}
                              />

                            </div>
                          </div> :
                            <>
                              <label style={{ marginBottom: 4, width: "100%" }}>Property Status{<FontAwesomeIcon icon={faTimes}
                                className='pl-1 mr-2'
                                color={this.state.selectedPropertyStatus ? 'red' : 'lightgrey'}
                                data-toggle="tool-tip"
                                id="clear Property Status"
                                onClick={() => this.handleChange("selectedPropertyStatus", "Delete", 'selectedPropertyStatus')}
                                title="Clear Filter"
                                style={this.state.selectedPropertyStatus ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                              />}</label>
                              <span className=''>
                                <MultiSelect
                                  name={"State"}
                                  value={this.state.selectedPropertyStatus}
                                  style={{ width: "100%", height: 32 }}
                                  appendTo={document.body}
                                  filter={true}
                                  id="Property Status"
                                  itemTemplate={this.editorTemplate}
                                  disabled={this.state.selectedSeller ? false : true}
                                  maxSelectedLabels={1}
                                  options={this.state.propertyStatusArray}
                                  onChange={(e) => this.handleChange(e, "selectedPropertyStatus")}

                                />
                              </span>
                              {this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? <div className='' >

                                <>
                                  <label >Property Reason{<FontAwesomeIcon icon={faTimes}
                                    className='pl-1 mr-2 clearIcon'
                                    color={this.state.selectedPropertyReasons ? 'red' : 'lightgrey'}
                                    data-toggle="tool-tip"
                                    id="clear Property Reason"
                                    onClick={() => this.handleChange("selectedPropertyReasons", "Delete", 'selectedPropertyReasons')}
                                    title="Clear Filter"
                                    style={this.state.selectedPropertyReasons ? { width: 25, cursor: "pointer", marginLeft: "64px", pointerEvents: "visible" } : { width: 25, cursor: "pointer", marginLeft: "64px", pointerEvents: "none" }}
                                  />}</label>
                                  <span className=''>
                                    <MultiSelect
                                      name={"State"}
                                      value={this.state.selectedPropertyReasons}
                                      style={{ width: "100%", height: 32 }}
                                      appendTo={document.body}
                                      filter={true}
                                      id="Property Reason"
                                      disabled={this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? false : true}
                                      maxSelectedLabels={1}
                                      options={this.state.shownreasons && this.state.shownreasons.length > 0 ? this.state.shownreasons : []}
                                      onChange={(e) => this.handleChange(e, "selectedPropertyReasons")}

                                    />

                                  </span> </>
                              </div> : null}</>
                          }
                        </div>
                        <div style={this.props.type === "ClaimsManagement" ? { width: "18%" } : this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ? { width: "18%", marginLeft: 30 } : this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { width: "13%", marginLeft: 3 } : this.props.type === "Deal Sizing Report For Companies" ? { display: "none" } : { width: "30%", marginLeft: 10 }}>
                          <div className=" pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>Source State{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.selectedState ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear State"
                              onClick={() => this.handleChange("selectedState", "Delete", 'property_StateId')}
                              title="Clear Filter"
                              style={this.state.selectedState ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className=''>
                              <MultiSelect
                                name={"State"}
                                id="State"
                                filter={true}
                                value={this.state.selectedState}
                                style={{ width: "100%", height: 32 }}
                                appendTo={document.body}
                                disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                                maxSelectedLabels={1}
                                options={modifiedStates}
                                onChange={(e) => this.handleChange(e, "State")}

                              />
                            </div></div></div>
                        <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                          <div className="pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>Property Id{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.OpraStId ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear State"
                              title="Clear Filter"
                              onClick={() => this.handleChange("OpraStId", "Delete", 'OpraStId')}
                              style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className='f'>
                              <textarea
                                // type="search"
                                name='OpraStId'
                                // id='estateIdsValue'
                                value={this.state.OpraStId}
                                onChange={(e) => this.handleChange(e.target.value, "OpraStId")}
                                placeholder={'Property Id'}
                                // size="10"
                                // height="100px"
                                rows="8"
                              />
                            </div>
                          </div>

                        </div>
                        <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                          <div className="pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>State Claim Id{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.stClaimId ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              onClick={() => this.handleChange("stClaimId", "Delete", 'stClaimId')}
                              style={this.state.stClaimId ? { width: 75, cursor: "pointer", float: "right", pointerEvents: "visible" } : this.props.type === "ClaimsManagement" ? { width: 14, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 75, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className='f'>
                              <textarea
                                // type="search"
                                name='stClaimId'
                                // id='estateIdsValue'
                                value={this.state.stClaimId}
                                onChange={(e) => this.handleChange(e.target.value, "stClaimId")}
                                placeholder={'State Claim Id'}
                                // size="10"
                                // height="100px"
                                rows="8"
                              />
                            </div>
                          </div>

                        </div>
                        <div className='mx-2' style={this.props.type === "ClaimsManagement" ? { display: "flex", width: '275px' } : { display: "none" }}>
                          <div className="pb-1">
                            <label style={{ marginBottom: 4, width: "100%" }}>Opp Claim Id{<FontAwesomeIcon icon={faTimes}
                              className='pl-1 mr-2 clearIcon'
                              color={this.state.OpraClaimId ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              title="Clear Filter"
                              onClick={() => this.handleChange("OpraClaimId", "Delete", 'OppClaimID')}
                              style={this.state.OpraClaimId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                            />}</label>
                            <div className='f'>
                              <textarea
                                // type="search"
                                name='OpraClaimId'
                                // id='estateIdsValue'
                                value={this.state.OpraClaimId}
                                onChange={(e) => this.handleChange(e, "OpraClaimId")}
                                placeholder={'Opp Claim Id'}
                                // size="10"
                                // height="100px"
                                rows="8"
                              />
                            </div>
                          </div>

                        </div>
                        {this.props.type === "ClaimsManagement" ?
                          <div className="col-sm-1 pl-0">
                            <label style={{ marginBottom: 4, width: "100%" }}>Analyst
                              {<FontAwesomeIcon icon={faTimes}
                                className='pl-1 mr-2 clearIcon'
                                color={this.state.selectedAnalyst ? 'red' : 'lightgrey'}
                                data-toggle="tool-tip"
                                onClick={() => this.handleChange("selectedAnalyst", "Delete", 'Analyst')}
                                title="Clear Filter"
                                style={this.state.selectedAnalyst ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                              />}
                            </label>
                            <div className=''>
                              <MultiSelect
                                name={"Analyst"}
                                filter={true}
                                value={this.state.selectedAnalyst}
                                style={{ width: "100%", height: 32 }}
                                appendTo={document.body}
                                // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                                maxSelectedLabels={1}
                                options={this.state.userArray}
                                onChange={(e) => this.handleChange(e, "Analyst")}

                              />
                            </div></div> : null}

                        <div className='mx-2'>
                          <div >
                            {this.props.type === "Properties" || this.props.type === "Deal Sizing-Properties" ?
                              <label style={{ color: "white", marginBottom: 4 }}>
                                Button
                              </label> : <label style={{ marginLeft: "px", marginBottom: 4 }}>

                              </label>}
                            <div className='justify-content-center'>
                              <Button color="primary"
                                outline
                                className="mb-0"
                                style={this.state.setGoButton ? { color: "white", backgroundColor: '#a0a2a5', width: 115 } : (this.state.selectedSeller || (this.props.type === "ClaimsManagement" && (this.state.selectedState || this.state.OpraClaimId || this.state.OpraStId))) ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
                                // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                                disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
                                onClick={(e) => this.submit()}>
                                Go
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Row className='mt-3 ml-0'>
                        {/* <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                  <label htmlFor="cb3 col-sm-3" className="p-checkbox-label" style={{ marginRight: "50px", marginLeft: "16px" }}>Excl. OPRA2 Validated</label>
                  <Checkbox
                    label='Unassigned Only'
                    type='checkbox'

                    // component={renderCheckBoxField}
                    name={'validated'}
                    className=" ml-3"
                    checked={this.state.isValidate}
                    onChange={(e) => this.handleChange(e, "Validate")}
                  />
                </div> */}

                        {/* <div style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "grid" } : { display: "none" }}>
                        <label htmlFor="cb3 col-sm-3" className="p-checkbox-label ml-2" style={{ marginRight: "50px" }}>Excl. OPRA Validated Prop.</label>
                        <Checkbox
                          label='Unassigned Only'
                          type='checkbox'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          className=" ml-2"
                          checked={this.state.isexcludeValidated}
                          onChange={(e) => this.handleChange(e, "isexcludeValidated")}
                        />
                      </div> */}
                        {/* <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "15%", height: "60px" } : { display: "none" }}>
                        <label htmlFor="cb3" className="p-checkbox-label" style={{ marginRight: "3%" }}>{"Property Value >="}{<FontAwesomeIcon icon={faTimes}
                          className='pl-1 mr-2'
                          color={this.state.isexcludeProperties ? 'red' : 'lightgrey'}
                          data-toggle="tool-tip"
                          onClick={() => this.setState({ isexcludeProperties: "", setGoButton: false })}
                          title="Clear Filter"
                          style={this.state.isexcludeProperties ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                        />}</label>
                        <InputText
                          label='Unassigned Only'
                          type='text'
                          // component={renderCheckBoxField}
                          name={'validated'}
                          // className=" ml-2"
                          value={this.state.isexcludeProperties}
                          onChange={(e) => this.handleChange(e.target.value, "isexcludeProperties")}
                        />
                      </div> */}
                        <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "9%", height: "60px" } : { display: "none" }}>
                          <label htmlFor="cb3" style={{ marginRight: "3%" }} className="p-checkbox-label"> Excl. Co Owners</label>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'validated'}
                            className=" ml-2"
                            checked={this.state.excludeCoOwners}
                            onChange={(e) => this.handleChange(e, "excludeCoOwners")}
                          />
                        </div>
                        <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "14%", height: "60px" } : { display: "none" }}>
                          <label htmlFor="cb3 " style={{ marginRight: "3%" }} className="p-checkbox-label">Excl. Not Confirmed Onsite</label>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'validated'}
                            className=" ml-2"
                            checked={this.state.excludeNotConfirmedOnSite}
                            onChange={(e) => this.handleChange(e, "excludeNotConfirmedOnSite")}
                          />
                        </div>
                        <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "10%", height: "60px" } : { display: "none" }}>
                          <label htmlFor="cb3 " className="p-checkbox-label ml-2" style={{ marginRight: "3%" }}>{"Excl.Possibly Paid"}</label>
                          <Checkbox
                            label='isexcludePossiblyPaid'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'isexcludePossiblyPaid'}
                            className=" ml-2"
                            checked={this.state.isexcludePossiblyPaid}
                            onChange={(e) => this.handleChange(e, "isexcludePossiblyPaid")}
                          />
                        </div>
                        <div style={this.props.type === "Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" ? { display: "grid", width: "10%", height: "60px" } : { display: "none" }}>
                          <label htmlFor="cb3" className="p-checkbox-label ml-2" style={{ marginRight: "3%" }}>{"Excl. Royalties"}</label>
                          <Checkbox
                            label='isexcludeRoyalties'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'isexcludeRoyalties'}
                            className=" ml-2"
                            checked={this.state.isexcludeRoyalties}
                            onChange={(e) => this.handleChange(e, "isexcludeRoyalties")}
                          />
                        </div>
                        {this.props.type === "Property Editor" ?
                          <div className='mx-2' style={{ width: "12%", display: "Block" }}>
                            <div className="pb-1">
                              <label style={{ marginBottom: 4, width: "100%" }}>Legacy Name Issue{<FontAwesomeIcon icon={faTimes}
                                className='pl-1 mr-2 clearIcon'
                                color={'red'}
                                data-toggle="tool-tip"
                                title="Clear Filter"
                                onClick={() => this.handleChange("nameIssueCode", "Delete", 'nameIssueCode')}
                                style={{ width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" }}
                              />}</label>
                              <div className='f' data-toggle="tool-tip" title={IssueCodes.nameIssueCodes ? "" : "No options available"}>
                                <MultiSelect
                                  value={this.state.nameIssueCode ? this.state.nameIssueCode : this.props.IssueCodes ? this.props.IssueCodes.defaultIssueNames : []}
                                  name={"Match_Status"}
                                  filter={true}
                                  style={{ width: "100%", height: 32 }}
                                  appendTo={document.body}
                                  disabled={this.state.selectedSeller ? false : true}
                                  maxSelectedLabels={1}
                                  onFocus={() => this.showRecordsCount("show", "nameIssueCodes")}
                                  onBlur={() => this.showRecordsCount("hide", "nameIssueCodes")}
                                  options={IssueCodes ? IssueCodes.nameIssueCodes : this.state.availableToMapLegacyNameIssueCode ? this.state.availableToMapLegacyNameIssueCode : []}
                                  onChange={(e) => this.handleChange(e, "nameIssueCode")}
                                />
                                <span>{this.state.isShownameIssueCodes ? "No options available" : null}</span>
                              </div>
                            </div>


                          </div> : null}
                        {this.props.type === "Property Editor" ?
                          <div className='mx-2' style={{ width: "12%", display: "Block" }} data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                            <div className="pb-1">
                              <label style={{ marginBottom: 4, width: "100%" }}>New Name Issue{<FontAwesomeIcon icon={faTimes}
                                className='pl-1 mr-2 clearIcon'
                                color={this.state.newNameIssueCode ? 'red' : 'lightgrey'}
                                data-toggle="tool-tip"
                                title="Clear Filter"
                                onClick={() => this.handleChange("newNameIssueCode", "Delete", 'newNameIssueCode')}
                                style={this.state.newNameIssueCode ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                              />}</label>
                              <div className='f' data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                                <MultiSelect
                                  value={this.state.newNameIssueCode}
                                  name={"Match_Status"}
                                  id="Match_Status"
                                  filter={true}
                                  style={{ width: "100%", height: 32 }}
                                  appendTo={document.body}
                                  disabled={this.state.selectedSeller ? false : true}
                                  maxSelectedLabels={1}
                                  onFocus={() => this.showRecordsCount("show", "newNameIssueCodes")}
                                  onBlur={() => this.showRecordsCount("hide", "newNameIssueCodes")}
                                  options={IssueCodes ? IssueCodes.newNameIssueCodes : this.state.availableToMapNameIssueCode ? this.state.availableToMapNameIssueCode : []}
                                  onChange={(e) => this.handleChange(e, "newNameIssueCode")}
                                />
                                <span>{this.state.isShownewNameIssueCodes ? "No options available" : null}</span>
                              </div>
                            </div>
                          </div> : null}
                        {this.props.type === "Property Editor" ?
                          <div className='mx-2' style={{ width: "12%", display: "Block" }} data-toggle="tool-tip" title={IssueCodes.newNameIssueCodes ? "" : "No options available"}>
                            <div className="pb-1">
                              <label style={{ marginBottom: 4, width: "100%" }}>Group{<FontAwesomeIcon icon={faTimes}
                                className='pl-1 mr-2 clearIcon'
                                color={this.state.selectedGroup ? 'red' : 'lightgrey'}
                                data-toggle="tool-tip"
                                title="Clear Filter"
                                id="Clear Group"
                                onClick={() => this.handleChangeSelectedGroup("selectedGroup", "Delete", 'selectedGroup')}
                                style={this.state.selectedGroup ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                              />}</label>
                              <div className='f' data-toggle="tool-tip" >
                                <Dropdown
                                  value={this.state.selectedGroup}
                                  name={"selectedGroup"}
                                  id="Group"
                                  itemTemplate={this.groupTemplate}
                                  filter={true}
                                  style={{ width: "100%", height: 32 }}
                                  disabled={this.state.selectedSeller ? false : true}
                                  // onFocus={() => this.showRecordsCount("show", "newNameIssueCodes")}
                                  // onBlur={() => this.showRecordsCount("hide", "newNameIssueCodes")}
                                  options={this.props.tabDescriptionArray}
                                  onChange={(e) => this.handleChangeSelectedGroup(e, "selectedGroup")}
                                />
                                <span>{this.state.isShownewNameIssueCodes ? "No options available" : null}</span>
                              </div>
                            </div>
                          </div> : null}
                        {
                          this.props.type === "Property Editor" ?
                            <div className='mx-2' style={{ display: "Block" }} >
                              <div style={{ paddingBottom: "4px" }}>
                                <p style={{ color: "black" }}>View By</p>
                                <Dropdown
                                  value={this.state.selectedViewType}
                                  // appendTo={document.body}
                                  style={{ borderLeft: "1px solid lightgray", height: "34px" }}
                                  options={config.viewTypes}
                                  disabled={this.props.allUsersData.length === 0 || this.props.isDisabledTab ? true : false}
                                  onChange={(e) => this.selectedViewType(e.target.value)}
                                />
                              </div>
                            </div>

                            : null

                        }
                      </Row>
                    </div>
                    {
                      this.props.type === "Deal Sizing Report For Companies" ?

                        <div className='col-sm-3'>
                          <div className='d-flex'>
                            <div className='col-sm-5 pl-0' style={{ fontWeight: 'bold' }}>Last Update Date</div>
                            {":"}
                            <div className='col-sm-4' >{this.state.hidePropValues ? "" : this.state.lastUpdatedDate}</div>
                          </div>

                        </div>

                        : null
                    }


                    <div className='col-sm-3 px-0'>
                      <div className='row mx-0'>
                        <div className="col-sm-6">
                          <label style={{ marginBottom: 4, width: "100%" }}>OPRA Property ID{<FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2 clearIcon'
                            color={this.state.Property_Id ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            onClick={() => this.handleChange("Property_Id", "Delete", 'matchMatchStatusId')}
                            style={this.state.Property_Id ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          />}</label>
                          <div className='custom_text'>
                            <textarea
                              // type="search"
                              name='Property_Id'
                              // id='estateIdsValue'
                              value={this.state.Property_Id}
                              onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}

                              // onChange={(e) => this.handleChange(e.target.value, "Property_Id")}
                              placeholder={'Opra Property Id'}
                              // size="10"
                              // height="100px"
                              rows="8"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <label style={{ marginBottom: 4, width: "100%" }}>State Property Id{<FontAwesomeIcon icon={faTimes}
                            className='pl-1 mr-2 clearIcon'
                            color={this.state.OpraStId ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            title="Clear Filter"
                            id='Clear OpraStId'
                            onClick={() => this.handleChange("OpraStId", "Delete", 'matchMatchStatusId')}
                            style={this.state.OpraStId ? { width: 25, cursor: "pointer", float: "right", pointerEvents: "visible" } : { width: 25, cursor: "pointer", float: "right", pointerEvents: "none" }}
                          />}</label>
                          <div className='custom_text'>
                            <textarea
                              // type="search"
                              name='OpraStId'
                              id='OpraStId'
                              value={this.state.OpraStId}
                              onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                              placeholder={'State Property Id'}
                              // size="10"
                              // height="100px"
                              rows="8"
                            />
                          </div>
                        </div>
                      </div>
                      {this.props.missingOPRAPropertyIds && this.props.missingOPRAPropertyIds.length > 0 || this.props.missingStatePropertyIds && this.props.missingStatePropertyIds.length > 0 ?
                        <div className='row mx-0 mb-2'>
                          <div className="col-sm-6"
                            style={this.props.missingOPRAPropertyIds && this.props.missingOPRAPropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
                          >
                            <label style={{ marginBottom: 4, width: "100%" }}>Missing Property IDs</label>
                            <div className='d-flex custom_text'>
                              <textarea
                                // type="search"
                                name='Property_Id'
                                // id='estateIdsValue'
                                value={this.props.missingOPRAPropertyIds}
                                onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}
                                disabled={true} style={{ backgroundColor: "white" }}
                                // onChange={(e) => this.handleChange(e.target.value, "Property_Id")}
                                placeholder={'Opra Property Id'}
                                // size="10"
                                // height="100px"
                                rows="8"
                              />
                              <FontAwesomeIcon icon={faCopy}
                                className='pl-1 mr-2'
                                style={{ width: 20 }}
                                color={'red'}
                                id="copy missing"
                                data-toggle="tool-tip"
                                title="Copy"
                                onClick={() => this.copyToClipboard(this.props.missingOPRAPropertyIds)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6"
                            style={this.props.missingStatePropertyIds && this.props.missingStatePropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
                          >
                            <label style={{ marginBottom: 4, width: "100%" }}> Missing State Property Id</label>
                            <div className='d-flex custom_text'>
                              <textarea
                                // type="search"
                                name='missingStatePropertyIds'
                                id='missingStatePropertyIds'
                                value={this.props.missingStatePropertyIds}
                                disabled={true} style={{ backgroundColor: "white" }}

                                onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                                placeholder={'State Property Id'}
                                // size="10"
                                // height="100px"
                                rows="8"
                              />
                              <FontAwesomeIcon icon={faCopy}
                                className='pl-1 mr-2'
                                color={'red'}
                                data-toggle="tool-tip"
                                style={{ width: 20 }}
                                title="Copy"
                                onClick={() => this.copyToClipboard(this.props.missingStatePropertyIds)}
                              />
                            </div>
                          </div>
                        </div>
                        : null}
                      <label className="text-white mb-0" style={{ width: "100%" }}>text</label >
                      <span style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "flex" } : { display: "none" }}>
                        <div className='row mx-0 col-sm-12 pr-0 dataSourcemain' style={this.props.lastScoredDate !== "" ? { marginTop: 23 } : {}}>
                          <div className='col-sm-9 dataSourceStyle' style={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor" ? { display: "flex" } : { display: "none" }}>
                            <label htmlFor="cb3 " className="p-checkbox-label ml-0" >{"Data Sources:"}</label>
                            <Checkbox
                              label='isexcludePossiblyPaid'
                              type='checkbox'
                              disabled={this.state.selectedSeller ? false : true}
                              // component={renderCheckBoxField}
                              name={'isexcludePossiblyPaid'}
                              className=" ml-2"
                              checked={this.state.stateUP}
                              onChange={(e) => this.setState(prevState => ({
                                stateUP: !prevState.stateUP
                              }))}
                            />
                            <label htmlFor="cb3 " className="p-checkbox-label ml-2" >{"State UP"}</label>
                            <Checkbox
                              label='isexcludePossiblyPaid'
                              disabled={this.state.selectedSeller ? false : true}
                              type='checkbox'
                              // component={renderCheckBoxField}
                              name={'isexcludePossiblyPaid'}
                              className=" ml-2"
                              checked={this.state.sec}
                              onChange={(e) => this.setState(prevState => ({
                                sec: !prevState.sec
                              }))}
                            />
                            <label htmlFor="cb3 " className="p-checkbox-label ml-2" >{"SEC"}</label>

                            <Checkbox
                              label='isexcludePossiblyPaid'
                              type='checkbox'
                              disabled={this.state.selectedSeller ? false : true}
                              // component={renderCheckBoxField}
                              name={'isexcludePossiblyPaid'}
                              className=" ml-2"
                              checked={this.state.isCA_N}
                              onChange={(e) => this.setState(prevState => ({
                                isCA_N: !prevState.isCA_N
                              }))}
                            />
                            <label htmlFor="cb3 " className="p-checkbox-label mx-2 " >{"CA-N"}</label>


                          </div>
                          <div className='col-sm-3 pl-0 dataSource_multiDiv d-flex'>
                            <MultiSelect
                              value={this.state.dataSource}
                              name={"dataSource"}
                              filter={true}
                              // className='dataSource_multiDiv'
                              style={{ width: "100%" }}
                              // appendTo={document.body}
                              disabled={this.state.selectedSeller ? false : true}
                              maxSelectedLabels={1}
                              options={this.state.dataArray ? this.state.dataArray : []}
                              onChange={(e) => this.handleChange(e, "dataSource")}
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div >
                  <Row className={this.state.isredirtRanges || this.state.isShowNote ? "ml-3" : "d-none"} >
                    {this.state.isredirtRanges && !this.state.groupMessage || this.state.isShowNote ?
                      < ><p className='col-sm-12 pl-0'><b style={{ fontSize: 14, fontWeight: 800 }}>{this.state.isredirtRanges || this.state.isShowNote ? "Exclusions :" : null}</b>
                        <span className="my-2 ml-2" data-toggle="tool-tip" title='Click to see all Exclusions' onClick={(e) => this.op.toggle(e)}>
                          {configMessages.ExclusionMessage} <span>......</span>
                        </span>
                        <OverlayPanel style={{ left: "700px", right: "66px" }} ref={(el) => this.op = el} appendTo={document.body} showCloseIcon={true} dismissable={true}>
                          <b style={{ fontSize: 14, fontWeight: 800 }}>Exclusions:</b> {configMessages.ExclusionMessage} <p className='mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p></OverlayPanel></p>
                        <span className="">
                          <span className=""><p> <b style={{ fontWeight: 800 }}> Note : </b>
                            {configMessages.NOTE_WHEN_REDIRECTING}
                          </p></span>
                        </span>
                      </>
                      :
                      <>
                        <div title='Click to see all Exclusions' onClick={(e) => this.op.toggle(e)}>
                          {this.state.groupMessage && this.state.groupMessage.length > 0 ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                            <h4 className='ml-2'><b> {this.state.Group}:</b>
                              {this.state.groupMessage}</h4></div> : null}
                          {this.state.fiftyk || this.state.unknown || this.state.Count10kTo50k ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                            <h4 className='ml-2'>
                              {this.state.fiftyk ? this.state.fiftyk : this.state.unknown ? this.state.unknown : this.state.Count10kTo50k ? this.state.Count10kTo50k : ""}</h4></div>
                            : null}

                        </div>
                        <span>
                          <OverlayPanel style={{ left: "700px", right: "66px" }} ref={(el) => this.op = el} appendTo={document.body} showCloseIcon={true} dismissable={true}>
                            <span className='ml-2'><b> {this.state.Group}:</b>
                              {this.state.groupMessage}</span><p className='ml-2 mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p></OverlayPanel>
                        </span>
                      </>

                    }

                  </Row>
                  {this.props.defaultExclusionNote && !this.state.groupMessage && !this.state.isredirtRanges ?
                    <div>
                      <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
                        title="click to see definations" >
                        {this.props.defaultExclusionNote}
                      </div>
                      <div>
                        <OverlayPanel style={{ left: "1500px" }} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
                          <p className='ml-2 mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p>
                        </OverlayPanel>

                      </div>
                    </div>
                    : null}
                </form >
                {this.state.SessionWarningModal ? this.getSessionWraningModal() : null}
                {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}

              </div >

              {this.state.openUnsaveModal ? this.getDeleteRowModalForNotes() : null}
            </AccordionTab >
          </Accordion >




    );
  }
}

export default withTranslation('common')(PropertyFilterForm);