import React from 'react';
import { Button } from 'reactstrap';
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox'
import { withTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages'
import apiCalls from '../../../../config/apiCalls';
import ConfirmationModal from '../../CommonModals/ConfirmationModal';
import DeleteRowModal from '../../CommonModals/DeleteRowModal';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import config from "../../../../config/config"
import Select from 'react-select';
import dateFormats from '../../../UI/FormatDate/formatDate';


import moment from 'moment-timezone';


const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            background: isSelected ? "rgba(28, 160, 181, 0.29)" : "",
            color: 'black',
            textAlign: 'left',
            //display: 'inline'

        };
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
        textTransform: 'capitalize',
        width: "max-content"
    }),
}



class PropertyEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiledBusinessName: "",
            selectedViewType: "Default",
            claimID: "",
            value: true,
            proof: ["Notes", "Proofs"],
            proofTab: "Notes",
            copySellerProof: false,
            copiedSellerProof: "",
            copiedAddressProof: "",
            copiedNameProof: "",
            copiedFeinProof: "",
            copiedHolderProof: "",
            copyNameProof: false,
            copyAddressProof: false,
            newselectedStatus: "",
            newselectedReason: "",
            copyFeinProof: false,
            copyHolderProof: false,
            blockFileFields: false,
            filterCriteria: this.props.filterCriteria,
            isOpenConfirmationModal: false,
            fileForOptions: [],
            mappedNames: [],
            downloadData: [],
            screenNames: ["ns_scored_propertys", "tab2Data", "tab3Data"],
            propertyStatusArray: [],
            propertyReasonArray: [],
            isSelectedRowValuemappedName: "",
            isSelectedRowValuerelevantSellerName: ""
        };
    }
    componentDidMount = async () => {
        let downloadData = []
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.getNewStatusReasonsForEditor()
    }

    componentDidUpdate = () => {
    }
    closeConfirmationModal = async (type) => {
        if (type === "close") {
            await this.props.isLoading()
            this.saveValidatedRecord()
            await this.setState({
                isOpenConfirmationModal: false,
                isDisabledTab: false
            })
        } else {
            this.props.isLoading("false")
            await this.setState({
                isOpenConfirmationModal: false,
                isDisabledTab: false
            })
        }
    }

    flattenArray = (arrayVal) => {
        let val = "";
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            val = val
                .replace(/"/g, "")
                .replace(/\[/g, "")
                .replace(/]/g, "")
                .replace(/{/g, "")
                .replace(/}/g, "")
                .replace(/,/g, " , ")
                .replace(/:/g, " : ");
        }
        return val;
    };

    formatChange = (data) => {
        data.forEach((item) => {
            for (let key in item) {
                if (item[key] && item[key].name) {
                    item[key] = item[key].name;
                }
                if (typeof item[key] == "object") {
                    delete item[key]
                }
            }
        });
        return data
    }

    updateDateFormat(itemdata, dateFormat, from, tableFieldsToShow) {
        let tableFields = tableFieldsToShow;
        let modifiedData = [];
        for (let i = 0; i < itemdata.length; i++) {
            for (let k = 0; k < tableFields.length; k++) {
                if ("Date" === tableFields[k]['fieldType']) {
                    if (itemdata[i][tableFields[k]['field']] !== null) {
                        let time = moment(itemdata[i][tableFields[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][tableFields[k]['field']]) ? '-05:00' : '-06:00');
                        itemdata[i][tableFields[k]['field']] =
                            dateFormats.formatDate(time, tableFields[k]['dateFormat'] && !from ? tableFields[k]['dateFormat'] : dateFormat);
                    }
                }
            }
            modifiedData.push(itemdata[i])
        }
        return modifiedData;
    }
    closeSaveModelPopUp = async () => {
        await this.setState({ openUnsaveModal: false })
        await this.handlePropertiesEditChange("", "save")
    }
    closeTaskAddModel = async () => {
        await this.setState({
            showSelectederror: false,
            errormsg: "",
            selectedValidatedStatus: "",
            newselectedStatus: "",
            newselectedReason: "",
            selectednote: "",
            claimID: "",
            openUnsaveModal: false,
            AddressProof: "",
            FeinProof: "",
            HolderProof: "",
            NameProof: "",
            SellerProof: "",
            selectedFiledBusinessName: "",
            relevantSellerName: "",
            selectedReason: "",
            selectedRows: []
        })
        this.props.removeSelectedRows()
        this.props.getshowSelectederrormsg("", false)
        if (this.props.commentEditedOrNo) {
            await this.props.commentEditedOrNo("no")
        }
    }
    setNoteEmpty = async () => {
        await this.setState({
            selectednote: "",
        })
    }
    getDeleteRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openUnsaveModal}
                closeDeleteModal={this.closeSaveModelPopUp}
                deleteSelectedRow={this.closeTaskAddModel}
                unSaved={true}
                notesUnsaved={true}
                confirmModalText={"You have unsaved notes."}
                type={this.props.type}
            />
        )
    }

    getConfirmaionModel() {
        return (
            <ConfirmationModal
                openConfirmationModal={this.state.isOpenConfirmationModal}
                closeConfirmationModal={this.closeConfirmationModal}
                shownText={this.state.proofTab === "Proofs" ? "Are you sure you want to update proofs for the Existing Record?" : "Are you sure you want to update notes for the Existing Record?"}
                confirm={this.saveValidatedRecord}
            />
        )
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    getNewStatusReasonsForEditor = async (val) => {
        var propertyStatusArray = this.state.propertyStatusArray
        let filterCriteria = {}
        if (val) {
            filterCriteria['criteria'] = [{ key: 'Status', value: val, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
                //  { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }
            ];

        } else {
            filterCriteria['criteria'] =
                [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
                { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
                ]
            if (this.props.type === "Deal Sizing Property Editor") {
                filterCriteria['criteria'].push({ key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true })
            } else {
                filterCriteria['criteria'].push({ key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true })
            }
        }

        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"

        return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
            .then(async (response) => {
                if (response && response["propertyStatusReasons"].length > 0) {
                    let propertyStatusReasons = response["propertyStatusReasons"]
                    for (let reason of propertyStatusReasons) {
                        propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
                    }
                    await this.setState({ propertyStatusArray: propertyStatusArray })
                } else if (response && response["propertyStatusReasons"].length == 0) {

                    await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
            }).catch((err) => {
                return err;
            })
    }


    handleUpdateValues = async (selectedRow) => {
        if (selectedRow.relevantSellerName && selectedRow.fileFor && selectedRow.fileFor.length > 0) {
            this.updatedrelevantSellerName(selectedRow.fileFor, "relevantSellerName")
        }
        if (selectedRow.relevantSellerName && selectedRow.relevantSellerName.length > 0) {
            this.updatedfileFor(selectedRow.relevantSellerName, "fileFor")
        }
        if (selectedRow.claimID && selectedRow.claimID.length > 0) {
            this.updateClaimID(selectedRow.claimID, "claimID")
        }
        if (selectedRow.propertyStatus && selectedRow.propertyStatus.length > 0 && !selectedRow.propertyStatus.includes("MATCHING")) {
            this.updateStatus(selectedRow.propertyStatus, "status")
        }
        if (selectedRow.propertyStatusReason && selectedRow.propertyStatusReason.length > 0 && !selectedRow.propertyStatus.includes("MATCHING")) {
            this.updateReason(selectedRow.propertyStatusReason, "reason")
        }
        if (selectedRow.addressProof && selectedRow.addressProof.length > 0) {
            this.updateAddressProof(selectedRow.addressProof, "AddressProof")
        }
        if (selectedRow.feinProof && selectedRow.feinProof.length > 0) {
            this.updateAddressProof(selectedRow.feinProof, "FeinProof")
        }
        if (selectedRow.holderProof && selectedRow.holderProof.length > 0) {
            this.updateAddressProof(selectedRow.holderProof, "HolderProof")
        }
        if (selectedRow.nameProof && selectedRow.nameProof.length > 0) {
            this.updateAddressProof(selectedRow.nameProof, "NameProof")
        }
        if (selectedRow.sellerProof && selectedRow.sellerProof.length > 0) {
            this.updateAddressProof(selectedRow.sellerProof, "SellerProof")
        }
        await this.setState({
            selectedRow: selectedRow
        })
    }


    handleUpdateValuestoempty = () => {
        this.updatedselectedExclude("", "noteEmpty")
        this.updatedfileFor("", "noteEmpty");
        this.updatedfileFor("", "noteEmpty");
        this.updatedrelevantSellerName("", "noteEmpty")
        this.enableCopyProof("false")
        this.removeCopiedFields()
        this.updatedselectedExclude("", "noteEmpty")
        this.updateAddressProof("empty", "AddressProof")
        this.updateAddressProof("empty", "FeinProof")
        this.updateAddressProof("empty", "HolderProof")
        this.updateAddressProof("empty", "NameProof")
        this.updateAddressProof("empty", "SellerProof")
        this.updateStatus('', "empty")
        this.updateReason('', "reason")
        this.updateClaimID()

    }

    getReasonForEditor = async (val) => {
        let selectednote = this.state.selectednote
        if (this.props.type === "Property Editor"
            && !this.state.fixedNote
            // val === "MAPPING-READY TO FILE"
        ) {
            selectednote = await this.applyDefaultTemplateForSelectedRows()
        } else {
            selectednote = this.state.fixedNote
        }
        this.setState({
            selectedValidatedStatus: val,
            selectedReason: "",
            // shownreasons: this.state.ReasonsArray
        })
        if (this.props.type === "Property Editor") {
            this.setState({ selectednote: selectednote })
        } else {
            this.setState({ selectednote: "" })
        }
        let ReasonsArray = this.state.propertyReasonArray;
        let filterCriteria = {};
        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"

        filterCriteria['criteria'] = [{ key: 'Status', value: val, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];

        if (this.props.type === "Deal Sizing Property Editor") {
            filterCriteria['criteria'].push({ key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true })
        } else {
            filterCriteria['criteria'].push({ key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true })
        }

        if (val && val.length > 0) {
            return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${"reason"}`)
                .then(async (response) => {
                    if (response && response["propertyStatusReasons"].length > 0) {
                        let propertyReasons = response["propertyStatusReasons"]
                        for (let reason of propertyReasons) {
                            let index = ReasonsArray.findIndex(obj => obj.label === reason.reason)
                            if (index === -1) {
                                ReasonsArray.push({ label: reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
                            }
                            if ((val === config.autoSelectStatus && reason.reason === config.autoSelectReason)) {
                                this.setState({
                                    newselectedReason: { reason: reason.reason, tooltip: reason.statusReasonDefinition }
                                })
                            }
                        }
                        if (ReasonsArray.length === 1) {
                            this.setState({
                                newselectedReason: ReasonsArray[0].value
                            })
                        }
                        await this.setState({ propertyReasonArray: ReasonsArray })
                    } else if (response && response["propertyStatusReasons"].length == 0) {

                        await this.setState({ propertyReasonArray: [] })
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,

                        })
                    }
                }).catch((err) => {
                    return err;
                })
        }
    }

    getFileForEditor = async (val) => {
        let fileForOptions = [];
        let mappedNames = [];
        let url, responseKey
        url = `seller/sellerRelevanceNames?estateId=${val}&nameType=mappedName`
        return fetchMethodRequest('GET', url)
            .then(async (response) => {
                if (response && response["sellerRelevanceNames"].length > 0) {
                    let propertyReasons = response["sellerRelevanceNames"]
                    for (let reason of propertyReasons) {
                        mappedNames.push({ label: reason.CombinedName, value: reason.CombinedName, BusinessName: reason.BusinessName, nameID: reason.BusinessNamesID })
                    }
                    await this.setState({ mappedNames: mappedNames })
                } else if (response && response["sellerRelevanceNames"].length === 0) {

                    await this.setState({ mappedNames: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,
                    })
                }

            }).catch((err) => {
                return err;
            })
    }
    getFileForEditorForSellerRelevanceName = async (val) => {
        let fileForOptions = [];
        // let mappedNames = [];
        let url, responseKey

        url = `seller/sellerRelevanceNames?estateId=${val}&nameType=sellerRelevanceName`

        return fetchMethodRequest('GET', url)
            .then(async (response) => {
                // if (this.props.selectedOpra == "OPRA2") {
                if (response && response["sellerRelevanceNames"].length > 0) {
                    let propertyReasons = response["sellerRelevanceNames"]
                    for (let reason of propertyReasons) {
                        // fileForOptions.push({ label: reason.BusinessName, value: reason.BusinessName, nameID: reason.BusinessNamesID })
                        fileForOptions.push({ label: reason.CombinedName, value: reason.CombinedName, BusinessName: reason.BusinessName, nameID: reason.BusinessNamesID })
                    }
                    await this.setState({ fileForOptions: fileForOptions })
                } else if (response && response["sellerRelevanceNames"].length === 0) {

                    await this.setState({ fileForOptions: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
                // }
            }).catch((err) => {
                return err;
            })

    }

    clipToCopyBoard(data) {

        navigator.clipboard.writeText(data)
    }

    updateStatus = async (value, type) => {
        if (type === "status" && value.length > 0) {
            var getStatus = this.state.propertyStatusArray.filter(function (el) {
                return el.label === value
            });
            if (getStatus[0]) {
                this.setState({
                    newselectedStatus: getStatus[0].value
                })
            }
        } else {
            this.setState({
                newselectedStatus: ""
            })
        }
        this.setState({
            propertyReasonArray: [],
            showSelectederror: false
        })
    }

    updateReason = async (value, type) => {
        if (type === "reason" && value.length > 0) {
            if (this.state.newselectedStatus && this.state.newselectedStatus.status) await this.getReasonForEditor(this.state.newselectedStatus.status);
            var getReason = this.state.propertyReasonArray.filter(function (el) {
                return el.label === value
            });
            if (getReason[0]) {

                this.setState({
                    newselectedReason: getReason[0].value
                })
            }
        } else {
            this.setState({
                newselectedReason: ""
            })
        }
    }
    updatedNote = async (selectednote, type) => {
        if (type === "note") {
            if (this.props.type === "Negative Notice List Viewer") {
                this.setState({
                    selectedExcludeNote: selectednote,

                })
            } else {
                this.setState({
                    selectednote: selectednote,
                    fixedNote: selectednote,
                    // selectedFiledBusinessName: "",
                })
            }
        } else if (type === "noteEmpty") {
            this.setState({
                selectednote: "",
                fixedNote: ""
            })
        } else {
            this.setState({
                selectedViewType: selectednote
            })
        }

    }

    updatedselectedExclude = async (selectednote, type) => {
        if (type === "note") {
            this.setState({
                selectedExclude: selectednote,
            })
        } else if (type === "noteEmpty") {
            this.setState({
                selectedExclude: "",
            })
        }
    }

    removeCopiedFields = async () => {
        await this.setState({
            claimID: "",
            AddressProof: "",
            FeinProof: "",
            HolderProof: "",
            NameProof: "",
            SellerProof: "",
            copiedSellerProof: "",
            copiedAddressProof: "",
            copiedNameProof: "",
            copiedFeinProof: "",
            copiedHolderProof: "",
            copiedOnceSellerProof: false,
            copiedOnceAddressProof: false,
            copiedOnceNameProof: false,
            copiedOnceFeinProof: false,
            copiedOnceHolderProof: false,
        })
    }

    enableCopyProof = async (type) => {
        if (type === "true") {
            await this.setState({ enableCopyProof: true })
        } else {
            await this.setState({
                enableCopyProof: false,

            })
        }
    }

    updatedrelevantSellerName = async (selectednote, type) => {
        if (selectednote && this.state.mappedNames.length > 0) {
            let index2 = this.state.mappedNames.findIndex(obj =>
                obj.BusinessName === selectednote
            )
            if (index2 !== -1) {
                await this.setState({
                    selectedFiledBusinessName: this.state.mappedNames[index2],
                    isSelectedRowValuemappedName: selectednote
                })
            }
        } else {
            await this.setState({
                selectedFiledBusinessName: "",
                isSelectedRowValuemappedName: ""
            })
        }
    }

    updatedfileFor = async (selectednote, type) => {
        if (selectednote && this.state.fileForOptions && this.state.fileForOptions.length > 0) {
            let index = this.state.fileForOptions.findIndex(obj => obj.BusinessName === selectednote)
            if (index !== -1 && type !== "relevantSellerName") {
                await this.setState({
                    relevantSellerName: this.state.fileForOptions[index],
                    isSelectedRowValuerelevantSellerName: selectednote
                })
            }
        } else {
            await this.setState({
                relevantSellerName: "",
                isSelectedRowValuerelevantSellerName: ""
                // fileForOptions: []
            })
        }
    }

    updateAddressProof = async (proof, type) => {
        if (proof !== "empty") {
            await this.setState({ [type]: proof })
        } else {
            if (type === "SellerProof" && this.state.copiedSellerProof) {
                await this.setState({ [type]: this.state.copiedSellerProof })
            } else
                if (type === "NameProof" && this.state.copiedNameProof) {
                    await this.setState({ [type]: this.state.copiedNameProof })
                } else
                    if (type === "HolderProof" && this.state.copiedHolderProof) {
                        await this.setState({ [type]: this.state.copiedHolderProof })
                    } else if (type === "FeinProof" && this.state.copiedFeinProof) {
                        await this.setState({ [type]: this.state.copiedFeinProof })
                    } else if (type === "AddressProof" && this.state.copiedAddressProof) {
                        await this.setState({ [type]: this.state.copiedAddressProof })
                    } else {
                        await this.setState({ [type]: "" })
                    }

        }
    }
    emptyStatus = async () => {
        await this.setState({ selectedValidatedStatus: "" })
    }
    updateClaimID = async (selectednote, type) => {
        if (selectednote) {
            await this.setState({
                claimID: selectednote
            })
        } else {
            await this.setState({
                claimID: ""
            })
        }
    }

    saveValidatedRecord = () => {
        // this.props.getErrorMessage("Please Wait untill it is validating")
        let apiUrl = "NsScoredProperties/validate"
        if (this.props.type === "Deal Sizing Property Editor") {
            apiUrl = apiUrl + "?screen=NSD"
        }

        return fetchMethodRequest('POST', apiUrl, this.state.editObj)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.props.getshowSelectederrormsg(response.respMessage, true, "success")
                    this.props.removeSelectedRows()
                    let body = {
                        propertyId: this.props.PropertyData.selectedProperty_Id ? this.props.PropertyData.selectedProperty_Id.toString() : null,
                        statePropertyId: this.props.PropertyData.selectedOpraStId ? this.props.PropertyData.selectedOpraStId.toString() : null
                    }
                    this.props.isLoading("false")
                    this.props.getDataFromServer(this.props.filterCriteria, null, false, body)
                    if (this.props.commentEditedOrNo) {
                        await this.props.commentEditedOrNo("no")
                    }
                    await this.setState({
                        showSelectederror: false,
                        errormsg: "",
                        selectedRows: [],
                        isDisabledTab: false,
                        isNoteEdited: false,
                        showSelectederror: false,
                        selectedFiledBusinessName: "",
                        errorColor: true,
                        selectednote: "",
                        claimID: "",
                        AddressProof: "",
                        FeinProof: "",
                        HolderProof: "",
                        NameProof: "",
                        relevantSellerName: "",
                        newselectedStatus: "",
                        newselectedReason: "",
                        SellerProof: "",
                        copySellerProof: false,
                        copyAddressProof: false,
                        copyFeinProof: false,
                        copyHolderProof: false,
                        copyNameProof: false,
                        copiedSellerProof: "",
                        copiedAddressProof: "",
                        copiedNameProof: "",
                        copiedFeinProof: "",
                        copiedHolderProof: "",
                        copiedOnceAddressProof: false,
                        copiedOnceFeinProof: false,
                        copiedOnceHolderProof: false,
                        copiedOnceNameProof: false,
                        copiedOnceSellerProof: false,
                        selectedReason: "",
                        selectedValidatedStatus: "",
                        errormsg: response.respMessage
                    })
                } else if (response && response.errorMessage) {
                    this.props.getErrorMessage(response.errorMessage, "", "error")
                    if (response.invalidID) {
                        let index, validatedArray = []
                        let data = this.props.allUsersData
                        for (var id of response.invalidID) {
                            index = data.findIndex(x => x._id === id);
                            validatedArray.push(this.state.allUsersData[index].propertyID)
                        }
                        await this.setState({
                            ValidatedIds: response.invalidID,
                            showSelectederror: true,
                            isDisabledTab: false,
                            validatedArray: validatedArray,
                            errormsg: `Records with the following Property Ids are already Validated ${validatedArray}`
                        })
                        this.props.getshowSelectederrormsg(`Records with the following Property Ids are already Validated ${validatedArray}`, true, "error")

                    }
                    await this.setState({ isDisabledTab: false, })
                }
            }).catch((err) => {
                return err;
            });
    }

    applyDefaultTemplateForSelectedRows = async () => {
        let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
        const shortName = new Date().toLocaleString('en-US', { month: 'short' });
        const DateName = new Date().getDate();
        let selectednote
        selectednote = ""
        // selectednote = `Seller: \nSeller Proof:\nName Proof:\nAddress Proof:\nFEIN Proof:\nHolder Proof:`
        return selectednote;
    }

    setProofTab = async (tabType) => {
        await this.setState({ proofTab: tabType })
    }

    sellerProofExpand = async (type) => {
        let expand = type + "Expand"
        await this.setState({ [expand]: this.state[expand] ? false : true })
    }
    copySellerProof = async (type) => {
        let expand = "copy" + type
        let expanded = "copied" + type
        let copiedOnce = "copiedOnce" + type
        if (!this.state[expand]) {
            this.setState({ [expanded]: this.state[type] })
        }
        await this.setState({ [expand]: this.state[expand] ? false : true, [copiedOnce]: this.state[expand] ? false : true })
    }

    handlePropertiesEditChangeProof = async (value, type, isSeller) => {
        let isEdited = this.props.checkDataisEdited()
        if (isEdited && isEdited.length > 0) {
            this.props.OpenConfirmaionModelorSave()
        } else {
            if (type === "save") {
                this.props.isLoading()
                this.setState({
                    isDisabledTab: true
                })
                let selectedRowsId = [];
                let selectedRows = [...this.props.selectedRows]

                if (selectedRows && selectedRows.length && selectedRows.length > 0) {
                    selectedRows.forEach((item, index) => {
                        selectedRowsId.push(item._id)
                    })
                }
                if (selectedRowsId && selectedRowsId.length > 1) {
                    let selectedRowsIdArr = selectedRowsId.shift();
                }
                let editObj = {
                    // status: this.state.selectedValidatedStatus,
                    // note: this.state.selectednote,
                    // claimID: this.state.claimID,
                    addressProof: this.state.AddressProof ? this.state.AddressProof : "",
                    feinProof: this.state.FeinProof ? this.state.FeinProof : "",
                    holderProof: this.state.HolderProof ? this.state.HolderProof : "",
                    nameProof: this.state.NameProof ? this.state.NameProof : "",
                    sellerProof: this.state.SellerProof ? this.state.SellerProof : "",

                    ids: selectedRowsId,
                }


                // if (this.state.saveValidatedRecordaddressArr && this.state.addressArr.length > 1) {
                //   await this.setState({
                //     showSelectederror: true,
                //     errormsg: "Please select the same Address Lines for all the selected rows before saving the record"
                //   })
                // } else 
                // let uniquebusinessName = [...new Set(selectedRows.map((item) => item.businessName))];

                let uniqueState = [...new Set(selectedRows.map((item) => item.sourceState))];
                let uniquefileFor = [...new Set(selectedRows.map((item) => item.fileFor))];



                if (selectedRowsId && selectedRowsId.length === 0) {
                    await this.setState({
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: "Please select records to update proofs"
                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg("Please select records to update proofs", true, "error")
                }
                else if (this.state.newselectedStatus && this.state.newselectedStatus.status === "Filed" && (uniqueState.length > 1 || uniquefileFor.length > 1)) {
                    await this.setState({
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: "Please select the records of same file for and source state  for status Filed."
                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg("Please select the records of same file for and source state for status Filed.", true, "error")
                }

                else {
                    await this.setState({
                        editObj: editObj
                    })
                    if ((this.state.newselectedStatus === null || this.state.newselectedStatus === undefined || this.state.newselectedStatus === "") && this.props.selectedRows && this.props.selectedRows.length === 1 && this.state.selectednote && this.state.selectednote.length > 0 && this.state.selectednote && this.state.selectednote.length > 0) {
                        this.props.isLoading('false')
                        await this.setState({
                            isOpenConfirmationModal: true,
                        })
                    } else {
                        this.saveValidatedRecord()
                    }
                }

            }
        }
    }

    handlePropertiesEditChange = async (value, type, isSeller) => {
        let isEdited = this.props.checkDataisEdited()
        if (isEdited && isEdited.length > 0) {
            this.props.OpenConfirmaionModelorSave()
        } else {
            if (type && type === "Reason") {
                this.setState({
                    selectedReason: value
                })
                this.props.getshowSelectederrormsg("", false)
            } if (type && type === "newReason") {
                this.setState({
                    newselectedReason: value,
                    showSelectederror: false
                })
                this.props.getshowSelectederrormsg("", false)
            } if (type && type === "newStatus") {
                await this.setState({
                    showSelectederror: false,
                    errormsg: "",
                    propertyReasonArray: [],
                    newselectedStatus: value,
                    // selectedFiledBusinessName: this.props.selectedRows.length > 1 && this.state.selectedFiledBusinessName ? this.state.selectedFiledBusinessName : "", // commented out for not to clear the dropdown values for now. 
                    newselectedReason: "",
                    isSelectedRowValuerelevantSellerName: this.props.selectedRows.length > 1 && this.state.isSelectedRowValuerelevantSellerName ? this.state.isSelectedRowValuerelevantSellerName : "",
                    // relevantSellerName: this.props.selectedRows.length > 1 && this.state.relevantSellerName ? this.state.relevantSellerName : "", // commented out for not to clear the dropdown values for now.
                    isSelectedRowValuemappedName: this.props.selectedRows.length > 1 && this.state.isSelectedRowValuemappedName ? this.state.isSelectedRowValuemappedName : "",
                })
                // if (data.status === "PENDING-CLM PENDING") {
                //   this.props.change("relevantSellerName", null)
                //   this.props.change("relevantSellerNameID", '')
                //   this.setState({
                //     selectedFiledBusinessName: ""
                //   })
                // }
                await this.getReasonForEditor(value.status);
                this.props.getshowSelectederrormsg("", false)
            } if (type && type === "selectedFiledBusinessName") {
                await this.setState({
                    selectedFiledBusinessName: value,
                    showSelectederror: false,
                    errormsg: ""
                })
            } if (type && type === "relevantSellerName") {
                this.setState({
                    relevantSellerName: value,
                    showSelectederror: false,
                    errormsg: ""
                })
            } else if (type === "Status") {
                await this.getReasonForEditor(value);
            } else if (type === "note") {
                // if (this.props.commentEditedOrNo) {
                //     await this.props.commentEditedOrNo("yes")
                // }
                this.setState({
                    selectednote: value,
                    showSelectederror: false,
                    isNoteEdited: true,
                    errormsg: ""
                })
            } else if (type === "claimID") {
                this.setState({
                    claimID: value,
                    showSelectederror: false,
                    errormsg: ""
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "SellerProof") {
                this.setState({
                    SellerProof: value,
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "NameProof") {
                this.setState({
                    NameProof: value,
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "AddressProof") {
                this.setState({
                    AddressProof: value,
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "FeinProof") {
                this.setState({
                    FeinProof: value,
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "HolderProof") {
                this.setState({
                    HolderProof: value,
                })
                // this.props.getshowSelectederrormsg("", false)
            }
            else if (type === "Cancel") {
                // if (this.state.isNoteEdited) {
                //     await this.setState({ openUnsaveModal: true })
                //     return
                // }
                this.setState({
                    showSelectederror: false,
                    errormsg: "",
                    selectedValidatedStatus: "",
                    newselectedStatus: "",
                    newselectedReason: "",
                    selectednote: "",
                    claimID: "",
                    AddressProof: "",
                    FeinProof: "",
                    HolderProof: "",
                    NameProof: "",
                    SellerProof: "",
                    selectedFiledBusinessName: "",
                    relevantSellerName: "",
                    selectedReason: "",
                    selectedRows: []
                })
                if (isSeller) {
                    this.setState({
                        fileForOptions: []
                    })
                }
                this.props.removeSelectedRows()
                this.props.getshowSelectederrormsg("", false)
            } else if (type === "save") {
                this.props.isLoading()
                this.setState({
                    isDisabledTab: true,
                })
                let selectedRowsId = [];
                let selectedRows = [...this.props.selectedRows]
                if (selectedRows.length > 0 && this.props.activeTab === "SP") {
                    var newArray = selectedRows.filter(function (el) {
                        return el.propertyStatus !== "RESULT-APPROVED" && el.propertyStatus !== "FILING-CLM FILED"
                    });
                    selectedRows = newArray
                }
                if (selectedRows && selectedRows.length && selectedRows.length > 0) {
                    selectedRows.forEach((item, index) => {
                        selectedRowsId.push(item._id)
                    })
                }

                let editObj = {
                    note: this.state.selectednote,
                    claimID: this.state.claimID,
                    addressProof: this.state.AddressProof,
                    feinProof: this.state.FeinProof,
                    holderProof: this.state.HolderProof,
                    nameProof: this.state.NameProof,
                    sellerProof: this.state.SellerProof,
                    ids: selectedRowsId,
                }

                if (this.state.newselectedStatus && this.state.newselectedStatus.status.length > 0) {

                    editObj.status = this.state.newselectedStatus.status
                }
                if (this.state.newselectedReason && this.state.newselectedReason.reason) {
                    editObj.reason = this.state.newselectedReason.reason
                }
                if (this.state.selectedFiledBusinessName) {
                    editObj.fileFor = this.state.selectedFiledBusinessName.BusinessName ? this.state.selectedFiledBusinessName.BusinessName : this.state.selectedFiledBusinessName
                }
                if (this.state.relevantSellerName) {
                    editObj.relevantSellerName = this.state.relevantSellerName.BusinessName ? this.state.relevantSellerName.BusinessName : this.state.relevantSellerName

                }

                let uniqueState = [...new Set(selectedRows.map((item) => item.sourceState))];
                let uniquefileFor = [...new Set(selectedRows.map((item) => item.fileFor))];

                if (selectedRowsId && selectedRowsId.length === 0) {
                    await this.setState({
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: configMessages.norecordsSelected
                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg(configMessages.norecordsSelected, true, "error")
                }
                else if (this.state.newselectedStatus && this.state.newselectedStatus.status === "Filed" && (uniqueState.length > 1 || uniquefileFor.length > 1)) {
                    await this.setState({
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: configMessages.sameFileforSelectedRecords
                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg(configMessages.sameFileforSelectedRecords, true, "error")
                } else if (!this.state.newselectedStatus && !this.state.newselectedReason && !this.state.selectednote && !this.state.claimID && !this.state.selectedFiledBusinessName && !this.state.relevantSellerName) {
                    await this.setState({
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: configMessages.selectAnyone
                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg(configMessages.selectAnyone, true, "error")

                } else if (this.state.newselectedStatus && this.state.newselectedStatus.status && this.state.newselectedStatus.status.length === 0 && this.state.selectednote.length <= 25 && this.state.claimID.length === 0 && !this.state.selectedFiledBusinessName && !this.state.relevantSellerName) {
                    this.setState({
                        isDisabledTab: false
                    })
                    if ((!this.state.newselectedReason || this.state.newselectedReason && this.state.newselectedReason.reason.length === 0 && this.state.newselectedReason.reason === "") || this.state.newselectedStatus.status === "" || (typeof (this.state.newselectedStatus.status) != "string" && this.state.newselectedStatus && this.state.newselectedStatus.status.length > 1)) {
                        await this.setState({
                            showSelectederror: true,
                            isDisabledTab: false,
                            errormsg: configMessages.selectAnyone
                        })
                        this.props.isLoading('false')
                        this.props.getshowSelectederrormsg("Please select the Status and Status Reason to update or add note or claim id ", true, "error")
                    }
                } else if (this.state.newselectedStatus && this.state.newselectedStatus.status && this.state.newselectedStatus.status.length > 0 && (this.state.newselectedReason === "" || this.state.newselectedReason === null)) {
                    this.setState({
                        isDisabledTab: false,
                        showSelectederror: true,
                        isDisabledTab: false,
                        errormsg: configMessages.statusReasonMandatory

                    })
                    this.props.isLoading('false')
                    this.props.getshowSelectederrormsg("Please select Status Reason to update ", true, "error")
                }
                //Commented out to remove the checking mapped/relevanr Seller names conditions for now when mapping properties.
                // else if (
                //   ((this.state.newselectedStatus && ( this.state.newselectedStatus.status === config.autoSelectStatus)
                //     && ((this.state.relevantSellerName.length === 0 && this.state.isSelectedRowValuerelevantSellerName.length === 0)
                //       || (this.state.selectedFiledBusinessName.length === 0 && this.state.isSelectedRowValuemappedName.length === 0)))
                //   ) && this.props.selectedRows.length === 1) {
                //   await this.setState({
                //     showSelectederror: true,
                //     isDisabledTab: false,
                //     errormsg: configMessages.mappedNameRelavantSellerNameMandatory
                //   })
                //   // this.props.getshowSelectederrormsg("Please select the Mapped Name and Relevent Seller Name before saving", true, "error")
                // }
                // else if (this.state.newselectedStatus.status === config.autoSelectStatus && this.props.selectedRows.length > 1 && this.isEmptyMappedOrSellerNamesExist(this.props.selectedRows)) {
                //   await this.setState({
                //     showSelectederror: true,
                //     isDisabledTab: false,
                //     errormsg: configMessages.emptyNamesError
                //   })
                //   // this.props.getshowSelectederrormsg("Please select the recods with same Mapped Name and Relevent Seller Name and SourceState before saving", true, "error")

                // } 

                else {
                    await this.setState({
                        editObj: editObj,
                        showSelectederror: false,
                        errormsg: ""
                    })
                    if ((this.state.newselectedStatus.status === null || this.state.newselectedStatus.status === undefined || this.state.newselectedStatus.status === "") && this.props.selectedRows && this.props.selectedRows.length === 1 && this.state.selectednote && this.state.selectednote.length > 0) {
                        this.props.isLoading('false')
                        await this.setState({
                            isOpenConfirmationModal: true,
                        })
                    } else {
                        this.saveValidatedRecord()
                    }
                }

            }


            // this.setState({

            // })
        }
    }
    makeNoteEditedFalse = async () => {
        await this.setState({ isNoteEdited: false })
    }
    /**
     * Checks if empty mapped name & relevant seller names exists in selected rows.
     * @param {Array} selRows 
     * @returns {Boolean}
     */
    isEmptyMappedOrSellerNamesExist = (selRows) => {
        for (let i = 0; i < selRows.length; i++) {
            if (!selRows[i].fileFor || !selRows[i].relevantSellerName) {
                return true;
            }
        }
        return false;
    }


    setFiledStatus = async (e) => {
        if (e === "true") {
            await this.setState({ blockFileFields: true, selectedValidatedStatus: null })
        } else {
            await this.setState({ blockFileFields: false })
        }
    }

    setFiledNote = async (e) => {
        // if (e === "true") {
        //   await this.setState({ blockPropertyFields: true })
        // } else {
        await this.setState({ blockPropertyFields: false })
        // }
    }

    removeFrozenRecords = async (e) => {
        await this.setState({
            value: e.value
        })
        let columnsData = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));

        let columns = columnsData.details[this.props.selectedViewType ? this.props.selectedViewType : "Default"]

        for (let i = 0; i < columns.length; i++) {
            columns[i].frozen = false
        }
        await localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columnsData))
        await this.props.selectedViewType(e.value, "Frozen")
    }
    selectedViewType = async (e) => {

        await this.setState({
            selectedViewType: e
        })
        await this.props.selectedViewType(e)
    }

    handleSellerNegativeNoticeChange = async (e, type) => {
        if (type === "Cancel") {
            await this.setState({
                selectedExclude: "",
                selectedExcludeNote: ""
            })
            this.props.removeSelectedRows()
        } else {
            let selectedRowsId = [];
            let selectedRows = [...this.props.selectedRows]
            if (selectedRows && selectedRows.length && selectedRows.length > 0) {
                selectedRows.forEach((item, index) => {
                    selectedRowsId.push(item._id)
                })
            }
            let editObj = {
                includedExcluded: this.state.selectedExclude,
                inclusionExclusionNote: this.state.selectedExcludeNote,
                ids: selectedRowsId
            }
            return fetchMethodRequest('POST', `nsScoredProperties/inclusionUpdate`, editObj)
                .then(async (response) => {
                    if (response && response && response.respMessage) {
                        this.props.getshowSelectederrormsg(response.respMessage, true, "success")
                        await this.setState({
                            selectedExclude: "",
                            selectedExcludeNote: ""
                        })
                        this.props.removeSelectedRows()
                        this.props.getDataFromServer(this.props.filterCriteria)
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        await this.setState({
                            sessionWarning: true,

                        })
                    } else if (response && response['errorMessage']) {
                        this.props.getErrorMessage(response.errorMessage, "", "error")
                    }
                }).catch((err) => {
                    return err;
                })
        }
    }

    editorTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <span style={{ margin: '.5em .25em 0 0' }} data-toggle="tool-tip"
                    title={option.value.tooltip}
                >{option.label}</span>
            );
        }
    }



    handlegetdata = () => {

    }

    render() {
        const { optFields, selectedRows } = this.props
        const customFilter = (option, searchText) => {
            if (
                option && option.data && option.data.BusinessName && option.data.BusinessName.toLowerCase().includes(searchText.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        };
        let settingsData = this.props.settingsData
        let isEditted = localStorage.getItem("ISEdited") ? true : false
        return (
            <div className={this.props.type === "Deal Sizing Property Editor" ? "w-100" : 'px-0 h-100'}>
                <div className={this.props.type === "Deal Sizing Property Editor" ? "col-sm-12 d-flex" : 'col-sm-12 d-flex px-0 h-100'}>
                    <div className="col-sm-6">
                        <Row className='ml-0' style={{ height: 60 }}>
                            <div className='col-sm-12 d-flex px-0'>
                                <div className={this.props.type === "Deal Sizing Property Editor" ? "col-sm-6 pr-2 pl-0" : "col-sm-6 px-0"}>
                                    <span>Prop. Status </span>
                                    <div
                                        className="p-inputgroup searchField" style={{ display: "grid" }}
                                    >
                                        <Dropdown
                                            value={selectedRows.length === 0 ? "" : this.state.newselectedStatus}
                                            filter={true}
                                            options={this.state.propertyStatusArray}
                                            disabled={isEditted || this.props.isDisabledTab || selectedRows.length === 0 || this.state.blockFileFields}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "newStatus")}
                                            itemTemplate={this.editorTemplate}
                                        />
                                    </div>
                                </div>
                                <div className={this.props.type === "Deal Sizing Property Editor" ? "col-sm-6 pl-2" : "col-sm-6"} style={this.props.type === "Deal Sizing Property Editor" ? { paddingRight: "30px" } : {}}>
                                    <div
                                        className=""
                                        style={this.props.selectedRows.length > 0 && this.state.newselectedStatus && this.state.newselectedStatus.status ? { display: "grid" } : { display: "none" }
                                        }
                                    >
                                        <span>Prop. Status Reason
                                            {this.state.newselectedStatus && this.state.newselectedStatus.status.length > 0 ? <span >
                                                <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 11 }}
                                                /></span> : null}
                                        </span>
                                        <Dropdown
                                            value={this.props.selectedRows.length === 0 ? "" : this.state.newselectedReason}
                                            style={{ borderLeft: "1px solid lightgray", height: 34 }}
                                            options={this.state.propertyReasonArray}
                                            filter={true}
                                            disabled={this.props.isDisabledTab || this.state.blockFileFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "newReason")}
                                            itemTemplate={this.editorTemplate}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Row>
                        {this.state.showSelectederror ?
                            <div className="col-sm-12 row mt-2 mb-2 errorColor" style={{ marginLeft: 1 }}>{this.state.errormsg}</div>
                            : null}
                        <>
                            <div className="col-sm-12 row ml-0 px-0" style={{ height: 60 }}>
                                <div className={this.props.type === "Deal Sizing Property Editor" ? "d-none" : "col-sm-4 px-0"}>
                                    <div style={this.props.type === "Claims" ? { display: "none" } : { display: "grid" }} className='sellerCNNamesDropDown'>
                                        <span>Mapped Name</span>
                                        <Select
                                            styles={customStyles}
                                            name="status"
                                            id={"status"}
                                            value={this.props.selectedRows.length > 0 ? this.state.selectedFiledBusinessName : ""}
                                            isSearchable={true}
                                            onChange={(e) => this.handlePropertiesEditChange(e, "selectedFiledBusinessName")}
                                            options={this.state.mappedNames}
                                            clearable={false}
                                            // getOptionLabel={labelField}
                                            // getOptionValue={valueField}
                                            className={this.state.isEditShow ? 'react-select-isedited' : 'react-select'}
                                            // className="react-select-isedited "
                                            placeholder={""}
                                            isDisabled={this.state.blockFileFields || isEditted}
                                            classNamePrefix={`react-select`}

                                            style={
                                                { background: 'white', textTransform: 'capitalize', color: 'black' }}
                                            filterOption={customFilter}
                                        />
                                    </div>


                                </div>
                                <div className={this.props.type === "Deal Sizing Property Editor" ? "d-none" : "col-sm-4"}>
                                    <div style={this.props.type === "Claims" ? { display: "none" } : { display: "grid" }}>
                                        <span>Relevant Seller Name</span>

                                        <Select
                                            styles={customStyles}
                                            name="status"
                                            id={"status"}
                                            value={this.props.selectedRows.length > 0 ? this.state.relevantSellerName : ""}
                                            isSearchable={true}
                                            onChange={(e) => this.handlePropertiesEditChange(e, "relevantSellerName")}
                                            options={this.state.fileForOptions}
                                            clearable={false}
                                            className={this.state.isEditShow ? 'react-select-isedited' : 'react-select'}
                                            placeholder={""}
                                            isDisabled={this.state.blockFileFields || isEditted}
                                            classNamePrefix={`react-select`}
                                            style={
                                                { background: 'white', textTransform: 'capitalize', color: 'black' }}
                                            filterOption={customFilter}
                                        />
                                    </div>
                                </div>
                                {/* {this.props.type !== "Deal Sizing Property Editor" ?
                                    <div className="col-sm-4">
                                        {this.props.type === "ClaimsManagement" ? null : <div >
                                            <div style={this.props.type === "ClaimsManagement" ? { display: "none" } : { display: "grid" }}>
                                                <span>{this.props.type === "Claims" ? "State Claim ID" : " St. Claim ID"}</span>
                                                <InputText
                                                    // type="search"
                                                    value={this.state.claimID ? this.state.claimID : ""}
                                                    name='claimID'
                                                    id='claimID'
                                                    disabled={isEditted}
                                                    style={{ height: "34px" }}
                                                    // disabled={this.state.blockPropertyFields || isEditted}
                                                    onChange={(e) => this.handlePropertiesEditChange(e.target.value, "claimID")}

                                                />
                                            </div>
                                        </div>}

                                    </div>
                                    : ""} */}
                            </div>

                        </>
                    </div>
                    <div className="col-sm-6 px-0">
                        <div className="col-sm-12 d-flex px-0">
                            <div className="col-sm-10 d-flex" >
                                <span style={{ color: "Blue", "font-weight": "bold", }}>
                                    {this.props.type === "Deal Sizing Property Editor" ? "Note :" : "Mapping Note :"}
                                </span>
                                <InputTextarea
                                    cols={50}
                                    rows={7}
                                    value={this.props.selectedRows.length === 0 ? "" : this.state.selectednote}
                                    style={{
                                        borderLeft: "1px solid lightgray", marginBottom: "12px", marginTop: "6px", marginLeft: "10px", maxHeight: 130
                                    }}
                                    disabled={this.props.isDisabledTab || this.state.blockPropertyFields || isEditted || this.props.selectedRows.length === 0}
                                    onChange={(e) => this.handlePropertiesEditChange(e.target.value, "note")}
                                />
                                {this.props.type === "Property Editor V2" ? <FontAwesomeIcon icon={faCopy}
                                    className='pl-1'
                                    color='red'
                                    data-toggle="tool-tip"
                                    title="Copy"
                                    onClick={(e) => this.clipToCopyBoard(this.state.selectednote)}
                                    style={{ width: 15 }}
                                /> : null}
                            </div>
                            <div className='col-sm-2' style={{
                                display: "grid",
                                alignContent: "center",

                            }}>
                                <Button color="primary"
                                    outline
                                    className="p-1 ml-2 mr-3"
                                    style={{ cursor: "pointer", height: 34, width: 115, }}
                                    disabled={this.props.isDisabledTab || isEditted || this.state.copiedOnceAddressProof || this.state.copiedOnceFeinProof || this.state.copiedOnceHolderProof || this.state.copiedOnceNameProof || this.state.copiedOnceSellerProof}
                                    onClick={() => this.handlePropertiesEditChange("", "save")}
                                >
                                    Save
                                </Button>
                                <Button color="danger"
                                    outline
                                    className="p-1 ml-2 mr-3"
                                    disabled={this.props.isDisabledTab || isEditted}
                                    style={{ cursor: "pointer", height: 34, width: 115, }}
                                    onClick={() => this.handlePropertiesEditChange("", "Cancel")}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.type !== "Deal Sizing Property Editor" ?
                    <p className="mb-2 mt-0 ml-3" style={{ color: "Blue", "font-weight": "bold", }}> Evidence</p>
                    : ""}
                {this.props.type !== "Deal Sizing Property Editor" ?
                    <div class="row mx-0">
                        <div
                            className=" col-sm-1" style={{ display: "grid" }}
                        >
                            {
                                !this.state.SellerProofExpand ?
                                    <div className="d-flex"><span>
                                        <span>{"Seller"}</span>
                                        <InputTextarea
                                            // type="search"
                                            value={this.state.SellerProof ? this.state.SellerProof : ""}
                                            name='SellerId'
                                            id='SellerId'

                                            style={{
                                                width: "250px",
                                                height: "32px"
                                            }}
                                            disabled={isEditted}
                                            // disabled={this.state.blockPropertyFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "SellerProof")}


                                        />
                                    </span>
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("SellerProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        title="Copy"
                                                        data-toggle="tooltip"
                                                        // component={renderCheckBoxField}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copySellerProof}
                                                        onChange={(e) => this.copySellerProof("SellerProof")}
                                                    />
                                                </div> : null}
                                        </span>

                                    </div> :
                                    <div className="d-flex"><span>
                                        <span>{"Seller"}</span>
                                        <InputTextarea
                                            // rows={2} cols={30}
                                            style={{
                                                width: "250px",
                                                height: "68%",
                                            }}
                                            name='SellerId'
                                            id='SellerId'
                                            disabled={isEditted}
                                            value={this.state.SellerProof ? this.state.SellerProof : ""}
                                            // onClick={(e) => this.sellerProofExpand("SellerProof")}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "SellerProof")}
                                        // autoResize
                                        />
                                    </span>
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("SellerProof")}
                                      style={{ width: 15 }}
                                    /> */}
                                            {/* </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        title="Copy"
                                                        data-toggle="tooltip"
                                                        // component={renderCheckBoxField}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copySellerProof}
                                                        onChange={(e) => this.copySellerProof("SellerProof")}
                                                    />
                                                </div> : null}
                                        </span>
                                    </div>
                            }



                        </div>
                        <div
                            className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >

                            {
                                !this.state.NameProofExpand ?
                                    <div className="d-flex"><span>
                                        <span>{"Name"}</span>
                                        <InputTextarea
                                            // type="search"
                                            value={this.state.NameProof ? this.state.NameProof : ""}
                                            name='sellerName'
                                            id='sellerName'
                                            disabled={isEditted}
                                            style={{
                                                width: "250px",
                                                height: "32px"
                                            }}
                                            // disabled={this.state.blockPropertyFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "NameProof")}

                                        />
                                    </span >
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>
                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("NameProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copyNameProof}
                                                        onChange={(e) => this.copySellerProof("NameProof")}
                                                    />
                                                </div> : null}</span>
                                    </div> :
                                    <div className="d-flex"><span>
                                        <span>{"Name"}</span>
                                        <InputTextarea
                                            style={{
                                                width: "250px",
                                                height: "68%",
                                                marginBottom: "5px"
                                            }}
                                            name='sellerName'
                                            id='sellerName'
                                            disabled={isEditted}
                                            value={this.state.NameProof ? this.state.NameProof : ""}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "NameProof")}
                                        // autoResize
                                        /></span>
                                        <div className='mt-4'>
                                            {/* <span >
                                      <FontAwesomeIcon icon={faArrowRight}
                                        className='pl-1 ml-2'
                                        color='red'
                                        data-toggle="tool-tip"
                                        title="Expand"
                                        onClick={(e) => this.sellerProofExpand("NameProof")}
                                        style={{ width: 15 }}
                                      />
                                    </span> */}
                                            {this.state.enableCopyProof ?
                                                <span >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copyNameProof}
                                                        onChange={(e) => this.copySellerProof("NameProof")}
                                                    />
                                                </span> : null}</div>
                                    </div>
                            }

                        </div>
                        <div
                            className="col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >

                            {
                                !this.state.AddressProofExpand ?
                                    <div className="d-flex"><span>
                                        <span> {"Address"}</span>
                                        <InputTextarea
                                            // type="search"
                                            value={this.state.AddressProof ? this.state.AddressProof : ""}
                                            name='sellerAddress'
                                            id='sellerAddress'
                                            disabled={isEditted}
                                            style={{
                                                width: "250px",
                                                height: "32px"
                                            }}
                                            // disabled={this.state.blockPropertyFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "AddressProof")}

                                        />
                                    </span>
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>

                                    <FontAwesomeIcon icon={faArrowRight}
                                      className='pl-1'
                                      color='red'
                                      data-toggle="tool-tip"
                                      title="Expand"
                                      onClick={(e) => this.sellerProofExpand("AddressProof")}
                                      style={{ width: 15 }}
                                    />
                                  </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        className=" ml-2"
                                                        checked={this.state.copyAddressProof}
                                                        onChange={(e) => this.copySellerProof("AddressProof")}
                                                    />
                                                </div> : null}
                                        </span>
                                    </div> :
                                    <div className="d-flex"><span>
                                        <span>{"Address"}</span>
                                        <InputTextarea

                                            style={{
                                                width: "250px"
                                                ,
                                                height: "68%",
                                            }}
                                            name='sellerAddress'
                                            id='sellerAddress'
                                            disabled={isEditted}
                                            value={this.state.AddressProof ? this.state.AddressProof : ""}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "AddressProof")}
                                        // autoResize
                                        />
                                    </span>
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>

                                  <FontAwesomeIcon icon={faArrowRight}
                                    className='pl-1'
                                    color='red'
                                    data-toggle="tool-tip"
                                    title="Expand"
                                    onClick={(e) => this.sellerProofExpand("AddressProof")}
                                    style={{ width: 15 }}
                                  />
                                </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        className=" ml-2"
                                                        checked={this.state.copyAddressProof}
                                                        onChange={(e) => this.copySellerProof("AddressProof")}
                                                    />
                                                </div> : null}
                                        </span>
                                    </div>
                            }
                        </div>
                        <div
                            className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >
                            {
                                !this.state.FeinProofExpand ?
                                    <div className="d-flex"><span>
                                        <span> {"FEIN"}</span>
                                        <InputTextarea
                                            // type="search"
                                            value={this.state.FeinProof ? this.state.FeinProof : ""}
                                            name='sellerFein'
                                            id='sellerFein'
                                            style={{
                                                width: "250px",
                                                height: "32px"
                                            }}
                                            disabled={isEditted}
                                            // disabled={this.state.blockPropertyFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "FeinProof")}

                                        />
                                    </span>
                                        <span className='mt-4'>
                                            {/* <div className='ml-2'>
                                      <FontAwesomeIcon icon={faArrowRight}
                                        className='pl-1'
                                        color='red'
                                        data-toggle="tool-tip"
                                        title="Expand"
                                        onClick={(e) => this.sellerProofExpand("FeinProof")}
                                        style={{ width: 15 }}
                                      />
                                    </div> */}
                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        checked={this.state.copyFeinProof}
                                                        onChange={(e) => this.copySellerProof("FeinProof")}
                                                    />
                                                </div> : null}
                                        </span>
                                    </div> :
                                    <div className="d-flex"><span>
                                        <span>{"FEIN"}</span>
                                        <InputTextarea

                                            style={{
                                                width: "250px"
                                                ,
                                                height: "68%",
                                            }}
                                            name='sellerFein'
                                            id='sellerFein'
                                            disabled={isEditted}
                                            value={this.state.FeinProof ? this.state.FeinProof : ""}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "FeinProof")}
                                        // autoResize 
                                        />
                                    </span>
                                        <span className='mt-4'>

                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        checked={this.state.copyFeinProof}
                                                        onChange={(e) => this.copySellerProof("FeinProof")}
                                                    />
                                                </div> : null}
                                        </span>
                                    </div>
                            }




                        </div>
                        <div
                            className=" col-sm-1" style={{ display: "grid", marginLeft: "160px" }}
                        >
                            {
                                !this.state.HolderProofExpand ?
                                    <div className="d-flex"><span>
                                        <span> {"Holder"}</span>
                                        <InputTextarea
                                            // type="search"
                                            value={this.state.HolderProof ? this.state.HolderProof : ""}
                                            name='sellerHolder'
                                            style={{
                                                width: "250px",
                                                height: "32px"
                                                ,
                                                // height: "100%"
                                            }}
                                            id='sellerHolder'
                                            disabled={isEditted}
                                            // disabled={this.state.blockPropertyFields || isEditted}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "HolderProof")}

                                        />
                                    </span>
                                        <span className='mt-4'>

                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copyHolderProof}
                                                        onChange={(e) => this.copySellerProof("HolderProof")}
                                                    />
                                                </div>
                                                : null}
                                        </span>
                                    </div> :
                                    <div className="d-flex"><span>
                                        <span>{"Holder"}</span>
                                        <InputTextarea
                                            style={{
                                                width: "250px",
                                                height: "68%",
                                            }}
                                            id='sellerHolder'
                                            disabled={isEditted}
                                            value={this.state.HolderProof ? this.state.HolderProof : ""}
                                            onChange={(e) => this.handlePropertiesEditChange(e.target.value, "HolderProof")}
                                        // autoResize 
                                        />
                                    </span>
                                        <span className='mt-4'>

                                            {this.state.enableCopyProof ?
                                                <div >
                                                    <Checkbox
                                                        label=''
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        // style={{ marginLeft: "460px", marginTop: "-33px" }}
                                                        name={'copy'}
                                                        className=" ml-2"
                                                        checked={this.state.copyHolderProof}
                                                        onChange={(e) => this.copySellerProof("HolderProof")}
                                                    />
                                                </div>
                                                : null}
                                        </span>
                                    </div>
                            }
                        </div>
                        <div
                            className=" col-sm-1" style={{ display: "grid", marginLeft: "140px" }}
                        >
                            <Button color="primary"
                                outline
                                className="p-1 ml-2 mr-3"
                                disabled={this.state.copiedOnceAddressProof || this.state.copiedOnceFeinProof || this.state.copiedOnceHolderProof || this.state.copiedOnceNameProof || this.state.copiedOnceSellerProof ? false : true}
                                style={{ cursor: "pointer", height: 34, width: 166, marginTop: "20px" }}
                                onClick={() => this.handlePropertiesEditChangeProof("", "save")}
                            >
                                Copy & Save Evidence
                            </Button>
                        </div>
                    </div> : ""}
                {
                    this.state.isOpenConfirmationModal ?
                        this.getConfirmaionModel() : null
                }
                {this.state.openUnsaveModal ? this.getDeleteRowModal() : null}

            </div>

        );
    }
}

export default withTranslation('common')(PropertyEditor);
