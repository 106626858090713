import React from 'react';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import * as XLSX from 'xlsx/xlsx.js'
import apiCalls from '../../../config/apiCalls';

class SummaryStatsExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentDidMount = async () => {
    // this.getStatsFromServer()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }


  componentWillUnmount = async () => {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  formatCurrency = (amount) => {
    if (typeof amount !== 'number' || !isFinite(amount) || amount < 0) {
      return amount;
    }

    // Format as currency
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(amount);
  }


  getStatsFromServer = async (sellerID, sellerName) => {
    return await fetchMethodRequest('GET', `${apiCalls.dealSizingSummaryExport}?sellerID=${sellerID}`)
      .then(async (response) => {
        if (response) {
          let totalMaxCount = 0
          let totalMaxValue = 0
          let totalMaxCA_Count = 0
          let totalMaxCA_Value = 0
          let totalKnownCount = 0
          let totalKnownValue = 0
          let totalUnKnown100Count = 0

          response.dealSizingStats.map(item => {
            totalMaxCount += item.maxCount;
            totalMaxValue += item.maxValue;
            totalMaxCA_Count += item.maxCA_Count;
            totalMaxCA_Value += item.maxCA_Value;
            totalKnownCount += item.knownCount;
            totalKnownValue += item.knownValue;
            totalUnKnown100Count += item.unKnown100Count;
          }
          );

          const Headers = [
            "Name ID",
            "Name",
            "Name Connection",
            "Connected Name",
            "Source",
            "Acquired Year",
            "Count – Total",
            "Value - Total",
            "Count - CA",
            "Value - CA",
            "Known Count - Total",
            "Known Value – Total",
            "Unknown/Over $100 Count – Total ",
          ];

          const Data = response.dealSizingStats.map(item => [
            item.businessNamesID,
            item.businessName,
            item.nameConnection,
            item.connectedName,
            item.nameSource,
            item.acquiredYear,
            item.maxCount,
            this.formatCurrency(item.knownValue),
            item.maxCA_Count,
            this.formatCurrency(item.maxCA_Value),
            item.knownCount,
            this.formatCurrency(item.knownValue),
            item.unKnown100Count,
          ]);

          let section2 = [...[Headers], ...Data]

          console.log(section2)
          return [
            {

              stats: [
                [
                  "EstateId",
                  "EstateName",
                  "",
                  "",
                  "",
                  "Snapshot Date",
                  "Count - Total",
                  "Value - Total",
                  "Count - CA",
                  "Value - CA",
                  "Known Count – Total",
                  "Known Count – Value",
                  "Unknown/Over $100 Count – Total",
                ],
                [
                  sellerID,
                  sellerName,
                  "",
                  "",
                  "",
                  new Date(),
                  totalMaxCount,
                  this.formatCurrency(totalKnownValue),
                  totalMaxCA_Count,
                  this.formatCurrency(totalMaxCA_Value),
                  totalKnownCount,
                  this.formatCurrency(totalKnownValue),
                  totalUnKnown100Count,
                ],
              ],
            },
            {

              stats: section2,
            },
          ];
        }
      }).catch((err) => {
        return err;
      });
  }


  exportSaleSheet = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    let row = 0;

    data.forEach((sectionData, index) => {

      row += 1;

      XLSX.utils.sheet_add_aoa(ws, sectionData.stats, { origin: row });

      const headerRow = row + 1;
      const headerColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      headerColumns.forEach((col) => {
        ws[`${col}${headerRow}`] = ws[`${col}${headerRow}`] || {};
        ws[`${col}${headerRow}`].s = {
          fill: {
            fgColor: { rgb: "008080" }
          },
          font: { bold: true },
        };
      });

      row += sectionData.stats.length;

      if (index < data.length - 1) {
        const borderRow = row;

        const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
        columns.forEach((col) => {
          const cell = `${col}${borderRow}`;
          ws[cell] = ws[cell] || {};
          ws[cell].s = ws[cell].s || {};
          ws[cell].s.border = {
            bottom: { style: "thick", color: { rgb: "000000" } },
          };
        });

        row += 1;
      }
    });

    ws['!cols'] = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
    ];

    XLSX.utils.book_append_sheet(wb, ws, "SummaryStats");

    XLSX.writeFile(wb, "SummaryStats.xlsx");
  };

  handleExport = async (sellerID, sellerName) => {
    const data = await this.getStatsFromServer(sellerID, sellerName);
    if (data) {
      this.exportSaleSheet(data);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
      </div >
    );
  }
}
export default withTranslation('common')(SummaryStatsExportModal);