import React, { useEffect, useState } from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from 'reactstrap';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes,faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../CommonDataTable/DataTable';
import fetchMethodRequest from "../../../config/service";
import TableFields from './MailForwardsIntegrityFields.json';
import TableFieldsForHistory from './MailFwdIntegrityHistoryFields.json';
import { Dialog } from 'primereact/dialog';
import Loader from '../../App/Loader';
import config from '../../../config/config';
import '../../../scss/component/pdf-editor.scss';

const MailForwardsIntegrity = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [updateData, setUpdateData] = useState<any>({});
    const [users, setUsers] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [respMessage, setRespMessage] = useState<any>('');
    const [recordCount, setRecordCount] = useState<any>({ CR: 0, BK: 0 });
    const [showUSPSDialog, setShowUSPSDialog] = useState<any>(false);
    const [selMailFwdID, setSelMailFwdID] = useState<any>('');
    const [fileName, setFileName] = useState<string>('');
    const [fileError, setFileError] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<any>('');
    const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
    const [showPDFDialog, setShowPDFDialog] = useState<boolean>(false);
    const [pdfURL, setPdfURL] = useState<any>('')

    useEffect(() => {
        getMailFwdStatuses();
        getUsers();
        getTheOtherTabCount();
    }, []);

    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        if (activeTab === 2 || activeTab === 4) {            
            tableFields = JSON.parse(JSON.stringify(TableFieldsForHistory));            
        } else {
            tableFields.push({
                show: true,
                mobile: true,
                textAlign: 'left',
                width: 100,
                field: '',
                header: 'Action',
                fieldType: "customActions",
                getCustomActions: getActionItems,
                "filter": false,
                "sortable": false,

            })
        }
        for(let i=0;i<tableFields.length;i++){
            if(tableFields[i].field === 'USPSScreenshot'){
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = showPDFScreenshot;
                break;
            }
        }
        return tableFields;
    }

    const getActionItems = (row: any) => {
        return <div className="text-center p-1">
            {(row.LastFwdRequestDate && row.MailFwdAtUSPSExpiredFlag) ? <Button color="primary" size='sm' className='mb-0' onClick={() => { onClickRenew(row) }}>
               <small> Renew </small>
            </Button> : !row.LastFwdRequestDate && <Button color="primary" className='mb-0' size='sm' onClick={() => { onClickNew(row) }}>
                <small> New </small>
            </Button>}
        </div>
    }

    const showPDFScreenshot = (row: any) => {
        return <div className="text-center">
            {(row.USPSScreenshot && row.USPSScreenshot.data) ? <FontAwesomeIcon icon={faFilePdf} className="cursor-pointer" onClick={() => onClickShowPDF(row.USPSScreenshot.data)}/> : ''}
        </div>

    }

    const onClickShowPDF = (pdfBuffer:any) => {
        const pdfURL = URL.createObjectURL(new Blob([new Uint8Array(pdfBuffer).buffer],{ type: "application/pdf" }));
        setShowPDFDialog(true);        
        setPdfURL(pdfURL)
    }

    const onClickNew = (row:any) => {
        setRespMessage('');
        setShowUSPSDialog(true);
        setSelMailFwdID(row.MailFwdTrackingID);
        setUpdateData({})
    }

    const onClickRenew = (row:any) => {
        setRespMessage('');
        setShowUSPSDialog(true);
        setSelMailFwdID(row.MailFwdTrackingID);
        setUpdateData({MailForwardNote:row.MailForwardNote,USPSMailFwdConfmCode:row.USPSMailFwdConfmCode,USPSErrorMessage:row.USPSErrorMessage})
    }

    const onSelectRows = (selectedRows: Array<any>) => {
        setSelectedRows(selectedRows);
    }    

    const getUsers = async () => {
        const res = await fetchMethodRequest('GET', 'users?filters: {"limit":1000,"page":1,"criteria":[{"key":"role","value":["Search Manager","Search Analyst"],"type":"in","add_Filter":false}],"sortfield":"created","direction":"desc"}', '', '', '', '');
        if (res && res.users && res.users.length > 0) {
            setUsers(res.users.filter((e: any) => e.display_name.includes('Integrity')));
        }
    }

    const getMailFwdStatuses = async () => {
        const res = await fetchMethodRequest('GET', `mftStatusAndReasons/getAllStatusAndReasons`, '', '', '', '');
        if (res && res.mftAllStatusAndReasons && res.mftAllStatusAndReasons.length > 0) {
            const filtersData = res.mftAllStatusAndReasons.filter((e: any) => e.screenTypeForStatus.includes("Mail Forwards Assignment"));
            setMftStatuses(filtersData);
            if (filtersData.length === 1) {
                onChangeInputValues('mailFwdStatus', filtersData[0]);
            }
        }
    }

    const onChangeInputValues = (field: string, value: any) => {
        setUpdateData((prevDetails: any) => ({ ...prevDetails, [field]: value }));        
    }

    const doUpdate = async () => {
        setRespMessage('');
        setIsLoading(true);
        setDoRefresh(false);
        let data:any = {
            MailForwardNote:updateData.MailForwardNote      
        }

        if(updateData.USPSErrorMessage) data.USPSErrorMessage = updateData.USPSErrorMessage;
        if(updateData.USPSMailFwdConfmCode) data.USPSMailFwdConfmCode = updateData.USPSMailFwdConfmCode;       
        let formData = new FormData();
        formData.append('data',JSON.stringify(data));        
        formData.append('viaIntegrity',JSON.stringify(true));
        formData.append('mailFwdID',selMailFwdID); 
        formData.append('USPSScreenshot',selectedFile);
        const loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials") || '') : {};
        formData.append('userid',loginCredentials.azureAdUserId);
        try {
            const resPromise = await fetch(`${config.mailForwardsURL}mailForwardsDetails/updateMFTIntegrity`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${loginCredentials.accessToken}`
                },
            });            
            const res = await resPromise.json(); 
            setIsLoading(false);                     
            if (res && res.success) {
                setDoRefresh(true);
                setShowUSPSDialog(false);
                setShowSuccessDialog(true);
            }
            if(res && res.message){
                setRespMessage(res.message);                
            }
        } catch (err) {        
            return Promise.resolve({ errorMessage: 'Something went wrong! contact OPRA Admin' });
        } 
        
    }

    const setRecordsCount = (count: any, tabType: string) => {
        setRecordCount((prevCount: any) => ({ ...prevCount, [tabType]: count }));
    }

    const convertPdfToBase64 = (file: File): Promise<any> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if (reader.result) {
                    resolve(reader.result);
                } else {
                    reject(null);
                }
            };

            reader.onerror = () => {
                reject(null);
            };           
        });
    }

    // Handle file change
    const handleFileChange = async (event: any) => {        
        const selectedFile = event.target.files && event.target.files[0] ? event.target.files[0] : null;
        if (selectedFile) {
            // Check if the file is a .pdf            
            const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
            const fileBase64String = await convertPdfToBase64(selectedFile);            
            if (fileExtension === 'pdf' && fileBase64String) {
                setFileName(selectedFile.name);
                setSelectedFile(selectedFile);
                onChangeInputValues('USPSScreenshot', fileBase64String);
            } else {
                setFileName('');
                onChangeInputValues('USPSScreenshot', '');
                setFileError('Please select PDF file')
            }
        }
    };

    const getTheOtherTabCount = async () => {
        const loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials") || '') : {};
        const body = {
            dealType: activeTab === 1 ? 'BK' : 'CR',
            userid:loginCredentials.azureAdUserId
        }
       

        const res = await fetchMethodRequest('POST','mailForwardIntegrity/getMFTIntegrity',body,'','','');
        if(res && res.details && (res.details.totalCount || res.details.totalCount === 0)){
            setRecordsCount(res.details.totalCount, activeTab === 1 ? 'BK' : 'CR');
        }
    }

    const getDataGrid = () => {
        const tabType: string = (activeTab === 1 || activeTab === 2) ? 'CR' : 'BK';
        const api: string = (activeTab === 1 || activeTab === 3) ? 'mailForwardIntegrity/getMFTIntegrityFiltered' : (activeTab === 2 || activeTab === 4) ? 'mailForwardIntegrity/getMFTIntegrityHistoryFiltered' : '';
        return <DataTables            
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"LastFwdRequestDate"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            isSelectMode={""}
            // isShownnFieldType={true}
            sample={false}
            scrollHeight={"580px"}
            dataKey={"MailFwdTrackingID"}            
            globalSearch={'Search'}
            type={"Mail Forwards Integrity"}
            displayright={true}
            icon='faCity'
            // settingsData={this.state.settingsData}
            fontAwesome={true}
            MFTab={tabType}
            // routeTo='propertyEditor'
            displayViewOfForm='modal'
            hideHeader={true}
            // apiResponseKey='details'
            className={true}
            // apiUrl={"mailForwardAssignments/getMFTAssignmentsFiltered"}
            apiUrl={api}
            entityType='employee'
            // selDealId={dealId}
            // docLibraryTopFilters={docLibraryTopFilters}
            hideProgress={true}
            // removeTopFilter={removeTopFilter}
            // doSearch={doSearch}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            donotIncludeParamsInFilter={true}
            showFilterElements={true}
            showTopActions={true}
            onSelectRows={onSelectRows}
            doRefresh={doRefresh}
            removePadding={true}
            setRecordsCount={setRecordsCount}
            activeTab={activeTab}
        />
    }


  



    return <div className="card-body mail-fwd-integrity p-2 mt-1">
        <Nav tabs className="border-line">
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 1, h6: true, dueHeader: true }}
                    onClick={() => { setActiveTab(1) }}
                >
                    CR Due({recordCount.CR})
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 2, h6: true }}
                    onClick={() => { setActiveTab(2) }}
                >
                    CR History
                </NavLink>
            </NavItem>
            <NavItem >
                <NavLink
                    className={{ active: activeTab === 3, h6: true, dueHeader: true }}
                    onClick={() => { setActiveTab(3) }}
                >
                    BK Due({recordCount.BK})
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={{ active: activeTab === 4, h6: true }}
                    onClick={() => { setActiveTab(4) }}
                >
                    BK History
                </NavLink>
            </NavItem>

            <NavItem className="d-flex flex-grow-1 justify-content-end pr-1">
                {/* <div>
                    <Button color="primary"  outline className="mb-1" onClick={() => { alert('Save Details') }}>
                    <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                </div> */}

            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId={1} >
                {/* {activeTab === 1 && <>{getAction()}</>} */}
                {activeTab === 1 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={2}>
                {activeTab === 2 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={3}>
                {/* {activeTab === 3 && <>{getAction()}</>} */}
                {activeTab === 3 && <>{getDataGrid()}</>}
            </TabPane>
            <TabPane tabId={4}>
                {activeTab === 4 && <>{getDataGrid()}</>}
            </TabPane>


        </TabContent>

        <Dialog header="Update" showHeader={false} visible={showUSPSDialog} style={{ width: '50vw' }} modal={true} onHide={() => setShowUSPSDialog(false)}>
        
            <div className="p-1 text-right">
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => {setShowUSPSDialog(false)}}/>
            </div>
            <div className="p-3">
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Confirmation Number</label>
                    <div className="col-sm-8">
                        <InputText className="w-100" value={updateData.USPSMailFwdConfmCode} onChange={(e) => onChangeInputValues('USPSMailFwdConfmCode', (e.target as HTMLInputElement).value)} disabled={updateData.USPSErrorMessage} />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Error Message (If any)</label>
                    <div className="col-sm-8">
                        <InputTextarea rows={5} className="w-100" value={updateData.USPSErrorMessage} onChange={(e) => onChangeInputValues('USPSErrorMessage', (e.target as HTMLInputElement).value)} disabled={updateData.USPSMailFwdConfmCode} />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end align-items-center">USPS Screenshots (.pdf only)</label>
                    <div className="col-sm-8">                        
                        <div className="d-flex">
                            <div>
                            <label htmlFor={"custom-editor-uploader"} className="custom-upload-button">
                            Upload
                        </label>
                                <input type='file' id="custom-editor-uploader" accept=".pdf" onChange={handleFileChange}></input>
                            </div>
                            <div className="ml-2">
                                {fileName && <span>{fileName}</span>}
                                {fileError && <span className="text-danger">{fileError}</span>}
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-sm-3 mb-0 pr-0 label-mailF-details d-flex justify-content-end ">Note</label>
                    <div className="col-sm-8">
                        <InputTextarea rows={5} className="w-100" value={updateData.MailForwardNote} onChange={(e) => onChangeInputValues('MailForwardNote', (e.target as HTMLInputElement).value)} />
                    </div>
                </div>
                {respMessage && <div className="text-center text-danger mt-2">
                    {respMessage}
                </div>}
                <div className="text-center mt-2">
                    <Button color="primary"  onClick={doUpdate} disabled={!updateData.USPSErrorMessage && !updateData.USPSMailFwdConfmCode}>
                        Update
                    </Button>
                </div>
            </div>
            <Loader loader={isLoading} />
        </Dialog>
        <Dialog showHeader={false} visible={showSuccessDialog} onHide={() => setShowSuccessDialog(false)} style={{ width: '30vw' }}>
            <div className="p-3  text-center">
                <h5 className="text-success">{respMessage}</h5>
                <div className="text-center mt-3">
                    <Button color="primary" onClick={() => { setShowSuccessDialog(false) }} >
                        OK
                    </Button>
                </div>
            </div>
        </Dialog>

        <Dialog showHeader={false} visible={showPDFDialog} style={{ width: '85vw' }} modal={true} onHide={() => setShowPDFDialog(false)}>

            <div className="p-1 text-right">
                <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => { setShowPDFDialog(false) }} />
            </div>
            <div className="p-2">
                <iframe src={pdfURL} style={{'width':'100%',height:'90vh'}} />
            </div>
        </Dialog>

        
        <Loader loader={isLoading} />
    </div>

}

export default MailForwardsIntegrity;