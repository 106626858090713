
import React from 'react';
import { Button, Row } from 'reactstrap';
// fecth method from service.js file
import fetchMethodRequest from '../../../../config/service';
import fetch from '../../../../config/service';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { OverlayPanel } from 'primereact/overlaypanel';
import { withTranslation } from 'react-i18next';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionWarningModal from '../../../Cruds/CommonModals/SessionWarningModal';
import DeleteRowModal from '../../../Cruds/CommonModals/DeleteRowModal';
import apiCalls from '../../../../config/apiCalls';
import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import { Redirect } from 'react-router';
import configMessages from '../../../../config/configMessages';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Accordion, AccordionTab } from 'primereact/accordion';
import statesList from '../../../../shared/states.json'

class PropertyFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sellers: [],
      filterCriteria: {},
      lastUpdatedDate: "",
      isChangeHit: false,
      filterednameSuggestions: [],
      sellerName: "",
      setGoButton: false,
      isSharing: false,
      modifiedAddress: [],
      selectedViewType: "Default",
      isexcludeProperties: "",
      SessionWarningModal: false,
      selectedAnalyst: [],
      isexcludeRoyalties: true,
      // taskProperties: {},
      isredirtRanges: false,
      selectedState: [],
      stateUP: true,
      sec: true,
      isCA_N: true,
      selectedDate: null,
      page: 0,
      modifiedStates: [...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
      isValidate: false,
      excludeCoOwners: false,
      excludeNotConfirmedOnSite: this.props.type === "Deal Sizing Property Editor" ? false : true,
      isexcludePossiblyPaid: false,
      modifiedStatus: [
        { label: "Possible Confirmation", value: "Possible Confirmation" },
        { label: "Waiting for Validation", value: "Waiting for Validation" },
      ],
      dropdownOpen: false,
    };
  }

  getClaimStatus = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.ClaimStatuses}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["claimStatuses"] && response["claimStatuses"].length > 0) {
        let propertyStatuses = response["claimStatuses"]
        for (let status of propertyStatuses) {
          if (status.status !== null) {
            StatusArray.push({ label: status.status, value: status.status })
          }
        }
        this.setState({ claimStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [], StateDataSource = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          if (status.dataSourceNames !== null && status.group !== null && status.group !== "State UP Data" && status.group !== "CA-N" && status.group !== "SEC") {
            StatusArray.push({ label: status.group, value: status.dataSourceNames })
            dataArray.push({ label: status.group, value: status.group })
          }
          if (status.group === "State UP Data" || status.group === "CA-N" || status.group === "SEC" || status.group === "Empty") {
            StateDataSource.push({ label: status.group, value: status.dataSourceNames })
          }
          if (status.group === "Empty") {

            await this.setState({ setDefaultDataGroup: true })
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray, StateDataSource: StateDataSource })
      }
    })
    return this.state.settingsData;
  }

  getNewStatusForEditor = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: this.props.type === 'Deal Sizing Property Editor' ? 'Deal Sizing Property Editor' : 'Property Editor', type: 'eq', add_Filter: true }

    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ propertyStatusArray: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  removeFileForAfterRedirection = async () => {
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "fileFor")
    if (index !== -1) filtercriteria["criteria"].splice(index, 1)
    this.setState({ filterCriteria: filtercriteria })
  }

  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
    await this.props.selectedOpra(val)
  }
  closeSaveModelPopUp = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.props.closeSaveModelPopUp()
    await this.props.makeNoteEditedFalse()
    await this.submit(this.state.selectedEvent)
  }
  closeTaskAddModel = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.props.makeNoteEditedFalse()
    await this.submit(this.state.selectedEvent)
  }
  /**
 * 
 * @param {string} edited 
 * function to save the note is edited or not for slecting and unselecting records
 */
  commentEditedOrNo = async (edited) => {
    await this.setState({ edited: edited })
  }
  // Delete Modal
  getDeleteRowModalForNotes() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }
  /**
   * Settings Call
   */
  getSettings = () => {
    var responseWarningTimeout
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    let filterCriteria = {};
    if (!responseWarningTimeout) {
      filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
      fetch('GET', `settings`).then(async (response) => {
        if (response && response.settings) {
          await this.setState({
            isexcludePossiblyPaid: response.settings[0].excludePossiblyDefaultEnable
          })
        } else {
          await this.setState({
            isexcludePossiblyPaid: false
          })
          return ""
        }
      })
    }
  }

  componentDidMount = async () => {
    let loginData = localStorage.getItem("loginCredentials");
    loginData = JSON.parse(loginData);
    let defanalyst = []
    defanalyst.push(loginData.displayName)

    this.getSettings()
    this.searchsellerItems();
    this.getClaimStatus()
    this.getNewStatusForEditor()
    this.getReasonForEditor()
    this.selectedOpra("OPRA")
    await this.getDataGroupSources()
    // this.getReasonForEditor()

    let taskProperties = JSON.parse(localStorage.getItem("TaskProperties"))
    if (taskProperties) {
      if (taskProperties && taskProperties.PropertiesSellerName && taskProperties.PropertiesSellerName.EstateID) {
        await this.getSellerName(taskProperties.PropertiesSellerName.EstateID)
        taskProperties.PropertiesSellerName.EstateName = this.state.PropertiesSellerEstateName
        taskProperties.PropertiesSellerName.DealType = this.state.EstateDetails.DealType
        taskProperties.PropertiesSellerName.DealYear = this.state.EstateDetails.DealYear
        taskProperties.PropertiesSellerName.SellerNickName = this.state.EstateDetails.SellerNickName
      }
      await this.setState({ dataSource: [] })
      let selectedStates = [], selectedProperties = []
      this.searchsellerItems(taskProperties.PropertiesSellerName.EstateID, taskProperties)

      // await this.handleChange(taskProperties.PropertiesSellerName, 'Seller', "FromTask")
      if (taskProperties.States && taskProperties.States.length > 0) {
        await this.handleChange(taskProperties.States, 'State')
        for (var sine of taskProperties.States) {
          selectedStates.push(
            sine.slice(-2),
          )
        }

      }
      if (taskProperties.Property_Id && taskProperties.Property_Id.length > 0) {
        for (var sine of taskProperties.Property_Id) {
          selectedProperties.push(
            sine
          )
        }
      }

      await this.handleChange(selectedStates, "State")
      await this.setState({
        // selectedSeller: taskProperties.PropertiesSellerName,
        // selectedState: selectedStates,
        stateUP: false,
        sec: false,
        isCA_N: false,
        taskProperties: taskProperties,
        Property_Ids: selectedProperties,
        isexcludeRoyalties: false,
        excludeNotConfirmedOnSite: false,
        selectedTaskId: taskProperties.taskID.taskID,
        selectedtask: taskProperties.taskID
      })
      await this.searchnameItems()
      this.submit("taskProperties")
      localStorage.removeItem("TaskProperties")
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.reportType && (params.reportType.includes("AvailableToMap" || params.reportType.includes("AvailableToReMap"))) && (params.bucket === "Green" || params.bucket === "Blue")) {
      await this.setState({ isShowNote: true })
    }
    if (this.props.type === "Properties") {
      await this.setState({
        selectedBucket: "Overall Near Exact"
      })
    }

    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.state.setDefaultDataGroup && this.props.type === "Property Editor V2" && !taskProperties) {
      this.handleChange({ value: ["Empty"] }, 'dataSource')
    }

    let userArray = []
    return fetch('GET', `${apiCalls.Users}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            if (user.display_name !== null) {
              userArray.push({ label: user.display_name, value: user.display_name })
            }
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });

    this.setState({ isLoading: false, selectedAnalyst: defanalyst, defanalyst: defanalyst });

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  groupTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <div className=""
          data-toggle="tool-tip"
          title={option.value.description}>{option.label}
        </div>
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  getData = async (obj) => {
    if ((obj && obj.selectedRanges || obj && obj.groupedflag) && this.props.type === "Property Editor V2") {
      await this.setState({
        isredirtRanges: true,
        Group: obj.groupedflag ? obj.groupedflag : null,
        groupMessage: obj.groupMessage ? obj.groupMessage : "",
        fiftyk: obj.fiftyk ? "Filtered On Properties Max Amount Greater than $50k" : "",
        unknown: obj.unknown ? "Filtered On Unknown Properties In Single State For A Single Name" : "",
        Count10kTo50k: obj.Count10kTo50k ? "Filtered On Properties Max Amount Between $10k and $50k" : ""

      })
    }
    if (this.props.type === 'Negative Notice List Viewer' || this.props.type === "Site Confirmation Editor") {
      await this.setState({
        selectedTaskId: obj.taskID,
      })
      if (obj.selectedSeller.EstateID !== null && obj.selectedSeller.EstateID !== "") {
        this.getNegativeNoticeList(obj.selectedSeller.EstateID)

      }
    }
    if (obj.isValidate) {
      await this.setState({
        isValidate: true,
      })
    }
    if (obj.redirectedGroup) {
      this.handleChangeSelectedGroup(obj.redirectedGroup)
    }
    if (obj.taskID) {
      this.getTaskIDs(obj.taskID)
    }
    await this.setState({
      isSharing: true,
      sharedobj: obj
    })
    if (obj.selectedProperty_Id) {
      let properties = obj.selectedProperty_Id
      await this.setState({
        Property_Id: properties
      })


    }
    if (obj.selectedOpraStId) {
      let stateProperties = obj.selectedOpraStId
      await this.setState({
        OpraStId: stateProperties
      })
    }
    if (obj.legacyNameIssueCode && obj.legacyNameIssueCode.length > 0) {
      let availableToMapLegacyNameIssueCode = []
      for (let code of obj.legacyNameIssueCode) {
        availableToMapLegacyNameIssueCode.push({ label: code, value: code })
      }
      await this.setState({ nameIssueCode: obj.legacyNameIssueCode.split(","), availableToMapLegacyNameIssueCode: availableToMapLegacyNameIssueCode })

    }
    if (obj.newNameIssueCode && obj.newNameIssueCode.length > 0) {
      let availableToMapNameIssueCode = []
      for (let code of obj.newNameIssueCode) {
        availableToMapNameIssueCode.push({ label: code, value: code })
      }
      await this.setState({ newNameIssueCode: obj.newNameIssueCode.split(","), availableToMapNameIssueCode: availableToMapNameIssueCode })
    }
    if (obj.isexcludeValidated && obj.isexcludeValidated === "true") {
      await this.setState({
        isexcludeValidated: true,
      })
    } else {
      await this.setState({
        isexcludeValidated: false,
      })
    }
    if (obj.excludeNotConfirmedOnSite && obj.excludeNotConfirmedOnSite === "true") {
      await this.setState({
        excludeNotConfirmedOnSite: true,
      })
    } else {
      await this.setState({
        excludeNotConfirmedOnSite: false,
      })
    }
    if (obj.isexcludeProperties) {
      await this.setState({
        isexcludeProperties: obj.isexcludeProperties,
      })
    }
    if (obj.isexcludePossiblyPaid && obj.isexcludePossiblyPaid === "true") {
      await this.setState({
        isexcludePossiblyPaid: true,
      })
    } else {
      await this.setState({
        isexcludePossiblyPaid: false,
      })
    }
    if (obj.isexcludeRoyalties) {
      await this.setState({
        isexcludeRoyalties: true
      })
    } else {
      await this.setState({
        isexcludeRoyalties: false
      })
    }
    if (obj.excludeCoOwners && obj.excludeCoOwners === "true") {
      await this.setState({
        excludeCoOwners: true,
      })
    } else {
      await this.setState({
        excludeCoOwners: false,
      })
    }

    if (obj.states && obj.states !== "null") {
      let decodedStates = obj.states.split(",")
      await this.setState({
        selectedStates: decodedStates
      })
    }

    if (obj.selectedPropertyStatus) {
      let decodedStatus = obj.selectedPropertyStatus.split(",")
      let eventt = {}
      eventt.value = decodedStatus
      var getstatus = this.state.propertyStatusArray.filter(function (el) {
        for (var item of decodedStatus) {
          if (el.label === item) {
            return el
          }
        }
      });
      let getStatusArray = {}
      getStatusArray.value = []
      for (var data of getstatus) {
        getStatusArray.value.push(data.value)
      }
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      await this.handleChange(getStatusArray, "selectedPropertyStatus", "redirect")
    }
    if (obj.selectedPropertyReasons) {
      let decodedStatus = obj.selectedPropertyReasons.split(",")
      let eventt = {}
      if (this.state.shownreasons === undefined) {
        await this.getReasonForEditor(obj.selectedPropertyStatus, "Redirect")
      }
      var getreasons = this.state.shownreasons.filter(function (el) {
        for (var item of decodedStatus) {
          if (el.label === item) {
            return el
          }
        }
      });
      let getreasonsArray = {}
      getreasonsArray.value = []
      for (var data of getreasons) {
        getreasonsArray.value.push(data.value)
      }
      this.handleChange(getreasonsArray, "selectedPropertyReasons")

    }
    if (obj.dataSourceRedirect && obj.dataSourceRedirect.length > 0) {
      await this.setState({
        dataSource: obj.dataSourceRedirect.split(",")
      })
    } else {
      await this.setState({ dataSource: [] })
    }

    if (obj.selectedPropertyReasonsForResource) {
      let eventt = {}
      eventt.value = obj.selectedPropertyReasonsForResource.split(",")
      // await this.setState({ selectedPropertyStatus: decodedStatus })
      this.handleChange(eventt, "selectedPropertyReasons")
    }

    await this.setState({
      stateUP: false,
      sec: false,
      isCA_N: false
    })
    // }
    // if (!obj.selectedProperty_Id) {
    this.handleChange(this.state.selectedStates, "State")
    this.searchsellerItems(obj.selectedSeller.EstateID, obj)
    await this.getSellerNameSelectedOpra(obj.selectedSeller.EstateID)
    // }
  }

  setGoButton = () => {
    this.setState({ setGoButton: false })
  }

  redircttope = () => {
    this.props.getUrlbyParams()
  }

  lastUpdatedDate = (e) => {
    this.setState({ lastUpdatedDate: dateFormats.formatDate(e, "MM/DD/YYYY") })
  }

  getSellerNameSelectedOpra = async (val) => {
    return fetch('GET', `${apiCalls.propertysellers}?dealID=${val}`)
      .then(async (response) => {
        if (response && response["sellers"].length > 0) {
          if (response["sellers"][0].MigratedFlag) {
            await this.selectedOpra("OPRA2")
          } else {
            await this.selectedOpra("OPRA")
          }
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getSellerName = async (val) => {
    return fetch('GET', `${apiCalls.propertysellers}?dealID=${val}`)
      .then(async (response) => {
        if (response && response["sellers"].length > 0) {
          // let sellers = response["sellers"][0].EstateName
          let sellers = `${response["sellers"][0].DealType} - ${response["sellers"][0].SellerNickName} (${response["sellers"][0].DealYear ? response["sellers"][0].DealYear : ""})`
          await this.setState({ PropertiesSellerEstateName: sellers, EstateDetails: response["sellers"][0] })
          if (response["sellers"][0].MigratedFlag) {
            await this.selectedOpra("OPRA2")
          } else {
            await this.selectedOpra("OPRA")
          }
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getReasonForEditor = async (val, key) => {
    var ReasonsArray = [], data = []
    let filterCriteria = {}
    let array = []
    if (val && val.length > 0) {
      for (var obj of val) {
        if (!array.includes(obj.status)) {
          if (obj.status) {
            data.push(obj.status)
          }
        }
      }
      filterCriteria['criteria'] = [{ key: 'status', value: data, type: 'in', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: this.props.type === 'Deal Sizing Property Editor' ? 'Deal Sizing Property Editor' : 'Property Editor', type: 'eq', add_Filter: true }
      ];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            ReasonsArray.push({ label: reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
          }
          await this.setState({ shownreasons: ReasonsArray })
          if (ReasonsArray.length === 1) {

          }
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ shownreasons: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  handleChangeSelectedGroup = async (e, type) => {
    await this.setState({
      selectedGroup: type == "Delete" ? "" : e.value
    })
  }


  showRecordsCount = (type, field) => {
    let newNameIssueCodes = this.props.IssueCodes.newNameIssueCodes
    let nameIssueCodes = this.props.IssueCodes.nameIssueCodes
    if (type === "show" && field === "nameIssueCodes" && nameIssueCodes && nameIssueCodes.length === 0) {
      this.setState({
        isShownameIssueCodes: true
      })
    } else if (type === "hide" && field === "nameIssueCodes") {
      this.setState({
        isShownameIssueCodes: false
      })
    } else if (type === "show" && field === "newNameIssueCodes" && newNameIssueCodes && newNameIssueCodes.length === 0) {
      this.setState({
        isShownewNameIssueCodes: true
      })
    } else if (type === "hide" && field === "newNameIssueCodes") {
      this.setState({
        isShownewNameIssueCodes: false
      })
    }
  }

  submit = async (values) => {
    // if (this.state.edited === "yes") {
    //   //to open model if note is edited and trying to close by click on icon
    //   await this.setState({ openUnsaveModal: true, selectedEvent: values })
    //   return
    // }
    if (values === "taskProperties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor") {

    } else {
      await this.setState({ selectedTaskId: null })
    }

    //Available To map redirection
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyMaxValueAmount" && obj.add_Filter === true)
    if (index !== -1) {
      filtercriteria.criteria = filtercriteria.criteria.filter(elem => elem.key !== "propertyMaxValueAmount")
    }
    // if (this.state.groupMessage && this.state.filterCriteria) {

    let scoredDateindex = filtercriteria["criteria"].findIndex(obj => obj.key === "ScoreDate" && obj.add_Filter === true)
    if (index !== -1) {
      filtercriteria["criteria"].splice(scoredDateindex, 1)
    }
    let confirmedOnSiteDateindex = filtercriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate" && obj.add_Filter === true)
    if (confirmedOnSiteDateindex !== -1) {
      filtercriteria["criteria"].splice(scoredDateindex, 1)
    }

    this.setState({ filterCriteria: filtercriteria })
    //Available To map redirection

    await this.setState({ isredirtRanges: false, groupMessage: "", fiftyk: "", unknown: "", Count10kTo50k: "", })

    var newArray = this.props.allUsersData.filter(function (el) {
      return el.edited === "yes"
    });
    if (newArray && newArray.length > 0) {
      this.props.OpenConfirmaionModelorSave()
    } else {
      if (!this.state.nameIssueCode && this.props.IssueCodes && this.props.IssueCodes.defaultIssueNames) {
        this.handleChange(this.props.IssueCodes.defaultIssueNames, "nameIssueCode")
      }
      const str = this.state.OpraStId ? this.state.OpraStId : null;
      var result = str ? str.split(/\r?\n/) : null;
      const str2 = this.state.StatePropertyID ? this.state.StatePropertyID : null;
      var result2 = str2 ? str2.split(/\r?\n/) : null;
      const opraClaimIdsstr = this.state.OpraClaimId ? this.state.OpraClaimId : null;
      var OpraclaimIdsresult = opraClaimIdsstr ? opraClaimIdsstr.split(/\r?\n/) : null;
      const opraStClaimIdsstr = this.state.stClaimId ? this.state.stClaimId : null;
      var OprastclaimIdsresult = opraStClaimIdsstr ? opraStClaimIdsstr.split(/\r?\n/) : null;
      const str1 = this.state.Property_Id ? this.state.Property_Id : null;
      var result1
      // if ((this.state.taskProperties && this.state.taskProperties.Property_Id) || this.state.selectedTaskId) {
      //   result1 = typeof str1;
      // } else {
      result1 = typeof str1 === "string" ? str1.split(/\r?\n/) : str1;

      // }

      let Object = {
        // selectedSeller: {},

        selectedName: this.state.selectedNamearr ? this.state.selectedNamearr : "",
        selectedStatus: this.state.selectedStatus,
        selectedAddress: this.state.selectedAddress,
        selectedState: this.state.selectedState,
        selectedBucket: this.state.selectedBucket,
        groupFlag: this.state.selectedGroup ? this.state.selectedGroup.flagName : null,
        // selectedProperty_Id: this.state.Property_Id,
        selectedProperty_Id: result1 ? result1 : null,
        // selectedOpraStId: this.state.OpraStId
        selectedOpraStId: result ? result : null,
        setectedStOpraId: result2 ? result2 : null,
        nameIssueCode: this.state.isDeleteNameIssue ? "delete" : this.state.nameIssueCode
      }
      if (this.state.selectedTaskId && (values === "taskProperties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor")) {
        Object.taskID = this.state.selectedTaskId
      }

      this.setState({ setGoButton: true, isredirtRanges: false })
      if (this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Report For Companies") {
        let data = {
          EstateName: this.state.selectedSeller.EntityName,
          EstateID: this.state.selectedSeller.DealID
        }
        Object.selectedSeller = data
      }

      else {
        Object.selectedSeller = this.state.taskProperties ? this.state.taskProperties.PropertiesSellerName : this.state.selectedSeller
      }
      if (this.state.isexcludeProperties) {
        Object.isexcludeProperties = this.state.isexcludeProperties
      }
      if (this.state.isexcludePossiblyPaid) {
        Object.isexcludePossiblyPaid = true
      }
      if (this.state.isexcludeRoyalties) {
        Object.isexcludeRoyalties = true
      }
      if (this.state.excludeCoOwners) {
        Object.excludeCoOwners = true
      }
      if (this.state.excludeNotConfirmedOnSite) {
        Object.excludeNotConfirmedOnSite = true
      }
      if (this.state.isexcludeValidated) {
        Object.isexcludeValidated = true
      }
      if (this.state.isValidate) {
        Object.isValidate = true
      }
      if (this.props.type === "Negative Notice List Viewer") {
        Object.excludeNotConfirmedOnSite = false
      }
      if (this.props.type === "Property Editor") {
        let filtercriteria = this.state.filterCriteria
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        if (this.state.dataSourceArrayNew && this.state.dataSourceArrayNew.length > 0 && this.state.dataSource && this.state.dataSource.length > 0) {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.dataSourceArrayNew,
            type: "in",
            add_Filter: true
          })
        }
        this.setState({ filterCriteria: filtercriteria })
      } else {
        let filtercriteria = this.state.filterCriteria
        let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (index !== -1) filtercriteria["criteria"].splice(index, 1)
        this.setState({ filterCriteria: filtercriteria })
      }
      let filterCriteria = await this.dataSourceCustumisations()
      await this.removeFileForAfterRedirection()
      this.props.getFilteredValues(Object, filterCriteria, this.state.isChangeHit, "setGoButton", this.props.type === "ClaimsManagement" ? this.state.filteredclaimSuggestions : this.state.filterednameSuggestions)

    }
  }

  dataSourceCustumisations = () => {
    let filtercriteria = this.state.filterCriteria
    let addindex = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
    if (addindex !== -1) filtercriteria["criteria"].splice(index, 1)
    if (this.state.dataSourceArrayNew && this.state.dataSourceArrayNew.length > 0 && this.state.dataSource && this.state.dataSource.length > 0) {
      filtercriteria["criteria"].push({
        key: "PropertyDataSource",
        value: this.state.dataSourceArrayNew,
        type: "in",
        add_Filter: true
      })
    }
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
    if (this.state.StateDataSource && this.state.StateDataSource.length > 0) {

      let dataindexforSEC = this.state.StateDataSource.findIndex(obj => obj.label === "SEC")
      if (this.state.sec) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexforSEC].value]
        } else {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexforSEC].value,
            type: "in",
            add_Filter: true
          })
        }
      } else {
        const set2 = new Set(this.state.StateDataSource[dataindexforSEC].value);
        if (index !== -1) {
          let arr = filtercriteria["criteria"][index].value
          filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      let dataindexisCA_N = this.state.StateDataSource.findIndex(obj => obj.label === "CA-N")
      if (this.state.isCA_N) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexisCA_N].value]
        } else {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexisCA_N].value,
            type: "in",
            add_Filter: true
          }
          )
        }
      } else {
        if (index !== -1) {
          const set2 = new Set(this.state.StateDataSource[dataindexisCA_N].value);
          let arr = filtercriteria["criteria"][index].value
          filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      let dataindexforUP = this.state.StateDataSource.findIndex(obj => obj.label === "State UP Data")
      if (this.state.stateUP) {
        if (index !== -1) {
          filtercriteria["criteria"][index].value = [...filtercriteria["criteria"][index].value, ...this.state.StateDataSource[dataindexforUP].value]
        } else {
          filtercriteria["criteria"].push({
            key: "PropertyDataSource",
            value: this.state.StateDataSource[dataindexforUP].value,
            type: "in",
            add_Filter: true
          }
          )
        }
      } else {
        const set2 = new Set(this.state.StateDataSource[dataindexforUP].value);
        if (index !== -1) {
          let arr = filtercriteria["criteria"][index].value
          filtercriteria["criteria"][index].value = arr.filter(item => !set2.has(item));
        }
      }
    }
    return filtercriteria
  }


  showCOunt = async (e, type) => {
    var val = JSON.stringify(e.target.value)
    val = val.replace(/\\n/g, '-')
    val = val.replace(/\,/g, '-')
    val = val.replace(/\"/g, '')
    let count = val.split("-")
    // let count = val.split("-").length
    // if (count2[count - 1].length === 0) {
    //   count2.pop()
    // }
    count = count.filter(u => u != "")


    await this.setState({ coutntValue: e.target.value, propCount: count.length, serverCount: count })
  }
  selectedViewType = async (e) => {

    await this.setState({
      selectedViewType: e
    })
    await this.props.selectedViewType(e)
  }

  copyToClipboard = async (data) => {
    navigator.clipboard.writeText(data)
  }

  handleChange = async (e, type, key) => {

    await this.setState({
      isDeleteNameIssue: false
    })
    let index;
    let filtercriteria = this.props.filterCriteria
    if (type === "Seller") {
      await this.setState({
        // selectedSeller: e.value,
        // EstateID: e.value.EstateID,
        selectedSeller: e.EstateName && e.EstateID ? e : e.value,
        EstateID: e && e.value && e.value.EstateID ? e.value.EstateID : e.EstateID ? e.EstateID : "",
        selectedNamearr: "",
        selectedName: "",
        selectedState: "",
        selectedPropertyStatus: "",
        selectedPropertyReasons: "",
        OpraClaimId: "",
        StatePropertyID: "",
        ClaimName: "",
        OpraStId: "",
        Property_Id: "",
        isexcludeRoyalties: false,
        isexcludeRoyalties: true,
        excludeCoOwners: false,
        isexcludeProperties: "",
        // excludeNotConfirmedOnSite: false,
        excludeCoOwners: false,
        selectedClaimStatus: "",
        newNameIssueCode: "",
        nameIssueCode: "",
        filterednameSuggestions: [],
        selectedGroup: ""

      })
      if (e && e.value === "") {
        // await this.props.getremoveFilters("remove")

        this.props.isShowTable(false)
      }

      if (this.props.type === "SearchTerms") {
        await this.setState({
          // search
          selectedSearchTerm: "",
          showSearchTable: false,
          errorResponse: false,
          errorMessage: ''
        })
      }
      if (this.props.type === 'Negative Notice List Viewer' || this.props.type === "Site Confirmation Editor") {
        if (this.state.EstateID !== null && this.state.EstateID !== "") {
          this.getNegativeNoticeList(this.state.EstateID)

        }
      }

      this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      if (this.props.type === "Properties") {
        await this.setState({
          selectedBucket: "Overall Near Exact"
        })
      }
      this.searchnameItems()
      this.setGoButton()
      if (key !== "FromTask") {
        if (e && e.value && e.value.MigratedFlag) {
          this.selectedOpra("OPRA2")
        } else {
          this.selectedOpra("OPRA")
        }
      }
      filtercriteria["criteria"] = []
      // this.props.change('Name', '')
    }
    else if (type === "Name") {
      let selectedNamearr = []
      if (e && e.target && e.target.value) {
        for (var arrName of e.target.value)
          selectedNamearr.push(arrName.id)
      } else {
        for (var arr of e)
          selectedNamearr.push(arr.id)
      }
      this.setGoButton()
      await this.setState({
        // selectedName: e.value
        selectedName: e && e.target && e.target.value ? e.target.value : e,
        selectedNamearr: selectedNamearr
      })
    } else if (type === "Status") {
      await this.setState({
        selectedStatus: e.value
      })
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchMatchStatusId")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "matchMatchStatusId",
        value: this.state.selectedStatus,
        type: "in",
        add_Filter: true
      })
    } else if (type === "newNameIssueCode") {
      await this.setState({
        newNameIssueCode: e.value
      })
      // this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "newNameIssueCode",
        value: this.state.newNameIssueCode,
        type: "in",
        add_Filter: true
      })
    } else if (type === "nameIssueCode") {
      await this.setState({
        nameIssueCode: e.value ? e.value : e
      })
      // this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.nameIssueCode.length > 0) {
        filtercriteria["criteria"].push({
          key: "nameIssueCode",
          value: this.state.nameIssueCode,
          type: "in",
          add_Filter: true
        })
      }
    }
    else if (type === "dataSource") {

      let dataSourceArray = []
      await this.setState({
        dataSource: e.value ? e.value : e
      })
      for (let val of e.value) {
        let dataindex = this.state.dataSourceGroups.findIndex(obj => obj.label === val)
        if (dataindex !== -1) {
          let valueArr = this.state.dataSourceGroups[dataindex]
          dataSourceArray = [...dataSourceArray, ...valueArr.value]
          this.setState({ dataSourceArrayNew: dataSourceArray })
        }
      }
      // this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.dataSource.length > 0) {
        filtercriteria["criteria"].push({
          key: "PropertyDataSource",
          value: dataSourceArray,
          type: "in",
          add_Filter: true
        })

      }
    }
    else if (type === "MatchBucket") {
      await this.setState({
        selectedBucket: e.value
      })
      this.setGoButton()
    } else if (type === "Address") {
      await this.setState({
        selectedAddress: e.value
      })
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "matchingPropertyOwnerAddressLine1")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      filtercriteria["criteria"].push({
        key: "matchingPropertyOwnerAddressLine1",
        value: encodeURIComponent(this.state.selectedAddress),
        type: "eq",
        add_Filter: true
      })
      // this.submit()
    } else if (type === "selectedPropertyStatus") {
      await this.setState({
        selectedPropertyStatus: e.value,
        selectedPropertyReasons: "",
      })
      let dataforStatus = []
      this.setGoButton()
      await this.getReasonForEditor(e.value, key)
      if (e.value) {

        for (var obj of e.value) {
          if (!dataforStatus.includes(obj.status)) {
            if (obj.status) {
              dataforStatus.push(obj.status)
            }
          }
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "propertyStatus",
          value: dataforStatus,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "selectedPropertyReasons") {
      await this.setState({
        selectedPropertyReasons: e.value
      })
      let dataforReasons = []
      if (e.value) {

        for (var obj of e.value) {
          if (!dataforReasons.includes(obj.reason)) {
            if (obj.reason) {
              dataforReasons.push(obj.reason)
            }
          }
        }
      }

      this.setGoButton()
      // await this.getReasons(e.value)
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedPropertyReasons && this.state.selectedPropertyReasons.length > 0)
        filtercriteria["criteria"].push({
          key: "propertyStatusReason",
          value: dataforReasons,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "State") {
      if (e && e.value) {
        await this.setState({
          selectedState: e.value
        })
      } else {
        await this.setState({
          selectedState: e,
        })
      }
      if (this.props.type === "SearchTerms") {
        await this.setState({
          // search
          selectedSearchTerm: "",
          showSearchTable: false,
          errorResponse: false,
          errorMessage: ''
        })
      }
      // if (!this.state.selectedSeller && this.state.selectedState.length !== 0) {
      //   this.props.getErrorMessage("Please Select Seller", "selectedSeller")
      // }
      this.setGoButton()
      if (this.props.type === "Property Editor V2" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing Property Editor") {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
      } else {
        index = filtercriteria["criteria"].findIndex(obj => obj.key === "property_StateId" && obj.type === "in")
      }
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedState && this.state.selectedState.length > 0)
        filtercriteria["criteria"].push({
          key: this.props.type === "Property Editor V2" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" ? "SourceState" : this.props.type === "Deal Sizing-Properties" || this.props.type === "Claims" || this.props.type === "ClaimsManagement" || this.props.type === "Deal Sizing Property Editor" ? "SourceState" : "property_StateId",
          value: this.state.selectedState,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "Analyst") {
      if (e && e.value) {
        await this.setState({
          selectedAnalyst: e.value,
        })
      } else {
        await this.setState({
          selectedAnalyst: e,
          setGoButton: true
        })
      }
      if (this.state.selectedSeller !== "" && this.state.selectedState.length !== 0) {
        this.setGoButton()
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedAnalyst && this.state.selectedAnalyst.length > 0)
        filtercriteria["criteria"].push({
          key: "Analyst",
          value: this.state.selectedAnalyst,
          type: "in",
          add_Filter: true
        })      // this.submit()
    }
    else if (type === "ClaimStatus") {
      if (e && e.value) {
        await this.setState({
          selectedClaimStatus: e.value
        })
      } else {
        await this.setState({
          selectedClaimStatus: e
        })
      }
      this.setGoButton()
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus" && obj.type === "in")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedClaimStatus && this.state.selectedClaimStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "ClaimStatus",
          value: this.state.selectedClaimStatus,
          type: "in",
          add_Filter: true
        })      // this.submit()

    }
    else if (type === "isexcludeProperties") {
      await this.setState({
        isexcludeProperties: e
      })
      this.setGoButton()
    } else if (type === "isexcludeValidated") {
      await this.setState({
        isexcludeValidated: this.state.isexcludeValidated === true ? false : true
      })
      this.setGoButton()
    } else if (type === "Validate") {
      await this.setState({
        isValidate: this.state.isValidate === true ? false : true
      })
      this.setGoButton()
    } else if (type === "excludeCoOwners") {
      await this.setState({
        excludeCoOwners: this.state.excludeCoOwners === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "excludeNotConfirmedOnSite") {
      await this.setState({
        excludeNotConfirmedOnSite: this.state.excludeNotConfirmedOnSite === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "isexcludePossiblyPaid") {
      await this.setState({
        isexcludePossiblyPaid: this.state.isexcludePossiblyPaid === true ? false : true
      })
      this.setGoButton()
    } else if (type === "isexcludeRoyalties") {
      await this.setState({
        isexcludeRoyalties: this.state.isexcludeRoyalties === true ? false : true
      })
      this.setGoButton()
    }
    else if (type === "Property_Id") {
      await this.setState({
        Property_Id: e ? e : null
      })
      this.setGoButton()
    } else if (type === "OpraStId") {
      await this.setState({
        OpraStId: e ? e : ""
      })
      this.setGoButton()
    } else if (type === "StatePropertyID") {
      await this.setState({
        StatePropertyID: e ? e : null
      })
      this.setGoButton()
    } else if (type === "OpraClaimId") {
      await this.setState({
        OpraClaimId: e ? e : null
      })
      this.setGoButton()
    } else if (type === "stClaimId") {
      await this.setState({
        stClaimId: e ? e : null
      })
      this.setGoButton()
    } else if (type === "Delete") {
      if (e === "selectedSeller") {
        await this.setState({
          selectedName: "",
          selectedStatus: "",
          selectedState: "",
          nameIssueCode: [],
          // dataSource: [],
          selectedAddress: "",
          selectedNamearr: "",
          selectedState: [],
          selectedSeller: "",
          modifiedAddress: [],
          setGoButtonClaims: true,
          selectedBucket: "",
          EstateID: "",
          stClaimId: "",
          selectedPropertyStatus: "",
          selectedPropertyReasons: "",
          OpraClaimId: "",
          filterednameSuggestions: "",
          StatePropertyID: "",
          ClaimName: "",
          selectedtask: "",
          filterednameSuggestions: "",
          OpraStId: "",
          Property_Id: "",
          isexcludeRoyalties: false,
          isexcludeRoyalties: true,
          excludeCoOwners: false,
          isexcludeProperties: "",
          excludeCoOwners: false,
          selectedClaimStatus: "",
          newNameIssueCode: "",
          nameIssueCode: "",
          filterednameSuggestions: []
        })
        filtercriteria["criteria"] = []
        this.props.getErrorMessage("Please Select Seller", "removingSeller", "error")
        this.props.getFileOptions([])
      } else if (e === "selectedName") {
        await this.setState({
          selectedName: "",
          selectedNamearr: "",
        })
      }

      else {
        await this.setState({
          [e]: "",
        })
        if (e === "nameIssueCode") {
          await this.setState({
            nameIssueCode: [],
            isDeleteNameIssue: true
          })
        } else if (e === "dataSource") {
          await this.setState({
            dataSource: [],
          })
        } else
          if (!this.state.selectedSeller && this.state.selectedState.length === 0 && this.props.type === "ClaimsManagement") {
            this.props.getErrorMessage("Please Select Seller or State", "removingSeller", "error")
          }
        let type;
        if (e === "OpraClaimId" || e === "OpraStId" || e === "stClaimId" || e === "PropertyID" || e === "selectedState" || e === "selectedStatus" || e === "StatePropertyID" || e === "selectedAnalyst" || e === "selectedClaimStatus" || e === "newNameIssueCode" || e === "nameIssueCode") {
          type = "in"
        } else {
          type = "eq"
        }
        if ((this.props.type === "Property Editor V2" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor") && key === "property_StateId") {
          let kindex = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState" && obj.type === "in")
          if (kindex !== -1) {
            filtercriteria["criteria"].splice(kindex, 1)
          }
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (key === "ClaimStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "ClaimStatus")
        } else if (this.props.type === "Deal Sizing-Properties" || this.props.type === "ClaimsManagement" && key === "property_StateId") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState" && obj.type === "in")
        } else if (key === "newNameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
        } else if (key === "nameIssueCode") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "nameIssueCode")
        } else if (key === "dataSource") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        } else if (key === "selectedPropertyStatus") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
        } else if (key === "selectedPropertyReasons") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
        } else if (key === "Analyst") {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === "Analyst")
        } else {
          index = filtercriteria["criteria"].findIndex(obj => obj.key === key && obj.type === type)
        }
        if (index !== -1) {
          filtercriteria["criteria"].splice(index, 1)
        }
        if (key === "selectedPropertyStatus") {
          let reasonIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
          if (reasonIndex !== -1) {
            filtercriteria["criteria"].splice(reasonIndex, 1)
          }
          let reasonPROPIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyStatusReason")
          if (reasonIndex !== -1) {
            filtercriteria["criteria"].splice(reasonPROPIndex, 1)
          }
        }
      }

      this.setGoButton()
    }

    if (type !== "Seller") {
      await this.setState({
        enableClearButton: true
      })
    }

    await this.setState({
      isChangeHit: true,
      filterCriteria: filtercriteria
    })

  }

  mandatoryIcon(e) {
    return (
      <span className={e === "Deal" ? "pl-1 mr-auto" : "pl-1"}>
        <FontAwesomeIcon icon={faStarOfLife}
          className='pl-1'
          color='red'
          data-toggle="tool-tip"
          title="Mandotary Field"
          style={{ width: 11 }}
        /></span>
    )
  }

  clearIcon(e, field, type) {
    return (
      <>{e === "selectedSeller" && this.props.lastScoredDate !== "" ?
        <div style={{ marginRight: "6px" }}><b><span>Last Scored Date   </span></b>{this.props.lastScoredDate}</div>
        : null}
        <span className={e !== "selectedSeller" ? 'ml-auto' : ""}>
          <FontAwesomeIcon icon={faTimes}
            className={`pl-1 mr-2 clearIcon  ${this.state.selectedSeller ? "" : "disabled"}`}
            color={this.state.selectedSeller && this.state[e] ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            id="clear company"
            fontSize={'18'}
            title="Clear Filter"
            disable={this.state[e] ? false : true}
            onClick={() => this.handleChange(e, field, type)}
            style={this.state.selectedSeller ? { pointerEvents: "visible"} : { pointerEvents: "none" }}
          /></span></>
    )
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  getSessionWraningModal() {

    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.submit}
      />
    )
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event) {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && this.props.type !== "Deal Sizing Report For Companies") {
        if (event.query) {
          if (this.props.type === "Deal Sizing Property Editor") {
            apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}&type=Deal Sizing Property Editor v2`
          } else {
            apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
          }
        } else if (typeof event === "string" && event.length > 0) {
          if (criteria && criteria.selectedSeller) {
            apiUrl = `${apiCalls.propertysellers}?dealID=${encodeURIComponent(event)}`
          } else {
            apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event)}`
          }
        } else {
          apiUrl = `${apiCalls.propertysellers}`
        }
      } else {
        filterCriteria["criteria"] = [{
          key: "EntityName",
          value: event && event.query && event.query !== "" ? encodeURIComponent(event.query) : event && typeof event === "string" ? encodeURIComponent(event) : "",
          type: "regexOr"
        }]
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "EntityName")
        if (this.props.type === "Deal Sizing Report For Companies") {
          filterCriteria.sortfield = "EntityName"
          filterCriteria.page = "1"
        }
        if (filterCriteria["criteria"][index].value.length > 0) {
          apiUrl = `${apiCalls.Seller}?filter=${JSON.stringify(filterCriteria)}`
        } else {
          apiUrl = `${apiCalls.Seller}`
        }
      }
    } else {
      if (this.props.type !== "Deal Sizing-Properties" && this.props.type !== "OPRA2 Property Editor" && event) {
        apiUrl = `${apiCalls.propertysellers}?str = ${encodeURIComponent(event.query ? event.query : event)} `
      } else {
        apiUrl = `${apiCalls.Seller} `
      }
    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })

            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }


  searchnameItems = async (event) => {
    let apiUrl
    if (this.state.selectedSeller && this.state.selectedSeller.EstateID && (this.props.type === "Property Editor V2" || this.props.type === "OPRA2 Property Editor" || this.props.type === "SearchTerms")) {
      apiUrl = `${apiCalls.propertynames}?searchCompany=${this.state.selectedSeller.EstateID} `
    } else if (this.state.selectedSeller && this.state.selectedSeller.DealID && (this.props.type === "Deal Sizing-Properties" || this.props.type === "Deal Sizing Property Editor")) {
      apiUrl = `${apiCalls.dealSizingNames}?searchCompany=${this.state.selectedSeller.DealID} `
    }
    // }
    if (apiUrl) {
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let namedropdownData = [];
            if (response["sellernames"] && response["sellernames"].length && response["sellernames"].length > 0) {
              namedropdownData = response["sellernames"]

            } else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)

            }
            if (namedropdownData && namedropdownData.length === 0) {
              this.setState({
                filterednameSuggestions: [],
                noData: true
              });
            } else {
              let modifiedLabels = []
              for (let name of namedropdownData) {
                if (name.companyName !== null || name.companyName.length > 0 || name.nameID !== null || name.nameID.length > 0) {
                  modifiedLabels.push({
                    label: name.companyName,
                    value: {
                      labelName: name.companyName,
                      id: name.nameID,
                    },
                  })
                }
              }
              namedropdownData = modifiedLabels;
            }
            if (this.state.taskProperties) {
              let data = this.state.taskProperties.PropertiesSellerName.Names // for (var name of this.state.taskProperties.PropertiesSellerName.Names) {
              var newArray = [];
              for (var item of data) {
                for (var name of namedropdownData) {
                  if (name.label === item) {
                    newArray.push(name)
                  }
                }
              }
              let ids = []
              for (var itemData of newArray) {
                ids.push(itemData.value)
              }
              this.handleChange(ids, "Name")
            }
            if (event && this.state.isSharing && this.state.sharedobj.nameId) {
              let selectedValues = []
              const arr = event.nameId.split(',');
              for (var itemArr of arr) {
                for (var named of namedropdownData) {
                  if (named.value && named.value.id === itemArr) {
                    selectedValues.push(named.value)
                  }
                }
              }
              this.handleChange(selectedValues, "Name")
            }
            this.props.getFileOptions(namedropdownData)
            await this.setState({
              filterednameSuggestions: namedropdownData
            });
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  getNegativeNoticeList = async (e) => {

    let tasksArray = [];
    let body = {
      label: this.props.type === "Site Confirmation Editor" ? "Site Confirmation" : "Seller Negative Notice",
      estateID: e
    }
    return fetch('POST', `tasks/ConnectedTasks`, body)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"]
          for (let reason of propertyReasons) {

            if (this.state.selectedTaskId) {
              if (reason.taskID === this.state.selectedTaskId) {
                await this.setState({
                  reason: reason
                })
              }
            }
            tasksArray.push({ label: `${reason.taskID} - ${dateFormats.formatDate(reason.created, "MM/DD/YYYY")}`, value: reason })
          }
          await this.setState({ tasksArray: tasksArray, selectedtask: this.state.reason })
          // await this.getTaskIDs(this.state.selectedTaskId)
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getTaskIDs = async (e, type) => {
    let filter = {}
    filter.criteria = [{ key: "taskID", value: e, type: "eq" }]
    return fetch('GET', `tasks?filter=${JSON.stringify(filter)}&ignoreUnassigned=true`)
      .then(async (response) => {
        if (response && response["tasks"].length > 0) {
          let propertyReasons = response["tasks"][0].connectedProperties
          propertyReasons = propertyReasons.join("\n");
          if (type) {
            await this.setState({
              excludeNotConfirmedOnSite: false,
            })
            this.props.openFormModal(response["tasks"][0], 'edit')
          }
          await this.setState({ selectedTaskId: e })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        } else {
          this.props.getErrorMessage("Something went wrong", "", "error")
        }
      }).catch((err) => {
        return err;
      })
  }
  handleTaskChange = async (e, type) => {
    if (type) {
      await this.setState({
        selectedtask: '',
        selectedTaskId: "",
        setGoButton: false
      })
      this.props.getErrorMessage("Please Select Notice List", "removingSeller", "error")

    } else {
      await this.setState({
        selectedtask: e,
        selectedTaskId: e.taskID,
        Property_Ids: e.connectedProperties,
        setGoButton: false
      })
      this.props.getErrorMessage("", "selectedSeller")

    }
  }
  editorTemplate(option) {
    if (!option.value) {
      return option.label;
    }
    else {
      return (
        <span style={{ width: 11, float: 'right', margin: '.5em .25em 0 0' }} data-toggle="tool-tip"
          title={option.value.tooltip}
        >{option.label}</span>
      );
    }
  }

  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : option && option.APADate ? new Date(option.APADate).getFullYear() : ""})`)
  }
  hideTable() {
    if (this.props.type === "Property Editor V2") {
      this.props.isShowTable(false)
    }
  }

  onTabOpen = async (e, n) => {
    setTimeout(() =>
      this.props.updateDimensions(), 750);
  }


  getLeftSideFilters() {
    const { IssueCodes } = this.props
    const { nameIssueCode, selectedSeller } = this.state

    return (
      <>
        <Row className='col-sm-12 px-0 mx-0 pe_left'>
          <div className='col-sm-4 pr-0'>
            <label className={this.props.type === "Deal Sizing Property Editor" ? "align-items-center" : ""}
              style={{ marginBottom: 4, width: "100%", display: "flex" }}>
              {this.props.type === "Deal Sizing Report For Companies" ? "Company" :
                this.props.type === "Deal Sizing Property Editor" ?
                  <a style={{ lineHeight: 0, padding: 0 }} href={this.state.EstateID ? `${window.location.protocol}//${window.location.host}/sellersEdit/deal/${this.state.EstateID}` :
                    `${window.location.protocol}//${window.location.host}/sellers`
                  } target="_blank"> Deal</a> :
                  "Deal"}
              {this.mandatoryIcon("Deal")}
              {this.clearIcon('selectedSeller', "Delete")}
            </label>
            <span className="w-100">
              <AutoComplete
                value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} -${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` :
                  this.state.selectedSeller && this.state.selectedSeller.SellerNickName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller.APADate ? new Date(this.state.selectedSeller.APADate).getFullYear() : ""})`
                    : this.state.selectedSeller}
                suggestions={this.state.filteredSuggestions}
                completeMethod={this.searchsellerItems}
                onBlur={() => this.hideTable()}
                minLength={1}
                style={{ width: "100%" }}
                inputStyle={{ width: "85%" }}
                id="company"
                itemTemplate={this.editorTemplateForDeal}
                field={"EstateName"}
                dropdown={true}
                onChange={(e) => this.handleChange(e, 'Seller')}
                appendTo={document.body} />
            </span>
          </div>
          <div className='col-sm-3 pr-0'>
            <label style={{ marginBottom: 4, width: "100%" }}>
              {this.props.type === "Deal Sizing Property Editor" ?
                <a style={{ lineHeight: 0, padding: 0 }} href={this.state.EstateID ? `${window.location.protocol}//${window.location.host}/sellersEdit/name/${this.state.EstateID}` :
                  `${window.location.protocol}//${window.location.host}/sellers`
                } target="_blank"> Business Name</a> :
                "Business Name"}
              {this.clearIcon("selectedName", "Delete")}</label>
            <div >
              <MultiSelect
                className={"form__form-group-field "}
                style={{ width: "100%", height: 32 }}
                filter={true}
                id="bussiness Name"
                name={"Name"}
                options={this.state.filterednameSuggestions}
                value={this.state.selectedName && this.state.selectedName.CompanyName ? this.state.selectedName.CompanyName : this.state.selectedName}
                onChange={(e) => this.handleChange(e, "Name")}
                maxSelectedLabels={2}
              />
            </div>
          </div>
          <div className='col-sm-3 pr-0'>
            <label style={{ marginBottom: 4, width: "100%" }}>Property Status{this.clearIcon("selectedPropertyStatus", "Delete", 'selectedPropertyStatus')}</label>
            <span className=''>
              <MultiSelect
                name={"State"}
                value={this.state.selectedPropertyStatus}
                style={{ width: "100%", height: 32 }}
                appendTo={document.body}
                filter={true}
                id="Property Status"
                itemTemplate={this.editorTemplate}
                disabled={this.state.selectedSeller ? false : true}
                maxSelectedLabels={1}
                options={this.state.propertyStatusArray}
                onChange={(e) => this.handleChange(e, "selectedPropertyStatus")}
              />
            </span>
            {this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? <div className='' >

              <>
                <label >Property Reason{this.clearIcon("selectedPropertyReasons", "Delete", 'selectedPropertyReasons')}</label>
                <span className=''>
                  <MultiSelect
                    name={"State"}
                    value={this.state.selectedPropertyReasons}
                    style={{ width: "100%", height: 32 }}
                    appendTo={document.body}
                    filter={true}
                    id="Property Reason"
                    disabled={this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? false : true}
                    maxSelectedLabels={0}
                    options={this.state.shownreasons && this.state.shownreasons.length > 0 ? this.state.shownreasons : []}
                    onChange={(e) => this.handleChange(e, "selectedPropertyReasons")}
                  />
                </span> </>
            </div> : null}</div>
          <div className='col-sm-2 pr-0'>
            <label style={{ marginBottom: 4, width: "100%" }}>Source State{this.clearIcon("selectedState", "Delete", 'property_StateId')}</label>
            <div className=''>
              <MultiSelect
                name={"State"}
                id="State"
                filter={true}
                value={this.state.selectedState}
                style={{ width: "100%", height: 32 }}
                appendTo={document.body}
                disabled={this.state.selectedSeller ? false : true}
                maxSelectedLabels={1}
                options={this.state.modifiedStates}
                onChange={(e) => this.handleChange(e, "State")}
              />
            </div>
          </div>
        </Row>
        <Row className="col-sm-12 px-0 mx-0 pe_left">
          <div className="col-sm-6 pr-0 d-flex">
            <div class="">
              <label htmlFor="cb3" style={{ marginRight: "3%" }} className="p-checkbox-label"> Excl. Co Owners</label>
              <Checkbox
                label='Excl. Co Owners'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'excludeCoOwners'}
                id={"excludeCoOwners"}
                className=" ml-2"
                checked={this.state.excludeCoOwners}
                onChange={(e) => this.handleChange(e, "excludeCoOwners")}
              />
            </div>
            <div class="">
              <label htmlFor="cb3 " style={{ marginRight: "3%" }} className="p-checkbox-label">Excl. Not Confirmed Onsite</label>
              <Checkbox
                label='Excl. Not Confirmed Onsite'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'validated'}
                id={'excludeNotConfirmedOnSite'}
                className=" ml-2"
                checked={this.state.excludeNotConfirmedOnSite}
                onChange={(e) => this.handleChange(e, "excludeNotConfirmedOnSite")}
              />
            </div>
            <div class="">
              <label htmlFor="cb3 " className="p-checkbox-label ml-2" style={{ marginRight: "3%" }}>{"Excl.Possibly Paid"}</label>
              <Checkbox
                label='Excl.Possibly Paid'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'isexcludePossiblyPaid'}
                id={'isexcludePossiblyPaid'}
                className=" ml-2"
                checked={this.state.isexcludePossiblyPaid}
                onChange={(e) => this.handleChange(e, "isexcludePossiblyPaid")}
              />
            </div>
            <div class="">
              <label htmlFor="cb3" className="p-checkbox-label ml-2" style={{ marginRight: "3%" }}>{"Excl. Royalties"}</label>
              <Checkbox
                label='Excl. Royalties'
                type='checkbox'
                // component={renderCheckBoxField}
                name={'isexcludeRoyalties'}
                id={'isexcludeRoyalties'}
                className=" ml-2"
                checked={this.state.isexcludeRoyalties}
                onChange={(e) => this.handleChange(e, "isexcludeRoyalties")}
              />
            </div>
          </div>
          <div className='col-sm-2 pr-0'>
            <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"Legacy Name Issue"}
              {this.clearIcon("nameIssueCode", "Delete", 'nameIssueCode')}
            </label>
            <div className='f' data-toggle="tool-tip" title={IssueCodes && IssueCodes.nameIssueCodes ? "" : "No options available"}>
              <MultiSelect
                value={nameIssueCode ? nameIssueCode : IssueCodes ? IssueCodes.defaultIssueNames : []}
                name={"Match_Status"}
                filter={true}
                // filter={modifiedStatus.length > 6 ? true : false}
                style={{ width: "100%", height: 32 }}
                appendTo={document.body}
                disabled={selectedSeller ? false : true}
                maxSelectedLabels={1}
                onFocus={() => this.showRecordsCount("show", "nameIssueCodes")}
                onBlur={() => this.showRecordsCount("hide", "nameIssueCodes")}
                options={IssueCodes ? IssueCodes.nameIssueCodes : this.state.availableToMapLegacyNameIssueCode ? this.state.availableToMapLegacyNameIssueCode : []}
                onChange={(e) => this.handleChange(e, "nameIssueCode")}
              />
              <span>{this.state.isShownameIssueCodes ? "No options available" : null}</span>
            </div>
          </div>
          {this.props.type !== 'Deal Sizing Property Editor' ?
            <div className='col-sm-2 pr-0'>
              <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"New Name Issue"}
                {this.clearIcon("newNameIssueCode", "Delete", 'newNameIssueCode')}
              </label>
              <div className='f' data-toggle="tool-tip" title={IssueCodes && IssueCodes.nameIssueCodes ? "" : "No options available"}>
                <MultiSelect
                  value={this.state.newNameIssueCode}
                  name={"Match_Status"}
                  id="Match_Status"
                  filter={true}
                  // filter={modifiedStatus.length > 6 ? true : false}
                  style={{ width: "100%", height: 32 }}
                  appendTo={document.body}
                  disabled={this.state.selectedSeller ? false : true}
                  maxSelectedLabels={1}
                  onFocus={() => this.showRecordsCount("show", "newNameIssueCodes")}
                  onBlur={() => this.showRecordsCount("hide", "newNameIssueCodes")}
                  options={IssueCodes ? IssueCodes.newNameIssueCodes : this.state.availableToMapNameIssueCode ? this.state.availableToMapNameIssueCode : []}
                  onChange={(e) => this.handleChange(e, "newNameIssueCode")}
                />
                <span>{this.state.isShownewNameIssueCodes ? "No options available" : null}</span>
              </div>
            </div> : ""}
          <div class="col-sm-2 pr-0">
            <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>{"Group"}
              {this.clearIcon("selectedGroup", "Delete", 'selectedGroup')}
            </label>
            <div className='f' data-toggle="tool-tip" >
              <Dropdown
                value={this.state.selectedGroup}
                name={"selectedGroup"}
                id="Group"
                itemTemplate={this.groupTemplate}
                // filter={modifiedStatus.length > 6 ? true : false}
                style={{ width: "100%", height: 32 }}
                disabled={this.state.selectedSeller ? false : true}
                // onFocus={() => this.showRecordsCount("show", "newNameIssueCodes")}
                // onBlur={() => this.showRecordsCount("hide", "newNameIssueCodes")}
                options={this.props.tabDescriptionArray}
                onChange={(e) => this.handleChangeSelectedGroup(e, "selectedGroup")}
              />
            </div></div>
        </Row>
      </>
    )
  }

  clearFilters = async () => {
    let filterCriteria = this.state.filterCriteria
    filterCriteria.criteria = filterCriteria.criteria.filter(product => product.add_Filter !== true);


    await this.setState({
      filterCriteria: filterCriteria,
      stateUP: true,
      sec: true,
      isCA_N: true,
      selectedPropertyStatus: null,
      selectedPropertyReasons: null,
      selectedState: null,
      selectedName: "",
      selectedStatus: "",
      selectedAddress: "",
      selectedBucket: "",
      groupFlag: null,
      selectedProperty_Id: null,
      selectedOpraStId: null,
      setectedStOpraId: null,
      nameIssueCode: "",
      selectedNamearr: "",
      OpraClaimId: "",
      StatePropertyID: "",
      ClaimName: "",
      OpraStId: "",
      Property_Id: "",
      excludeNotConfirmedOnSite: this.props.type === "Deal Sizing Property Editor" ? false : true,
      isexcludeRoyalties: true,
      excludeCoOwners: false,
      isexcludePossiblyPaid: false,
      isexcludeProperties: "",
      selectedClaimStatus: "",
      newNameIssueCode: "",
      selectedGroup: "",
      dataSource: "",
    })

    this.submit()
  }

  applyFilters = () => {
    return (
      <>
        <Button color="primary" className="" onClick={() => this.submit()}
          disabled={this.state.setGoButton ? true : (this.state.selectedSeller) ? false : true}
        >Go</Button>
        <Button color="primary"
          outline
          className='mr-3 mb-1'
          // style={{ height: 30, width: "62px" }}
          disabled={this.state.enableClearButton ? false : true}
          onClick={() => this.clearFilters()}>
          <FontAwesomeIcon
            icon={faTimes}
            data-toggle="tool-tip" title={"Clear Search Filters"}
            className='pl-1' size='lg' />
        </Button>
      </>
    )
  }

  getRightSideFilters = () => {
    return (
      <div className='col-sm-12 px-0'>
        <Row>
          <div className='col-sm-6 pr-0'>
            <label style={{ marginBottom: 4, width: "100%" }}>OPRA Property ID{this.clearIcon("Property_Id", "Delete", 'matchMatchStatusId')}</label>
            <div className='custom_text'>
              <textarea
                id="Property_Id"
                name='Property_Id'
                value={this.state.Property_Id}
                onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}
                placeholder={'Opra Property Id'}
                rows="3"
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <label style={{ marginBottom: 4, width: "100%" }}>State Property Id{this.clearIcon("OpraStId", "Delete", 'matchMatchStatusId')}</label>
            <div className='custom_text'>
              <textarea
                id="OpraStId"
                name='OpraStId'
                value={this.state.OpraStId}
                onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                placeholder={'State Property Id'}
                rows="3"
              />
            </div>
          </div>
        </Row>

        <Row>
          {/* {this.props.missingOPRAPropertyIds && this.props.missingOPRAPropertyIds.length > 0 ? */}
          <div className='col-sm-6 pr-0'
            style={this.props.missingOPRAPropertyIds && this.props.missingOPRAPropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
          >
            <label style={{ marginBottom: 4, width: "100%" }}>Missing Property IDs</label>
            <div className='custom_text d-flex'>
              <textarea
                id="Property_Id"
                // type="search"
                name='Property_Id'
                // id='estateIdsValue'
                value={this.props.missingOPRAPropertyIds}
                onChange={(e) => this.setState({ Property_Id: e.target.value, setGoButton: false })}
                // onChange={(e) => this.handleChange(e.target.value, "Property_Id")}
                placeholder={'Missing Property IDs'}
                // size="10"
                // height="100px"
                rows="3"
              />
              <FontAwesomeIcon icon={faCopy}
                className='pl-1 mr-2'
                style={{ width: 20 }}
                color={'red'}
                id="copy missing"
                data-toggle="tool-tip"
                title="Copy"
                onClick={() => this.copyToClipboard(this.props.missingOPRAPropertyIds)}
              />
            </div>
          </div>
          {/* : null}
          {this.props.missingStatePropertyIds && this.props.missingStatePropertyIds.length > 0 ? */}
          <div className='col-sm-6 ml-auto'
            style={this.props.missingStatePropertyIds && this.props.missingStatePropertyIds.length > 0 ? { display: "block" } : { display: "none" }}
          >
            <label style={{ marginBottom: 4, width: "100%" }}> Missing State Property Ids</label>
            <div className='custom_text d-flex'>
              <textarea
                id="Property_Id"
                // type="search"
                name='Property_Id'
                // id='estateIdsValue'
                value={this.props.missingStatePropertyIds}
                onChange={(e) => this.setState({ OpraStId: e.target.value, setGoButton: false })}
                placeholder={'Missing State Property Ids'}
                rows="3"
              />
              <FontAwesomeIcon icon={faCopy}
                className='pl-1 mr-2'
                color={'red'}
                data-toggle="tool-tip"
                style={{ width: 20 }}
                title="Copy"
                onClick={() => this.copyToClipboard(this.props.missingStatePropertyIds)}
              />
            </div>

          </div>
          {/* : null} */}
        </Row>

        <Row className="col-sm-12 px-0 mx-0 mt-1 dataSourcemain">
          <div className='col-sm-9 px-1 dataSourceStyle' >
            <label htmlFor="cb3 " className="p-checkbox-label ml-0" >{"Data Sources :"}</label>
            <Checkbox
              label='isexcludePossiblyPaid'
              type='checkbox'
              disabled={this.state.selectedSeller ? false : true}
              name={'isexcludePossiblyPaid'}
              className=" ml-2"
              checked={this.state.stateUP}
              onChange={(e) => this.setState(prevState => ({
                stateUP: !prevState.stateUP
              }))}
            />
            <label htmlFor="cb3 " className="p-checkbox-label ml-2" >{"State UP"}</label>
            <Checkbox
              label='isexcludePossiblyPaid'
              type='checkbox'
              name={'isexcludePossiblyPaid'}
              className=" ml-2"
              disabled={this.state.selectedSeller ? false : true}
              checked={this.state.sec}
              onChange={(e) => this.setState(prevState => ({
                sec: !prevState.sec
              }))}
            />
            <label htmlFor="cb3 " className="p-checkbox-label ml-2" >{"SEC"}</label>
            <Checkbox
              label='isexcludePossiblyPaid'
              type='checkbox'
              name={'isexcludePossiblyPaid'}
              disabled={this.state.selectedSeller ? false : true}
              className=" ml-2"
              checked={this.state.isCA_N}
              onChange={(e) => this.setState(prevState => ({
                isCA_N: !prevState.isCA_N
              }))}
            />
            <label htmlFor="cb3 " className="p-checkbox-label mx-2 " >{"CA-N"}</label>
          </div>
          <div className='col-sm-3 pl-0 dataSource_multiDiv d-flex'>
            <MultiSelect
              value={this.state.dataSource}
              name={"dataSource"}
              filter={true}
              // className='dataSource_multiDiv'
              style={{ width: "100%" }}
              // appendTo={document.body}
              disabled={this.state.selectedSeller ? false : true}
              maxSelectedLabels={1}
              options={this.state.dataArray ? this.state.dataArray : []}
              onChange={(e) => this.handleChange(e, "dataSource")}
            />
          </div>
        </Row>
      </div>
    )
  }


  render() {
    const { handleSubmit, settingsData, IssueCodes, PropertySharebody } = this.props;
    const { modifiedStates, modifiedStatus, matchBuckets } = this.state
    let isEditted = localStorage.getItem("ISEdited") ? true : false

    return (
      <>
        <Accordion className="pb-0" activeIndex={this.state.isOpenAccordion !== null ? 0 : null} onTabOpen={this.onTabOpen} onTabClose={this.onTabOpen}>
          <AccordionTab header="Filters" >
            <Row className="col-sm-12 px-0">
              <div className="col-sm-8 px-0">{this.getLeftSideFilters()}</div>
              <div className="col-sm-1 applyFilterBtn">{this.applyFilters()}</div>
              <div className="col-sm-3">{this.getRightSideFilters()}</div>
            </Row >

            <Row className={this.state.isredirtRanges || this.state.isShowNote ? "ml-3" : "d-none"} >
              {this.state.isredirtRanges && !this.state.groupMessage || this.state.isShowNote ?
                < ><p className='col-sm-12 pl-0'><b style={{ fontSize: 14, fontWeight: 800 }}>{this.state.isredirtRanges || this.state.isShowNote ? "Exclusions :" : null}</b>
                  <span className="my-2 ml-2" data-toggle="tool-tip" title='Click to see all Exclusions' onClick={(e) => this.op.toggle(e)}>
                    {configMessages.ExclusionMessage} <span>......</span>
                  </span>
                  <OverlayPanel style={{ left: "700px", right: "66px" }} ref={(el) => this.op = el} appendTo={document.body} showCloseIcon={true} dismissable={true}>
                    <b style={{ fontSize: 14, fontWeight: 800 }}>Exclusions:</b> {configMessages.ExclusionMessage} <p className='mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p></OverlayPanel></p>
                  <span className="">
                    <span className=""><p> <b style={{ fontWeight: 800 }}> Note : </b>
                      {configMessages.NOTE_WHEN_REDIRECTING}
                    </p></span>
                  </span>
                </>
                :
                <>
                  <div title='Click to see all Exclusions' onClick={(e) => this.op.toggle(e)}>
                    {this.state.groupMessage && this.state.groupMessage.length > 0 ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                      <h4 className='ml-2'><b> {this.state.Group}:</b>
                        {this.state.groupMessage}</h4></div> : null}
                    {this.state.fiftyk || this.state.unknown || this.state.Count10kTo50k ? <div className="d-flex align-items-center" style={this.state.Group ? { width: "100%", backgroundColor: "#f7f3e9", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 1" ? { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" } : this.state.Group === "Group 3" ? { width: "100%", backgroundColor: "#e9f5f7", marginBottom: "5px", height: "40px" } : {}}>
                      <h4 className='ml-2'>
                        {this.state.fiftyk ? this.state.fiftyk : this.state.unknown ? this.state.unknown : this.state.Count10kTo50k ? this.state.Count10kTo50k : ""}</h4></div>
                      : null}

                  </div>
                  <span>
                    <OverlayPanel style={{ left: "700px", right: "66px" }} ref={(el) => this.op = el} appendTo={document.body} showCloseIcon={true} dismissable={true}>
                      <span className='ml-2'><b> {this.state.Group}:</b>
                        {this.state.groupMessage}</span><p className='ml-2 mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p></OverlayPanel>
                  </span>
                </>

              }

            </Row>
            {this.props.defaultExclusionNote && !this.state.groupMessage && !this.state.isredirtRanges ?
              <div>
                <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
                  title="click to see definations" >
                  {this.props.defaultExclusionNote}
                </div>
                <div>
                  <OverlayPanel style={{ left: "1500px" }} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
                    <p className='ml-2 mt-1'><b>Inclusions / Exclusions Appear here : </b>{this.props.defaultExclusionNote}</p>
                  </OverlayPanel>

                </div>
              </div>
              : null}
            {this.state.openUnsaveModal ? this.getDeleteRowModalForNotes() : null}
          </AccordionTab>
        </Accordion>
      </>
    );
  }
}


export default withTranslation('common')(PropertyFilters);