import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import * as commonApis from '../commonApis';


const DealDropdown = ({selectedDeal,onSelectDeal}) => {
    const [selectedValue, setSelectedValue] = useState();
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (selectedDeal) {
            setSelectedValue(selectedDeal);
        }
        return () => {
            setSelectedValue('');
        }
    }, [selectedDeal]);

    
    

    const onSearchDeals = async (event) => {
        if (event && event.query && event.query.length > 0) {
            const res = await commonApis.getDealsDropdownValues(event.query);
            if(res && res.length > 0){                
                setSuggestions(res);
            }
        }
    }     
    const formattedDealName = (option) => {
        return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`);
    }
    const onSelectingDeal = (e) => {        
        if (e && e.value) {
            setSelectedValue(e.value);
            onSelectDeal(e.value);
        }
    }

    

    return <span className='common-deal-auto-complete'><AutoComplete
        value={selectedValue}
        suggestions={suggestions}
        completeMethod={onSearchDeals}
        field={'EstateName'}
        itemTemplate={formattedDealName}
        selectedItemTemplate={selectedValue && selectedValue.DealType ? formattedDealName : ''}
        onChange={(e) => setSelectedValue(e.value)}
        onSelect={onSelectingDeal}
        onClear={() => onSelectDeal('')}
        style={{ width: "100%" }}
        className='w-100'
        dropdown={false}
        
        
    />
    </span>
}
export default DealDropdown;