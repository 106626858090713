import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class Label extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Labels'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }


  /**
   * 
   * @returns tablefields to show in grid
   */

  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        // width: 6,
        field: 'reOrder',
      },

      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'labelName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        "textAlign": "left",
        //"width": 20,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        filter: true,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formFIelds to show in form
   */
  getFormFields = () => {
    return ([
      {
        "value": "",
        "type": "text",
        "name": "labelName",
        "label": "Label",
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
      },
    ]);
  }


  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"sequence"}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          // isClearFilters={true}
          gridRequried={false}
          sample={false}
          reorderkey={"sequence"}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Labels'
          displayright={true}
          icon='tag'
          routeTo='labels'
          displayViewOfForm='modal'
          apiResponseKey={apiCalls.Labels}
          apiUrl={apiCalls.Labels}
          entityType='employee'
        />

      </span>
    );
  }
}