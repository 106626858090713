import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class NameIssueConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Purposes',
      issueNameCodes: [],
      LegacyIssueNameCodes: [],
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getRolesData()
    this.getIssueNameCode()
    this.getLegacyIssueNameCode()
  }


  /**
   * 
   * @param {Object} loginCredentials 
   * @returns Roles Api 
   */
  getRolesData = () => {
    return fetch('GET', apiCalls.Roles)
      .then(async (response) => {
        if (response && response.roles && response.roles.length > 0) {
          let data = response.roles, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
          }
          await this.setState({
            roleOptions: tempData,
            // loginRole: loginCredentials.roleName

          })
        } else {
          await this.setState({
            roleOptions: []
          })
        }
      }).catch((err) => {
        return err;
      });
  }


  //Issue Names Dropdown
  getIssueNameCode = async () => {
    let issueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            let index = issueNameCodes.findIndex(obj => obj.label === "Blank")
            let codeIndex = issueNameCodes.findIndex(obj => obj.label === name.BusinessNameIssueCode)
            if (codeIndex === -1) {
              if (name.BusinessNameIssueCode === "" && index === -1) {
                issueNameCodes.push({ label: "Blank", value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
              else if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
                issueNameCodes.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
            }
          }

          await this.setState({ issueNameOptions: issueNameCodes })
        } else {
          this.setState({ issueNameOptions: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  //Issue Names Dropdown
  getLegacyIssueNameCode = async () => {
    let LegacyIssueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            let index = LegacyIssueNameCodes.findIndex(obj => obj.label === name.LegacyIssueID)
            let blankIndex = LegacyIssueNameCodes.findIndex(obj => obj.label === "Blank")
            if (index === -1) {
              if (name.LegacyIssueID === "" && blankIndex === -1) {
                LegacyIssueNameCodes.push({ label: "Blank", value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
              else if (name.LegacyIssueID && name.LegacyIssueID !== null) {
                LegacyIssueNameCodes.push({ label: name.LegacyIssueID, value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
            }
          }

          await this.setState({ LegacyIssueNameCodes: LegacyIssueNameCodes })
        } else {
          this.setState({ LegacyIssueNameCodes: [] })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @returns tableFieldsto show in grid
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        //width: 80,
        field: 'role',
        "mobile": true,
        header: 'Role',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 40,
        field: 'v1NameIssueCodes',
        fieldType: "Array",
        "mobile": true,
        header: 'Name Issue Codes V1',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 40,
        field: 'v1LegacyNameIssueCodes',

        fieldType: "Array",
        "mobile": true,
        header: 'Legacy Name Issue Codes for Available to Map',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 40,
        field: 'v2NameIssueCodes',
        fieldType: "Array",
        "mobile": true,
        header: ' Name Issue Codes V2',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 40,
        field: 'v2LegacyNameIssueCodes',
        fieldType: "Array",
        "mobile": true,
        header: 'Legacy Name Issue Codes for Available to Map V2',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        //"width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return ([

      {
        "value": "",
        "type": "dropDown",
        "name": "role",
        options: this.state.roleOptions,
        "label": "Role",
        "id": "name",
        "placeholder": "Role Name",
        "required": true
      },
      {
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "v1NameIssueCodes",
        "label": "Name Issue Codes for Available To Map",
        "id": "name",
        "options": this.state.issueNameOptions,
        // "placeholder": "Name",
        // "required": true
      }, {
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "v1LegacyNameIssueCodes",
        "label": "Legacy Name Issue Codes for Available to Map",
        "id": "name",
        "options": this.state.LegacyIssueNameCodes,
        // "placeholder": "Name",
        // "required": true
      }, {
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "v2NameIssueCodes",
        "label": "Name Issue Codes for Available to Map V2",
        "id": "name",
        "options": this.state.issueNameOptions,
        // "placeholder": "Name",
        // "required": true
      }, {
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "v2LegacyNameIssueCodes",
        "label": "Legacy Name Issue Codes for Available to Map V2",
        "id": "name",
        "options": this.state.LegacyIssueNameCodes,
        // "placeholder": "Name",
        // "required": true
      },
      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        // "placeholder": "Name",
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"role"}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          // screen
          isClearFilters={true}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Available To Map Default'
          displayright={true}
          icon='user'
          routeTo='nameissueConfiguration'
          displayViewOfForm='modal'
          apiResponseKey='availableToMapDefaults'
          apiUrl={apiCalls.availableToMapDefault}
          entityType='employee'
        />
      </span>
    );
  }
}