import React from 'react';
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import DataTables from '../../CommonDataTable/DataTable';

//config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service';

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Users',
      loading: false,
      sessionWarning: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    this.getDepartmentData()
    this.getRolesData(loginCredentials)
  }

  /**
   * 
   * @param {Object} loginCredentials 
   * @returns Roles Api 
   */
  getRolesData = (loginCredentials) => {
    return fetch('GET', apiCalls.Roles)
      .then(async (response) => {
        if (response && response.roles && response.roles.length > 0) {
          let data = response.roles, tempData = [];
          for (let i = 0; i < data.length; i++) {
            tempData.push({ 'label': data[i]['role'], "value": data[i]['role'] })
          }

          await this.setState({
            roleOptions: tempData,
            loginRole: loginCredentials.roleName

          })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        } else {
          await this.setState({
            roleOptions: []
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @returns department api
   */
  getDepartmentData = () => {
    let DepartmentArray = [];
    let filterCriteria = {}
    filterCriteria.sortField = "departmentName"
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'activeIndicator', value: true, type: 'eq' }];
    fetchMethodRequest('GET', `${apiCalls.Departments}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["departments"] && response["departments"].length > 0) {
        let departments = response["departments"]
        for (let department of departments) {
          DepartmentArray.push({ label: department.departmentName, value: department.departmentName, departmentId: department._id })
        }
        this.setState({ DepartmentArray: DepartmentArray })
      }
    })
    return this.state.DepartmentArray;
  }

  /**
   * 
   * @returns tableFields to show in grid
   */
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": 45,
        "fieldType": 'profile',
        "field": "image",
        "mobile": false,
        "header": "",
        "filter": true,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": false
      },
      {
        "show": true,
        textAlign: 'center',
        width: 150,
        field: 'display_name',
        "mobile": true,
        fieldType: 'Badge',
        tableField: 'name',
        header: 'Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
        options: [{ "label": "Active", "value": "Active", "color": "success" }, { "label": "Inactive", "value": "Inactive", "color": "warning" }, { "label": "Pending", "value": "Pending", "color": "danger", }],
        filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "email",
        "mobile": true,
        "header": "UserName",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "secondaryEmail",
        "mobile": true,
        "header": "email",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "phone",
        "header": "Phone",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "department",
        "header": "Team",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "role",
        "mobile": true,
        "header": "Role",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "defaultUser",
        "filterType": "dropdown",
        "mobile": true,
        fieldType: "ActiveIndicator",
        "header": "Coreops Default User",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },


      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formFields to show in Form
   */
  getFormFields = () => {
    return ([
      {
        "value": "",
        "type": "text",
        "name": "fname",
        "label": "First Name",
        "id": "name",
        "placeholder": "First Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "lname",
        "label": "Last Name",
        "id": "lname",
        "placeholder": "Last Name",
        "required": true
      },
      {
        "value": "",
        "type": "email",
        "name": "email",
        "label": "UserName",
        "id": "email",
        "placeholder": "UserName",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "secondaryEmail",
        "label": "Email",
        "id": "email",
        "placeholder": "Email",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "phone",
        "label": "Phone",
        "id": "phone",
        "formatType": 'US',
        "placeholder": "Phone",
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "role",
        "label": "Role",
        "id": "Role",
        "placeholder": "Role",
        "options": this.state.roleOptions,
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "department",
        "label": "Team",
        "id": "Team",
        "placeholder": "Team",
        "options": this.state.DepartmentArray,
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "defaultUser",
        "label": "Coreops Default User",
        "id": "Coreops Default User",
        "placeholder": "Coreops Default User",
        // "required": true
      },
      {
        "value": "",
        "type": "profile",
        "name": "image",
        "label": "Profile Image",
        "id": "image",
        "placeholder": "Profile",
      },

    ]);
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          statusFilter={true}
          isClearFilters={true}//to show CLear filters button to remove table filters 
          className={true}
          exportRequried={this.state.loginRole === "Admin" ? true : false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          DepartmentArrayForUsers={this.state.DepartmentArray}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Users'
          displayright={true}
          icon='users'
          routeTo='users'
          displayViewOfForm='modal'
          apiResponseKey='users'
          apiUrl={apiCalls.Users}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}