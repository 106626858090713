import React from "react";
import store from "../../../App/store";
import moment from "moment-timezone";
import apiCalls from "../../../../config/apiCalls";
import ClaimFilter from "../../../Form/ClaimFilter";
import PropertyDetails from "../../CommonModals/PropertyDetails";
import ClaimDetails from "../../CommonModals/ClaimDetails";
import EditDataTable from "../../CommonModals/EditDatatable";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Column } from "primereact/column";
import ClaimDocumentation from "../../CommonModals/ClaimDocumentation";
import SessionWarningModal from "../../CommonModals/SessionWarningModal";
import DeleteRowModal from '../../CommonModals/DeleteRowModal';
import ConfirmationModal from '../../CommonModals/ConfirmationModal';
import SaveBookMarkModal from "../../CommonModals/SaveBookMarkModal";
import dateFormats from "../../../UI/FormatDate/formatDate";
import { Redirect } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "reactstrap";
import fetch from "../../../../config/service";
import Loader from "../../../App/Loader";
import { faTimesCircle, faBookmark, faCross, faTimes, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configMessages from "../../../../config/configMessages";
import NoViewComponent from '../../../Cruds/CommonDataTable/NoView'
import config from "../../../../config/config";
import RFIForm from "../../../Cruds/CommonModals/RFIForm";
import RolePermissions from "../../../Cruds/CommonModals/Permissions"
const filter = {
  criteria: [],
  sortfield: "updatedAtUtc",
  direction: "desc",
  limit: config.claimManagerDefaultRows
};

export default class ClaimManagementNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "ClaimManagementNew",
      multiSaveUrl: `${apiCalls.addNames}/multi`,
      multiDeleteUrl: `${apiCalls.addNames}/MultiDelete`,
      hitUpdateButtonFromPropertyDetails: false,
      bookMarkNameDetails: {},
      bookMarkDealID: "",
      // newRecordObj: this.namesFieldsref.getnewRecord(),
      // requiredFields: this.namesFieldsref.getrequiredFieldsforScreen(),
      type: "Names",
      deleteRestrictionField: "DataSourceID",

      deleteRestrictionFieldValues: [3, 4],
      uniqueDBField: "BusinessNamesID",
      recentRFITasks: {},
      screenPermissions:"",
      filterCriteria: {
        criteria: [
          {
            key: "ASSET_TRACKING_STATUS",
            value: ["Active"],
            type: "in",
            add_Filter: true,
          },
        ],
        sortfield: "updatedAtUtc",
        direction: "desc",
      },
      rfiHistory: [],
      rfiHistorycolumns: [
        { header: "Actions", field: "Actions" },
        { header: "RFI Task Number", field: "taskID" },
        {
          header: "RFI Received Date",
          field: "rfiReceivedDate",
          fieldType: "date",
        },
        {
          header: "RFI Response Date",
          field: "rfiResponseDate",
          fieldType: "date",
        },
        { header: "RFI Priority", field: "priority" },
        { header: "RFI Reasons", field: "reasons" },
        { header: "RFI Task Purpose", field: "purpose" },
        { header: "RFI Assigned To", field: "assignTo" },
        { header: "RFI Value", field: "valueRFI" },
        { header: "RFI Property Count", field: "propertyCountRFI" },
        { header: "Property Count", field: "propertyCount" },
        { header: "RFI Task Status", field: "status" },
        { header: "Request Description", field: "rfiRequestDescription" },
        { header: "RFI Responded By", field: "rfiRespondedBy" },
        { header: "Create By", field: "createdByUser" },
        { header: "Created", field: "created", fieldType: "date", },
        {
          header: "Payment Approved Email Received Date",
          field: "paymentApproveEmailReceivedDate",
          fieldType: "date",
        },
      ],
      limit: config.claimManagerDefaultRows
    };
    this.topFilters = [];
    this.topFiltersFromBookMark = []
    this.inLineFiltersFromBookMark = []
    this.inLineFilters = [];
    this.redirected = "";
    this.isRedirected="";
    this.claimNewID = "";
    this.bookMarkDealID = "";
    this.companyID = "";
    this.oppClaimID = ""
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let screenPermissions = RolePermissions.screenPermissions('Claims');
    await this.setState({screenPermissions:screenPermissions})
    let obj = {};
    let user = JSON.parse(localStorage.getItem("loginCredentials"));
    if (
      user &&
      user.department &&
      user.roleName &&
      user.roleName.includes("CoreOps")&&!user.defaultUser
    ) {
      obj.selectedDepartment = [user.departmentId];
    }
    this.getDataFromServer(obj, filter, "", "");

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params) {
      this.setState({
        params: params,
      });
    }
    if (
      document.getElementsByClassName("layout layout--collapse").length === 0
    ) {
      document
        .getElementsByClassName("topbar__button topbar__button--desktop")[0]
        .click();
    }
    this.getStatusOptions();
    await this.getBookMarks();
    await this.getClaimStatusReason();
    await this.getClaimStatusReasonforReasonArray();
    //Screen permisions value can be edit,view, no view
  
  };
  setDepartment = async (DepartmentArray, departmentIdArray) => {
    await this.setState({
      selectedDepartment: DepartmentArray,
      departmentIdArray: departmentIdArray,
    });
  };

  /**
   *
   * @returns the claim status reason array for claims filter dropdown
   */
  getClaimStatusReason = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetch(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {
          if (
            !claimStatusArray.some(
              (obj) => obj.value == propertyStatusReason.status
            )
          ) {
            await claimStatusArray.push({
              label: propertyStatusReason.status,
              value: propertyStatusReason.status,
            });
          }
          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({
          claimStatusArray: claimStatusArray,
          claimReasonArray: claimReasonArray,
          propertyStatusReasons: propertyStatusReasons,
        });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimStatusArray;
  };

  /**
   *
   * @returns the claim status reason array for claims filter dropdown
   */
  getClaimStatusReasonforReasonArray = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetch(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {

          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({ claimReasonArray: claimReasonArray });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimReasonArray;
  };

  //user Task label options
  getStatusOptions = () => {
    fetch("GET", apiCalls.ClaimStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.claimStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            });
          }
          await this.setState({
            Statuses: modifiedStatuses,
          });
          // this.setUnitName()
        } else if (response && response["errorMessage"]) {
          this.getErrorResponsefromServer(response);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  //user Task label options
  getBookMarks = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "screen", value: "Claims", type: "eq" },]
    return fetch("GET", `${apiCalls.BookMark}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response['filterBookmark']) {
          let bookMarks = response.filterBookmark;
          let bookMarkDetails = [];
          for (let bookMark of bookMarks) {
            bookMarkDetails.push({
              label: bookMark.filterName,
              value: bookMark._id
            });
          }
          await this.setState({
            BookMarks: bookMarkDetails,
            allDetails: bookMarks
          });
          // this.setUnitName()
        } else if (response && response["errorMessage"]) {
          this.getErrorResponsefromServer(response);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  openClaimDetails = async () => {
    // await this.setState({ openClaimDetails: true });
    this.toggle("1");
    window.scrollTo(0, document.body.scrollHeight);
    await this.setState({ openNewClaim: true });
  };
  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "checkBox",
        header: "",
        // selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   "show": true,
      //   "mobile": true,
      //   "textAlign": 'left',
      //   "field": "expander",
      //   "width": 60,
      //   "fieldType": 'expander',
      //   "header": "expander"
      // },
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   // fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   frozen: true,
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      {
        show: true,
        textAlign: "left",
        width: 140,
        field: "oppClaimID",
        mobile: true,
        editField: "text",
        header: "OPP Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      }, {
        show: true,
        textAlign: "left",
        width: 120,
        field: "previousOppClaimID",
        mobile: true,
        editField: "text",
        header: "Prev. OPP Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        field: "stateClaimID",
        mobile: true,
        editField: "text",
        header: "St. Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        field: "previousStateClaimID",
        mobile: true,
        editField: "text",
        header: "Prev. St. Claim ID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 90,
        field: "companyID",
        mobile: true,
        allowInt: true,
        editField: "text",
        filterType: "num",
        header: "Deal ID ",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Deal",
        mobile: true,
        field: "estateName",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 70,
        header: "Src. State",
        mobile: true,
        field: "sourceState",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 70,
        header: "Source",
        mobile: true,
        field: "source",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // "filterType": "date",
        header: "Date Initiated",
        mobile: true,
        editField: "date",
        fieldType: "dateTime",
        editField: "date",
        filterType: "num",
        field: "date__Initiated",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 230,
        header: "Relevant Seller Name",
        mobile: true,
        field: "relevantSellerName",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 230,
        header: "Mapped Name",
        mobile: true,
        field: "mappedName",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "width": 70,
      //   "field": "hasH__Claims",
      //   "mobile": true,
      //   "allowInt": true,
      //   "editField": "text",
      //   // filterType: "num",
      //   // inputType: 'number',
      //   "header": "# of Properties",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      {
        show: true,
        textAlign: "right",
        width: 70,
        field: "hasH__Claims",
        mobile: true,
        allowInt: true,
        editField: "text",
        filterType: "num",
        header: "# of Properties",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "width": 150,
      //   "allowInt": true,
      //   "filterType": "num",
      //   "header": "Claim Amount",
      //   "mobile": true,
      //   "editField": "text",
      //   "fieldType": "formatCurrency",
      //   "field": "claimAmount",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Claim Status",
        mobile: true,
        field: "claimStatus",
        filterType: "multiSelect",
        editField: "text",
        filterOptions: this.state.claimStatusArray
          ? this.state.claimStatusArray
          : [],
        filterType: "multiSelect",
        displayInSettings: true,
        options: this.state.claimStatus,
        filter: true,
        sortable: true,
        fieldType: "ClaimStatus",
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Claim Reason",
        mobile: true,
        filterType: "multiSelect",
        field: "claimReason",
        editField: "text",
        options: [],
        filterType: "multiSelect",
        filterOptions: this.state.claimReasonArray
          ? this.state.claimReasonArray
          : [],
        displayInSettings: true,
        filter: true,
        sortable: true,
        fieldType: "ClaimReason",
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        field: "oppMappedSummarizedOnlineStateUPClaimStatus",
        mobile: true,
        editField: "text",
        header: "Summarized Online State UP Claim Status",
        displayInSettings: true,
        // filter: true,
        // sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 70,
        header: "Co-Owner Prop.",
        mobile: true,
        field: "coOwnerProperties",
        fieldType: "showTextColor",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      }, {
        show: true,
        textAlign: "left",
        width: 90,
        header: "Cashier's Check Prop",
        mobile: true,
        fieldType: "showTextColor",
        field: "cashiersCheckProperties",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      }, {
        show: true,
        textAlign: "left",
        width: 100,
        header: "Prop. Missing ADDR EVID",
        mobile: true,
        field: "propertiesMissingAddressEvidence",
        fieldType: "showTextColor",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      }, {
        show: true,
        textAlign: "left",
        width: 100,
        header: "UP Declaration Submitted",
        mobile: true,
        fieldType: "showTextColor",
        field: "upDeclarationSubmitted",
        editField: "text",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // "filterType": "date",
        header: "Last Status Update Date",
        mobile: true,
        editField: "date",
        fieldType: "dateTime",
        filterType: "num",
        field: "lastStatusUpdateDate",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        // "filterType": "date",
        header: "Next Action Date",
        mobile: true,
        editField: "date",
        fieldType: "dateTime",
        filterType: "num",
        field: "next_Action_Date",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        field: "oppFollowupRequired",
        mobile: true,
        "fieldType": "Boolean",
        editField: "text",
        header: "OPP Action Required?",
        displayInSettings: true,
        // filter: true,
        // sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Next Action User",
        mobile: true,
        field: "analyst",
        editField: "dropDown",
        displayInSettings: true,
        options: this.state.userArray,
        filter: false,
        sortable: true,
        fieldType: "Analyst",
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "team",
        "mobile": false,
        "header": "Team",
        "filter": false,
        filterType: "multiselect",
        "filterOptions": this.state.taskDepartmentItems,
        "displayInSettings": true,
        "sortable": true
      },
      {
        show: true,
        textAlign: "left",
        width: 70,
        field: "source_Type",
        mobile: true,
        editField: "text",
        allowInt: true,
        filterType: "num",
        header: "Asset Type",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "PropertyID",
      //   "mobile": true,
      //   "editField": "text",
      //   filterType: "num",
      //   "header": "Prop. ID",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "StatePropertyID",
      //   "mobile": true,
      //   "editField": "text",
      //   filterType: "num",
      //   "header": "St. Prop. ID",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "header": "Src. State",
      //   "mobile": true,
      //   "editField": "text",
      //   "field": "SourceState",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Biz Name",
        mobile: true,
        editField: "text",
        field: "BusinessName",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Payments",
      //   "mobile": true,
      //   "field": "paymentsdata",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": false,
      //   "fieldType": "sumArray"
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Prop. Status",
        editField: "text",
        mobile: true,
        field: "PropertyStatus",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Updated By",
        editField: "text",
        mobile: true,
        field: "updatedBy",
        // "editField": "dropDown",
        displayInSettings: true,
        // filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "PropertyReason",
        mobile: true,
        // "editField": "dropDown",
        header: "Prop. Reason",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "header": "Payments",
      //   "mobile": true,
      //   "field": "Payments",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": false,
      //   "fieldType": "text"
      // },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Prop. Note",
        mobile: true,
        field: "PropertyNote",
        displayInSettings: true,
        filter: true,
        sortable: false,
        openNotes: true,
        fieldType: "Array",
      },

      {
        show: true,
        textAlign: "right",
        width: 150,
        header: "Total Payment",
        mobile: true,
        field: "payment",
        displayInSettings: true,
        filter: false,
        sortable: false,
        fieldType: "SumAmount",
      },
      {
        show: true,
        textAlign: "left",
        width: 80,
        header: "Add Payment(+)",
        mobile: true,
        field: "PaymentBtn",
        fieldType: "PaymentBtn",
        displayInSettings: true,
        filter: false,
        sortable: false,
        // "fieldType": "ClaimReason"
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "FileFor",
        mobile: true,
        header: "Mapped For",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "right",
        width: 120,
        allowInt: true,
        filterType: "date",
        header: "Claim Creation Date",
        mobile: true,
        filterType: "num",
        // "editField": "date",
        fieldType: "dateTime",
        field: "created",
        dateFormat: "MM-DD-yyyy",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 150,
        allowInt: true,
        filterType: "text",
        header: "Claim Notes",
        mobile: true,
        // "editField": "text",
        fieldType: "ArrayNote",
        openNotes1: true,
        field: "claimNotes",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Claim Reason",
        mobile: true,
        field: "ClaimReason",
        editField: "dropDown",
        options: [],
        displayInSettings: true,
        filter: false,
        sortable: false,
        fieldType: "ClaimReason",
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Analyst",
        mobile: true,
        field: "Analyst",
        editField: "dropDown",
        displayInSettings: true,
        options: this.state.userArray,
        filter: false,
        sortable: false,
        fieldType: "Analyst",
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Seller Proof",
        mobile: true,
        field: "sellerProof",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Name Proof",
        mobile: true,
        field: "nameProof",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Address Proof",
        mobile: true,
        field: "addressProof",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "FEIN Proof",
        mobile: true,
        field: "feinProof",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Holder Proof",
        mobile: true,
        field: "holderProof",
        // "editField": "dropDown",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Claim Latest Estimated Value",
        mobile: true,
        // allowInt: true,
        field: "claimLatestEstimatedValue",
        editField: "text",
        filterType: "num",
        fieldType: "formatCurrencywithdec",
        displayInSettings: true,
        filter: true,
        sortable: true,
        // "fieldType": "Analyst"
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Claim Estimated Value At Filing",
        mobile: true,
        // allowInt: true,
        field: "claimEstimatedValueAtFiling",
        // "editField": "dropDown",
        filterType: "num",
        fieldType: "formatCurrencywithdec",
        editField: "text",
        inputType: "number",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "right",
        width: 120,
        header: "Claim Amount Paid",
        mobile: true,
        fieldType: "formatCurrencywithdec",
        // allowInt: true,
        field: "claimAmountPaid",
        filterType: "num",
        // "editField": "dropDown",
        editField: "text",
        inputType: "number",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "outTheDoor_DateTime_UTC",
        mobile: true,
        editField: "text",
        fieldType: "dateTime",
        dateFormat: "MM-DD-yyyy",
        header: "Claim Filed Date",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        editField: "text",
        field: "outTheDoor_ByUser_Name",
        mobile: true,
        header: "Claim Filed By",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "updatedAtUtc",
        mobile: true,
        editField: "text",
        fieldType: "dateTime",
        dateFormat: "MM-DD-yyyy",
        header: "Updated Date",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        editField: "text",
        field: "claimValueComputeColor",
        mobile: true,
        filterType: "multiSelect",
        filterType: "multiSelect",
        fieldType: "MultiColors",
        filterOptions: [{ label: "Actual", value: "Green" }, { label: "Estimated", value: "Light Brown" }, { label: "Actual + Estimated", value: "Brown" }, { label: "No Connected Properties", value: null }],
        header: "Claim Value Type",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        //'show': false,
        value: "",
        type: "text",
        name: "reason",
        label: "Reason",
        id: "name",
        placeholder: "Reason Name",
        required: true,
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        value: "",
        type: "dropDown",
        name: "status",
        label: "Status",
        options: this.state.Statuses,
        id: "status",
        placeholder: "status",
        required: true,
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': Number,
      //   "value": "",
      //   "type": "text",
      //   "formatType": "Number",
      //   "name": "sequence",
      //   "label": "Sequence",
      //   // "options": this.state.company,
      //   // "id": "name",
      //   "placeholder": "Sequence",
      //   // "required": true
      // },
      {
        //'show': false,
        value: "",
        type: "switch",
        name: "activeIndicator",
        label: "Active /Inactive Status",
        id: "name",
        placeholder: "Name",
        // "required": true
      },
    ];
  };

  /**
   *
   * @param {Array} itemdata
   * @returns updated values
   */
  updateDealIDToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (this.state.first) {
        item.Sno = index + 1 + this.state.first;
      } else {
        item.Sno = index + 1;
      }
      item.DealID = this.state.selectedDealID;
      modifiedData.push(item);
    });
    return modifiedData;
  }
  /**
  * 
  * @param {string} edited 
  * function to save the note is edited or not for slecting and unselecting records
  */
  commentEditedOrNo = async (edited) => {
    // set not edited or not in editdatatable for selecting and unselecting rows
    await this.editdt.commentEditedOrNo(edited)
    await this.setState({ edited: edited })
  }
  setCompanyId = async (id) => {
    await this.setState({ CompanyID: id });
  };
  setLoading = async (type) => {
    this.editdt.setLoading(type);
  };
  saveClaim = async (values, newType) => {
    let api,
      type,
      selectedIds = [];
    let body =
      this.state.allSelectedRows && this.state.allSelectedRows.length > 1
        ? {}
        : values;
    if (this.state.allSelectedRows && this.state.allSelectedRows.length > 1) {
      const selRows = this.editdt
        ? this.editdt.getSelectedTable()
        : this.state.allSelectedRows;
      for (let selectedRow of selRows) {
        selectedIds.push(selectedRow._id);
      }
      body.selectedIds = selectedIds;
      body.claimStatus = values.claimStatus;
      body.claimReason = values.claimReason;
      body.claimNotes = values.claimNotes;
      // if (values.next_Action_Date) {
      //   body.nextActionDate = values.next_Action_Date;
      // }
      // body.analyst = values.analyst;
      type = "PUT";
      api = `${apiCalls.Claims}/multi`;
    } else if (body._id) {
      type = "PUT";
      api = `${apiCalls.Claims}/${body._id}`;
      if (!values.CompanyID) {
        body.CompanyID = this.state.CompanyID;
      }
    } else {
      type = "POST";
      api = `${apiCalls.Claims}`;
      if (!values.CompanyID) {
        body.CompanyID = this.state.CompanyID;
      }
    }

    return fetch(type, api, body).then(async (resp) => {
      if (resp && resp.respCode && (resp.respCode === 200 || resp.respCode === "200")) {
        await this.setState({ edited: "no" })
        await this.editdt.commentEditedOrNo("no")
        await this.toggle("close");
        const filterCriteria = this.editdt && this.editdt.state && this.editdt.state.filterCriteria ? this.editdt.state.filterCriteria : filter;
        await this.getDataFromServer(this.state.Object1, filterCriteria, "Preset", newType);
        await this.showToasterMessage(resp);
      } else {
        await this.setState({
          isLoading: false,
        });
        if (this.claimDetailsRef) {
          this.claimDetailsRef.showErrorMessage(resp.errorMessage);
        }
      }
    });
  };
  /**
   *
   * @param {*} itemdata
   * @param {*} tableFields
   * @param {*} dateFormat
   * @returns
   */
  updateDateFormat = async (itemdata, tableFields, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("dateTime" === tableFields[k]["fieldType"]) {
          if (itemdata[i][tableFields[k]["field"]] !== null) {
            itemdata[i][tableFields[k]["field"]] = dateFormats.formatDate(
              itemdata[i][tableFields[k]["field"]],
              "MM/DD/YYYY",
              "NS"
            );
            if (i === 0) {
            }
          }
        }
      }
      modifiedData.push(itemdata[i]);
    }
    return modifiedData;
  };
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false,
    });
  };
  closeSaveModelPopUp = async () => {
    await this.setState({ openUnsaveModal: false })
    if (this.state.activeTab === "4") {
      await this.rfiFormRef.getForm()
      await this.closeTaskAddModel()
    } else {
      await this.claimDetailsRef.getForm()
    }

  }
  closeTaskAddModel = async () => {
    await this.setState({
      activeTab: "closeByIcon",
      openClaimDetails: false,
      edited: "no",
      openUnsaveModal: false,
      isSelectedRow: false,
      // selectedObj: selectedObj
    });
    await this.setState({ selectedRows: [], allSelectedRows: [] });
    this.editdt.onSelectRowsUpdateForClaims([]);
    this.editdt.onSelectAll("onClickIcon")
    await this.editdt.commentEditedOrNo("no")
    // window.scrollTo(0, document.body.scrollHeight);
  }
  // Delete Modal
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }
  makeValuesEmptyAfterHittingSaveOrClear = async () => {
    this.redirected = ""
    this.isRedirected=""
    this.claimNewID = ""
    this.companyID = ""
     this.bookMarkDealID=""
    this.oppClaimID = ""
    await this.setState({ activeTab: "close", allSelectedRows: [] })
  }
  closeBookMarkModal = async () => {
    await this.setState({ isOpenSaveBookMark: false })
  }

  showToasterMessage = (res) => {
    const message = res['respMessage'] || res['errorMessage'];
    if (res && message && this.editdt) {
      this.editdt.showErrorMessage(message, res['errorMessage'] ? 'error' : 'success');
    }
  }


  bookmarkResponseHandler = async (response) => {
    if (response && response.respMessage) {
      await this.closeBookMarkModal()
      await this.getBookMarks()
      if (this.state.allDetails && this.state.allDetails.length > 0) {
        await this.getBookMarkChange(response.filterBookmarkId);
        this.showToasterMessage(response);
      }
    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
      await this.setState({
        sessionWarning: true,
      })
    } else if (response && response.errorMessage) {
      if (this.bookMarkRef) {
        this.bookMarkRef.showToasterMessage(response);
      }
    }

    await this.setState({ isLoading: false })
  }

  saveBookMarkDetails = async (filterName) => {
    await this.setState({ isLoading: true })
    let BMUrl = this.state.bookMarkURL.split("?")
    const urlSearchParams = new URLSearchParams(BMUrl[1]);
    const params = Object.fromEntries(urlSearchParams.entries());
    let body = {
      "filterName": filterName,
      "filterJson": JSON.stringify(params),
      "screen": "Claims"
    }
    return fetch('POST', apiCalls.BookMark, body)
      .then(this.bookmarkResponseHandler).catch((err) => {
        return err;
      });
  }

  updateBookMark = async (filterName, selectedBookMark) => {
    await this.setState({ isLoading: true })
    let BMUrl = this.state.bookMarkURL.split("?")
    const urlSearchParams = new URLSearchParams(BMUrl[1]);
    const params = Object.fromEntries(urlSearchParams.entries());
    let body = {
      "assignedTo": selectedBookMark.assignedTo,
      "filterName": filterName,
      "filterJson": JSON.stringify(params),
      _id: selectedBookMark._id
    }
    return fetch('PUT', `${apiCalls.BookMark}/${selectedBookMark._id}`, body)
      .then(this.bookmarkResponseHandler).catch((err) => {
        return err;
      });
  }
  deleteBookMark = async (value) => {
    await this.setState({ isLoading: true })
    let bookMarkNameDetails = this.state.allDetails.find(
      (elem) => elem._id == value
    );
    return fetch('DELETE', `${apiCalls.BookMark}/${bookMarkNameDetails._id}`)
      .then(async (response) => {
        let isSelectedBookMarkDelete = false
        if (response && response.respMessage) {
          await this.getBookMarks();
          isSelectedBookMarkDelete = this.state.selectedBookMark === value;
          if (isSelectedBookMarkDelete) {
            await this.removeExistingBookmark()
          } else {
            await this.setState({ isLoading: false })
          }
          this.showToasterMessage(response);
        } else if (response && response.errorMessage) {
          this.showToasterMessage(response);
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
          })
        }
        if (!isSelectedBookMarkDelete) {
          await this.setState({ isLoading: false });
        }
      }).catch((err) => {
        return err;
      });
  }

  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getDataFromServer}
      />
    );
  }

  getErrorResponsefromServer = async (response) => {
    if (
      response &&
      response["errorMessage"] ===
      configMessages.SESSION_EXPIRATION_WARNING_MESSAGE
    ) {
      await this.setState({
        sessionWarning: true,
      });
    } else if (
      response &&
      response["errorMessage"] === configMessages.SESSION_EXPIRED_MESSAGE
    ) {
      await this.setState({
        sessionExpiryModal: true,
      });
    } else {
      if (this.editdt) {
        this.editdt.showErrorMessage(response["errorMessage"], 'error');
      }
    }
  };

  setFilterCriteriaOnClear = async () => {
    if (this.editdt) {
      this.editdt.setFilterCriteriaOnClear();
    }
  };
  /**
   * to remove datatable all Filters
   * @param {String} type
   */
  getremoveFiltersForClaims = async (type) => {
    await this.setState({
      clearFilters: true,
      page: 1,
      first: 0,
      isshowToasterMsg: false,
      showToasterMsg: "",
    });
    let filteredNo = document.getElementsByClassName("p-column-filter").length;
    for (let i = 0; i <= filteredNo; i++) {
      if (
        document.getElementsByClassName("p-column-filter")[i] &&
        document.getElementsByClassName("p-column-filter")[i].value
      )
        if (
          document.getElementsByClassName("p-column-filter")[i].value !== ""
        ) {
          document.getElementsByClassName("p-column-filter")[i].value = "";
          let getName =
            document.getElementsByClassName("p-column-filter")[i].name;
          this.setState({
            [getName]: "",
          });
        }
      if (
        document.getElementsByClassName("p-column-filterComponent")[i] &&
        document.getElementsByClassName("p-column-filterComponent")[i].style
          .display === "block"
      ) {
        document.getElementsByClassName("p-column-filterComponent")[
          i
        ].style.display = "none";
      }
    }
    this.setState({
      claimStatus: "",
      claimReason: "",
    });
  };
  /**
   * Model to make model open for bookmark save
   */
  saveBookMark = async () => {
    await this.setState({ isOpenSaveBookMark: true })
  }
  handleCalltoPreventParentProp(e) {
    e.stopPropagation()
    e.preventDefault()
  }
  itemTemplate = (option) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 10px'
      }}>
        <div>
          <span>{option.label ? option.label : " "}</span> { }
        </div>
        <div>

          <FontAwesomeIcon
            icon={faTrash}
            onClick={(e) => this.openConfirmaionModal(e, option)}
            style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
          />
        </div>
      </div>
    );
  };

  setBookMarkNoDataFoundMsg = () => {
    if (!this.state.BookMarks || (this.state.BookMarks && this.state.BookMarks.length === 0)) {
      this.setState({ displayBookNoDataFoundMsg: true });
      setTimeout(() => {
        this.setState({ displayBookNoDataFoundMsg: false });
      }, 3000);
    }
  }

  openConfirmaionModal = (e, option) => {
    e.stopPropagation(); // Prevents the event from bubbling up
    this.setState({ isOpengetConfirmaionModel: true, confirmationType: "bookMark", confirmationData: option.value }); // Opens the modal
  }

  closeConfirmaionModal() {
    this.setState({ isOpengetConfirmaionModel: false, confirmationType: null, confirmationData: null });
  }

  closeConfirmationModal = (type) => {
    if (type === 'close') {
      if (this.state.confirmationType === "bookMark" && this.state.confirmationData) {
        this.deleteBookMark(this.state.confirmationData);
      }
    }
    this.closeConfirmaionModal();
  }

  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isOpengetConfirmaionModel}
        closeConfirmationModal={this.closeConfirmationModal}
        shownText={"Are you sure you want to delete?"}
      />
    )
  }

  getAccordionHeader(e) {
    return (
      <span className={"d-flex col-sm-12 pl-0 pr-0"}>
        <div className={" mr-auto px-0 accordian_custum_header"} style={{ width: "50rem" }}>
          <span>Filters</span>
          {/* <span style={{ marginLeft: "40px", textAlign: "center", color: this.state.errorcolor }}>{this.state.errorResponse && this.props.type !== "Property Editor V2" ? this.state.errorMessage : ""}</span> */}

        </div>
        <div className='ml-2 d-flex custum_btns_for_PE accordian_custum_header w-100' onClick={(e) => this.handleCalltoPreventParentProp(e)}>
          <div className="d-flex w-100">
            <div className='ml-2 mr-2'>
              Filter Views :
            </div>
            <div className='ml-2 mr-1'>
              <Dropdown
                placeholder={"Select Filter View"}
                value={this.state.selectedBookMark}
                filter={true}
                style={{ minWidth: "25rem", maxWidth: "200px" }}
                itemTemplate={this.itemTemplate}
                appendTo={document.body}
                // disabled={selectedRows && selectedRows.length > 0 && properData && properData.selectedSeller && !this.state.createTask ? false : true}
                options={this.state.BookMarks ? this.state.BookMarks : []}
                onMouseDown={this.setBookMarkNoDataFoundMsg}
                onChange={(e) => this.getBookMarkChange(e.value,)}
              />
              <div className="position-relative">
                <small className="d-block p-0 m-0 position-absolute text-danger" style={{ top: '-6px' }}>
                  {this.state.displayBookNoDataFoundMsg && 'No bookmarks found.'}
                </small>
              </div>
            </div>

            <div className='ml-2 mr-3'>
              <FontAwesomeIcon
                className={"bookmark"}
                color="#354f6e"
                style={{
                  marginTop: "13px",
                  marginRight: "6px",
                  color: this.state.selectedBookMark ? "red" : "lightgrey",
                }}
                data-toggle="tool-tip"
                title={"Remove Bookmark"}
                onClick={() => {
                  this.removeExistingBookmark();
                }}
                icon={faTimes}
              />
            </div>
            <div className='ml-2 mr-auto'>
              <FontAwesomeIcon
                className={"bookmark"}
                color="#354f6e"
                style={{
                  marginTop: "13px",
                  marginRight: "6px",
                }}
                data-toggle="tool-tip"
                title={"Save BookMark"}
                onClick={() => {
                  this.saveBookMark();
                }}
                icon={faBookmark}
              />
            </div>

          </div>
        </div>
      </span>
    )
  }

  setSortFilters = async (key) => {
    if (key === "selectedDepartment") {
      await this.setState({ setDepartmentForFilter: null })
    } else if (key === "nextActionDateFilter") {
      await this.setState({ setNextActionDateForFilter: null })
    } else {
      await this.setState({ setNextActionDateForFilter: null, setDepartmentForFilter: null })
    }
  }
  setSellerNickName = async (details) => {
    await this.setState({
      sellerNickName: details.sellerNickName,
      dealType: details.dealType,
    });
  };
  setNewRfiClaim = async (taskID) => {
    await this.setState({ activeTab: "4", selectedObj: { _id: taskID } })
    this.toggle("4", "RFI Active", "RFI Active");
  }
  setTopFiltersInClaimFilters = async (filters) => {
    let value
    let topFilters = filters.criteria

    for (let topFilter of topFilters) {
      if (topFilter.add_Filter) {
        let changeKeyname = { oppClaimID: "OppClaimID", stateClaimID: 'StateClaimID', companyID: 'CompanyID' };
        topFilter.key = changeKeyname[topFilter.key] ? changeKeyname[topFilter.key] : topFilter.key;
        if (topFilter.key === "StateClaimID" || topFilter.key === "OppClaimID" || topFilter.key === "ConnectedProperties.StatePropertyID" || topFilter.key === "ConnectedProperties.PropertyID") {
          if (topFilter.key === "ConnectedProperties.StatePropertyID") {
            topFilter.key = "StatePropertyID"
          } else if (topFilter.key === "ConnectedProperties.PropertyID") {
            topFilter.key = "PropertyID"
          }
          value = Array.isArray(topFilter.value) ? topFilter.value.join("\n") : topFilter.value;
          this.ClaimFilterref.onFilterChanges(topFilter.key, value)
        } else {
          if (topFilter.key === "CompanyID") {
            await this.ClaimFilterref.setDeal(topFilter.value);
            await this.setState({ bookMarkDealID: topFilter.value })
            topFilter.value = await this.ClaimFilterref.state.selectedSeller ? this.ClaimFilterref.state.selectedSeller : topFilter.value;
          }
          this.ClaimFilterref.onFilterChanges(topFilter.key, topFilter.value)
        }
      }
    }
  }
  removeExistingBookmark = async () => {
    await this.setState({ selectedBookMark: null, bookMarkNameDetails: {}, })
    this.topFilters = []
    this.inLineFilters = []
    let obj = {};
    let user = JSON.parse(localStorage.getItem("loginCredentials"));
    if (
      user &&
      user.department &&
      user.roleName &&
      user.roleName.includes("CoreOps")&&!user.defaultUser
    ) {
      obj.selectedDepartment = [user.departmentId];
    }
    await this.editdt.getTableFieldsforTableColumns([])
    await this.editdt.getTableFieldsforTableColumns(await this.getTableFields())
    let sendpresetForBookmark = await this.editdt.sendpresetForBookmark()
    if (sendpresetForBookmark) {
      await this.setState({ sendpresetForBookmark: sendpresetForBookmark })
      await this.editdt.getDataFromServerToSetPreset(sendpresetForBookmark)
    } else {
      await this.editdt.getDataFromServerToSetPreset(await this.editdt.getDefaultPresetForUser())

    }
    await this.editdt.clearDropDownOptions()
    await this.ClaimFilterref.clearFilters()
  }

  getBookMarkChange = async (id) => {
    await this.setState({ isBookMarkLoading: true });
    await this.editdt.getTableFieldsforTableColumns([])
    await this.editdt.getTableFieldsforTableColumns(await this.getTableFields())
    let sendpresetForBookmark = await this.editdt.sendpresetForBookmark()
    if (sendpresetForBookmark) {
      await this.setState({ sendpresetForBookmark: sendpresetForBookmark })
      await this.editdt.getDataFromServerToSetPreset(sendpresetForBookmark)
    }
    let bookMarkNameDetails = this.state.allDetails.find(
      (elem) => elem._id == id
    );
    let filterJson = bookMarkNameDetails.filterJson;
    await this.ClaimFilterref.setFiltersForBookMark()
    let topFilter = [], inLineFilter = []
    await this.setState({ selectedBookMark: id, bookMarkNameDetails: bookMarkNameDetails })
    let filterJsonObj = JSON.parse(filterJson)
    let filterCriteriaFromBookmark = JSON.parse(filterJsonObj.filter)
    for (let bk of filterCriteriaFromBookmark.criteria) {
      // if (bk && bk.value) {
      //   bk.value = typeof (bk.value) === "string" ? encodeURIComponent(bk.value) : Array.isArray(bk.value) ? bk.value.map(str => encodeURIComponent(str)) : bk.value;
      // }
      if (bk.add_Filter) {
        topFilter.push(bk)
      } else {
        inLineFilter.push(bk)
      }
    }
    this.inLineFiltersFromBookMark = inLineFilter
    this.topFiltersFromBookMark = topFilter
    await this.setTopFiltersInClaimFilters(JSON.parse(JSON.stringify(filterCriteriaFromBookmark)));
    if (filterJsonObj.nextActionDateFilter) {
      this.ClaimFilterref.onFilterChanges("nextActionDateFilter", filterJsonObj.nextActionDateFilter)
    }
    if (filterJsonObj.selectedDepartment) {
      const selectedDepartment = filterJsonObj.selectedDepartment.split(",").map(item => item.trim());
      this.ClaimFilterref.onFilterChanges("selectedDepartment", selectedDepartment);
    }
    await this.getDataFromServer({ NextActionDate: filterJsonObj.nextActionDateFilter, selectedDepartment: filterJsonObj.selectedDepartment && filterJsonObj.selectedDepartment.length > 0 ? filterJsonObj.selectedDepartment.split(", ") : null, selectedClaims: { EstateID: this.state.bookMarkDealID } }, filterCriteriaFromBookmark, "", "fromBookMark")

    await this.editdt.handlefiltersafterreorder(filterCriteriaFromBookmark)
    await this.setState({ isBookMarkLoading: false });
  }
  openSaveBookMarkModal() {
    return (
      <SaveBookMarkModal
        onRef={(ref) => (this.bookMarkRef = ref)}
        openDeleteModal={this.state.isOpenSaveBookMark}
        saveBookMark={this.saveBookMarkDetails}
        updateBookMark={this.updateBookMark}
        closeBookMarkModal={this.closeBookMarkModal}
        type={this.props.type}
        bookMarkNameDetails={this.state.bookMarkNameDetails}
      />
    )
  }
  getDataFromServer = async (
    Object1,
    filterCriteriaObj,
    DontHitPreset,
    type
  ) => {
    await this.setState({
      emptyselectedRows: false,
      activeTab: null,
      isLoading: true,
    });
    let filtersForQuery = Object1
    let filterCriteria = JSON.parse(JSON.stringify(filterCriteriaObj));
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (type === "refresh") {
      this.removeExistingBookmark();
      return;
    }
    for (let i in params) {
      if (i === "redirected") {
        if (params[i] === "fromStateClaimID") {
          this.redirected = "fromStateClaimID"
        } else if (params[i] === "fromHashClaims") {
          this.redirected = "fromHashClaims"
        }
        this.claimNewID = params["claimNewID"]
        this.oppClaimID = params["oppClaimID"]
        this.companyID = params["companyID"]
      }
       this.isRedirected=true
            // else if (i === "redirected" && params[i] === "fromHashClaims") {
      //   this.claimNewID = params["claimNewID"]
      //   this.oppClaimID = params["oppClaimID"]
      //   this.companyID = params["companyID"]
      // }
      let data = [];
      data.push(params[i]);
      if (i && i !== "ASSET_TRACKING_STATUS" && i !== "redirected" && i !== "claimNewID") {
        let obj = {};
        obj["key"] = i;
        obj["value"] = i === "companyID" ? parseInt(params[i]) : params[i];
        obj["type"] = "eq";
        obj["add_Filter"] = true;
        let index = filterCriteria["criteria"].findIndex(
          (data) => data.key == i
        );
        if (index !== -1) {
          filterCriteria["criteria"][index].value = params[i];
        } else {
          if (obj.key !== "estateName" && obj.value !== "null") {
            filterCriteria["criteria"].push(obj);
          }
        }
      }
      if (i === "companyID") {
        this.bookMarkDealID = params[i]
      }
      if (i && i === "ASSET_TRACKING_STATUS" && this.redirected) {
        let obj = {};
        obj["key"] = i;
        obj["value"] = [params[i]];
        obj["type"] = "in";
        obj["add_Filter"] = true
        filterCriteria["criteria"].push(obj);
      }
      this.topFilters = JSON.parse(JSON.stringify(filterCriteria["criteria"]));
    }

    if (type === "setGoButton") {
      this.editdt.resetPaginator();
      this.topFilters = JSON.parse(JSON.stringify(filterCriteriaObj.criteria));
    }
    else if (type === "fromBookMark") {
      this.topFilters = JSON.parse(JSON.stringify(this.topFiltersFromBookMark))
      const filter = JSON.parse(JSON.stringify(this.inLineFiltersFromBookMark))
      let filerObj = {};
      filter.forEach((e) => {
        filerObj[e.key] = e;
      });
      this.inLineFilters = Object.values(filerObj);
    }
    else {
      if (type === "closeRedirect") {
        this.redirected = ""
        this.claimNewID = ""
        this.isRedirected=""
        this.companyID = ""
        this.oppClaimID = ""
      }
      const filters = JSON.parse(JSON.stringify(filterCriteriaObj.criteria));
      let filerObj = {};
      filters.forEach((e) => {
        filerObj[e.key] = e;
      });
      this.inLineFilters = Object.values(filerObj);
    }
    filterCriteria.criteria = [...this.topFilters, ...this.inLineFilters];
    const assetStatus = filterCriteria.criteria.find(
      (elem) => elem.key === "ASSET_TRACKING_STATUS"
    );
    if (!assetStatus && this.state.setAssetTrackingStatus !== "Empty" && !this.state.selectedBookMark&&!this.isRedirected) {
      filterCriteria.criteria.push({
        key: "ASSET_TRACKING_STATUS",
        value: ["Active"],
        type: "in",
        add_Filter: true,
      });
    }

    if (!filterCriteria.sortfield) {
      filterCriteria["sortfield"] = "updatedAtUtc";
      filterCriteria["direction"] = "desc";
    }
    if (!filterCriteria.limit) {
      filterCriteria.limit = this.state.limit;
    }

    if (params) {
      window.history.pushState(null, null, "/claims");
    }

    filterCriteria.criteria = await filterCriteria.criteria.map(bk => {
      if (bk && bk.value) {
        const isEncoded = str => str !== decodeURIComponent(str);

        bk.value = typeof bk.value === "string"
          ? isEncoded(bk.value) ? bk.value : encodeURIComponent(bk.value)
          : Array.isArray(bk.value)
            ? bk.value.map(str => isEncoded(str) ? str : encodeURIComponent(str))
            : bk.value;
      }
      return bk;
    })

    let url = `${apiCalls.claims}?filter=${JSON.stringify(filterCriteria)}`;
    await this.setState({ exportNamesUrl: url, Object1: Object1 });
    if (Object1 && Object1.selectedClaims && Object1.selectedClaims) {
      this.setState({
        selectedDealID: Object1.selectedClaims.EstateID,
        selectedDealName: Object1.selectedClaims.EstateName,
      });
    }
    if (Object1 && Object1.NextActionDate) {
      url = `${url}&nextActionDateFilter=${Object1.NextActionDate && Object1.NextActionDate ? Object1.NextActionDate : this.state.filtersForQuery.NextActionDate}`;
      await this.setState({ setNextActionDateForFilter: Object1.NextActionDate })
    }
    if (Object1 &&
      Object1.selectedDepartment &&
      Object1.selectedDepartment.length > 0) {
      const selDep = Object1.selectedDepartment.join(", ");
      url = `${url}&selectedDepartment=${selDep}`;
      await this.setState({ setDepartmentForFilter: selDep })
    }
    // if (this.state.filtersForQuery && this.state.filtersForQuery.NextActionDate) {
    //   url = `${url}&nextActionDateFilter=${this.state.filtersForQuery.NextActionDate}`;
    // }
    // if (
    //   this.state.filtersForQuery && this.state.filtersForQuery.selectedDepartment
    // ) {
    //   const selDep2 = this.state.filtersForQuery.selectedDepartment.join(", ");
    //   url = `${url}&selectedDepartment=${selDep2}`;
    // }
    // url = `${url}&project=true`;
    this.setState({ filteredURL: url, filtersForQuery: filtersForQuery });
    var tableFields = this.getTableFields();
    if ((type === "filter" || type === "Sort" || type === "Clear Column"||type==="Page") && this.state.setDepartmentForFilter) {
      url = `${url}&selectedDepartment=${this.state.setDepartmentForFilter}`;
    }
    if ((type === "filter" || type === "Sort" || type === "Clear Column"||type==="Page") && this.state.setNextActionDateForFilter) {
      url = `${url}&nextActionDateFilter=${this.state.setNextActionDateForFilter}`;
    }
    await this.setState({ bookMarkURL: url })
    const urlObj = new URL(`${config.apiUrl}${url}`);
    const bodyParams = new URLSearchParams(urlObj.search);
        const body = {};
        bodyParams.forEach((value, key) => {
      body[key] = value;
    });
 
    return fetch("POST", apiCalls.PostListClaims,body)
      .then(async (response) => {
        if (response && response["claims"] && response["claims"].length > 0) {
          var updatedData = this.updateDealIDToData(response["claims"]);
          updatedData = await this.updateDateFormat(
            response["claims"],
            tableFields,
            "MM/DD/YYYY"
          );
          if (this.editdt) {
            if (type === "fromBookMark" && this.state.sendpresetForBookmark) {
              await this.editdt.loadAllPresets(this.state.sendpresetForBookmark);
              this.editdt.setActiveTab("ClaimsManagement");
            }
            else if (!DontHitPreset) {
              await this.editdt.loadAllPresets();
              this.editdt.setActiveTab("ClaimsManagement");
            }
            this.editdt.getValueforTable(
              updatedData,
              response.pagination.totalCount,
              this.redirected, this.claimNewID
            );
          }
        } else if (
          response &&
          response["claims"] &&
          response["claims"].length === 0
        ) {
          await this.setState({
            ["claims"]: [],
            totalRecordsLength: 0,
            totalRecordsLength: 0,
          });
          await this.setState({ isLoading: false });
          if (this.editdt) {
            this.editdt.getValueforTable(
              response["claims"],
              response.pagination.totalCount
            );
            if (!DontHitPreset) {
              await this.editdt.loadAllPresets();
            }
            this.editdt.setActiveTab("ClaimsManagement");
          }
        } else if (response && response["errorMessage"]) {
          this.getErrorResponsefromServer(response);
        }
        await this.setState({ isLoading: false });
        if (!this.redirected) {
          await this.editdt.onSelectRowsUpdate({ value: [] });
        }
        this.toggle("close");
      })
      .catch((err) => {
        return err;
      });
  };

  createBasicDataforRFI = async (selectedRows) => {
    let selectedRow = selectedRows;
    var selectedObj = {}
    if (selectedRow && Object.keys(selectedRow).length > 0) {
      selectedObj = {
        company: selectedRow.estateName,
        states: selectedRow.sourceState,
        relevantSellerName: selectedRow.relevantSellerName,
        mappedName: selectedRow.mappedName,
        title: "RFI - Request for Information",
        purpose: "RFI Received",
        status: "Not-Started",
        propertyCount:selectedRow.connectedProperties?selectedRow.connectedProperties.length:null,
        claimObjId: selectedRow._id,
        stateClaimID: selectedRow.stateClaimID,
        oPPClaimID: selectedRow.oppClaimID,
        oppClaimID: selectedRow.oppClaimID,
        documents: selectedRow.documents,
        claimID: selectedRow.oppClaimID,
        opraEstateID: selectedRow.companyID
      };
      return selectedObj
    } else {
      return {}
    }

  }
  setEditedRows = async (selectedRows) => {
    let selectedRow = selectedRows[0];

    let selectedObj = await this.createBasicDataforRFI(selectedRow)
    await this.setState({
      selectedObj: selectedObj,
      basicCreateObj: selectedObj,
      allSelectedRows: selectedRows,
      selectedRows: selectedRows && selectedRows[0] ? selectedRows[0] : {},
      isSelectedRow: selectedRows && selectedRows.length > 0 ? true : false,
      selectedProperties:
        selectedRows && selectedRows[0] && selectedRows[0].connectedProperties
          ? selectedRows[0].connectedProperties
          : [],
      activeTab: selectedRows && selectedRows.length > 0 ? "1" : null,
    });
    if (this.claimDetailsRef) {
      this.claimDetailsRef.setEditedRowsNumber(selectedRows);
    }
    if (selectedRows && selectedRows.length === 0) {
      this.setState({ emptyselectedRows: false, activeTab: null });
      this.toggle(0);
    } else {
      if (this.redirected === "fromStateClaimID") {
        this.setState({ emptyselectedRows: true, activeTab: "1" });
      } else if (this.redirected === "fromHashClaims") {
        this.setState({ emptyselectedRows: true, activeTab: "2", emptyselectedRows: false, openClaimDetails: false });
      } else {
        this.setState({ emptyselectedRows: true, activeTab: "1" });
      }
    }
    if (selectedRows && selectedRows.length > 0) {
      this.getRFIRecentconnectedTask(selectedRow);
    }
  };

  setEditedRowsEmpty = async () => {
    await this.setState({ allSelectedRows: [] });
  };
  //Datatable properties sending to the Edit Datatable component(child component)
  getDataTable = () => {
    return (
      <div
        className={
          this.state.updatedRecords && this.state.updatedRecords.length > 0
            ? "disabledSelection disabledRow"
            : ""
        }
      >
        <EditDataTable
          onRef={(ref) => (this.editdt = ref)}
          selectedDeal={this.state.selectedDeal}
          selectedDealID={this.state.selectedDealID}
          closeSaveModelPopUp={this.closeSaveModelPopUp}
          // exportToCSVCriteria={this.state.filterCriteria.criteria}
          filteredURL={this.state.filteredURL}
          metaKeySelection={false}
          Object1={this.state.Object1}
          redirected={this.redirected}
          companyID={this.companyID}
          claimNewID={this.claimNewID}
          oppClaimID={this.oppClaimID}
          isEditableGrid={false}
          defSortField={this.state.defSortField}
          getTableFields={this.getTableFields}
          setEditedRows={this.setEditedRows}
          setHitUpdateButtonFromPropertyDetails={this.setHitUpdateButtonFromPropertyDetails}
          setEditedRowsEmpty={this.setEditedRowsEmpty}
          strArray={this.state.strArray}
          scrollHeight={
            (this.state.allSelectedRows &&
              this.state.allSelectedRows.length > 0) ||
              (this.state.activeTab &&
                this.state.activeTab !== "close" &&
                this.state.activeTab !== "closeByIcon")
              ? "185px"
              : "calc(100vh - 380px)"
          }
          disableInlineEdit={this.disableInlineEdit}
          disabled={true}
          tablefieldsToShow={this.getTableFields}
          getTableFieldsforData={this.getTableFields}
          deleArray={this.state.deleArray}
          openClaimDetails={this.openClaimDetails}
          shownFieldTab={this.state.shownFieldTab}
          removeUtc={true}
          claimStatusArray={
            this.state.claimStatusArray ? this.state.claimStatusArray : []
          }
          colorValues={[{ label: "Actual", value: "Green" }, { label: "Estimated", value: "Light Brown" }, { label: "Actual + Estimated", value: "Brown" }, { label: "No Connected Properties", value: "" }]}
          claimReasonArray={
            this.state.claimReasonArray ? this.state.claimReasonArray : []
          }
          anyOneField={
            this.state.CrudInputs &&
              this.state.CrudInputs.validationType === "or"
              ? this.state.CrudInputs.anyOneField
              : []
          }
          multiSaveUrl={
            this.state.CrudInputs ? this.state.CrudInputs.multiSaveUrl : ""
          } //multi save
          multiDeleteUrl={
            this.state.CrudInputs ? this.state.CrudInputs.multiDeleteUrl : ""
          } //multi delete
          newRecordEmptyObj={
            this.state.CrudInputs ? this.state.CrudInputs.newRecordObj : {}
          } //new record obj
          requiredFields={
            this.state.CrudInputs ? this.state.CrudInputs.requiredFields : []
          } //required field in array of strings
          uniqueDBField={
            this.state.CrudInputs ? this.state.CrudInputs.uniqueDBField : ""
          } //unique id for the rec
          deleteRestrictionField={
            this.state.CrudInputs
              ? this.state.CrudInputs.deleteRestrictionField
              : ""
          }
          deleteRestrictionFieldValues={
            this.state.CrudInputs
              ? this.state.CrudInputs.deleteRestrictionFieldValues
              : []
          }
          type="ClaimsManagement"
          activeTab="ClaimsManagement" // onSort={this.sortChange}
          filterCriteria={filter}
          toggle={this.getDataFromServer}
          table_name={"Claims"}
          noShowHideColOpt={true}
          updateLimit={(limit) => { this.setState({ limit: limit }) }}
        />
      </div>
    );
  };
  showMessage = async (resp) => {
    await this.editdt.getResponseMsgfromServer(resp.respMessage, "success");
  };
  downLoadExcel = async (propertyUrl) => {
    await this.editdt.getExcelFileForDownload(
      [],
      [],
      "",
      [{ label: "Prev. St.Claim ID", field: "previousStateClaimID" },
      { label: "St.Claim ID", field: "claimID" },
      { label: "Prev Opp Claim ID", field: "previousOppClaimID" },
      { label: "OPP Claim ID", field: "connectedClaimID" },
      { label: "St. Prop. ID", field: "statePropertyId" },
      { label: "OPP Prop. ID", field: "propertyID" },
      { label: "Min Amt", field: "propertyMinValueAmount" },
      { label: "Max Amt", field: "propertyMaxValueAmount" },
      { label: "Prop. Status", field: "propertyStatus" },
      { label: "Prop. Reason", field: "propertyStatusReason" },
      { label: "Src. St", field: "sourceState" },
      { label: "Mapped Name", field: "fileFor" },
      { label: "Relevant Seller Name", field: "relevantSellerName" },
      { label: "Property Description", field: "propertyDescription" },
      { label: "Holder Name", field: "holderName" },
      { label: "Reported Owner Name", field: "propertyName" },
      { label: "Prop. Address Line1", field: "propertyAddressLine1" },
      { label: "Prop. Zip", field: "propertyZipCode" },
      { label: "Prop. City", field: "propertyCity" },
      { label: "Prop. St", field: "propertyState" },
      { label: "Filed By", field: "filedBy" },
      { label: "Mapped Note", field: "matchStatusNotes" },
      ],
      "PropertyDetails",
      this.state.allSelectedRows[0],
      propertyUrl
    );
  };
  toggle = async (tab) => {
    let selectedObj = this.state.selectedObj
    if (tab === "4") {
      selectedObj = await this.getRFIRecentconnectedTask();
    } else if (tab === "5") {
      await this.getrfihistoryData();
    } else if (tab === "1") {
      selectedObj = await this.createBasicDataforRFI(this.state.allSelectedRows[0])
      await this.setState({ selectedObj: selectedObj })
    }


    if (tab === "closeByIcon") {
      if (this.state.edited === "yes" && (this.state.isSelectedRow || this.state.noteSavedStatus)
      ) {
        //to open model if note is edited and trying to close by click on icon
        await this.setState({ openUnsaveModal: true })
        return
      }
      await this.setState({ selectedRows: [], allSelectedRows: [], edited: "no" });
      this.editdt.onSelectRowsUpdateForClaims([]);
      await this.editdt.commentEditedOrNo("no")
      this.redirected = ""
      this.isRedirected=""
      this.editdt.onSelectAll("onClickIcon")
      if (this.state.hitUpdateButtonFromPropertyDetails) {
        await this.getDataFromServer(this.state.Object1, filter, "Preset");
        this.setHitUpdateButtonFromPropertyDetails(false)
      }

    }
    await this.setState({
      activeTab: tab,
      openClaimDetails: false,
      isSelectedRow: false,
      // selectedObj: selectedObj
    });
    // window.scrollTo(0, document.body.scrollHeight);
  };
  setHitUpdateButtonFromPropertyDetails = async (value) => {
    await this.setState({ hitUpdateButtonFromPropertyDetails: value })
  }

  getRFIRecentconnectedTask = async (selectedRows) => {
    this.setState({ isLoading: true })
    let filterCriteria = {
      criteria: [
        {
          key: "claimID",
          value: selectedRows ? selectedRows.oppClaimID : this.state.selectedRows.oppClaimID,
          type: "eq",
        },
      ],
      limit: "1",
      sortfield: "created",
      direction: "desc",
    };
    await fetch(
      "GET",
      `${apiCalls.Tasks}?filter=${JSON.stringify(filterCriteria)}`
    ).then(async (response) => {
      if (response && response["tasks"] && response["tasks"].length > 0) {
        let recentrfiTasks = response["tasks"][0];
        // this.props.load(recentrfiTasks);
        await this.setState({ selectedObj: recentrfiTasks, recentTaskID: recentrfiTasks.taskID, recentRFITasks: recentrfiTasks, isLoading: false });
        return response["tasks"][0];
      } else if (response && response["errorMessage"]) {
        await this.setState({ selectedObj: {}, recentTaskID: null, isLoading: false });

        this.getErrorResponsefromServer(response);
      } else {
        this.setState({ isLoading: false, recentTaskID: null, selectedObj: {} })

      }
    });
  };

  /**
   * RFI History fetch call
   */
  getrfihistoryData = async () => {
    let filterCriteria = {
      criteria: [
        {
          key: "claimID",
          value: this.state.selectedRows.oppClaimID,
          type: "eq",
        },
      ],
      sortfield: "created",
      direction: "desc",
    };
    await fetch(
      "GET",
      `${apiCalls.Tasks}?filter=${JSON.stringify(filterCriteria)}`
    ).then(async (response) => {
      if (response && response["tasks"] && response["tasks"].length > 0) {
        let rfiTasks = response["tasks"];
        await this.setState({ rfiHistory: rfiTasks });
      } else if (response && response["errorMessage"]) {
        await this.setState({ rfiHistory: [], isLoading: false });
        this.getErrorResponsefromServer(response);
      } else {
        await this.setState({ rfiHistory: [], isLoading: false });

      }
    });
  };

  setAssetTrackingStatus = async (value) => {
    await this.setState({ setAssetTrackingStatus: value });
  };

  //getting Error Message from formModa;
  getErrorMessage = async (errorMessage, type, color) => {
    if (type === "removingSeller") {
      let counts = {
        Green: 0,
        Blue: 0,
        Teal: 0,
        Brown: 0,
        Yellow: 0,
        Black: 0,
        All: 0,
        PV: 0,
        SP: 0,
      };
      await this.setState({
        allUsersData: [],
        PendingCount: 0,
        ConfirmedCount: 0,
        MissingCount: 0,
        Counts: counts,
        fileForOptions: [],
        lastScoredDate: "",
        IssueCodes: {},
        redirectSellerId: null,
        negativePeriod: null,
      });
      if (
        this.props.type !== "Up Prop Confirmation" &&
        this.editorComponentRef
      ) {
        this.editorComponentRef.handlePropertiesEditChange(
          "",
          "Cancel",
          "removingSeller"
        );
      }
      if (
        this.props.type === "Deal Sizing Report For Companies" ||
        this.props.type === "Deal Sizing Report For Private Equity Portfolio"
      ) {
        await this.setState({
          hidePropValues: true,
        });
      }
      // this.isShowTable(false)
    }
    if (errorMessage && errorMessage.length > 0) {
      await this.setState({
        errorMessage: errorMessage,
        errorResponse: true,
        errorcolor: color === "error" ? "red" : "green",
      });
    } else {
      await this.setState({
        errorMessage: errorMessage,
        errorResponse: false,
        errorcolor: color === "error" ? "red" : "green",
      });
    }
    if (type === "selectedSeller" || type === "claimName") {
      await this.setState({
        errorMessage: "",
        errorResponse: false,
      });
    }
  };


  redirectToMostRecentTabwithSelectedRecord(item) {
    this.setState({
      selectedObj: item,
      activeTab: "4",
      openClaimDetails: false,
      isSelectedRow: false,
    });
    // window.scrollTo(0, document.body.scrollHeight);
  }

  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    if (column.field === "Actions") {
      return (
        <span style={{ textTransform: "capitalize" }}>
          <Button
            className="mr-1 my-1"
            color="primary"
            outline
            onClick={() => this.setState({
              selectedObj: item,
              activeTab: "4",
              openClaimDetails: false,
              isSelectedRow: false,
            })}
          >
            {"Open"}
          </Button>
        </span>
      );
    } else if (column.fieldType === "date") {
      return dateFormats.formatDate(item[column.field], "MM/DD/YYYY", "NS");
    } else if (column.field === "reasons") {
      let val = item[column.field]
      return <span data-toggle="tool-tip"
        title={val ? val.join(" , ") : ""}>
        {val ? val.join(" , ") : ""}
      </span >
    } else {
      if (item[column.field] && typeof item[column.field] !== "object") {
        return item[column.field];
      }
    }
  };
  noteSavedStatusFunCheck = async (status) => {
    await this.editdt.commentEditedOrNo(status)
    await this.setState({ noteSavedStatus: status, edited: status })
  }
  getRFIHistoryTable = () => {
    return (
      <div className="disabledSelection">
        <DataTable value={this.state.rfiHistory} emptyMessage={configMessages.noRecords}>
          {this.state.rfiHistorycolumns.map((col, i) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              fieldType={col.fieldType}
              body={this.changeFieldValues}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  onClickClaimDocument = () => {
    if (this.state.selectedRows && this.state.selectedRows.companyID && this.state.selectedRows.oppClaimID) {
      const { companyID, mappedNameID, relevantSellerNameID, stateClaimID, sourceState, oppClaimID } = this.state.selectedRows;
      window.open(`/sellersEdit/claimDocumentation/${companyID}?dealId=${companyID}&mappedNameId=${mappedNameID}&relSellerNameId=${relevantSellerNameID}&stateCode=${sourceState}&stateClaimId=${stateClaimID}&OPPClaimID=${oppClaimID}`)
    }
  }

  render() {
    return (
      this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View" ?

      <span>


        <span
          className={
            this.props.type !== "Deal Sizing-Properties" ||
              window.location.pathname !== "/nspropertiesnew"
              ? "d-block"
              : "d-none"
          }
        >
          <Accordion multiple={true} activeIndex={[0]}>
            <AccordionTab header={this.getAccordionHeader()}>
              <div className="row" style={{ display: "flex" }}>
                <ClaimFilter
                  onRef={(ref) => (this.ClaimFilterref = ref)}
                  type="ClaimsManagement"
                  params={this.state.params}
                  filterCriteria={filter}
                  makeValuesEmptyAfterHittingSaveOrClear={this.makeValuesEmptyAfterHittingSaveOrClear}
                  setSortFilters={this.setSortFilters}
                  // filterCriteria={this.state.filterCriteria}
                  setDepartment={this.setDepartment}
                  setAssetTrackingStatus={this.setAssetTrackingStatus}
                  getremoveFiltersForClaims={this.getremoveFiltersForClaims}
                  getErrorMessage={this.getErrorMessage}
                  setFilterCriteriaOnClear={this.setFilterCriteriaOnClear}
                  getDataFromServer={this.getDataFromServer}
                />
              </div>
            </AccordionTab>
          </Accordion>
        </span>
        <form className="form" style={{ width: "100%" }}>
          {/* {this.state.selectedDealID ? */}
          <div style={{ width: "inherit" }} className="claims-manager">
            {this.getDataTable()}
            {/* {this.getActivitiesHeader()} */}
          </div>
        </form>

        {(this.state.allSelectedRows &&
          this.state.allSelectedRows.length > 0) ||
          (this.state.activeTab &&
            this.state.activeTab !== "close" &&
            this.state.activeTab !== "closeByIcon") ? (
          <div className="claimTab" style={{ width: "100%" }}>
            <Nav tabs style={{ backgroundColor: "#354f6e" }}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Claim Detail
                </NavLink>
              </NavItem>
              {this.state.allSelectedRows &&
                this.state.allSelectedRows.length === 1 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2", "business", "Business");
                      }}
                      disabled={
                        this.state.isTabdisabled || this.state.isChildTrue
                      }
                    >
                      Connected Properties
                    </NavLink>
                  </NavItem>
                )}

              {this.state.allSelectedRows &&
                this.state.allSelectedRows.length === 1 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                        disableClick: !(this.state.selectedRows && this.state.selectedRows.companyID && this.state.selectedRows.oppClaimID),
                        disableVisual: !(this.state.selectedRows && this.state.selectedRows.companyID && this.state.selectedRows.oppClaimID)
                      })}

                      onClick={() => {
                        this.onClickClaimDocument();
                      }}
                    // disabled={true}
                    >
                      Claim Documentation
                    </NavLink>
                  </NavItem>
                )}
              {this.state.allSelectedRows &&
                this.state.allSelectedRows.length === 1 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4", "RFI Active", "RFI Active");
                      }}
                    // disabled={true}
                    >
                      Most Recent RFI
                    </NavLink>
                  </NavItem>
                )}
              {this.state.allSelectedRows &&
                this.state.allSelectedRows.length === 1 && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={() => {
                        this.toggle("5", "RFI History", "RFI History");
                      }}
                    // disabled={true}
                    >
                      All RFI
                    </NavLink>
                  </NavItem>
                )}
              <div style={{ marginLeft: "auto" }}>
                <FontAwesomeIcon
                  className={"tableheadericon"}
                  color="#354f6e"
                  style={{
                    marginTop: "13px",
                    marginRight: "6px",
                    color: "white",
                  }}
                  onClick={() => {
                    this.toggle("closeByIcon");
                  }}
                  icon={faTimesCircle}
                />
                {/* <Button style={{ backgroundColor: "Blue", color: "white", marginBottom: "1px" }}  >{"Claim Builder"}</Button>
                <Button className="mr-1" style={{ backgroundColor: "Blue", color: "white" }}  >{"Save"}</Button> */}
              </div>

              {/* <span className="topbar__centerresponse topBarImageAlignment" >{this.state.successResponse ? this.state.successMessage : null}</span> */}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {this.state.activeTab === "1" ||
                (this.state.isSelectedRow && this.state.emptyselectedRows) ||
                this.state.openClaimDetails ? (
                <TabPane tabId={this.state.activeTab}>

                  <ClaimDetails
                    onRef={(ref) => (this.claimDetailsRef = ref)}
                    selectedObj={this.state.basicCreateObj}
                    taskID={this.state.recentTaskID}
                    commentEditedOrNo={this.commentEditedOrNo}
                    details={this.state.selectedRows}
                    screenPermissions={this.state.screenPermissions}
                    setNewRfiClaim={this.setNewRfiClaim}
                    saveClaim={this.saveClaim}
                    setCompanyId={this.setCompanyId}
                    setSellerNickName={this.setSellerNickName}
                    setLoading={this.setLoading}
                    openNewClaim={this.state.openNewClaim}
                    isSelectedRow={this.state.isSelectedRow}
                    allSelectedRows={this.state.allSelectedRows}
                    getErrorResponsefromServer={this.getErrorResponsefromServer}
                    dealId={
                      this.state.Object1 && this.state.Object1.selectedClaims
                        ? this.state.Object1.selectedClaims : this.bookMarkDealID ? { EstateID: this.bookMarkDealID }
                          : null
                    }
                    type={
                      this.state.selectedRows &&
                        this.state.selectedRows.length > 0
                        ? "Edit"
                        : "Add"
                    }
                    from={"claimsManager"}
                  />
                </TabPane>
              ) : null}
              {this.state.activeTab === "2" ? (
                <TabPane tabId={this.state.activeTab}>
                  <PropertyDetails
                    details={this.state.selectedProperties}
                    Rowdetails={this.state.selectedRows}
                    selectedDealID={this.state.selectedDealID}
                    showMessage={this.showMessage}
                    downLoadExcel={this.downLoadExcel}
                    setHitUpdateButtonFromPropertyDetails={this.setHitUpdateButtonFromPropertyDetails}
                    toggle={this.toggle}
                    Object1={this.state.Object1}
                    filter={filter}
                    getDataFromServer={this.getDataFromServer}
                    isSelectedRow={this.state.isSelectedRow}
                    selectedDealName={this.state.selectedDealName}

                  // saveClaim={this.saveClaim}
                  />
                </TabPane>
              ) : null}
              {this.state.activeTab === "3" ? (
                <TabPane tabId={this.state.activeTab}>
                  <ClaimDocumentation
                    Rowdetails={this.state.selectedRows}
                    selectedDealID={this.state.selectedDealID}
                    sellerNickName={this.state.sellerNickName}
                    dealType={this.state.dealType}
                  />
                </TabPane>
              ) : null}

              {this.state.activeTab === "4" ?
                Object.keys(this.state.selectedObj).length > 0 ? (
                  <TabPane tabId={this.state.activeTab}>
                    <RFIForm
                      onRef={(ref) => (this.rfiFormRef = ref)}
                      screenPermissions={this.state.screenPermissions}
                      selectedObj={this.state.selectedObj} noteSavedStatusFunCheck={this.noteSavedStatusFunCheck} type="tab" formType="edit" getRFIRecentconnectedTask={this.getRFIRecentconnectedTask} />
                  </TabPane>
                ) : configMessages.NO_RFI_TO_CLAIM
                : null}
              {this.state.activeTab === "5" ?
                this.state.rfiHistory.length > 0 ? (
                  <TabPane tabId={this.state.activeTab}>
                    {this.getRFIHistoryTable()}
                  </TabPane>
                ) : configMessages.NO_RFI_TO_CLAIM
                : null}
            </TabContent>
          </div>
        ) : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        {this.state.openUnsaveModal ? this.getDeleteRowModal() : null}
        {this.state.isOpengetConfirmaionModel ? this.getConfirmaionModel() : null}
        {this.state.isOpenSaveBookMark ? this.openSaveBookMarkModal() : null}
        {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
        <Loader loader={this.state.isBookMarkLoading || this.state.isLoading} />
      </span>:this.state.screenPermissions===""?null
      :<NoViewComponent/>
                    
    );
  }
}
