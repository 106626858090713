import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class PropCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'ValidatedProperties',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions["StatusReason Screen"] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
  }

  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      data.push(
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "fieldType": "Actions",
          "field": "Actions",
          //"header": "Delete",
          "filter": false,
          // "displayInSettings": true,
          "sortable": false
        },

      )
    }
    return data;
  }

  //user Task label options
  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'BusinessName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Business Name',
        filter: true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'ValidatedReadyToFile',
        // fieldType: 'BoolText',
        "mobile": true,
        "allowInt": true,
        filterType: "num",
        header: 'Validated-Ready to File',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        "allowInt": true,
        filterType: "num",
        field: 'Filed',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Filed',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'Paid',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Paid',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'ValidatedSellerNegativeNotice',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Validated Seller Negative Notice',
        filter: false,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 80,
      //   field: 'propertyCount',
      //   fieldType: 'number',
      //   "mobile": true,
      //   header: 'Number of Properties',
      //   filter: false,
      //   sortable: true,
      // },

    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "reason",
        "label": "Reason",
        "id": "name",
        "placeholder": "Reason Name",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "status",
        "label": "Status",
        "options": this.state.Statuses,
        "id": "status",
        "placeholder": "status",
        "required": true
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': Number,
      //   "value": "",
      //   "type": "text",
      //   "formatType": "Number",
      //   "name": "sequence",
      //   "label": "Sequence",
      //   // "options": this.state.company,
      //   // "id": "name",
      //   "placeholder": "Sequence",
      //   // "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      this.props.EstateID ?
        <span>
          <Loader loader={this.state.isLoading} />
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            dontShowTitle={true}
            isClearFilters={true}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            exportRequried={false}
            printRequried={false}
            sortField={"BusinessName"}
            sortDirection={"asc"}
            criteria={this.props.criteria}
            statsUrl={"NsScoredProperties/ValidatedStats"}
            sellerID={this.props.EstateID}
            addRequried={true}
            editRequired={true}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            activeTab={"1"}
            sample={false}
            activeTab={"PropCount"}
            isTabView={true}
            isShownnFieldType={true}
            expandTableFields={this.expandTableFields}
            // globalSearchFieldName='user'
            // globalSearch={'Search'}
            type='Business Names Prop Count View'
            displayright={true}
            icon='user'
            viewBy={true}
            routeTo='properties'
            displayViewOfForm='modal'
            apiResponseKey='result'
            apiUrl={"NsScoredProperties/SummaryReportCount"}
            entityType='employee'
          />
        </span>
        : null
    );
  }
}